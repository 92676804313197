import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../../../context/appContext";
import {
  ApiResponseInfoProveedor,
  BankAccountProveedor,
  DaysOperation,
  ContactProveedor,
  EntryData,
  EntryDataResponse,
  EntryService,
  FileDataOPI,
  InitialContextOP,
  OperationRequestDocumentsI,
  ProviderInterfaceOP,
  QuoteItemI,
  extraerBase64,
  isBase64,
} from "../InterfacesOperacion/OperacionesI";
import { Link, useNavigate } from "react-router-dom";
import edit from "../../../../images/edit.png";
import DesgloceAlimentos from "./DesgloceAlimentos";
import { OperationContext } from "../../../../context/operationContext";
import DesgloceDolares from "./DesgloceDolares";
import guardardatos from "../../../../images/GUARDARDATOS.png";
import HeaderOpRead from "../HeaderOpRead";
import { calcularNumeroDeNoches } from "../functionsOP";
import UploadDocuments from "./UploadDocuments";
import { contratoContextInicial } from "../../../../context/initialContext";

export default function ResumenDesgloce() {
  const [isEditionMode, setIsEditionMode] = useState<boolean>(() => {
    const grupo = localStorage.getItem("groupUsuario");

    return grupo !== null && parseInt(grupo) !== 1018 ? true : false;
  });
  const navigate = useNavigate();

  const {
    currentOperation,
    setCurrentOperation,
    currentEntrada,
    setCurrentEntrada,
    providerTypeOperacion,
    setProviderTypeOperacion,
  } = useContext(OperationContext);

  const {
    dataCotizacionContext,
    currentUser,
    contratoContext,
    setContratoContext,
  } = useContext(AppContext);

  const [desgloceGeneralInfo, setDesgloceGeneralInfo] = useState<EntryData>({
    ...currentEntrada.entry_data,
    sgl:
      currentEntrada.entry_data.sgl == 0
        ? currentOperation.quote.sgl
        : currentEntrada.entry_data.sgl,
    dbl:
      currentEntrada.entry_data.dbl == 0
        ? currentOperation.quote.dbl
        : currentEntrada.entry_data.dbl,
    tpl:
      currentEntrada.entry_data.tpl == 0
        ? currentOperation.quote.tpl
        : currentEntrada.entry_data.tpl,
    cpl:
      currentEntrada.entry_data.cpl == 0
        ? currentOperation.quote.cpl
        : currentEntrada.entry_data.cpl,
  });

  const [listContactosProveedor, setListContactoProveedor] = useState<
    ContactProveedor[]
  >([]);
  const [listBankAccounts, setListBanckAccounts] = useState<
    BankAccountProveedor[]
  >([]);
  const [listDays, setListDays] = useState<DaysOperation[]>([]);

  const getExtraInfoProvider = async (idProveedor: number) => {
    if (!idProveedor) {
      return;
    }

    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified =
      backEndPoint + `/operations/getProviderData/${idProveedor}`;
    try {
      const response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        // //console.log(response);
        // //console.log("bien");
        const data: ApiResponseInfoProveedor = await response.json();

        //console.log("VALOR DE LA CONSULTA DE DATOS DE PROVEEDOR:");
        //console.log(data.data.bank_account_list);
        //console.log(data.data.business_name);
        //console.log(data.data.contact_list);
        //console.log(dataCotizacionContext);
        setListContactoProveedor(data.data.contact_list);
        setListBanckAccounts(data.data.bank_account_list);
      } else {
      }
    } catch (error) {
      alert("ERROR AL REALIZAR LA SOLICITUD");
    }
  };

  const saveEntrada = async () => {
    saveDocuments();

    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/entryUpdate`;
    const bodyRequest: InitialContextOP = {
      entry_data: {
        entry_identifier: currentEntrada.entry_data.entry_identifier,
        operation_identifier: currentEntrada.entry_data.operation_identifier,
        provider_identifier: currentEntrada.entry_data.provider_identifier,
        status_identifier: 1,
        entry_number: currentEntrada.entry_data.entry_number,
        initial_date: currentEntrada.entry_data.initial_date,
        final_date: currentEntrada.entry_data.final_date,
        sgl: currentEntrada.entry_data.sgl,
        dbl: currentEntrada.entry_data.dbl,
        tpl: currentEntrada.entry_data.tpl,
        cpl: currentEntrada.entry_data.cpl,
        adults: currentEntrada.entry_data.adults,
        childs: currentEntrada.entry_data.childs,
        confirmation_number: currentEntrada.entry_data.confirmation_number,
        creator_user_identifier:
          currentEntrada.entry_data.creator_user_identifier,
        contact1_identifier: desgloceGeneralInfo.contact1_identifier,
        contact2_identifier: desgloceGeneralInfo.contact2_identifier,
        guide_identifier: currentEntrada.entry_data.guide_identifier,
        payday_limit: desgloceGeneralInfo.payday_limit,
        comment: desgloceGeneralInfo.comment,
        total_mxn:
          currentEntrada.entry_data.entry_number == 1
            ? currentEntrada.entry_data.total_without_advance_mxn +
              currentEntrada.entry_data.advance_mxn
            : 0,
        services_tips_mxn: currentEntrada.entry_data.services_tips_mxn,
        lodging_taxes_mxn: currentEntrada.entry_data.lodging_taxes_mxn,
        iva_mxn: currentEntrada.entry_data.iva_mxn,
        advance_mxn: currentEntrada.entry_data.advance_mxn,
        total_usd:
          currentEntrada.entry_data.entry_number == 1
            ? currentEntrada.entry_data.total_without_advance_usd +
              currentEntrada.entry_data.advance_usd
            : 0,
        services_tips_usd: currentEntrada.entry_data.services_tips_usd,
        lodging_taxes_usd: currentEntrada.entry_data.lodging_taxes_usd,
        iva_usd: currentEntrada.entry_data.iva_usd,
        advance_usd: currentEntrada.entry_data.advance_usd,
        guide_courtesy: currentEntrada.entry_data.guide_courtesy,
        provider_name: currentEntrada.entry_data?.provider_name,
        total_without_advance_usd:
          currentEntrada.entry_data.total_without_advance_usd,
        total_without_advance_mxn:
          currentEntrada.entry_data.total_without_advance_mxn,
        creator_user: currentEntrada.entry_data.creator_user,
      },
      entry_services_list: {
        mxn_services_list: currentEntrada.entry_services_list.mxn_services_list,
        usd_services_list: currentEntrada.entry_services_list.usd_services_list,
      },
    };

    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        body: JSON.stringify(bodyRequest),
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data);

        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION

        navigate("/dashboard/Operaciones/Definicion-Prestador");
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al guardar");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al guardar");
      console.error("Error in request:", error);

      alert("Error en el servicio");
      // setShowLoader(false);
    }
  };

  ////////////////Guardar archivos

  const saveDocuments = async () => {
    //console.log("Save documents");
    //console.log("LISTA DE CONTEXTO");
    //console.log(contratoContext);
    const listDocumentsToSend: FileDataOPI[] = contratoContext.files
      .map((currentDocument, index) => {
        const documentoOperacion: FileDataOPI = {
          file: currentDocument.file,
          file_extension: currentDocument.file_extension,
          file_name: currentDocument.fileName,
        };
        return documentoOperacion;
      })
      .filter((documento, index) => {
        return isBase64(documento.file);
      });

    const bodyRequest: OperationRequestDocumentsI = {
      entry_identifier: currentEntrada.entry_data.entry_identifier,
      operation_identifier: currentOperation.quote.quote_identifier,
      files: listDocumentsToSend,
    };
    //console.log("Esta será la petición a enviar para guardar documentos:");
    //console.log(bodyRequest);

    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/UploadEntryFiles`;

    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        body: JSON.stringify(bodyRequest),
      });

      if (response.ok) {
        const data = await response.json();
        ////??? SE ENVIARON LOS ARCCHIVOS, para eso es esta alerta

        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION
        setContratoContext(contratoContextInicial);

        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al guardar archivos");
      }
    } catch (error) {
      alert("Error al guardar");
      console.error("Error in request:", error);

      alert("Error en el servicio");
    }
  };

  const getDaysServices = async (idOperacion) => {
    try {
      // Realiza la solicitud HTTP GET al endpoint
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/daysservices/${idOperacion}`,
        {
          method: "GET", // Método HTTP
          headers: {
            "Content-Type": "application/json", // Cabeceras de la solicitud
          },
        }
      );

      // Verifica si la respuesta es exitosa (código 200-299)
      if (!response.ok) {
        throw new Error("Error al obtener los datos");
      }

      // Convierte la respuesta a JSON
      const data: DaysOperation[] = await response.json();
      setListDays(data);
    } catch (error) {
      // Maneja cualquier error que ocurra durante la solicitud
      console.error("Error:", error);
      throw error; // Relanza el error para que pueda ser manejado por el llamador
    }
  };

  //////////////INICIALIZAR VARIABLES
  const calcularServicios = () => {
    const listaUSD: EntryService[] =
      currentEntrada.entry_services_list.usd_services_list.map(
        (currentService, index) => {
          let cantidadDos = 1;
          let cantidadUno = 1;

          //////////////////////////SERVICE NAME OR OCCUPATION////////////////////////
          const ocuppationOrName =
            currentService.provider_type_identifier == 11 &&
            currentService.occupation !== "N/A" &&
            currentService.provider_type_identifier == 11 &&
            currentService.occupation !== ""
              ? currentService.occupation
              : currentService.service_name;

          ///////////////////////////////////////END SERVICE NAME OR OCUPPATION

          /////////////////////////////QUANTITY1

          const numberRooms =
            (currentEntrada.entry_data.sgl == 0
              ? currentOperation.quote.sgl
              : currentEntrada.entry_data.sgl) +
            (currentEntrada.entry_data.dbl == 0
              ? currentOperation.quote.dbl
              : currentEntrada.entry_data.dbl) +
            (currentEntrada.entry_data.tpl == 0
              ? currentOperation.quote.tpl
              : currentEntrada.entry_data.tpl) +
            (currentEntrada.entry_data.cpl == 0
              ? currentOperation.quote.cpl
              : currentEntrada.entry_data.cpl);
          ////Cuidado con ese 11, no se supone que el servicio tipo habitación es 10?
          if (currentService.provider_type_identifier == 11) {
            cantidadUno =
              currentService.occupation == "SGL"
                ? currentOperation.quote.sgl
                : currentService.occupation == "DBL"
                ? currentOperation.quote.dbl
                : currentService.occupation == "TPL"
                ? currentOperation.quote.tpl
                : currentService.occupation == "CPL"
                ? currentOperation.quote.cpl
                : currentService.occupation == "BB"
                ? 1
                : currentService.occupation == "MAID"
                ? numberRooms
                : currentOperation.quote.pax;
          } else if (
            currentService.provider_type_identifier == 20 ||
            currentService.provider_type_identifier == 1
          ) {
            cantidadUno = currentOperation.quote.pax;
          }
          /////////////////////////END QUANTITY 1
          //////////////////////////QUANTITY 2
          const numberDays = calcularNumeroDeNoches(
            currentEntrada.entry_data.initial_date,
            currentEntrada.entry_data.final_date
          );
          ////Cantidad 2 cambia para habitación:
          //console.log("OCUPACION");
          //console.log(currentService);
          //console.log(currentEntrada);
          //console.log("OCUPACION FIN");

          if (currentService.provider_type_identifier == 11) {
            if (currentService.occupation == "BB") {
              cantidadDos = currentOperation.quote.pax;
            } else {
              cantidadDos = numberDays == 0 ? 1 : numberDays;
            }
          }

          if (
            currentService.quantity1 !== 0 &&
            currentService.quantity1 !== 1
          ) {
            cantidadUno = currentService.quantity1;
            const filteredDays = listDays.filter(
              (day) =>
                day.ProovedorID == currentEntrada.entry_data.provider_identifier
            );

            //cambiamos la cantidad de dias para que no considere servicios como noches de hotel
            if (filteredDays.length > 0) {
              cantidadDos = filteredDays[0].Dias;
            } else {
              cantidadDos = currentService.quantity2;
            }
          }
          return {
            ...currentService,
            service_name: ocuppationOrName,
            quantity1: cantidadUno,
            quantity2: cantidadDos,
          };
        }
      );

    const listaMXN: EntryService[] =
      currentEntrada.entry_services_list.mxn_services_list.map(
        (currentService, index) => {
          //console.log("OCUPACION");
          //console.log(currentService);
          //console.log(currentEntrada);
          //console.log("OCUPACION FIN");

          let cantidadDos = 1;
          let cantidadUno = 1;
          const ocuppationOrName =
            currentService.provider_type_identifier == 11 &&
            currentService.occupation !== "N/A"
              ? currentService.occupation
              : currentService.service_name;

          const numberRooms =
            (currentEntrada.entry_data.sgl == 0
              ? currentOperation.quote.sgl
              : currentEntrada.entry_data.sgl) +
            (currentEntrada.entry_data.dbl == 0
              ? currentOperation.quote.dbl
              : currentEntrada.entry_data.dbl) +
            (currentEntrada.entry_data.tpl == 0
              ? currentOperation.quote.tpl
              : currentEntrada.entry_data.tpl) +
            (currentEntrada.entry_data.cpl == 0
              ? currentOperation.quote.cpl
              : currentEntrada.entry_data.cpl);

          if (currentService.provider_type_identifier == 11) {
            cantidadUno =
              currentService.occupation == "SGL"
                ? currentOperation.quote.sgl
                : currentService.occupation == "DBL"
                ? currentOperation.quote.dbl
                : currentService.occupation == "TPL"
                ? currentOperation.quote.tpl
                : currentService.occupation == "BB"
                ? 1
                : currentService.occupation == "MAID"
                ? numberRooms
                : currentOperation.quote.pax;
          } else if (
            currentService.provider_type_identifier == 20 ||
            currentService.provider_type_identifier == 1 ||
            currentService.provider_type_identifier == 6
          ) {
            cantidadUno = currentOperation.quote.pax;
          }
          /////////////////////////END QUANTITY 1
          //////////////////////////QUANTITY 2
          // opcion vieja queda descartado ya que considera servicios que no son de hotel
          const numberDays = calcularNumeroDeNoches(
            currentEntrada.entry_data.initial_date,
            currentEntrada.entry_data.final_date
          );

          //console.log("-----------------  dias -----------------------------------------------------");
          //console.log(currentOperation);
          //console.log(currentEntrada);
          //console.log(providerTypeOperacion);
          //console.log("------------------- dias fin ---------------------------------------------");
          if (currentService.provider_type_identifier == 11) {
            if (currentService.occupation == "BB") {
              cantidadDos = currentOperation.quote.pax;
            } else {
              cantidadDos = numberDays == 0 ? 1 : numberDays;
            }
          }

          if (
            currentService.quantity1 !== 0 &&
            currentService.quantity1 !== 1
          ) {
            cantidadUno = currentService.quantity1;

            const filteredDays = listDays.filter(
              (day) =>
                day.ProovedorID == currentEntrada.entry_data.provider_identifier
            );

            //cambiamos la cantidad de dias para que no considere servicios como noches de hotel
            if (filteredDays.length > 0) {
              cantidadDos = filteredDays[0].Dias;
            } else {
              cantidadDos = currentService.quantity2;
            }
          }

          return {
            ...currentService,
            service_name: ocuppationOrName,
            quantity1: cantidadUno,
            quantity2: cantidadDos,
          };
        }
      );

    const totalTarifaMXN = listaMXN.reduce((total, item) => {
      //console.log("CALCULOS::::");
      //console.log("quantity1: ",item.quantity1,"quantity2: ",item.quantity2,"unitary_fee: ",item.unitary_fee);
      //console.log(total + item.quantity1 * item.quantity2 * item.unitary_fee);
      return total + item.quantity1 * item.quantity2 * item.unitary_fee;
    }, 0);
    const totalTarifaUSD = listaUSD.reduce((total, item) => {
      //console.log("CALCULOS::::");
      //console.log("quantity1: ",item.quantity1,"quantity2: ",item.quantity2,"unitary_fee: ",item.unitary_fee);
      //console.log(total + item.quantity1 * item.quantity2 * item.unitary_fee);
      return total + item.quantity1 * item.quantity2 * item.unitary_fee;
    }, 0);

    const totalSinAnticipoMXN =
      totalTarifaMXN - currentEntrada.entry_data.advance_mxn;

    const totalSinAnticipoUSD =
      totalTarifaUSD - currentEntrada.entry_data.advance_usd;

    const initialEntryData: EntryData = {
      ...currentEntrada.entry_data,
      total_mxn: totalTarifaMXN,
      total_usd: totalTarifaUSD,
      total_without_advance_mxn: totalSinAnticipoMXN,
      total_without_advance_usd: totalSinAnticipoUSD,
    };
    //console.log("Datos modificados::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::");
    //console.log(initialEntryData);
    setCurrentEntrada({
      ...currentEntrada,
      entry_data: initialEntryData,
      entry_services_list: {
        mxn_services_list: listaMXN,
        usd_services_list: listaUSD,
      },
    });
  };

  const printDocument = () => {
    window.print();
  };

  const [grupoActual, setGrupoActual] = useState(() => {
    const grupo = localStorage.getItem("groupUsuario");

    return grupo !== null ? parseInt(grupo) : currentUser.group_identifier;
  });

  useEffect(() => {
    getDaysServices(currentEntrada.entry_data.operation_identifier);
    calcularServicios();
  }, [currentEntrada]);

  useEffect(() => {
    getExtraInfoProvider(desgloceGeneralInfo.provider_identifier);
  }, [desgloceGeneralInfo]);

  useEffect(() => {
    setCurrentEntrada({
      ...currentEntrada,
      entry_data: {
        ...currentEntrada.entry_data,
        sgl: desgloceGeneralInfo.sgl,
        dbl: desgloceGeneralInfo.dbl,
        tpl: desgloceGeneralInfo.tpl,
        cpl: desgloceGeneralInfo.cpl,
        initial_date: desgloceGeneralInfo.initial_date,
        final_date: desgloceGeneralInfo.final_date,
        guide_courtesy: desgloceGeneralInfo.guide_courtesy,
        childs: desgloceGeneralInfo.childs,
        confirmation_number: desgloceGeneralInfo.confirmation_number,
        contact1_identifier: desgloceGeneralInfo.contact1_identifier,
        contact2_identifier: desgloceGeneralInfo.contact2_identifier,
        payday_limit: desgloceGeneralInfo.payday_limit,
      },
    });
  }, [desgloceGeneralInfo]);

  return (
    <ResumenDesloceStyle>
      <HeaderOpRead />

      <h3 className="title-desglose-info">Desglose</h3>

      <div className="file-1 title-header-entrada">
        <h3>
          {desgloceGeneralInfo?.provider_name} - Entrada{" "}
          {desgloceGeneralInfo.entry_number}
        </h3>
      </div>
      <div className="file-2">
        <div className="date-container">
          <h3>Fecha Inicial:</h3>
          {isEditionMode ? (
            <input
              type="date"
              placeholder="DD/MM/YYYY"
              onChange={(e) => {
                setDesgloceGeneralInfo({
                  ...desgloceGeneralInfo,
                  initial_date: e.target.value,
                });
              }}
              value={desgloceGeneralInfo.initial_date}
            />
          ) : (
            <h3>{desgloceGeneralInfo.initial_date}</h3>
          )}
        </div>
        <div className="date-container">
          <h3>Fecha Final:</h3>
          {isEditionMode ? (
            <input
              type="date"
              onChange={(e) => {
                setDesgloceGeneralInfo({
                  ...desgloceGeneralInfo,
                  final_date: e.target.value,
                });
              }}
              value={desgloceGeneralInfo.final_date}
            />
          ) : (
            <h3>{" " + desgloceGeneralInfo.final_date}</h3>
          )}
        </div>
        {grupoActual !== 1018 && (
          <div className="pencil non-printable-section">
            <img
              src={edit}
              alt=""
              width="20"
              height="20"
              onClick={() => setIsEditionMode(!isEditionMode)}
            />
          </div>
        )}
      </div>
      <div className="file-3">
        <div>
          <h3>SGL</h3>
          {isEditionMode ? (
            <input
              type="text"
              value={desgloceGeneralInfo.sgl}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setDesgloceGeneralInfo({
                    ...desgloceGeneralInfo,
                    sgl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setDesgloceGeneralInfo({
                      ...desgloceGeneralInfo,
                      sgl: parsedValue,
                    });
                  }
                }
              }}
            />
          ) : (
            <h3>{desgloceGeneralInfo.sgl}</h3>
          )}
        </div>
        <div>
          <h3>DBL:</h3>
          {isEditionMode ? (
            <input
              type="text"
              value={desgloceGeneralInfo.dbl}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setDesgloceGeneralInfo({
                    ...desgloceGeneralInfo,
                    dbl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setDesgloceGeneralInfo({
                      ...desgloceGeneralInfo,
                      dbl: parsedValue,
                    });
                  }
                }
              }}
            />
          ) : (
            <h3>{desgloceGeneralInfo.dbl}</h3>
          )}
        </div>
        <div>
          <h3>TPL:</h3>
          {isEditionMode ? (
            <input
              type="text"
              value={desgloceGeneralInfo.tpl}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setDesgloceGeneralInfo({
                    ...desgloceGeneralInfo,
                    tpl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setDesgloceGeneralInfo({
                      ...desgloceGeneralInfo,
                      tpl: parsedValue,
                    });
                  }
                }
              }}
            />
          ) : (
            <h3>{desgloceGeneralInfo.tpl}</h3>
          )}
        </div>
        <div>
          <h3>CPL:</h3>
          {isEditionMode ? (
            <input
              type="text"
              value={desgloceGeneralInfo.cpl}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setDesgloceGeneralInfo({
                    ...desgloceGeneralInfo,
                    cpl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setDesgloceGeneralInfo({
                      ...desgloceGeneralInfo,
                      cpl: parsedValue,
                    });
                  }
                }
              }}
            />
          ) : (
            <h3>{desgloceGeneralInfo.cpl}</h3>
          )}
        </div>
        <div>
          <h3>Cortesia guía:</h3>
          {isEditionMode ? (
            <input
              type="text"
              value={desgloceGeneralInfo.guide_courtesy}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setDesgloceGeneralInfo({
                    ...desgloceGeneralInfo,
                    guide_courtesy: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setDesgloceGeneralInfo({
                      ...desgloceGeneralInfo,
                      guide_courtesy: parsedValue,
                    });
                  }
                }
              }}
            />
          ) : (
            <h3>Texto</h3>
          )}
        </div>
        <div>
          <h3>Adultos:</h3>

          <h3>{currentOperation.quote.pax}</h3>
        </div>
        <div>
          <h3>Menores:</h3>
          {isEditionMode ? (
            <input
              type="text"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setDesgloceGeneralInfo({
                    ...desgloceGeneralInfo,
                    childs: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setDesgloceGeneralInfo({
                      ...desgloceGeneralInfo,
                      childs: parsedValue,
                    });
                  }
                }
              }}
              value={desgloceGeneralInfo.childs}
            />
          ) : (
            <h3>{desgloceGeneralInfo.childs}</h3>
          )}
        </div>
        <div></div>
      </div>
      <div className="file-4">
        <div>
          <h3>Número de confirmación</h3>
          {isEditionMode ? (
            <input
              type="text"
              onChange={(e) => {
                setDesgloceGeneralInfo({
                  ...desgloceGeneralInfo,
                  confirmation_number: e.target.value,
                });
              }}
              value={desgloceGeneralInfo.confirmation_number}
            />
          ) : (
            <h3>{desgloceGeneralInfo.confirmation_number}</h3>
          )}
        </div>
        <div>
          <h3>Empleado: </h3>
          <h3>{desgloceGeneralInfo.creator_user}</h3>
        </div>
      </div>
      <div className="file-5">
        <div className="container-combo">
          <h3>Contacto: </h3>
          {isEditionMode ? (
            <SelectListdefault
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
              id="sl6"
              onChange={(e) => {
                setDesgloceGeneralInfo({
                  ...desgloceGeneralInfo,
                  contact1_identifier: parseInt(e.target.value),
                });
              }}
              value={desgloceGeneralInfo.contact1_identifier || ""}
            >
              <option value="" disabled selected>
                Contactos
              </option>

              {listContactosProveedor.length > 0
                ? listContactosProveedor.map((item) => {
                    return (
                      <option
                        key={item.contact_identifier}
                        value={item.contact_identifier}
                      >
                        {item.contact_name}
                      </option>
                    );
                  })
                : null}
            </SelectListdefault>
          ) : (
            listContactosProveedor.find(
              (proveedor, index) =>
                proveedor.contact_identifier ==
                desgloceGeneralInfo.contact1_identifier
            )?.contact_name
          )}
        </div>
        <div>
          <h3>Contacto 2: </h3>
          {isEditionMode ? (
            <SelectListdefault
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
              id="sl6"
              onChange={(e) => {
                setDesgloceGeneralInfo({
                  ...desgloceGeneralInfo,
                  contact2_identifier: parseInt(e.target.value),
                });
              }}
              value={desgloceGeneralInfo.contact2_identifier || ""}
            >
              <option value="" disabled selected>
                Contactos
              </option>

              {listContactosProveedor.length > 0
                ? listContactosProveedor.map((item) => {
                    return (
                      <option
                        key={item.contact_identifier}
                        value={item.contact_identifier}
                      >
                        {item.contact_name}
                      </option>
                    );
                  })
                : null}
            </SelectListdefault>
          ) : (
            listContactosProveedor.find(
              (proveedor, index) =>
                proveedor.contact_identifier ==
                desgloceGeneralInfo.contact2_identifier
            )?.contact_name
          )}
        </div>
      </div>
      <div className="file-5">
        <div>
          <h3>Correo contacto: </h3>
          <h3>
            {listContactosProveedor.find((proveedor, index) => {
              return (
                proveedor.contact_identifier ==
                desgloceGeneralInfo.contact1_identifier
              );
            })?.contact_email ?? ""}{" "}
          </h3>
        </div>
        <div>
          <h3>Correo contacto 2: </h3>
          <h3>
            {listContactosProveedor.find((proveedor, index) => {
              return (
                proveedor.contact_identifier ==
                desgloceGeneralInfo.contact2_identifier
              );
            })?.contact_email ?? ""}
          </h3>
        </div>
      </div>
      <div className="file-6">
        <div>
          <h3>Fecha Limite de Pago</h3>
          {isEditionMode ? (
            <input
              type="date"
              onChange={(e) => {
                setDesgloceGeneralInfo({
                  ...desgloceGeneralInfo,
                  payday_limit: e.target.value,
                });
              }}
              value={desgloceGeneralInfo.payday_limit}
            />
          ) : (
            <h3>{desgloceGeneralInfo.payday_limit}</h3>
          )}
        </div>
      </div>
      <div className="file-7">
        <div>
          <h3>Comentarios</h3>
        </div>
        <div>
          {isEditionMode ? (
            <input
              type="text"
              onChange={(e) => {
                setDesgloceGeneralInfo({
                  ...desgloceGeneralInfo,
                  comment: e.target.value,
                });
              }}
              value={desgloceGeneralInfo.comment}
            />
          ) : (
            <h3>{desgloceGeneralInfo.comment}</h3>
          )}
        </div>
      </div>

      {/*  HOTEL*/}
      {currentEntrada.entry_services_list.mxn_services_list.length > 0 &&
        providerTypeOperacion == 11 && (
          <DesgloceAlimentos
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Habitación"
            cantidad1="Acomodo"
            cantidad2="Noches"
          />
        )}
      {currentEntrada.entry_services_list.usd_services_list.length > 0 &&
        providerTypeOperacion == 11 && (
          <DesgloceDolares
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Habitación"
            cantidad1="Acomodo"
            cantidad2="Noches"
          />
        )}

      {/* Restaurante */}
      {currentEntrada.entry_services_list.mxn_services_list.length > 0 &&
        providerTypeOperacion == 20 && (
          <DesgloceAlimentos
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Alimentos"
            cantidad1="No PAX"
            cantidad2="Cantidad"
          />
        )}
      {currentEntrada.entry_services_list.usd_services_list.length > 0 &&
        providerTypeOperacion == 20 && (
          <DesgloceDolares
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Alimentos"
            cantidad1="No PAX"
            cantidad2="Cantidad"
          />
        )}

      {/* Agencias */}
      {currentEntrada.entry_services_list.mxn_services_list.length > 0 &&
        providerTypeOperacion == 1 && (
          <DesgloceAlimentos
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Agencia"
            cantidad1="No PAX"
            cantidad2="Cantidad"
          />
        )}
      {currentEntrada.entry_services_list.usd_services_list.length > 0 &&
        providerTypeOperacion == 1 && (
          <DesgloceDolares
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Agencia"
            cantidad1="No PAX"
            cantidad2="Cantidad"
          />
        )}

      {/* Transporte */}
      {currentEntrada.entry_services_list.mxn_services_list.length > 0 &&
        providerTypeOperacion == 24 && (
          <DesgloceAlimentos
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Transporte"
            cantidad1="Cantidad"
            cantidad2="Servicio"
          />
        )}
      {currentEntrada.entry_services_list.usd_services_list.length > 0 &&
        providerTypeOperacion == 24 && (
          <DesgloceDolares
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Transporte"
            cantidad1="Cantidad"
            cantidad2="Servicio"
          />
        )}

      {/*Gastos a comprobar*/}
      {currentEntrada.entry_services_list.mxn_services_list.length > 0 &&
        providerTypeOperacion == 6 && (
          <DesgloceAlimentos
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Gastos a comprobar"
            cantidad1="No PAX"
            cantidad2="Cantidad"
          />
        )}
      {currentEntrada.entry_services_list.usd_services_list.length > 0 &&
        providerTypeOperacion == 6 && (
          <DesgloceDolares
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Gastos a comprobar"
            cantidad1="No PAX"
            cantidad2="Cantidad"
          />
        )}

      {/* Honorarios guía */}
      {currentEntrada.entry_services_list.mxn_services_list.length > 0 &&
        (providerTypeOperacion == 7 ||
          providerTypeOperacion == 8 ||
          providerTypeOperacion == 10) && (
          <DesgloceAlimentos
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Honorarios Guía"
            cantidad1="Cantidad"
            cantidad2="Servicio"
          />
        )}
      {currentEntrada.entry_services_list.usd_services_list.length > 0 &&
        (providerTypeOperacion == 7 ||
          providerTypeOperacion == 8 ||
          providerTypeOperacion == 10) && (
          <DesgloceDolares
            isEditionMode={isEditionMode}
            setIsEditionMode={setIsEditionMode}
            tableTitle="Honorarios Guía"
            cantidad1="Cantidad"
            cantidad2="Servicio"
          />
        )}
      {currentOperation.quote.status_identifier == 1 &&
        grupoActual !== 1018 && (
          <div className="non-printable-section">
            <div
              className="buttons "
              style={{ backgroundColor: "transparent" }}
            >
              <img
                src={guardardatos}
                alt="leavePage"
                width="45px"
                onClick={saveEntrada}
                style={{ cursor: "pointer", marginBottom: "1rem" }}
              />
            </div>
          </div>
        )}

      <div className="non-printable-section">
        <div className="print-box">
          <button onClick={printDocument}>Imprimir</button>
        </div>

        <div className="non-printable-section">
          <UploadDocuments />
        </div>
      </div>
    </ResumenDesloceStyle>
  );
}

const ResumenDesloceStyle = styled.div`
  .title-header-entrada {
    background-color: orange;
  }
  .title-header-entrada h3 {
    color: white;
  }
  .title-desglose-info {
    font-size: 1.2vw;
    font-weight: 600 !important;
    color: rgb(8, 166, 165);
    margin: 1rem 0;
  }

  .container-combo {
    display: flex;
    align-items: center;
  }

  .buttons {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 1rem;
    bottom: 0.2rem;
    background-color: #f2f2f2;
    z-index: 9;
  }

  .file-4 {
  }
  .date-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .date-container input {
    margin-left: 0.4rem;
  }
  h3 {
    font-weight: 300;
  }
  margin-top: 2rem;
  .file-1 {
    border: black solid 1px;
    padding: 0.8rem;
  }
  .file-1 h3 {
    font-size: 0.85vw;
  }
  .file-2 {
    border-left: black solid 1px;
    border-right: black solid 1px;
    display: flex;
    border-bottom: black solid 1px;
  }
  .file-2 div {
    padding: 0.8rem;
    border-right: black solid 1px;
  }
  .file-2 div h3 {
    font-size: 0.85vw;
  }
  .file-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr 1fr 3fr;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
  }
  .file-3 div {
    padding: 0.8rem;
    border-right: black solid 1px;
    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .file-3 div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }
  .file-3 div h3 {
    font-size: 0.85vw;
  }
  .pencil {
    border: none !important;
  }
  .pencil img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .file-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
  }
  .file-4 div {
    padding: 0.8rem;
    border-right: black solid 1px;
    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .file-4 div h3 {
    font-size: 0.85vw;
  }
  .file-4 div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }
  //////
  .file-5 {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
  }
  .file-5 div {
    width: 400px;
    padding: 0.8rem;
    border-right: black solid 1px;
    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .file-5 div h3 {
    font-size: 0.85vw;
  }
  .file-5 div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }

  /////////////////////////
  .file-6 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
  }
  .file-6 div {
    padding: 0.8rem;
    border-right: black solid 1px;
    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .file-6 div h3 {
    font-size: 0.85vw;
  }
  .file-6 div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }
  //////////////
  .file-7 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    margin-bottom: 0.4rem;
  }
  .file-7 div {
    padding: 0.8rem;

    font-size: 0.85vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .file-7 div h3 {
    font-size: 0.85vw;
  }
  .file-7 div input {
    width: 100%;
    max-width: 100%;
    font-size: 0.85vw;
    text-align: center;
  }

  @media print {
    /* Estilo para imprimir secciones imprimibles */
    .printable-section {
      display: block;
    }

    /* Estilo para ocultar secciones no imprimibles */
    .non-printable-section {
      display: none;
    }
    .file-2 div,
    .file-3 div,
    .file-4 div,
    .file-5 div,
    .file-6 div,
    .file-7 div {
      padding: 0.4rem !important;
      border-right: black solid 1px;
    }
    .file-3 div {
      padding: 0.4rem;
    }
    margin-top: -2.5rem !important;
    .title-desglose-info {
      font-size: 1.4vw;
      margin: 0.4rem 0;
    }
    .title-header-entrada {
      background-color: white;
    }
    .title-header-entrada h3 {
      color: black;
    }

    .file-1 {
      padding: 0.4rem !important;
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: none;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  min-height: 38.4px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
