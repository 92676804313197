import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import { NumericFormat } from "react-number-format";

import GElement from "./GElement";
import {
  GeneralServiceI,
  IndividualServiceI,
  QuoteGeneralElement,
  QuoteObjectI,
  ServiceIndividualI,
} from "../../../interfaces/GeneralTypes";

export default function GList() {
  const {
    setDataCotizacionContext,
    dataCotizacionContext,
    currentCurrency,
    cotizacionMode,
  } = useContext(AppContext);
  const [serviciosGeneralesList, setServiciosGeneralesList] = useState<
    QuoteGeneralElement[]
  >(dataCotizacionContext.generalServices);

  const deleteFromServices = async (idService: string) => {
    if (cotizacionMode == 2) {
      const serviciosGenerales: GeneralServiceI[] =
        dataCotizacionContext.generalServices
          .map((currentService, index) => {
            const generalService: GeneralServiceI = {
              lender_identifier: 2,
              provider_identifier: currentService.provider_id ?? 0,
              service_identifier: currentService.service_type,
              fee: currentService.fee_price,
              number_of_services: parseFloat(currentService.number_days),
              service_total:
                currentService.fee_price *
                parseFloat(currentService.number_days),
              general_service_identifier:
                currentService.general_service_identifier,
              status_identifier:
                currentService.identifierGeneralService == idService
                  ? 2
                  : currentService.status_identifier,
            };
            return generalService;
          })
          .filter((currentService, index) => {
            return (
              currentService.fee != 0 && currentService.number_of_services != 0
            );
          });

      const bodySaveRequest: QuoteObjectI = {
        user_identifier: 1,
        quote: {
          private_quote: true,
          name: dataCotizacionContext.personalInfo.name,
          type: dataCotizacionContext.personalInfo.typeCotizacion,
          agency: dataCotizacionContext.personalInfo.agencia,
          series: dataCotizacionContext.personalInfo.serieId,
          PAX: dataCotizacionContext.personalInfo.pax,
          TL: dataCotizacionContext.personalInfo.TL,
          MIN: dataCotizacionContext.personalInfo.min,
          MAX: dataCotizacionContext.personalInfo.max,
          arrival_date:
            dataCotizacionContext.personalInfo.arrivingData == "arrivingData"
              ? ""
              : dataCotizacionContext.personalInfo.arrivingData,
          departure_date:
            dataCotizacionContext.personalInfo.departureDate == "departureData"
              ? ""
              : dataCotizacionContext.personalInfo.departureDate,
          currency_identifier: currentCurrency,
          commission: dataCotizacionContext.feeCharges.comision,
          commission_auto: dataCotizacionContext.feeCharges.comisonAuto,
          courtesy: dataCotizacionContext.feeCharges.cortesia,
          exchange_rate: dataCotizacionContext.feeCharges.changeType,
          comments: dataCotizacionContext.feeCharges.comments,
          total: dataCotizacionContext.totalPrice.totalPrice,
          quote_register_type:
            dataCotizacionContext.personalInfo.typeCotizacion,
          number_of_days:
            dataCotizacionContext.personalInfo.numberDaysOpenQuote,
          serie:
            dataCotizacionContext.personalInfo.modeCotizacion == "Serie"
              ? true
              : false,
          status_identifier:
            dataCotizacionContext.personalInfo.status_identifier,
          quote_identifier: dataCotizacionContext.personalInfo.quote_identifier,
          courtesy_type_identifier:
            dataCotizacionContext.feeCharges.courtesy_type_identifier,
          optional_quote:
            dataCotizacionContext.personalInfo.modeCotizacion == "Opcional"
              ? true
              : false,
        },
        quote_general_services: serviciosGenerales,
        quote_individual_services: [],
        general_transport_services: [],
        optional_groups: [],
      };

      const urlModified: string = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/update`;
      try {
        const response = await fetch(urlModified, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodySaveRequest),
        });

        if (response.ok) {
          const data = await response.json();
        } else {
        }
      } catch (error) {
        ///ERROR AL REALIZAR PETICION
      }
      return;
    }
  };

  const deleteGeneralService = (idService: string) => {
    // if (serviciosGeneralesList.length == 1) {
    //   alert("No puedes borrar más elementos");
    //   return;
    // }
    deleteFromServices(idService);
    const newServiciosGenerales: QuoteGeneralElement[] =
      serviciosGeneralesList.filter((general, index) => {
        return general.identifierGeneralService !== idService;
      });
    setServiciosGeneralesList(newServiciosGenerales);
  };
  useEffect(() => {
    setDataCotizacionContext({
      ...dataCotizacionContext,
      generalServices: serviciosGeneralesList,
    });
    //console.log("Nuevos data cotizacion context:");
    //console.log(dataCotizacionContext.generalServices);
  }, [serviciosGeneralesList]);
  const totalServiciosTransporte =
    dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement
      .map((servicioDia, index) => {
        return {
          totalDia: servicioDia?.serviciosIndividuales.reduce(
            (total, item) => total + item.tarifa * item.number_of_services,
            0
          ),
        };
      })
      .reduce((total, item) => total + item.totalDia, 0);
  const totalGeneral = serviciosGeneralesList.reduce((total, item) => {
    return total + item.fee_price * parseFloat(item.number_days);
  }, 0);
  const feeGeneralElement: QuoteGeneralElement = {
    service_type: 0,
    provider_id: 0,
    service_name_id: 0,
    fee_price: 0,
    number_days: "0",
    total_price: 0,
    identifierGeneralService: "",
    currency: currentCurrency == 2 ? "Peso" : "Dolar",
    currency_identifier: currentCurrency,
    expenses_type_identifier: 9,
    general_service_identifier: 0,
    status_identifier: 1,
    isIncludedOpt: false,
  };

  const addNewGeneralService = (indiceSelected: number) => {
    //console.log("Adding Process");
    const identificador = uuidv4();
    if (serviciosGeneralesList.length == 0) {
      const newArrayServiciosGenerales: QuoteGeneralElement[] = [];
      ///////////////////REGRESAR ESTE CODIGO A LA NORMALIDAD

      newArrayServiciosGenerales.push({
        ...feeGeneralElement,
        identifierGeneralService: identificador,
        service_type: 0,
      });

      setServiciosGeneralesList(newArrayServiciosGenerales);
      return;
    }
    // Crea una nueva copia del array original antes de modificarlo
    const newArrayServiciosGenerales = [...serviciosGeneralesList];
    newArrayServiciosGenerales.splice(indiceSelected + 1, 0, {
      ...feeGeneralElement,
      identifierGeneralService: identificador,
      service_type: serviciosGeneralesList[indiceSelected].service_type,
    });

    //console.log("Viajo Arreglo: ");
    //console.log(serviciosGeneralesList);
    //console.log("Nuevo Arreglo:");
    //console.log(newArrayServiciosGenerales);

    // Actualiza el estado con la nueva copia del array
    setServiciosGeneralesList(newArrayServiciosGenerales);
  };
  return (
    <>
      <div className="container-generales">
        <div className="header-title-generales">
          <div>
            <h3>Prestador</h3>
          </div>
          <div>
            <h3>Categoría</h3>
          </div>
          <div>
            <h3>Proveedor</h3>
          </div>
          <div>
            <h3>Servicio</h3>
          </div>
          <div>
            <h3>Tarifa</h3>
          </div>
          <div>
            <h3>Número de servicios</h3>
          </div>
          <div>
            <h3>Total [{currentCurrency == 1 ? "USD" : "MXN"}]</h3>
          </div>
        </div>
        <div className="fake-icons-box">
          {serviciosGeneralesList.length == 0 && (
            <img
              src={agregar}
              alt=""
              width={25}
              onClick={() => {
                //console.log("Adding new service");
                addNewGeneralService(1);
              }}
            />
          )}
        </div>
      </div>

      <GListStyles>
        {serviciosGeneralesList.map((servicioGeneral, index) => {
          return (
            <GElement
              key={servicioGeneral.identifierGeneralService}
              isShowingAddButton={
                serviciosGeneralesList.length - 1 == index ? true : false
              }
              deleteGeneralService={deleteGeneralService}
              servicioGeneral={servicioGeneral}
              serviciosGeneralesList={serviciosGeneralesList}
              setServiciosGeneralesList={setServiciosGeneralesList}
              addNewGeneralService={addNewGeneralService}
              indiceSelected={index}
            />
          );
        })}

        <div className="generales-suma">
          <h3>
            Total de Servicios Generales: ${" "}
            <NumericFormat
              value={(totalGeneral + totalServiciosTransporte).toFixed(2)}
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
            />{" "}
            {currentCurrency == 2 ? " Pesos" : " Dolares"}{" "}
          </h3>
        </div>
      </GListStyles>
    </>
  );
}

const GListStyles = styled.div`
  width: 100%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  margin: 0 auto;
  .generales-suma {
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 0.85vw;
  }
`;
