import React, { createContext, useContext, useEffect, useState } from "react";
import { AppContext } from "./appContext";
import {
  CatalogsData,
  Country,
  City,
  Neighborhood,
  Zipcode,
  Currency,
  Season,
  RegistrationType,
  ProviderType,
  ServiceType,
  View,
  Bank,
  CatalogsContextType,
  CatalogsContextTypeDos,
  CatalogsContextTypeOperacion,
} from "../interfaces/GeneralTypes";
import { CatalogsDataDos, QuoteType, Series } from "../interfaces/GeneralTypes";
import { CatalogDataOperacion } from "./OperacionI";

const OperacionCatalog = createContext<CatalogsContextTypeOperacion>({
  catalogsDataOperaciones: {
    users_list: [],
    guides_list: [],
    ops_users_list: [],
    complaint_type_list: [],
    alico_bank_accounts_list: [],
    service_type_list: [],
  },
});
interface AppProviderProps {
  children: React.ReactNode;
}

const CatalogsProviderOperacion: React.FC<AppProviderProps> = ({
  children,
}) => {
  const { showLoader, setShowLoader } = useContext(AppContext);
  const [catalogsDataOperaciones, setCatalogsDataOperaciones] =
    useState<CatalogDataOperacion>({
      users_list: [],
      guides_list: [],
      ops_users_list: [],
      complaint_type_list: [],
      alico_bank_accounts_list: [],
      service_type_list: [],
    });

  useEffect(() => {
    setShowLoader(true);
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/OpsCatalogs`)
      .then((response) => response.json())
      .then((data) => {
        //console.log('Trayendo datos de la API PARA EL ULTIMO CATALOGOOOO--------------------------------------------------: ');
        //console.log(data.data);
        setCatalogsDataOperaciones({
          users_list: data.data.users_list,
          guides_list: data.data.guides_list,
          ops_users_list: data.data.ops_users_list,
          complaint_type_list: data.data.complaint_type_list,
          alico_bank_accounts_list: data.data.alico_bank_accounts_list,
          service_type_list: data.data.service_type_list,
        });
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        console.error(error);
      });
  }, []);

  return (
    <OperacionCatalog.Provider value={{ catalogsDataOperaciones }}>
      {children}
    </OperacionCatalog.Provider>
  );
};

const useCatalogsOperacion = () => useContext(OperacionCatalog);

export { CatalogsProviderOperacion, useCatalogsOperacion };
