import React, { ChangeEvent, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import CatalogoMobile from './CatalogoMobile';
import WebCatalog from '../../pages/WebCatalog';
import SearchBox from '../common/SearchBox';
import {catalogo} from '../../mockData/mock';
import { catalogElement } from '../../../interfaces/interfaces';

export default function Catalogo() {

  const [catalogElements, setCatalogElements] = useState<Array<catalogElement>>(catalogo);

  useEffect(()=>{
    //console.log('El arreglo del catalogo ha sido modificado: ');
    //console.log(catalogElements); 
    localStorage.setItem("lastPage","0");
  }, [catalogElements]);
  
  const handleChangeArray = (dataViajeSelected: catalogElement) => {
    const catalogElementsUpdated: Array<catalogElement> = catalogElements.map(
      (dataViaje: catalogElement) => {
        return dataViaje.clave != dataViajeSelected.clave
          ? dataViaje
          : dataViajeSelected;
      }
    );
    setCatalogElements(catalogElementsUpdated);
  };
  const deleteElementArray = (dataViajeClave: string) => {
    const catalogElementsUpdated: Array<catalogElement> =
      catalogElements.filter((dataViaje: catalogElement) => {
        return dataViajeClave != dataViaje.clave;
      });
    setCatalogElements(catalogElementsUpdated);
  };
 
  return (
    <CatalogoStyles>
      <div>
      <SearchBox catalogElements={catalogElements} setCatalogElements = {setCatalogElements}/>
        <div className="mobile-catalog">
          <CatalogoMobile catalogElements={catalogElements} setCatalogElements = {setCatalogElements} handleChangeArray = {handleChangeArray} deleteElementArray = {deleteElementArray}/>
        </div>
        <div className="desktop-catalog">
          <WebCatalog catalogElements={catalogElements} setCatalogElements = {setCatalogElements} handleChangeArray = {handleChangeArray} deleteElementArray = {deleteElementArray}></WebCatalog>
        </div>
      </div>
 
    </CatalogoStyles>
  )
}

const CatalogoStyles = styled.div`
.rombo-picture{
    border: red solid 1px;
    height: 200px;
    overflow-y: hidden;
    background-color: #08A6A5;
}
.imagen-test{
    background-image: url("../../../images/rombos.png"); 
    height: 200px;
} 

.desktop-catalog{
  display: none;
  padding-top: 2rem;
  min-height: 100vh;
  background-color: #f4f4f4;
}

@media screen and (min-width: 900px) {
  .mobile-catalog{
    display: none;
  }

  .desktop-catalog{
    display: block;
  }
}
`