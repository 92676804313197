import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import ServiciosLayout from "../../components/layout/ServiciosLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faPencil,
  faPen,
  faFloppyDisk,
  faCopy,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../../components/entrys/CustomInput";
import { v4 as uuidv4 } from "uuid";
import CustomInputt from "../../components/entrys/CustomInputt";
import {
  dataServicesI,
  seasonInterface,
  coinInterface,
  viewInterface,
  serviceInterface,
  serviciosInterface,
  categoriaI,
  maealI,
  feeIndividual,
  serviceObject,
} from "../../../interfaces/GeneralTypes";
import Vigencia from "./Vigencia";
import ImpuestosGet from "./ImpuestosGet";
import deletedatos from "../../../images/deletelogo.png";
import guardar from "../../../images/GUARDARDATOS.png";
import edit from "../../../images/edit.png";
import agregar from "../../../images/AGREGAR.png";
import copiar from "../../../images/DUPLICAR.png";
interface tarifaIndividualGI {
  service_fee_list: Array<feeIndividual>;
  tarifa: feeIndividual;
  localTarifaParent: serviceObject;
  setLocalTarifaParent: React.Dispatch<React.SetStateAction<serviceObject>>;
  isTaxesIncluded: boolean;
  serviceCurrentType: number;
}
export default function TarifaIndividualG({
  service_fee_list,
  tarifa,
  localTarifaParent,
  setLocalTarifaParent,
  isTaxesIncluded,
  serviceCurrentType,
}: tarifaIndividualGI) {
  const { catalogsData } = useCatalogs();
  const [editionMode, setEditionMode] = useState(true);
  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  const [isTaxesAvailable, setIsTaxesAvailable] = useState<boolean>(true);
  const [localTarifa, setLocalTarifa] = useState<feeIndividual>(tarifa);
  useEffect(() => {
    setCurrencyList(catalogsData?.currency_list);
  }, [catalogsData]);
  const deleteCurrentTarifa = () => {
    if (localTarifaParent.service_fee_list.length == 1) {
      alert("No puedes borrar más elementos");
      return;
    }
    const newArrayTarifa = localTarifaParent.service_fee_list.filter(
      (currentTarifa, indes) => {
        return currentTarifa.fee_key !== tarifa.fee_key;
      }
    );
    setLocalTarifaParent({
      ...localTarifaParent,
      service_fee_list: newArrayTarifa,
    });
  };
  useEffect(() => {
    let impuesto = isNaN(localTarifa.fee_tax) ? 0 : localTarifa.fee_tax;
    const costoTotalTarifa =
      localTarifa.fee +
      localTarifa.fee * (impuesto / 100) +
      localTarifa.fee * (localTarifa.fee_iva / 100)
        ? localTarifa.fee +
          localTarifa.fee * (impuesto / 100) +
          localTarifa.fee * (localTarifa.fee_iva / 100)
        : 0.0;
    //console.log("COSTO TOTAL TARIFA: ");
    //console.log(costoTotalTarifa);

    const newArrayTarifa = localTarifaParent.service_fee_list.map(
      (currentTarifa, index) => {
        return currentTarifa.fee_key !== tarifa.fee_key
          ? currentTarifa
          : {
              ...localTarifa,
              fee_total: parseFloat(costoTotalTarifa.toFixed(2)),
            };
      }
    );
    //console.log("NUEVO ARREGLO: ");
    //console.log(newArrayTarifa);
    setLocalTarifa({
      ...localTarifa,
      fee_total: costoTotalTarifa,
    });
    setLocalTarifaParent({
      ...localTarifaParent,
      service_fee_list: newArrayTarifa,
    });
  }, [
    localTarifa.fee,
    localTarifa.fee_tax,
    localTarifa.fee_iva,
    localTarifa.fee_capacity,
    localTarifa.fee_currency_identifier,
  ]);

  useEffect(() => {
    //console.log("Local tarifa changed");
    //console.log(localTarifa);
  }, [localTarifa]);

  return (
    <TarifaIndividualGStyles>
      <div className="tarifa-container">
        <div>
          <SelectListdefault
            style={{
              width: "95%",
              maxWidth: "95%",
            }}
            id="sl6"
            onChange={(e) => {
              //console.log("ONCHANGE");
              //console.log(parseInt(e.target.value));
              setLocalTarifa({
                ...localTarifa,
                fee_currency_identifier: parseInt(e.target.value),
              });
            }}
          >
            <option value="" disabled selected>
              Moneda
            </option>

            {currencyList?.length > 0
              ? currencyList.map((item) => {
                  return (
                    <option
                      key={item.identifier}
                      value={item.identifier}
                      selected={
                        item.identifier == localTarifa.fee_currency_identifier
                      }
                    >
                      {item.description}
                    </option>
                  );
                })
              : null}
          </SelectListdefault>
        </div>
        {serviceCurrentType == 18 && (
          <div>
            <input
              type="text"
              placeholder="Capacidad"
              value={localTarifa.fee_capacity}
              className="input-style-data"
              onChange={(e) =>
                setLocalTarifa({
                  ...localTarifa,
                  fee_capacity: e.target.value,
                })
              }
            />
          </div>
        )}
        <div>
          {editionMode ? (
            <input
              type="number"
              inputMode="decimal"
              value={localTarifa.fee}
              className="input-style-data"
              onChange={(e) =>
                setLocalTarifa({
                  ...localTarifa,
                  fee: parseFloat(e.target.value),
                })
              }
            />
          ) : (
            <h3>{localTarifa.fee}</h3>
          )}
        </div>
        <div>
          {editionMode ? (
            <input
              type="number"
              inputMode="decimal"
              placeholder="Tarifa"
              value={localTarifa.fee_iva}
              className="input-style-data"
              onChange={(e) =>
                setLocalTarifa({
                  ...localTarifa,
                  fee_iva: parseFloat(e.target.value),
                })
              }
            />
          ) : (
            <h3>{localTarifa.fee_iva}</h3>
          )}
        </div>
        {serviceCurrentType != 18 && (
          <div>
            {editionMode && serviceCurrentType != 18 ? (
              <input
                type="number"
                inputMode="decimal"
                value={localTarifa.fee_tax}
                className="input-style-data"
                onChange={(e) =>
                  setLocalTarifa({
                    ...localTarifa,
                    fee_tax: parseFloat(e.target.value),
                  })
                }
              />
            ) : editionMode && serviceCurrentType == 18 ? (
              <input
                type="text"
                placeholder="Capacidad"
                value={localTarifa.fee_capacity}
                className="input-style-data"
                onChange={(e) =>
                  setLocalTarifa({
                    ...localTarifa,
                    fee_capacity: e.target.value,
                  })
                }
              />
            ) : !editionMode && serviceCurrentType != 18 ? (
              <h3>
                {isTaxesIncluded ? localTarifa.fee_tax : localTarifa.fee_tax}
              </h3>
            ) : !editionMode && serviceCurrentType == 18 ? (
              <h3>{localTarifa.fee_capacity}</h3>
            ) : (
              <h3>{isNaN(localTarifa.fee_tax) ? "" : localTarifa.fee_tax}</h3>
            )}
          </div>
        )}

        <div>
          <h3 className="result-tarifa-final">
            {" "}
            {localTarifa.fee_total % 1 === 0
              ? localTarifa.fee_total.toFixed(0)
              : localTarifa.fee_total.toFixed(2)}
          </h3>
        </div>
        <div>
          <img
            src={deletedatos}
            className="trash trash-tarifa"
            width="30px"
            onClick={deleteCurrentTarifa}
          />
        </div>
      </div>
    </TarifaIndividualGStyles>
  );
}
const TarifaIndividualGStyles = styled.div`
  .trash-tarifa {
    cursor: pointer;
  }
  .tarifa-container {
  }
  @media screen and (max-width: 900px) {
    .result-tarifa-final {
      font-size: 3.8vw !important;
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: none;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }

  max-height: 40.8px !important;
`;
