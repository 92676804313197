import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontawesomeObject } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";

interface navigationItemProps {
  linkInfo: string;
  iconItem: IconDefinition;
  urlLink: string;
  showSideBar: boolean;
  setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  imgIcon: string;
}

export default function NavigationItem({
  linkInfo,
  iconItem,
  urlLink,
  showSideBar,
  setShowSideBar,
  imgIcon,
}: navigationItemProps) {
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;

  useEffect(() => {
    //console.log("HELLO: ");
    //console.log(screenWidth);
  }, [screenWidth]);
  return (
    <Link
      to={`${urlLink}`}
      className="link-item"
      onClick={() => {
        // if (screenWidth < 900) {
        //   setShowSideBar(!showSideBar);
        // }
      }}
    >
      <NavigationItemStyle>
        <div className="font-icon-container">
          <img src={imgIcon} alt="Imagen" width="20" className="img-icon" />
        </div>
        <div>
          <h3>{linkInfo}</h3>
        </div>
      </NavigationItemStyle>
    </Link>
  );
}

const NavigationItemStyle = styled.div`
  /* border: white solid 1px; */
  width: 200px;

  .font-icon-container {
    display: flex;
    justify-content: center;
  }
  .font-icon {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 0.9vw;
  }

  @media screen and (max-width: 900px) {
    h3 {
      font-size: 1.8vw !important;
    }
    grid-template-columns: 2fr 2fr;
    max-width: 60%;
    margin: 0 auto;
    .img-icon {
      width: 15px;
    }
    margin-bottom: 0.7rem;
  }
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  color: white;
  text-decoration: none;
  a:focus {
    outline: none;
  }
  a:focus {
    outline: none;
  }
  a:focus {
    outline: none !important;
  }

  :focus {
    outline: none !important;
  }
`;
