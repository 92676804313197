import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import CustomInputt from "../../components/entrys/CustomInputt";
import FiscalElement from "../../components/layout/FiscalElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import {
  objectCard,
  coinInterface,
  bankInterface,
  RegisterGeneralI,
} from "../../../interfaces/GeneralTypes";
import deletelogo from "../../../images/deletelogo.png";
import agregar from "../../../images/AGREGAR.png";
import {
  TaxDataDelete,
  BankAccountDelete,
  DeleteDataFiscal,
} from "../../../interfaces/DeleteTypes";
import { GeneralModifiedI } from "../../../interfaces/GuardadoTypes";
interface taxData {
  name: string;
  business_name: string;
  rfc: string;
  bank_account: string;
  clabe: string;
  bank: string;
  currency_identifier: number;
  email: string;
}

export default function Fiscal() {
  const { catalogsData } = useCatalogs();
  const {
    registerData,
    setRegisterData,
    fiscalContext,
    setFiscalContext,
    registerGeneral,
    setRegisterGeneral,
    dataServicesContext,
    idCurrentProvider,
    setShowLoader,
  } = useContext(AppContext);
  const [bankList, setBankList] = useState<bankInterface[]>([]);
  const { showAlert, setShowAlert } = useContext(AppContext);
  const { contentAlert, setContentAlert } = useContext(AppContext);
  const { titleAlert, setTitleAlert } = useContext(AppContext);
  let identificador = uuidv4();
  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  const [taxDataObject, settaxDataObject] = useState({
    name: registerGeneral.nombreComercial,
    business_name: fiscalContext.business_name,
    rfc: fiscalContext.rfc,
    bank_account: registerData.tax_data.bank_account,
    clabe: registerData.tax_data.clabe,
    bank: registerData.tax_data.bank,
    currency_identifier: fiscalContext.currency_identifier,
    email: fiscalContext.email,
  });
  useEffect(() => {}, []);

  useEffect(() => {
    setBankList(catalogsData?.bank_list);
    setCurrencyList(catalogsData?.currency_list);
  }, [catalogsData]);
  useEffect(() => {
    setFiscalContext({
      ...fiscalContext,
      name: "PRUEBA",
      business_name: taxDataObject.business_name,
      rfc: taxDataObject.rfc,
      email: taxDataObject.email,
      currency_identifier: taxDataObject.currency_identifier,
    });
  }, [taxDataObject]);
  useEffect(() => {}, [fiscalContext]);
  useEffect(() => {}, [registerData]);
  let identifier = uuidv4();
  const [arrayDataCards, setArrayDataCards] = useState<objectCard[]>(
    fiscalContext.bank_accounts_list
  );
  useEffect(() => {
    setFiscalContext({
      ...fiscalContext,
      name: taxDataObject.name,
      business_name: taxDataObject.business_name,
      rfc: taxDataObject.rfc,
      email: taxDataObject.email,
      currency_identifier: taxDataObject.currency_identifier,
    });
  }, [taxDataObject]);
  useEffect(() => {
    setFiscalContext({
      ...fiscalContext,
      bank_accounts_list: arrayDataCards,
    });
  }, [arrayDataCards]);
  const addNewFiscal = () => {
    const isEmpty = arrayDataCards.some((item) => {
      const values = Object.values(item);
      return values.some((value) => value === "");
    });

    if (isEmpty) {
      alert("Aún hay campos vacios");
      return;
    } else {
    }

    setArrayDataCards([
      ...arrayDataCards,
      {
        bank_account_identifier: 0,
        /////////////////////
        numberCard: "",
        clabe: "",
        identifier: identificador,
        bank_identifier: 0,
        currency_element: 0,
      },
    ]);
  };

  const deleteCard = async (indice: number) => {
    if (arrayDataCards.length === 1) {
      setContentAlert("No puedes borrar más elementos");
      setTitleAlert("Error al borrar");
      setShowAlert(true);
      return;
    }

    //console.log(indice);
    const newCardList = arrayDataCards.filter((contacto, index) => {
      return indice != index;
    });
    ////BORRADO DESDE SERVICIOS:
    if (idCurrentProvider != 0) {
      setShowLoader(true);
      const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
      //console.log('REACT BACK END POINT: ');
      //console.log(backEndPoint);

      const urlModified = backEndPoint + "/provider/update";
      const modifiedGeneral: GeneralModifiedI = {
        register_identifier: idCurrentProvider,
        name: registerGeneral.name,
        registration_type_identifier:
          registerGeneral.registration_type_identifier,
        lender_type_identifier:
          registerGeneral.lender_type_identifier == 0
            ? 1
            : registerGeneral.lender_type_identifier,
        provider_type_identifier: registerGeneral.provider_type_identifier
          ? registerGeneral.provider_type_identifier
          : 1,
        business_name: registerGeneral.business_name,
        street: registerGeneral.calles,
        number: registerGeneral.telefono,
        neighborhood_identifier: registerGeneral.neighborhood_identifier,
        city_identifier: registerGeneral.city_identifier
          ? registerGeneral.city_identifier
          : 1,
        country_identifier: registerGeneral.country_identifier,
        zip_code: registerGeneral.zip_code,
        important_notes: registerGeneral.important_notes,
        description: registerGeneral.lead,
        id: registerGeneral.id,
        key: registerGeneral.claveGeneral,
        rfc: registerGeneral.rfc,
        category:
          registerGeneral.categoriaGeneral == 0
            ? null
            : registerGeneral.categoriaGeneral,
        last_purchase: registerGeneral.ultimaCompra,
        feedback: registerGeneral.feedback,
        lead: registerGeneral.lead,
        commission_scheme: registerGeneral.comisionEsquema,
        web: registerGeneral.web,
        comments: registerGeneral.comments,
        category_identifier: registerGeneral.category_identifier,
      };
      const fiscalCardDeleteList: BankAccountDelete[] | null = arrayDataCards
        ?.filter((card, index) => {
          return indice == index;
        })
        .map((card, index) => {
          const cardToDelete: BankAccountDelete = {
            bank_account_identifier: card.bank_account_identifier,
            status_identifier: 2,
            bank_account: card.numberCard,
            clabe: card.clabe,
            bank_identifier: card.bank_identifier,
            currency_identifier: card.currency_element,
          };
          return cardToDelete;
        });
      const modifiedFiscal: TaxDataDelete = {
        tax_identifier: fiscalContext.tax_identifier,
        name: fiscalContext.name,
        business_name: fiscalContext.business_name,
        rfc: fiscalContext.rfc,
        email: fiscalContext.email,
        bank_accounts_list: fiscalCardDeleteList,
        status_identifier: 1,
      };
      const modifiedObject: DeleteDataFiscal = {
        user_identifier: 1,
        general: modifiedGeneral,
        tax_data: modifiedFiscal,
      };

      //console.log('CUERPO DE PETICION: ' );
      //console.log(modifiedObject);
      try {
        const response = await fetch(urlModified, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(modifiedObject),
        });
        if (response.ok) {
          ////RESPUESTA CORRECTA
          //console.log(response);
          //console.log("bien");

          setTitleAlert("El borrado se realizó correctamente");
          setContentAlert("La cuenta que seleccionaste fue borrada.");

          setShowAlert(true);
          setShowLoader(false);
          setArrayDataCards(newCardList);
        } else {
          ///RESPUESTA INCORRECTA DE SERVICIOS
          // setShowLoader(false);
          setTitleAlert("Error al BORRAR:");
          setContentAlert(
            "Tus datos no fueron guardados correctamente, por favor, verificalos."
          );
          setShowAlert(true);
          setShowLoader(false);
        }
      } catch (error) {
        ///ERROR AL REALIZAR PETICION
        alert("ERROR AL REALIZAR BORRADO");

        setTitleAlert("Error al BORRAR:");
        setContentAlert(
          "Tus datos no fueron guardados correctamente, por favor, verificalos."
        );
        setShowLoader(false);
      }
    } else {
      setArrayDataCards(newCardList);
    }

    /////
  };
  return (
    <FiscalStyles>
      <div className="box-container">
        {/* <h2 className="title-fiscales">Datos Fiscales</h2> */}
        <br />
        <br />
        <div className="data-container-dos">
          <CustomInputt<RegisterGeneralI>
            text="Nombre"
            txtWidth={90}
            setGeneralData={setRegisterGeneral}
            field="name"
            value={registerGeneral.name}
          />
          <CustomInputt<RegisterGeneralI>
            text="Razón social"
            txtWidth={90}
            setGeneralData={setRegisterGeneral}
            field="business_name"
            value={registerGeneral.business_name}
          />
          <CustomInputt<taxData>
            text="RFC"
            txtWidth={90}
            setGeneralData={settaxDataObject}
            field="rfc"
            value={taxDataObject.rfc}
          />

          <CustomInputt<taxData>
            text="Correo Electrónico"
            txtWidth={90}
            setGeneralData={settaxDataObject}
            field="email"
            value={taxDataObject.email}
          />
        </div>
        <div className="second-box">
          <div>
            <h2>Moneda</h2>
          </div>
          <div>
            <h2>Cuenta bancaria</h2>
          </div>
          <div>
            <h2>CLABE</h2>
          </div>
          <div>
            <h2>Banco</h2>
          </div>
          <div></div>
        </div>
        <div>
          {arrayDataCards.map((card, index) => {
            return (
              <FiscalElement
                numberCard={card.numberCard}
                bankList={bankList}
                clabe={card.clabe}
                bank_account_identifier={card.bank_account_identifier}
                currency_element={card.currency_element}
                identifier={card.identifier}
                key={card.identifier}
                deleteCard={deleteCard}
                indice={index}
                arrayDataCards={arrayDataCards}
                setArrayDataCards={setArrayDataCards}
                bank_identifier={card.bank_identifier}
                initialState={arrayDataCards.length - 1 == index ? true : false}
                addNewFiscal={addNewFiscal}
              />
            );
          })}
        </div>

        {/* <div className="data-container">
          <CustomInputt<taxData>
            text="Cuenta bancaria"
            txtWidth={70}
            setGeneralData={settaxDataObject}
            field="bank_account"
            value={taxDataObject.bank_account}
          />
          <CustomInputt<taxData>
            text="CLABE"
            txtWidth={70}
            setGeneralData={settaxDataObject}
            field="clabe"
            value={taxDataObject.clabe}
          />
          <SelectListdefault
            style={{
              width: "70%",
              maxWidth: "70%",
            }}
            id="sl6"
            onChange={(e) => {
              //console.log(e.target.value);
              settaxDataObject({
                ...taxDataObject,
                bank: e.target.value,
              });
            }}
          >
            <option value="" disabled selected>
              Banco
            </option>

            {bankList.map((item) => {
              return (
                <Fragment>
                  <option
                    key={item.description}
                    value={item.description}
                    selected={item.description == taxDataObject.bank}
                  >
                    {item.description}
                  </option>
                </Fragment>
              );
            })}
          </SelectListdefault>
        </div> */}
      </div>
    </FiscalStyles>
  );
}

const FiscalStyles = styled.div`
  width: 90%;
  margin: 0 auto;
  h2 {
    font-size: 1rem;
  }
  .second-box div h2 {
    font-size: 0.85vw !important;
  }
  @media screen and (max-width: 900px) {
    .second-box div h2 {
      font-size: 1.6vw !important;
    }
    width: 100%;
  }
  .button-container {
    display: flex;
    justify-content: right;
    padding-right: 3vw;
    margin-top: 1rem;
  }
  .plus-icon {
    font-size: 1.8rem;
    cursor: pointer;
    color: rgb(8, 166, 165) !important;
  }

  .second-box {
    border: rgb(8, 166, 165) solid 2px;
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    padding: 0.8rem;
    justify-items: center;
    align-items: center;
  }
  .second-box div h2 {
    color: rgb(8, 166, 165);
    font-size: 1.2rem;
  }
  .data-container-dos {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 1rem;
    justify-items: center; /* Centra horizontalmente los elementos */
    align-items: center; /* Centra verticalmente los elementos */
  }
  @media screen and (max-width: 900px) {
    .data-container-dos {
      grid-template-columns: 1fr 1fr;
    }
  }
  .title-fiscales {
    color: rgb(8, 166, 165);
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .data-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center; /* centrado horizontal */
    align-items: center; /* centrado vertical */

    grid-row-gap: 0.5rem;
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(133, 198, 195);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
`;
