import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAngleDown,
  faBan,
  faRotateRight,
  faXmark,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import edit from "../../../images/edit.png";
import {
  servicioDayElementI,
  servicioIndividual,
  serviciosDaysElementsI,
} from "../../../context/InterfacesContext";
import deletelogo from "../../../images/deletelogo.png";
import { useCatalogs } from "../../../context/CatalogContext";
import {
  serviceInterface,
  categoriaI,
  lenderI,
  City,
  maealI,
  ProviderType,
} from "../../../interfaces/GeneralTypes";
import { NameProvider, searchDataI } from "../../../interfaces/Cotizacion";
import { ProviderSearchList } from "../../../interfaces/Cotizacion";
import { generalElementI } from "../../../context/InterfacesContext";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import { obtenerFechaDesdeString } from "../RegisterNewProvider-Client/FunctionsTarifa";
import { useCatalogsDos } from "../../../context/CatalogContextDos";
import { transformarFecha } from "./funciontes";
export default function SearchServices() {
  const {
    showAlert,
    setShowAlert,
    showInfoContact,
    showSeachServices,
    setShowSearchServices,
    setDataCotizacionContext,
    dataCotizacionContext,
    idCurrentDayService,
    setIdCurrentDayService,
    cotizationDate,
    setShowLoader,
    serviciosType,
    route,
    setRoute,
    currentCurrency,
    numberDaySearch,
    setNumberDaySearch,
  } = useContext(AppContext);
  const [providerType, setProviderType] = useState<ProviderType[]>([]);
  const [serviceList, setServiceList] = useState<serviceInterface[]>([]);
  const [categoryServices, setCategoryServices] = useState<categoriaI[]>([]);
  const [prestador, setPrestador] = useState<lenderI[]>([]);
  const [cityList, setCityList] = useState<City[]>([]);
  const [foodPlan, setFoodPlan] = useState<maealI[]>([]);
  const [paramsSearch, setParamsSearch] = useState<searchDataI>({
    service_date: cotizationDate ? cotizationDate : "",
    service_type_identifier: null,
    category_identifier: null,
    lender_type_identifier: null,
    register_identifier: null,
    search_parameter: "",
    city_identifier: null,
    mealplan_identifier: null,
    room_type: dataCotizacionContext.personalInfo.typeRoom ?? 1,
    provider_type_identifier: null,
    provider_identifier: null,
  });
  const { catalogsDataDos } = useCatalogsDos();

  const [listResults, setListResults] = useState<ProviderSearchList[] | null>(
    null
  );
  const getCitiesFromCountries = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/catalog/Country_Cities/${117}`
    )
      .then((response) => response.json())
      .then((data) => {
        setCityList(data.data.city_list);
      })
      .catch((error) => console.error(error));
  };

  const { catalogsData } = useCatalogs();
  const cleanInformation = () => {
    setParamsSearch({
      ...paramsSearch,
      search_parameter: "",
      service_type_identifier: null,
      category_identifier: null,
      lender_type_identifier: null,
      register_identifier: null,
      city_identifier: null,
      mealplan_identifier: null,
      provider_type_identifier: null,
      provider_identifier: null,
    });
    setListResults(null);
    //console.log("LIMPIANDO INFORMACION: ");
    limpiarBotonesGris();
  };

  useEffect(() => {
    //console.log("----------------------------------------Valores del catalogsData----------------------------------------------");
    getCitiesFromCountries();
    //console.log(catalogsData);

    setCategoryServices(catalogsData?.category_list);
    setCategoryServices(catalogsData?.category_list);
    setPrestador(catalogsData?.lender_type_list);
    setFoodPlan(catalogsData?.meal_plan_list);
    setProviderType(catalogsData?.provider_type_list);
  }, [catalogsData]);
  useEffect(() => {
    setServiceList(catalogsDataDos?.service_type_list);
  }, [catalogsDataDos]);
  useEffect(() => {
    //console.log("PROVIDER TIPE LIST:");
    //console.log(providerType);
  }, [providerType]);
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      //console.log("Apretaste escape");
      setShowSearchServices(false);
    }
  };
  useEffect(() => {
    //console.log("Actualizacndo paramsSearch");
    //console.log(paramsSearch);
    if (
      paramsSearch.service_type_identifier != 10 &&
      paramsSearch.mealplan_identifier != null
    ) {
      //console.log("Haciendo null paramsSearch");
      setParamsSearch({
        ...paramsSearch,
        mealplan_identifier: null,
      });
    }
  }, [paramsSearch]);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const [listResultsProviders, setListResultsProviders] = useState<
    NameProvider[]
  >([]);

  const searchProveedores = async () => {
    //console.log("PARAMETROS DE BUSQUEDA:");
    //console.log(paramsSearch.search_parameter);

    if (true) {
      const loginUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/searchProvider`;
      const user = "userEmail";
      const password = "userPassword";
      //  setShowLoader(true);
      //console.log("cuerpo de busqueda");
      //console.log(paramsSearch);
      try {
        setShowLoader(true);
        const response = await fetch(loginUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paramsSearch),
        });

        if (response.ok) {
          const data = await response.json();
          //console.log("Datos de busqueda:", data);
          if (data.data.provider_list) {
            if (data.data.provider_list.length == 0) {
              alert("No se encontraron coincidencias");

              setShowLoader(false);
            }

            setListResults(data.data.provider_list);
            setListResultsProviders(data.data.provider_list_search);

            setShowLoader(false);
          } else {
            alert("No se encontraron coincidencias");

            setShowLoader(false);
          }
        } else {
          console.error("Error in response:", response);
          alert("Error al realizar la busqueda");
        }
      } catch (error) {
        alert("Error al realizar la busqueda");
        console.error("Error in request:", error);
        alert("Error en el servicio");

        setShowLoader(false);
      }
    }
  };

  useEffect(() => {
    //console.log("Nueva LISTA DE PROVEEDORES:::");
    //console.log(listResultsProviders);
  }, [listResultsProviders]);
  const saveServicio = (
    idArregloDia: string,
    proveedor: ProviderSearchList,
    tarifaSelected: number,
    currencyName: string,
    currencyId: number,
    fee_identifier: number,
    typeRoom?: number,
    ocupacion?: string,
    tarifaSingleComplement?: number,
    tarifaTripleSuplement?: number,
    capacidadServicio?: string
  ) => {
    //console.log("MODIFICANDO EL ARREGLO:");
    //console.log(idArregloDia);
    let identificador = uuidv4();
    //console.log("PROVEDOR SELECCIONADO:");
    //console.log(proveedor);
    let tarifaSelectedConverted = tarifaSelected;
    let currencyIdModified = currencyId;
    let tarifaSingleComplementConverted = 0;
    let tarifaTripleSuplementConverted = 0;
    let ocupacionRoom = "";
    //console.log("ID DEL ITEM SELECCIONADO:");
    //console.log(currencyId);
    //console.log("CURRENCY BUSCADO: ");
    //console.log(currentCurrency);
    if (ocupacion) {
      ocupacionRoom = ocupacion;
    } else {
      ocupacionRoom = "N/A";
    }
    if (currentCurrency == 2 && currencyId == 1) {
      ////Lo quiero en pesos y está en dolar
      //console.log("Lo quiero en pesos y está en dolar");
      tarifaSelectedConverted =
        tarifaSelectedConverted * dataCotizacionContext.feeCharges.changeType;
      if (tarifaSingleComplement) {
        tarifaSingleComplementConverted =
          tarifaSingleComplement * dataCotizacionContext.feeCharges.changeType;
      }
      if (tarifaTripleSuplement) {
        tarifaTripleSuplementConverted =
          tarifaTripleSuplement * dataCotizacionContext.feeCharges.changeType;
      }
      currencyIdModified = currentCurrency;
    } else if (currentCurrency == 1 && currencyId == 2) {
      ////Lo quiero en dolar y está en pesos
      //console.log("Lo quiero en dolar y está en pesos");
      tarifaSelectedConverted =
        tarifaSelectedConverted / dataCotizacionContext.feeCharges.changeType;
      if (tarifaSingleComplement) {
        tarifaSingleComplementConverted =
          tarifaSingleComplement / dataCotizacionContext.feeCharges.changeType;
      }
      if (tarifaTripleSuplement) {
        tarifaTripleSuplementConverted =
          tarifaTripleSuplement / dataCotizacionContext.feeCharges.changeType;
      }
      currencyIdModified = currentCurrency;
    } else {
      //console.log("No necesito convertir");
      tarifaSelectedConverted = tarifaSelected;
      if (tarifaSingleComplement) {
        tarifaSingleComplementConverted = tarifaSingleComplement;
      }
      if (tarifaTripleSuplement) {
        tarifaTripleSuplementConverted = tarifaTripleSuplement;
      }
      currencyIdModified = currentCurrency;
    }

    if (serviciosType == 1) {
      //console.log("GUARDANDO SERVICIO TIPO 1");
      const newElement: servicioIndividual = {
        category: proveedor.service.category,
        lendertype: 0,
        lenderDescripcion: proveedor.service.lender_type,
        ocupacion: ocupacionRoom,
        ocupacionDescripcion: ocupacionRoom,
        providerType: proveedor.provider.register_identifier,
        providerDescripcion: proveedor.provider.provider_name,
        serviceType: proveedor.service.service_type_identifier,
        serviceDescripcion: proveedor.service.service_name,
        tarifa: tarifaSelectedConverted,
        identifierService: identificador,
        typeRoom: typeRoom ?? 1,
        dateRequired: cotizationDate,
        currencyName: currencyName,
        currencyId: currencyIdModified,
        tarifaSingleComplementConverted: tarifaSingleComplementConverted,
        tarifaTripleSuplementConverted: tarifaTripleSuplementConverted,
        general_service_identifier: 0,
        status_identifier: 1,
        number_of_services: 0,
        service_name_identifier: proveedor.service.service_identifier,
        service_name: proveedor.service.service_name,
        vigencias: proveedor.validity_list,
        fee_identifier: fee_identifier,
        isIncludedOpt: false,
      };
      //console.log("DATOS DEL SERVICIO POR AGREGAR:::");
      //console.log(newElement);
      const newServicios: Array<servicioDayElementI> =
        dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
          (servicioDia, index) => {
            return servicioDia.identifierDay != idArregloDia
              ? servicioDia
              : {
                  ...servicioDia,
                  serviciosIndividuales: [
                    ...servicioDia.serviciosIndividuales,
                    newElement,
                  ],
                };
          }
        );
      //console.log("Arreglo actual TRANSPORTE FUERA:");
      const newServiciosDos: Array<servicioDayElementI> =
        dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
          (servicioDia, index) => {
            return servicioDia.identifierDay != idArregloDia
              ? servicioDia
              : {
                  ...servicioDia,
                  serviciosIndividuales: [
                    ...servicioDia.serviciosIndividuales,
                    newElement,
                  ],
                };
          }
        );

      setDataCotizacionContext({
        ...dataCotizacionContext,
        serviciosDayElements: {
          servicioDayElement: newServicios,
        },
      });
      //console.log("Asi quedó el contexto: ...................");
      //console.log({...dataCotizacionContext,serviciosDayElements: {servicioDayElement: newServicios,},});
    } else if (serviciosType == 2) {
      //console.log("GUARDANDO SERVICIO TIPO 2");
      const newElement: generalElementI = {
        category: proveedor.service.category,
        lendertype: 0,
        lenderDescripcion: proveedor.service.lender_type,
        ocupacion: 0,
        ocupacionDescripcion: "Descripción del servicio",
        providerType: 0,
        providerDescripcion: proveedor.provider.provider_name,
        serviceType: proveedor.service.service_type_identifier,
        serviceDescripcion: proveedor.service.service_name,
        tarifa: tarifaSelectedConverted,
        identifierService: identificador,
        numberServices: 1,
        totalPrice: tarifaSelectedConverted,
        currencyName: currencyName,
        currencyId: currencyIdModified,
        fee_identifier: fee_identifier,
      };
      //console.log("Se está agregando un servicio general.");
      setDataCotizacionContext({
        ...dataCotizacionContext,
        generalsElements: {
          generalElement: [
            ...dataCotizacionContext.generalsElements.generalElement,
            newElement,
          ],
        },
      });
    } else if (serviciosType == 3) {
      //console.log("GUARDANDO SERVICIO TIPO TRANSPORTE");
      const newElement: servicioIndividual = {
        category: proveedor.service.category,
        lendertype: 0,
        lenderDescripcion: proveedor.service.lender_type,
        ocupacion: ocupacionRoom,
        ocupacionDescripcion: ocupacionRoom,
        providerType: proveedor.provider.register_identifier,
        providerDescripcion: proveedor.provider.provider_name,
        serviceType: proveedor.service.service_type_identifier,
        serviceDescripcion: proveedor.service.service_name,
        tarifa: tarifaSelectedConverted,
        identifierService: identificador,
        typeRoom: typeRoom ?? 1,
        dateRequired: cotizationDate,
        currencyName: currencyName,
        currencyId: currencyIdModified,
        tarifaSingleComplementConverted: tarifaSingleComplementConverted,
        tarifaTripleSuplementConverted: tarifaTripleSuplementConverted,
        general_service_identifier: 0,
        status_identifier: 1,
        number_of_services: 1,
        service_name_identifier: proveedor.service.service_identifier,
        service_name: proveedor.service.service_name,
        fee_identifier: fee_identifier,
        isIncludedOpt: false,
        capacidad: capacidadServicio,
      };
      //console.log("DATOS DEL SERVICIO POR AGREGAR:::");
      //console.log(newElement);
      const newServicios: Array<servicioDayElementI> =
        dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
          (servicioDia, index) => {
            return servicioDia.identifierDay != idArregloDia
              ? servicioDia
              : {
                  ...servicioDia,
                  serviciosIndividuales: [
                    ...servicioDia.serviciosIndividuales,
                    newElement,
                  ],
                };
          }
        );

      setDataCotizacionContext({
        ...dataCotizacionContext,
        serviciosDayElementsTransporte: {
          servicioDayElement: newServicios,
        },
      });
      //console.log("Asi quedó el contexto: ...................");
      //console.log({...dataCotizacionContext,serviciosDayElements: {servicioDayElement: newServicios,},});
    }
  };

  // Crear un estado local para seguir el primer renderizado
  const [primerRender, setPrimerRender] = useState(true);
  useEffect(() => {
    if (primerRender) {
      // Actualizar el estado para indicar que no es la primera renderización
      setPrimerRender(false);
    } else {
      // Aquí puedes colocar la lógica que deseas ejecutar cuando cambie una dependencia
      //console.log("La dependencia ha cambiado");
      if (
        paramsSearch.provider_type_identifier !== null ||
        paramsSearch.service_type_identifier !== null ||
        paramsSearch.category_identifier !== null ||
        paramsSearch.city_identifier !== null ||
        paramsSearch.mealplan_identifier ||
        null
      ) {
        searchProveedores();
      }
    }
  }, [
    paramsSearch.service_type_identifier,
    paramsSearch.category_identifier,
    paramsSearch.city_identifier,
    paramsSearch.mealplan_identifier,
    paramsSearch.provider_type_identifier,
    paramsSearch.provider_identifier,
  ]);

  const [botonesGris, setBotonesGris] = useState(new Set());

  const toggleBotonGris = (index: number, room_type: string) => {
    const key = `${index}-${room_type}`;

    if (!botonesGris.has(key)) {
      botonesGris.add(key);
      setBotonesGris(new Set(botonesGris));
    }
  };

  useEffect(() => {
    //console.log("LIST RESULTS CAMBIO: ");
    //console.log(listResults);
  }, [listResults]);
  useEffect(() => {
    //console.log("...dataCotizacionContext.generalsElements.generalElement");
    //console.log(dataCotizacionContext.generalsElements.generalElement);
  }, [dataCotizacionContext.generalsElements.generalElement]);
  const [clickedButtons, setClickedButtons] = useState([] as number[]);

  const handleButtonClick = (index: number) => {
    // Verificar si el botón ya ha sido clickeado
    if (clickedButtons.includes(index)) {
      // Si ya ha sido clickeado, lo eliminamos de la lista de clickeados
      setClickedButtons((prevClickedButtons) =>
        prevClickedButtons.filter((buttonIndex) => buttonIndex !== index)
      );
    } else {
      // Si no ha sido clickeado, lo agregamos a la lista de clickeados
      setClickedButtons((prevClickedButtons) => [...prevClickedButtons, index]);
    }
  };
  const limpiarBotonesGris = () => {
    setBotonesGris(new Set());
  };

  return (
    <SearchServicesStyles className="search-services">
      <div className="x-mark-box"></div>
      <div className="search-box-container">
        <div className="header-box-container">
          <h3>
            Busqueda{" "}
            {!cotizationDate.trim() ? "" : transformarFecha(cotizationDate)}{" "}
            {route}
          </h3>
          <FontAwesomeIcon
            icon={faXmark}
            className="x-mark"
            onClick={() => {
              setShowSearchServices(false);
            }}
          />
        </div>

        <div className="container-box-dos">
          <div className="combo-box-features">
            <div className="list-combos">
              <div>
                <h3>Tipo de proveedor</h3>
                <SelectListdefault
                  className="select-default-dos"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    setParamsSearch({
                      ...paramsSearch,
                      provider_type_identifier: parseInt(e.target.value),
                    });
                  }}
                  value={paramsSearch.provider_type_identifier || ""}
                >
                  <option value="" disabled selected>
                    Tipo de proveedor
                  </option>

                  {providerType.map((item) => {
                    return (
                      <option key={item.identifier} value={item.identifier}>
                        <h3>{item.description}</h3>
                      </option>
                    );
                  })}
                </SelectListdefault>
              </div>
              {serviciosType != 3 && (
                <div>
                  <h3>Servicio</h3>
                  <SelectListdefault
                    className="select-default-dos"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                    }}
                    id="sl6"
                    onChange={(e) => {
                      setParamsSearch({
                        ...paramsSearch,
                        service_type_identifier: parseInt(e.target.value),
                      });
                    }}
                    value={paramsSearch.service_type_identifier || ""}
                  >
                    <option value="" disabled selected>
                      Servicio
                    </option>

                    {serviceList.map((item) => {
                      return (
                        <option
                          key={item.identifier}
                          value={item.identifier}
                          selected={
                            item.identifier ==
                            paramsSearch.service_type_identifier
                          }
                        >
                          <h3>{item.description}</h3>
                        </option>
                      );
                    })}
                  </SelectListdefault>
                </div>
              )}

              <div>
                <h3>Proveedor</h3>
                <SelectListdefault
                  className="select-default-dos"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    //console.log("CATEGORIA ID SELECTED: ");
                    //console.log(e.target.value);
                    setParamsSearch({
                      ...paramsSearch,
                      provider_identifier: parseInt(e.target.value),
                    });
                  }}
                  value={paramsSearch.provider_identifier || ""}
                >
                  <option value="" disabled selected>
                    Proveedor
                  </option>

                  {listResultsProviders?.length > 0
                    ? listResultsProviders.map((item) => {
                        return (
                          <option
                            key={item.provider_identifier}
                            value={item.provider_identifier}
                          >
                            {item.name}
                          </option>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>
              <div>
                <h3>Ciudad</h3>
                <SelectListdefault
                  className="select-default-dos"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    //console.log("CATEGORIA ID SELECTED: ");
                    //console.log(e.target.value);
                    setParamsSearch({
                      ...paramsSearch,
                      city_identifier: parseInt(e.target.value),
                    });
                  }}
                  value={paramsSearch.city_identifier || ""}
                >
                  <option value="" disabled selected>
                    Ciudad
                  </option>

                  {cityList?.length > 0
                    ? cityList.map((item) => {
                        return (
                          <option key={item.identifier} value={item.identifier}>
                            {item.description}
                          </option>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>
              <div>
                <h3>Categoría</h3>
                <SelectListdefault
                  className="select-default-dos"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    //console.log("CATEGORIA ID SELECTED: ");
                    //console.log(e.target.value);
                    setParamsSearch({
                      ...paramsSearch,
                      category_identifier: parseInt(e.target.value),
                    });
                  }}
                  value={paramsSearch.category_identifier || ""}
                >
                  <option value="" disabled selected>
                    Categoría
                  </option>

                  {categoryServices?.length > 0
                    ? categoryServices.map((item) => {
                        return (
                          <option key={item.identifier} value={item.identifier}>
                            {item.description}
                          </option>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>

              {paramsSearch.service_type_identifier == 10 && (
                <div>
                  <h3>Plan de Alimentos</h3>
                  <SelectListdefault
                    className="select-default-dos"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                    }}
                    id="sl6"
                    onChange={(e) => {
                      setParamsSearch({
                        ...paramsSearch,
                        mealplan_identifier: parseInt(e.target.value),
                      });
                    }}
                    value={paramsSearch.mealplan_identifier || ""}
                  >
                    <option value="" disabled selected>
                      Planes
                    </option>

                    {foodPlan?.length > 0
                      ? foodPlan.map((item) => {
                          return (
                            <option
                              key={item.identifier}
                              value={item.identifier}
                            >
                              {item.description}
                            </option>
                          );
                        })
                      : null}
                  </SelectListdefault>
                </div>
              )}
            </div>

            <div className="deleteIcon-box">
              <FontAwesomeIcon
                className="deleteIcon"
                icon={faBroom}
                onClick={cleanInformation}
              />
            </div>
          </div>

          <div className="search-container">
            <div
              className="search-bar"
              draggable="true"
              onDragStart={(e) => {
                //console.log("ARRASTRANDO: ");
                e.preventDefault();
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  limpiarBotonesGris();
                  if (
                    paramsSearch.search_parameter != "" ||
                    paramsSearch.service_type_identifier !== null ||
                    paramsSearch.category_identifier != null ||
                    paramsSearch.city_identifier != null
                  ) {
                    searchProveedores();
                    //console.log("ENTRASTE A BUSCAR");
                  } else {
                    alert("Introduce más terminos por favor.");
                  }
                  //console.log("PARAMS DESDE SUBMIT");
                  //console.log(paramsSearch);
                }}
              >
                <input
                  type="text"
                  value={paramsSearch.search_parameter}
                  onChange={(e) => {
                    setParamsSearch({
                      ...paramsSearch,
                      search_parameter: e.target.value,
                    });
                  }}
                />

                <button onClick={() => {}}>Buscar</button>
              </form>
            </div>
            <div className="search-results">
              {listResults?.map((proveedor, index) => {
                if (proveedor.common_fee_list !== null) {
                  const moneda =
                    proveedor?.common_fee_list?.[0].currency_identifier == 1
                      ? "USD"
                      : "MXN";
                  const monedaOpuesta =
                    proveedor?.common_fee_list?.[0].currency_identifier == 1
                      ? "MXN"
                      : "USD";
                  const currentIdentifier =
                    proveedor?.common_fee_list?.[0].currency_identifier ?? 2;

                  const tarifa: number = parseFloat(
                    proveedor?.common_fee_list?.[0]?.fee ?? "0"
                  );
                  let tarifaTransformed: number = 0;
                  if (currentCurrency == 1 && currentIdentifier == 2) {
                    //// lo quiero en dolar y está en pesos
                    tarifaTransformed =
                      tarifa / dataCotizacionContext.feeCharges.changeType;
                  } else if (currentCurrency == 2 && currentIdentifier == 1) {
                    ///Lo quiero en pesos y está en dolar
                    tarifaTransformed =
                      tarifa * dataCotizacionContext.feeCharges.changeType;
                  } else {
                    tarifaTransformed = tarifa;
                  }

                  return (
                    <>
                      <div className="container-search-term">
                        <div className="search-term">
                          <h3 className="provider-style">
                            {truncateText(proveedor.provider.provider_name, 40)}
                          </h3>
                          <button
                            className={
                              botonesGris.has(`${index}-${"comun"}`)
                                ? "gris "
                                : "select-button "
                            }
                            onClick={() => {
                              const tarifaAmount: number = parseFloat(
                                proveedor?.common_fee_list?.[0]?.fee ?? "0"
                              );
                              toggleBotonGris(index, "comun");
                              saveServicio(
                                idCurrentDayService,
                                proveedor,
                                tarifaAmount,
                                proveedor?.common_fee_list?.[0].currency ??
                                  "Peso",
                                proveedor?.common_fee_list?.[0]
                                  .currency_identifier ?? 2,
                                proveedor?.common_fee_list?.[0]
                                  .fee_identifier ?? 0
                              );
                            }}
                          >
                            Seleccionar
                          </button>

                          <div>
                            <h3>
                              {" "}
                              {truncateText(
                                proveedor.service.service_name,
                                150
                              )}
                            </h3>
                            <h3>{proveedor.service.service_type}</h3>
                          </div>

                          <h3>
                            ${proveedor.common_fee_list[0].fee} {moneda}{" "}
                            {currentCurrency !== currentIdentifier
                              ? `[${tarifaTransformed.toFixed(
                                  2
                                )} ${monedaOpuesta}]`
                              : ""}
                          </h3>
                        </div>
                        {dataCotizacionContext.personalInfo.modeCotizacion ==
                          "Serie" && (
                          <div className="vigencia-box">
                            {dataCotizacionContext.personalInfo
                              .modeCotizacion == "Serie" && (
                              <div className="vigencia-box">
                                {proveedor.validity_list.map(
                                  (vigencia, index) => {
                                    return (
                                      <h3>
                                        {obtenerFechaDesdeString(
                                          vigencia.validity_start_date
                                        )}{" "}
                                        -{" "}
                                        {obtenerFechaDesdeString(
                                          vigencia.validity_end_date
                                        )}
                                      </h3>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  );
                } else if (proveedor.room_fee_list !== null) {
                  const baseDoble = proveedor?.room_fee_list?.[0]?.fee_TDBL / 2;
                  const tarifaSingleComplement =
                    (proveedor?.room_fee_list?.[0]?.fee_TSGL ?? 0) - baseDoble;

                  const tarifaSingle =
                    proveedor?.room_fee_list?.[0]?.fee_TSGL ?? 0;
                  const tarifaDouble =
                    (proveedor?.room_fee_list?.[0]?.fee_TDBL
                      ? proveedor?.room_fee_list?.[0]?.fee_TDBL / 2
                      : proveedor?.room_fee_list?.[0]?.fee_TDBL) ?? 0;
                  const tarifaTriple =
                    proveedor?.room_fee_list?.[0]?.fee_TTPL ?? 0;
                  const tarifaChild =
                    proveedor?.room_fee_list?.[0]?.fee_FP ?? 0;
                  const tarifaBB = proveedor?.room_fee_list?.[0]?.fee_BB ?? 0;
                  const tarifaMaid =
                    proveedor?.room_fee_list?.[0]?.fee_TMAID ?? 0;

                  const currentIdentifier =
                    proveedor?.room_fee_list?.[0]?.currency_identifier ?? 2;
                  const moneda =
                    proveedor?.room_fee_list?.[0]?.currency_identifier == 1
                      ? "USD"
                      : "MXN";
                  const monedaOpuesta =
                    proveedor?.room_fee_list?.[0]?.currency_identifier == 1
                      ? "MXN"
                      : "USD";
                  let tarifaTransformedSGL: number = 0;
                  let tarifaTransformedDBL: number = 0;
                  let tarifaTripleTransformed: number = 0;
                  let tarifaChildTransformed: number = 0;
                  let tarifaBBTransformed: number = 0;
                  let tarifaMaidTransformed: number = 0;

                  if (currentCurrency == 1 && currentIdentifier == 2) {
                    //// lo quiero en dolar y está en pesos
                    tarifaTransformedSGL =
                      tarifaSingle /
                      dataCotizacionContext.feeCharges.changeType;
                    tarifaTransformedDBL =
                      tarifaDouble /
                      dataCotizacionContext.feeCharges.changeType;
                    tarifaTripleTransformed =
                      tarifaTriple /
                      dataCotizacionContext.feeCharges.changeType;
                    tarifaChildTransformed =
                      tarifaChild / dataCotizacionContext.feeCharges.changeType;
                    tarifaBBTransformed =
                      tarifaBB / dataCotizacionContext.feeCharges.changeType;
                    tarifaMaidTransformed =
                      tarifaMaid / dataCotizacionContext.feeCharges.changeType;
                  } else if (currentCurrency == 2 && currentIdentifier == 1) {
                    ///Lo quiero en pesos y está en dolar
                    tarifaTransformedSGL =
                      tarifaSingle *
                      dataCotizacionContext.feeCharges.changeType;
                    tarifaTransformedDBL =
                      tarifaDouble *
                      dataCotizacionContext.feeCharges.changeType;
                    tarifaTripleTransformed =
                      tarifaTriple *
                      dataCotizacionContext.feeCharges.changeType;
                    tarifaChildTransformed =
                      tarifaChild * dataCotizacionContext.feeCharges.changeType;
                    tarifaBBTransformed =
                      tarifaBB * dataCotizacionContext.feeCharges.changeType;
                    tarifaMaidTransformed =
                      tarifaMaid * dataCotizacionContext.feeCharges.changeType;
                  } else {
                    tarifaTransformedSGL = tarifaSingle;
                    tarifaTransformedDBL = tarifaDouble;
                    tarifaTripleTransformed = tarifaTriple;
                    tarifaChildTransformed = tarifaChild;
                    tarifaBBTransformed = tarifaBB;
                    tarifaMaidTransformed = tarifaMaid;
                  }

                  return (
                    <>
                      {/*----------------------------- TSGL HABITACION------------------------------------- */}

                      <>
                        {dataCotizacionContext.personalInfo.typeRoom == 1 && (
                          <div className="container-search-term">
                            <div className="search-term-habitacion">
                              <h3 className="provider-style">
                                {truncateText(
                                  proveedor.provider.provider_name,
                                  40
                                )}
                              </h3>

                              <button
                                className={
                                  botonesGris.has(`${index}-${"SGL"}`)
                                    ? "gris "
                                    : "select-button "
                                }
                                onClick={() => {
                                  //console.log("TOCASTE EL BOTON CON CURRENCY::::: ");
                                  //console.log(proveedor?.room_fee_list?.[0]?.currency_identifier);
                                  toggleBotonGris(index, "SGL");
                                  saveServicio(
                                    idCurrentDayService,
                                    proveedor,
                                    proveedor?.room_fee_list?.[0]?.fee_TSGL ??
                                      0,
                                    proveedor?.room_fee_list?.[0]?.currency ??
                                      "Peso",
                                    currentIdentifier,
                                    proveedor?.room_fee_list?.[0]
                                      ?.fee_identifier ?? 0,
                                    0,
                                    "SGL"
                                  );
                                }}
                              >
                                Seleccionar
                              </button>
                              <div>
                                <h3>
                                  {truncateText(
                                    proveedor.service.service_name,
                                    100
                                  )}
                                </h3>
                                <h3>
                                  {proveedor.service.mealplan == "No Aplica"
                                    ? ""
                                    : proveedor.service.mealplan}
                                </h3>
                              </div>

                              <h3>
                                TSGL: ${proveedor.room_fee_list[0].fee_TSGL}{" "}
                                {moneda}
                                {currentCurrency !== currentIdentifier
                                  ? `[${tarifaTransformedSGL.toFixed(
                                      2
                                    )} ${monedaOpuesta}]`
                                  : ""}
                              </h3>
                            </div>

                            {dataCotizacionContext.personalInfo
                              .modeCotizacion == "Serie" && (
                              <div className="vigencia-box">
                                {proveedor.validity_list.map(
                                  (vigencia, index) => {
                                    return (
                                      <h3>
                                        {obtenerFechaDesdeString(
                                          vigencia.validity_start_date
                                        )}{" "}
                                        -{" "}
                                        {obtenerFechaDesdeString(
                                          vigencia.validity_end_date
                                        )}
                                      </h3>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </>

                      {/*----------------------------- TDBL HABITACION------------------------------------- */}
                      {dataCotizacionContext.personalInfo.typeRoom == 2 && (
                        <div className="container-search-term">
                          <div className="search-term-habitacion">
                            <h3 className="provider-style">
                              {truncateText(
                                proveedor.provider.provider_name,
                                40
                              )}
                            </h3>

                            <button
                              className={
                                botonesGris.has(`${index}-${"DBL"}`)
                                  ? "gris "
                                  : "select-button "
                              }
                              onClick={() => {
                                const thirdComplement =
                                  baseDoble -
                                  (proveedor?.room_fee_list?.[0]?.fee_TTPL ??
                                    0) /
                                    3;
                                toggleBotonGris(index, "DBL");
                                saveServicio(
                                  idCurrentDayService,
                                  proveedor,
                                  (proveedor?.room_fee_list?.[0]?.fee_TDBL
                                    ? proveedor?.room_fee_list?.[0]?.fee_TDBL /
                                      2
                                    : proveedor?.room_fee_list?.[0]
                                        ?.fee_TDBL) ?? 0,
                                  proveedor?.room_fee_list?.[0]?.currency ??
                                    "Peso",
                                  currentIdentifier,
                                  proveedor?.room_fee_list?.[0]
                                    ?.fee_identifier ?? 0,
                                  1,

                                  "DBL",
                                  tarifaSingleComplement,
                                  thirdComplement
                                );
                              }}
                            >
                              Seleccionar
                            </button>
                            <div>
                              <h3>
                                {truncateText(
                                  proveedor.service.service_name,
                                  100
                                )}{" "}
                                (p/p)
                              </h3>

                              <h3>
                                {proveedor.service.mealplan == "No Aplica"
                                  ? ""
                                  : proveedor.service.mealplan}
                              </h3>
                              <h3>
                                {proveedor.service.category == ""
                                  ? ""
                                  : proveedor.service.category}
                              </h3>
                            </div>

                            <h3>
                              TDBL: ${proveedor.room_fee_list[0].fee_TDBL / 2}{" "}
                              {moneda}{" "}
                              {currentCurrency !== currentIdentifier
                                ? `[${tarifaTransformedDBL.toFixed(
                                    2
                                  )} ${monedaOpuesta}]`
                                : ""}
                            </h3>
                          </div>
                          {dataCotizacionContext.personalInfo.modeCotizacion ==
                            "Serie" && (
                            <div className="vigencia-box">
                              {proveedor.validity_list.map(
                                (vigencia, index) => {
                                  return (
                                    <h3>
                                      {obtenerFechaDesdeString(
                                        vigencia.validity_start_date
                                      )}{" "}
                                      -{" "}
                                      {obtenerFechaDesdeString(
                                        vigencia.validity_end_date
                                      )}
                                    </h3>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {/*----------------------------- TTPL HABITACION------------------------------------- */}
                      {dataCotizacionContext.personalInfo.typeRoom == 3 && (
                        <div className="container-search-term">
                          <div className="search-term-habitacion">
                            <h3 className="provider-style">
                              {truncateText(
                                proveedor.provider.provider_name,
                                40
                              )}
                            </h3>

                            <button
                              className={
                                botonesGris.has(`${index}-${"TPL"}`)
                                  ? "gris "
                                  : "select-button "
                              }
                              onClick={() => {
                                const thirdComplement =
                                  baseDoble -
                                  (proveedor?.room_fee_list?.[0]?.fee_TTPL ??
                                    0) /
                                    3;
                                toggleBotonGris(index, "TPL");
                                saveServicio(
                                  idCurrentDayService,
                                  proveedor,
                                  proveedor?.room_fee_list?.[0]?.fee_TTPL ?? 0,
                                  proveedor?.room_fee_list?.[0]?.currency ??
                                    "Peso",
                                  currentIdentifier,
                                  proveedor?.room_fee_list?.[0]
                                    ?.fee_identifier ?? 0,
                                  2,
                                  "TPL"
                                );
                              }}
                            >
                              Seleccionar
                            </button>
                            <div>
                              <h3>
                                {truncateText(
                                  proveedor.service.service_name,
                                  100
                                )}
                              </h3>

                              <h3>
                                {proveedor.service.mealplan == "No Aplica"
                                  ? ""
                                  : proveedor.service.mealplan}
                              </h3>
                            </div>

                            <h3>
                              TTPL: ${proveedor.room_fee_list[0].fee_TTPL}{" "}
                              {moneda}{" "}
                              {currentCurrency !== currentIdentifier
                                ? `[${tarifaTripleTransformed.toFixed(
                                    2
                                  )} ${monedaOpuesta}]`
                                : ""}
                            </h3>
                          </div>
                          {dataCotizacionContext.personalInfo.modeCotizacion ==
                            "Serie" && (
                            <div className="vigencia-box">
                              {proveedor.validity_list.map(
                                (vigencia, index) => {
                                  return (
                                    <h3>
                                      {obtenerFechaDesdeString(
                                        vigencia.validity_start_date
                                      )}{" "}
                                      -{" "}
                                      {obtenerFechaDesdeString(
                                        vigencia.validity_end_date
                                      )}
                                    </h3>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {/*----------------------------- TChild HABITACION------------------------------------- */}

                      {true && (
                        <div className="container-search-term">
                          <div className="search-term-habitacion">
                            <h3 className="provider-style">
                              {truncateText(
                                proveedor.provider.provider_name,
                                40
                              )}
                            </h3>

                            <button
                              className={
                                botonesGris.has(`${index}-${"Child"}`)
                                  ? "gris "
                                  : "select-button "
                              }
                              onClick={() => {
                                toggleBotonGris(index, "Child");
                                saveServicio(
                                  idCurrentDayService,
                                  proveedor,
                                  proveedor?.room_fee_list?.[0]?.fee_TFP ?? 0,
                                  proveedor?.room_fee_list?.[0]?.currency ??
                                    "Peso",
                                  currentIdentifier,
                                  proveedor?.room_fee_list?.[0]
                                    ?.fee_identifier ?? 0,
                                  4,
                                  "Child"
                                );
                              }}
                            >
                              Seleccionar
                            </button>
                            <h3>Child</h3>
                            <h3>
                              TCHILD: ${proveedor.room_fee_list[0].fee_TFP}{" "}
                              {moneda}{" "}
                              {currentCurrency !== currentIdentifier
                                ? `[${tarifaChildTransformed.toFixed(
                                    2
                                  )} ${monedaOpuesta}]`
                                : ""}
                            </h3>
                          </div>
                          {dataCotizacionContext.personalInfo.modeCotizacion ==
                            "Serie" && (
                            <div className="vigencia-box">
                              {proveedor.validity_list.map(
                                (vigencia, index) => {
                                  return (
                                    <h3>
                                      {obtenerFechaDesdeString(
                                        vigencia.validity_start_date
                                      )}{" "}
                                      -{" "}
                                      {obtenerFechaDesdeString(
                                        vigencia.validity_end_date
                                      )}
                                    </h3>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {/*----------------------------- TBB HABITACION------------------------------------- */}
                      {true && (
                        <div className="container-search-term">
                          <div className="search-term-habitacion">
                            <h3 className="provider-style">
                              {truncateText(
                                proveedor.provider.provider_name,
                                50
                              )}
                            </h3>

                            <button
                              className={
                                botonesGris.has(`${index}-${"BB"}`)
                                  ? "gris "
                                  : "select-button "
                              }
                              onClick={() => {
                                toggleBotonGris(index, "BB");
                                saveServicio(
                                  idCurrentDayService,
                                  proveedor,
                                  proveedor?.room_fee_list?.[0]?.fee_BB ?? 0,
                                  proveedor?.room_fee_list?.[0]?.currency ??
                                    "Peso",
                                  currentIdentifier,
                                  proveedor?.room_fee_list?.[0]
                                    ?.fee_identifier ?? 0,
                                  4,
                                  "BB"
                                );
                              }}
                            >
                              Seleccionar
                            </button>
                            <h3>BB</h3>
                            <h3>
                              TBB: $
                              {proveedor.room_fee_list[0].fee_BB
                                ? proveedor.room_fee_list[0].fee_BB
                                : 0}{" "}
                              {moneda}{" "}
                              {currentCurrency !== currentIdentifier
                                ? `[${tarifaBBTransformed.toFixed(
                                    2
                                  )} ${monedaOpuesta}]`
                                : ""}
                            </h3>
                          </div>
                          {dataCotizacionContext.personalInfo.modeCotizacion ==
                            "Serie" && (
                            <div className="vigencia-box">
                              {proveedor.validity_list.map(
                                (vigencia, index) => {
                                  return (
                                    <h3>
                                      {obtenerFechaDesdeString(
                                        vigencia.validity_start_date
                                      )}{" "}
                                      -{" "}
                                      {obtenerFechaDesdeString(
                                        vigencia.validity_end_date
                                      )}
                                    </h3>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {/*----------------------------- TMAID HABITACION------------------------------------- */}
                      {true && (
                        <div className="container-search-term">
                          <div className="search-term-habitacion">
                            <h3 className="provider-style">
                              {truncateText(
                                proveedor.provider.provider_name,
                                40
                              )}
                            </h3>

                            <button
                              className={
                                botonesGris.has(`${index}-${"MAID"}`)
                                  ? "gris "
                                  : "select-button "
                              }
                              onClick={() => {
                                toggleBotonGris(index, "MAID");
                                saveServicio(
                                  idCurrentDayService,
                                  proveedor,
                                  proveedor?.room_fee_list?.[0]?.fee_TMAID ?? 0,
                                  proveedor?.room_fee_list?.[0]?.currency ??
                                    "Peso",
                                  currentIdentifier,
                                  proveedor?.room_fee_list?.[0]
                                    ?.fee_identifier ?? 0,
                                  4,
                                  "MAID"
                                );
                              }}
                            >
                              Seleccionar
                            </button>
                            <h3>MAID</h3>
                            <h3>
                              TMAID: ${proveedor.room_fee_list[0].fee_TMAID}{" "}
                              {moneda}{" "}
                              {currentCurrency !== currentIdentifier
                                ? `[${tarifaMaidTransformed.toFixed(
                                    2
                                  )} ${monedaOpuesta}]`
                                : ""}
                            </h3>
                          </div>
                          {dataCotizacionContext.personalInfo.modeCotizacion ==
                            "Serie" && (
                            <div className="vigencia-box">
                              {proveedor.validity_list.map(
                                (vigencia, index) => {
                                  return (
                                    <h3>
                                      {obtenerFechaDesdeString(
                                        vigencia.validity_start_date
                                      )}{" "}
                                      -{" "}
                                      {obtenerFechaDesdeString(
                                        vigencia.validity_end_date
                                      )}
                                    </h3>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  );
                } else if (proveedor.general_fee_list !== null) {
                  return (
                    <>
                      {proveedor?.general_fee_list?.map((servicio, indeex) => {
                        let tarifaTransformed: number = 0;
                        const currentIdentifier =
                          proveedor?.general_fee_list?.[indeex]
                            ?.currency_identifier ?? 2;

                        const moneda =
                          proveedor?.general_fee_list?.[indeex]
                            ?.currency_identifier == 1
                            ? "USD"
                            : "MXN";
                        const monedaOpuesta =
                          proveedor?.general_fee_list?.[indeex]
                            ?.currency_identifier == 1
                            ? "MXN"
                            : "USD";
                        if (currentCurrency == 1 && currentIdentifier == 2) {
                          //// lo quiero en dolar y está en pesos
                          tarifaTransformed =
                            parseFloat(servicio.fee) /
                            dataCotizacionContext.feeCharges.changeType;
                        } else if (
                          currentCurrency == 2 &&
                          currentIdentifier == 1
                        ) {
                          ///Lo quiero en pesos y está en dolar
                          tarifaTransformed =
                            parseFloat(servicio.fee) *
                            dataCotizacionContext.feeCharges.changeType;
                        } else {
                          tarifaTransformed = parseFloat(servicio.fee);
                        }

                        return (
                          <>
                            <div className="container-search-term">
                              <div className="search-term">
                                <h3 className="provider-style">
                                  {truncateText(
                                    proveedor.provider.provider_name,
                                    40
                                  )}
                                </h3>
                                <button
                                  className={
                                    botonesGris.has(`${index}-${indeex}`)
                                      ? "gris "
                                      : "select-button "
                                  }
                                  onClick={() => {
                                    //console.log("servicio ACTUAL::::::");
                                    //console.log(servicio);
                                    //console.log(">>>>>>>>>>CURRENCY IDENTIFIER!!!");
                                    //console.log(proveedor?.general_fee_list?.[indeex]?.currency_identifier);
                                    //console.log("Muestra de su arreglo: ");
                                    //console.log(proveedor?.general_fee_list);
                                    toggleBotonGris(index, indeex.toString());
                                    saveServicio(
                                      idCurrentDayService,
                                      proveedor,
                                      parseFloat(servicio.fee),
                                      proveedor?.general_fee_list?.[indeex]
                                        ?.currency ?? "Peso",
                                      proveedor?.general_fee_list?.[indeex]
                                        ?.currency_identifier ?? 2,
                                      proveedor?.general_fee_list?.[indeex]
                                        ?.fee_identifier ?? 0,
                                      0,
                                      "",
                                      0,
                                      0,
                                      proveedor?.general_fee_list?.[indeex]
                                        ?.capacity ?? ""
                                    );
                                  }}
                                >
                                  Seleccionar
                                </button>

                                <div>
                                  <h3>{proveedor.service.service_name} </h3>
                                  {servicio.capacity.trim() && (
                                    <h3>
                                      Capacidad:{" "}
                                      {truncateText(servicio.capacity, 40)}
                                    </h3>
                                  )}
                                </div>

                                <h3>
                                  ${servicio.fee} {moneda}
                                  {currentCurrency !== currentIdentifier
                                    ? `[${tarifaTransformed.toFixed(
                                        2
                                      )} ${monedaOpuesta}]`
                                    : ""}
                                </h3>
                              </div>

                              {(dataCotizacionContext.personalInfo
                                .modeCotizacion == "Serie" ||
                                serviciosType == 3) && (
                                <div className="vigencia-box">
                                  {proveedor.validity_list
                                    .filter(
                                      (vigencia, indiceActual) =>
                                        indiceActual == indeex
                                    )
                                    .map((vigencia, index) => {
                                      return (
                                        <h3>
                                          {obtenerFechaDesdeString(
                                            vigencia.validity_start_date
                                          )}{" "}
                                          -{" "}
                                          {obtenerFechaDesdeString(
                                            vigencia.validity_end_date
                                          )}
                                        </h3>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </SearchServicesStyles>
  );
}

const SearchServicesStyles = styled.div`
  .deleteIcon-box {
    position: fixed;
  }
  .list-combos {
  }
  h3 {
    font-weight: 400;
  }
  .provider-style {
    font-weight: 600;
  }
  .gris {
    background-color: grey;
    border: none;
    color: white;
    font-size: 0.6vw;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: all 0.4s ease-out;
    border: grey;
    padding: 0.2rem;
  }
  form {
    width: 100%;
  }
  .search-bar {
    display: flex;
  }
  .search-bar input {
    width: 80%;
  }
  .container-search-term {
    border-bottom: grey solid 1px;
    margin-bottom: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .vigencia-box {
  }
  .vigencia-box h3 {
    font-size: 0.85vw;
  }

  @media screen and (max-width: 900px) {
    .vigencia-box h3 {
      font-size: 1.5vw;
    }
  }
  .deleteIcon {
    margin-top: 2rem;
    font-size: 1.2rem;
    border-radius: 50%;
    padding: 0.6rem;
    cursor: pointer;
    background-color: #29a5dc;
    color: white;
  }

  .select-button {
    border: none;
    background-color: rgb(8, 166, 165);
    color: white;
    font-size: 0.6vw;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: all 0.4s ease-out;
    border: rgb(8, 166, 165) solid 1px;
    padding: 0.2rem;
    max-height: 1.4rem;
  }
  .provider-style {
    color: rgb(8, 166, 165);
  }
  .select-button:hover {
    color: rgb(8, 166, 165);
    background-color: white;
    border: rgb(8, 166, 165) solid 1px;
  }
  .search-term {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .search-term-habitacion {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 0.2rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .search-term h3,
  .search-term-habitacion h3 {
    font-size: 0.86vw !important;
  }
  @media screen and (max-width: 900px) {
    .search-term h3,
    .search-term-habitacion h3 {
      font-size: 1.6vw !important;
    }
    .select-button {
      font-size: 1.2vw !important;
    }
    .combo-box-features h3 {
      font-size: 1.6vw !important;
    }
  }
  .search-results {
    height: 85%;
    overflow-y: scroll;
    margin-top: 1rem;
  }
  .combo-box-features {
    /* border: black solid 1px; */
    width: 40%;
    min-width: 40%;
    height: 100%;
    min-height: 100%;
  }
  .combo-box-features div div {
    display: grid;
    grid-template-columns: 2fr 3fr;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
  }
  .combo-box-features div h3 {
    text-align: center;
  }

  .container-box-dos {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    overflow-y: hidden;
    max-height: 80%;
    min-height: 80%;
  }
  .search-container {
    width: 60%;
    min-width: 60%;
  }
  .combo-box-features h3 {
    font-size: 0.85vw;
  }
  .header-box-container {
    margin-top: 1rem;
    margin-left: 1rem;

    display: flex;
    justify-content: space-between;
  }
  .header-box-container h3 {
    color: rgb(8, 166, 165);
  }
  .combo-box-directions {
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(211, 211, 211, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  z-index: 101;
  padding: 50px 0px;
  .x-mark-box {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 20%;
    padding-top: 15%;
    position: fixed;
    top: -50px;
    right: -10px;
  }
  .x-mark {
    font-size: 1.8rem;
    cursor: pointer;
    margin-right: 1rem;
  }
  .search-box-container {
    width: 40%;
    height: 600px;
    border: rgb(8, 166, 165) solid 2px;
    border-radius: 1.2rem;
    background-color: white;
    position: absolute;
    top: calc(50% - 300px);
  }
  @media screen and (max-width: 900px) {
    .search-box-container {
      width: 80%;
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: none;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  align-items: center;
  max-height: 38.4px !important;
  border: black solid 1px;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;

function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength) + "...";
  }
}
