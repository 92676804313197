import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import GerenteTable from "./GerenteTable";
import OptionSwitch from "./Components/OptionSwitch";
import CotizacionDetails from "./CotizacionDetails";
import { BrowserRouter, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import OperationsContainer from "./OperationsContainer";
import DefinicionPrestador from "./DefinicionPrestador";
import DesgloceHotel from "./Desgloces/DesgloceHotel";
import ResumenDesgloce from "./Desgloces/ResumenDesgloce";
import AssignGuide from "./AssignGuide";
import VerDesgloce from "./VerDesgloce";
import ManageQuejas from "./ManageQuejas";
import ManageProforma from "./Proforma/ManageProforma";
import HojaControl from "./HojaControl/HojaControl";

export default function ManageOperacion() {
  return (
    <ManageOperationStyles>
      <Routes>
        <Route
          path="Manage-Operacion"
          element={<OperationsContainer />}
        ></Route>
        <Route
          path="Definicion-Prestador"
          element={
            <>
              <DefinicionPrestador />
            </>
          }
        ></Route>
        <Route
          path="Desgloce-Entrada"
          element={
            <>
              <ResumenDesgloce />
            </>
          }
        ></Route>
        <Route
          path="Desgloce-Asignar-Guia"
          element={
            <>
              <AssignGuide />
            </>
          }
        ></Route>
        <Route
          path="Desgloce-Guia"
          element={
            <>
              <VerDesgloce />
            </>
          }
        ></Route>
        <Route
          path="Quejas-Operacion"
          element={
            <>
              <ManageQuejas />
            </>
          }
        ></Route>
        <Route
          path="Proforma-Operacion"
          element={
            <>
              <ManageProforma />
            </>
          }
        ></Route>
        <Route
          path="Hoja-Control"
          element={
            <>
              <HojaControl />
            </>
          }
        ></Route>
      </Routes>

      <br />
      <br />

      <br />
      <br />
      <br />
    </ManageOperationStyles>
  );
}

const ManageOperationStyles = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 3rem;
`;
