import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import { useCatalogs } from "../../../context/CatalogContext";
import {
  CategoryInterface,
  GeneralService,
  ProveedorElement,
  QuoteGeneralElement,
  ServiceElementI,
  coinInterface,
} from "../../../interfaces/GeneralTypes";
import agregar from "../../../images/AGREGAR.png";
import deletelogo from "../../../images/deletelogo.png";
import edit from "../../../images/edit.png";
import { serviceInterface } from "../../../interfaces/GeneralTypes";
import { DataModel } from "../../../interfaces/CotizacionI";
import { useCatalogsDos } from "../../../context/CatalogContextDos";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";
import Select from "react-select";
interface GElementI {
  isShowingAddButton: boolean;
  deleteGeneralService: (idService: string) => void;
  servicioGeneral: QuoteGeneralElement;
  serviciosGeneralesList: QuoteGeneralElement[];
  setServiciosGeneralesList: React.Dispatch<
    React.SetStateAction<QuoteGeneralElement[]>
  >;
  addNewGeneralService: (idSelected: number) => void;
  indiceSelected: number;
}
export default function GElement({
  isShowingAddButton,
  deleteGeneralService,
  servicioGeneral,
  serviciosGeneralesList,
  setServiciosGeneralesList,
  addNewGeneralService,
  indiceSelected,
}: GElementI) {
  const { catalogsData } = useCatalogs();
  const { catalogsDataDos } = useCatalogsDos();
  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  const [serviceList, setServiceList] = useState<serviceInterface[]>([]);
  const [proveedoresList, setProveedoresList] = useState<ProveedorElement[]>(
    []
  );
  const [servicios, setServicios] = useState<GeneralService[]>();
  const [categoriaServicios, setCategoriaServicios] =
    useState<CategoryInterface[]>();
  const [servicesList, setServicesList] = useState<ServiceElementI[]>([]);

  const [generalServiceData, setGeneralServiceData] =
    useState<QuoteGeneralElement>(servicioGeneral);

  const { showLoader, setShowLoader, currentCurrency, dataCotizacionContext } =
    useContext(AppContext);
  useEffect(() => {
    //console.log("Tipo de servicios:::");
    //console.log(catalogsData?.service_type_list);
    setServiceList(catalogsData?.service_type_list);
  }, [catalogsData]);
  useEffect(() => {
    //console.log("VALOR DE LISTA DE SERVICIOS:::::::");
    //console.log(serviceList);
  }, [serviceList]);
  useEffect(() => {
    setServicios(catalogsDataDos?.general_services_list);
    //console.log("SERVICIOS ACTUALIZADOS:::");
    //console.log(catalogsDataDos?.general_services_list);
    setCategoriaServicios(catalogsDataDos?.expenses_category_list);
  }, [catalogsDataDos]);
  useEffect(() => {
    //console.log("Forma final de servicios:");
    //console.log(servicios);
  }, [servicios]);
  useEffect(() => {
    setCurrencyList(catalogsData?.currency_list);
  }, [catalogsData]);
  const getListProviders = async (tipoServicio: number) => {};
  useEffect(() => {
    // Definir una función async dentro del useEffect
    const fetchData = async () => {
      //console.log("IMPRIME TIPO DE SERVICIO");
      if (generalServiceData.service_type !== 0) {
        //console.log("EJECUTA BUSQUEDA DE LISTA:::::");
        setShowLoader(true);

        if (
          generalServiceData.service_type == 29 &&
          generalServiceData.expenses_type_identifier == 0
        ) {
          setShowLoader(false);
          return;
        }
        const searchProviderUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/searchProviderByServiceType`;

        const service_date = "2023-07-17";
        const service_type_identifier = generalServiceData.service_type;
        const category_identifier = null;
        const lender_type_identifier = null;
        const search_parameter = null;
        let expense_type_identifier =
          generalServiceData.expenses_type_identifier == 0 ||
          generalServiceData.expenses_type_identifier == 9
            ? null
            : generalServiceData.expenses_type_identifier;
        expense_type_identifier =
          service_type_identifier !== 29 ? null : expense_type_identifier;

        try {
          const response = await fetch(searchProviderUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              service_type_identifier,
              expense_type_identifier,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            //console.log("LLAMADA:");
            //console.log("service_type_identifier", service_type_identifier);
            //console.log("expense_type_identifier", expense_type_identifier);
            //console.log("Data for combobox SERVICIOS!!! BUSQUEDA>>>>>>>:",data);

            setProveedoresList(data.data.providers_list);

            setShowLoader(false);
          } else {
            console.error("Error in response:", response);
            setShowLoader(false);
          }
        } catch (error) {
          console.error("Error in request:", error);
          alert("Error en el servicio");
        }
      }
    };

    // Llamar a la función async dentro del useEffect
    fetchData();
  }, [
    generalServiceData.service_type,
    generalServiceData.expenses_type_identifier,
  ]);

  const [isMounted, setIsMounted] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      if (
        generalServiceData.service_type != 0 &&
        generalServiceData.provider_id != 0
      ) {
        //console.log("BUSCANDO NUEVOS SERVICIOS:::::");
        setShowLoader(true);
        const searchProviderUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/searchGeneralServices`;
        //console.log("generalServiceData.service_type");
        //console.log(generalServiceData.service_type);
        //console.log("generalServiceData.provider_id");
        //console.log(generalServiceData.provider_id);

        const service_type_identifier = generalServiceData.service_type;
        const provider_identifier = generalServiceData.provider_id;

        try {
          const response = await fetch(searchProviderUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              service_type_identifier,
              provider_identifier,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            // //console.log("Data for SERVICES:", data);
            //   setProveedoresList(data.data.providers_list);
            setServicesList(data.data.services_list);
            if (isMounted) {
              setGeneralServiceData({
                ...generalServiceData,
                service_name_id: 0,
                fee_price: 0,
                number_days: "0",
                total_price: 0,
              });
            } else {
              setIsMounted(true);
            }

            setShowLoader(false);
          } else {
            console.error("Error in response:", response);
            setShowLoader(false);
          }
        } catch (error) {
          console.error("Error in request:", error);
          alert("Error en el servicio");
          setShowLoader(false);
        }
      }
    };

    // Llamar a la función async dentro del useEffect
    fetchData();
  }, [generalServiceData.service_type, generalServiceData.provider_id]);
  useEffect(() => {
    // //console.log("Proveedores LIST CAMBIO---------------");
    // //console.log(proveedoresList);
  }, [proveedoresList]);
  useEffect(() => {
    // //console.log("Servicios cambió");
    // //console.log(servicesList);
  }, [servicesList]);

  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    setIsShownDelete(false);
    // borrarServicios(serviceIndividual.identifierService, servicioDay.identifierDay)
    borrarElementoGeneral();
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };

  const borrarElementoGeneral = () => {
    deleteGeneralService(servicioGeneral.identifierGeneralService);
  };
  useEffect(() => {
    setServiciosGeneralesList({
      ...serviciosGeneralesList,
    });
    const newServiciosGenerales: QuoteGeneralElement[] =
      serviciosGeneralesList.map((servicioG, index) => {
        return servicioG.identifierGeneralService !=
          servicioGeneral.identifierGeneralService
          ? servicioG
          : generalServiceData;
      });
    setServiciosGeneralesList(newServiciosGenerales);
    //console.log("GENERAL SERVICE DATA::::");
    //console.log(generalServiceData);
  }, [generalServiceData]);
  let nombreServicio = servicios?.find((serv, index) => {
    return serv.identifier == generalServiceData.service_type;
  })?.description;
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    {
      value: "1",
      label:
        "Texto largo de la opción 1 que se desborda en varias líneas si es necesario.",
    },
    { value: "2", label: "Texto corto de la opción 2." },
    {
      value: "3",
      label:
        "Opción 3 con un texto largo que también se desborda en varias líneas.",
    },
    // Agrega más opciones según sea necesario
  ];

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log("CHECKBOX!!!!!!!!!!!!!!!!!!!!!!!!::");
    //console.log(event.target.checked);
    setGeneralServiceData({
      ...generalServiceData,
      isIncludedOpt: event.target.checked,
    });
  };
  return (
    <>
      <GElementStyles>
        <div className="box-service-general">
          <div className="select-g-box">
            <div>
              <SelectListdefault
                style={{
                  width: "90%",
                  maxWidth: "90%",
                }}
                id="sl6"
                onChange={(e) => {
                  setGeneralServiceData({
                    ...generalServiceData,
                    service_type: parseInt(e.target.value),
                    provider_id: null,
                    service_name_id: null,
                    fee_price: 0,
                    number_days: "0",
                    total_price: 0,
                    fee_identifier: undefined,
                  });

                  getListProviders(parseInt(e.target.value));
                }}
                value={generalServiceData.service_type}
              >
                <option value="" disabled selected>
                  Servicio
                </option>

                {servicios?.length ?? 0 > 0
                  ? servicios?.map((item) => {
                      return (
                        <option key={item.identifier} value={item.identifier}>
                          {item.description}
                        </option>
                      );
                    })
                  : null}
              </SelectListdefault>
            </div>
            <div>
              <SelectListdefault
                style={{
                  width: "90%",
                  maxWidth: "90%",
                }}
                id="sl6"
                onChange={(e) => {
                  setGeneralServiceData({
                    ...generalServiceData,
                    expenses_type_identifier: parseInt(e.target.value),
                    provider_id: null,
                    service_name_id: null,
                    fee_price: 0,
                    number_days: "0",
                    total_price: 0,
                    fee_identifier: undefined,
                  });
                  setServicesList([]);

                  getListProviders(parseInt(e.target.value));
                }}
              >
                <option value="" disabled selected>
                  Categoria
                </option>

                {categoriaServicios?.length ?? 0 > 0
                  ? categoriaServicios?.map((item) => {
                      return (
                        <option
                          key={item.identifier}
                          value={item.identifier}
                          selected={
                            item.identifier ==
                            generalServiceData.expenses_type_identifier
                          }
                        >
                          {item.description}
                        </option>
                      );
                    })
                  : null}
              </SelectListdefault>
            </div>
            <div>
              <SelectListdefault
                style={{
                  width: "90%",
                  maxWidth: "90%",
                }}
                id="sl6"
                onChange={(e) => {
                  if (generalServiceData.service_name_id) {
                    if (
                      generalServiceData.service_type > 0 &&
                      generalServiceData.service_name_id > 0
                    ) {
                      setGeneralServiceData({
                        ...generalServiceData,
                        provider_id: parseInt(e.target.value),
                        service_name_id: null,
                        fee_price: 0,
                        number_days: "0",
                        total_price: 0,
                        fee_identifier: undefined,
                      });

                      return;
                    }
                  }

                  setGeneralServiceData({
                    ...generalServiceData,
                    provider_id: parseInt(e.target.value),
                    fee_identifier: undefined,
                  });
                }}
                value={generalServiceData.provider_id || ""}
              >
                <option value="" disabled selected>
                  Proveedor
                </option>

                {proveedoresList?.length > 0
                  ? proveedoresList.map((item, index) => {
                      return (
                        <option
                          key={item.fee_identifier}
                          value={item.register_identifier}
                        >
                          {item.provider_name}
                        </option>
                      );
                    })
                  : null}
              </SelectListdefault>
            </div>
            <div>
              <SelectListdefault
                style={{
                  width: "90%",
                  maxWidth: "90%",
                }}
                id="sl6"
                onChange={(e) => {
                  let tarifaTransformed = 0;
                  let tarifaOriginal: number = parseFloat(
                    servicesList.find((servicioData, index) => {
                      return (
                        servicioData.general_fee_list.fee_identifier ==
                        parseInt(e.target.value)
                      );
                    })?.general_fee_list.fee ?? "0"
                  );

                  let service_identifier: number =
                    servicesList.find((servicioData, index) => {
                      return (
                        servicioData.general_fee_list.fee_identifier ==
                        parseInt(e.target.value)
                      );
                    })?.service_identifier ?? 0;

                  let curencyId: number =
                    servicesList.find((servicioData, index) => {
                      return (
                        servicioData.general_fee_list.fee_identifier ==
                        parseInt(e.target.value)
                      );
                    })?.general_fee_list.currency_identifier ?? 1;
                  if (currentCurrency == 1 && curencyId == 2) {
                    //// lo quiero en dolar y está en pesos
                    tarifaTransformed =
                      tarifaOriginal /
                      dataCotizacionContext.feeCharges.changeType;
                  } else if (currentCurrency == 2 && curencyId == 1) {
                    ///Lo quiero en pesos y está en dolar
                    tarifaTransformed =
                      tarifaOriginal *
                      dataCotizacionContext.feeCharges.changeType;
                  } else {
                    tarifaTransformed = tarifaOriginal;
                  }
                  setGeneralServiceData({
                    ...generalServiceData,
                    fee_identifier: parseInt(e.target.value),
                    service_name_id: service_identifier,
                    fee_price: tarifaTransformed,
                    number_days: "1",
                    currency: currentCurrency == 1 ? "Dolar" : "Peso",
                    currency_identifier: currentCurrency,
                  });
                }}
                value={generalServiceData.fee_identifier || ""}
              >
                <option value="" disabled selected>
                  Servicio
                </option>

                {servicesList?.length ?? 0 > 0
                  ? servicesList?.map((item, index) => {
                      // //console.log("Service LIST<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ");
                      // //console.log(item);
                      return (
                        <option
                          key={item.general_fee_list.fee_identifier}
                          value={item.general_fee_list.fee_identifier}
                          // selected={
                          //   item.general_fee_list.fee_identifier ==
                          //   generalServiceData.service_name_id
                          // }
                        >
                          {!item.service_name.trim()
                            ? "Servicio"
                            : `${item.service_name} [$ ${
                                currentCurrency == 1 &&
                                item.general_fee_list.currency_identifier == 2
                                  ? (
                                      parseFloat(item.general_fee_list.fee) /
                                      dataCotizacionContext.feeCharges
                                        .changeType
                                    ).toFixed(2)
                                  : currentCurrency == 2 &&
                                    item.general_fee_list.currency_identifier ==
                                      1
                                  ? (
                                      parseFloat(item.general_fee_list.fee) *
                                      dataCotizacionContext.feeCharges
                                        .changeType
                                    ).toFixed(1)
                                  : parseFloat(
                                      item.general_fee_list.fee
                                    ).toFixed(2)
                              }]`}
                        </option>
                      );
                    })
                  : null}
              </SelectListdefault>
            </div>

            <div>
              <h3 className="fee-results">
                {generalServiceData.fee_price == 0
                  ? ""
                  : generalServiceData.fee_price.toFixed(2)}
              </h3>
            </div>
            <div className="input-container">
              <input
                type="text"
                value={generalServiceData.number_days}
                onChange={(e) => {
                  let value = e.target.value;

                  // Elimina puntos adicionales si hay más de uno
                  const parts = value.split(".");
                  if (parts.length > 2) {
                    value = parts[0] + "." + parts.slice(1).join("");
                  }

                  if (value == "") value = "0";

                  // Validar que solo se permitan números y un único punto decimal
                  if (/^\d*\.?\d*$/.test(value)) {
                    setGeneralServiceData({
                      ...generalServiceData,
                      number_days: value,
                    });
                  }
                }}
              />
            </div>
            <div className="text-price">
              <h3>
                {(
                  generalServiceData.fee_price *
                  parseFloat(generalServiceData.number_days)
                ).toFixed(2)}
              </h3>
            </div>
          </div>

          <div className="box-icons-g">
            <img
              src={deletelogo}
              alt=""
              width={25}
              onClick={handleDeleteClick}
            />
            {true && (
              <img
                src={agregar}
                alt=""
                width={25}
                onClick={() => addNewGeneralService(indiceSelected)}
              />
            )}
          </div>
        </div>
      </GElementStyles>

      {isShownDelete && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          itemName={`¿Deseas borrar el servicio  ${
            servicios?.find((serv, index) => {
              return serv.identifier == generalServiceData.service_type;
            })?.description ?? "Alimentos"
          }?`}
          setIsShownDelete={setIsShownDelete}
        />
      )}
    </>
  );
}

const GElementStyles = styled.div`
  .input-container {
  }
  .checkbox-container {
    display: flex;
    justify-content: center;
  }
  .input-container input {
    width: 95%;
    max-width: 95%;
    text-align: center;
  }
  .box-service-general {
    display: flex;
  }

  .select-g-box {
    width: 93%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.3fr 0.4fr 0.5fr;
    align-items: center;
    justify-content: center;
    border: black solid 1px;
    padding-bottom: 0.4rem;
  }
  .box-icons-g {
    width: 7%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
  }
  .text-price h3 {
    text-align: center;
    font-size: 0.7vw;
  }
  .fee-results {
    font-size: 0.7vw;
    text-align: center;
  }
  @media screen and (max-width: 900px) {
    .text-price h3 {
      font-size: 1.2vw;
    }
    input,
    .fee-results {
      font-size: 1.2vw;
    }
  }
`;
const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem 0.2rem 0.5rem;
  width: 100% !important;
  min-width: 100%;
  border: none;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.7vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  .fake-icon {
    max-width: 10px !important;
  }
  /* white-space: pre-wrap; */
  @media screen and (max-width: 900px) {
    font-size: 1.2vw;
  }
`;
