import React, { useEffect, useState } from "react";
import { ProformaAdvance } from "./ProformaI";
import styled from "styled-components";
import deleteIcon from "../../../../images/deletelogo.png";
import addIcon from "../../../../images/AGREGAR.png";
import { v4 as uuidv4 } from "uuid";
import { useLanguage } from "./contextLanguage/LanguageContext";
import { translations } from "./contextLanguage/translations";
import { isEqual } from "lodash";

interface AnticipoElementI {
  currentProformaAdvance: ProformaAdvance;
  proformaAdvances: ProformaAdvance[];
  setProformaAdvances: React.Dispatch<React.SetStateAction<ProformaAdvance[]>>;
  isIncluidingPlus: boolean;
}

export default function AnticipoElement({
  currentProformaAdvance,
  proformaAdvances,
  setProformaAdvances,
  isIncluidingPlus,
}: AnticipoElementI) {
  const [actualAdvance, setActualAdvance] = useState<ProformaAdvance>(
    currentProformaAdvance
  );
  const { language, setLanguage } = useLanguage();
  useEffect(() => {
    if (!isEqual(actualAdvance, currentProformaAdvance)) {
      const newArrayAdvances: ProformaAdvance[] = proformaAdvances.map(
        (advanceN, index) => {
          return advanceN.id_local !== actualAdvance.id_local
            ? advanceN
            : actualAdvance;
        }
      );
      setProformaAdvances(newArrayAdvances);
    }
  }, [actualAdvance]);
  const deleteAdvance = () => {
    const newArrayAdvance: ProformaAdvance[] = proformaAdvances.filter(
      (advanceN, index) => {
        return advanceN.id_local !== actualAdvance.id_local;
      }
    );
    setProformaAdvances(newArrayAdvance);
  };
  const addNewAdvance = () => {
    let identificador = uuidv4();
    const newElement: ProformaAdvance = {
      advance_identifier: 0,
      proforma_identifier: 0,
      payment_number: 0,
      payday_limit: "",
      currency_identifier: 1,
      currency: "",
      total: 0,
      id_local: identificador,
    };
    const newArrayAdvance: ProformaAdvance[] = [
      ...proformaAdvances,
      newElement,
    ];
    setProformaAdvances(newArrayAdvance);
  };
  useEffect(() => {
    setActualAdvance(currentProformaAdvance);
  }, [currentProformaAdvance]);
  return (
    <AnticipoElementStyle>
      <div className="container-icons">
        {/* <img src={deleteIcon} alt="" width={25} onClick={deleteAdvance} className="icon-anticipo"/> */}
        {true && (
          <img
            src={addIcon}
            alt=""
            width={25}
            onClick={addNewAdvance}
            className="icon-anticipo"
          />
        )}
      </div>
      <div className="anticipo-element">
        <div className="contenido">
          <div>
            <h3>{translations[language].extra_fields.limit_date}</h3>
          </div>
          <div>
            <input
              type="date"
              value={actualAdvance.payday_limit}
              onChange={(e) => {
                setActualAdvance({
                  ...actualAdvance,
                  payday_limit: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <input
          type="text"
          value={actualAdvance.total}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "") {
              setActualAdvance({
                ...actualAdvance,
                total: 0,
              });
            } else {
              const parsedValue = parseFloat(value);
              if (!isNaN(parsedValue)) {
                setActualAdvance({
                  ...actualAdvance,
                  total: parsedValue,
                });
              }
            }
          }}
        />
      </div>
    </AnticipoElementStyle>
  );
}

const AnticipoElementStyle = styled.div`
  display: flex;
  justify-content: end;
  .icon-anticipo {
    cursor: pointer;
  }
  .container-icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .anticipo-element {
    /* border: black solid 1px; */
    margin-bottom: 0.5rem;
    padding: 0.3rem;
    display: grid;
    grid-template-columns: 3fr 0.5fr;
    width: 50%;
  }
  .container-input {
    border: red solid 1px;
  }
  .contenido {
    display: grid;
    grid-template-columns: 3fr 1fr;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 0, 128, 0.6);
    padding: 0.2rem;
  }
  input {
    min-width: 90%;
    max-width: 90%;
    text-align: center;
  }
  h3 {
    font-size: 0.85vw;
  }
  @media screen and (max-width: 900px) {
    h3 {
      font-size: 1.5vw;
    }
    .anticipo-element {
      width: 80%;
    }
  }
`;
