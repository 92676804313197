import React, { createContext, useContext, useEffect, useState } from "react";
import { AppContext } from "./appContext";
import {
  CatalogsData,
  Country,
  City,
  Neighborhood,
  Zipcode,
  Currency,
  Season,
  RegistrationType,
  ProviderType,
  ServiceType,
  View,
  Bank,
  CatalogsContextType,
  CatalogsContextTypeDos,
  CatalogsContextTypeOperacion,
} from "../interfaces/GeneralTypes";
import { CatalogsDataDos, QuoteType, Series } from "../interfaces/GeneralTypes";
import { CatalogDataOperacion } from "./OperacionI";
import {
  CatalogDataContabilidad,
  CatalogsContextTypeContabilidad,
} from "./ContabilidadCatalogI";

const OperacionCatalog = createContext<CatalogsContextTypeContabilidad>({
  catalogsDataContabilidad: {
    accounting_bank_accounts_list: [],
    banks_list: [],
    refund_type_list: [],
    refund_status_list: [],
    provider_refund_reason_list: [],
    agency_refund_reason_list: [],
  },
});
interface AppProviderProps {
  children: React.ReactNode;
}

const CatalogsProviderContabilidad: React.FC<AppProviderProps> = ({
  children,
}) => {
  const { showLoader, setShowLoader } = useContext(AppContext);
  const [catalogsDataContabilidad, setCatalogsDataContabilidad] =
    useState<CatalogDataContabilidad>({
      accounting_bank_accounts_list: [],
      banks_list: [],
      refund_type_list: [],
      refund_status_list: [],
      provider_refund_reason_list: [],
      agency_refund_reason_list: [],
    });

  useEffect(() => {
    setShowLoader(true);
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/accounting/accountingCatalogs`
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log("------------------------------------Trayendo datos de la API CATALOGO DE CONTABILIDAD--------------------------------------------------: ");
        //console.log(data.data);
        setCatalogsDataContabilidad({
          accounting_bank_accounts_list:
            data.data.accounting_bank_accounts_list,
          banks_list: data.data.banks_list,
          refund_type_list: data.data.refund_type_list,
          refund_status_list: data.data.refund_status_list,
          provider_refund_reason_list: data.data.provider_refund_reason_list,
          agency_refund_reason_list: data.data.agency_refund_reason_list,
        });

        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        console.error(error);
      });
  }, []);

  return (
    <OperacionCatalog.Provider value={{ catalogsDataContabilidad }}>
      {children}
    </OperacionCatalog.Provider>
  );
};

const useCatalogsContabilidad = () => useContext(OperacionCatalog);

export { CatalogsProviderContabilidad, useCatalogsContabilidad };
