import { NameProvider } from "../../../../interfaces/Cotizacion";

export interface QuoteItemI {
  quote_identifier: number;
  name: string;
  creation_date: string;
  agency: string;
  arrival_date: string;
  departure_date: string;
  creator_user_identifier: number;
  creator_user: string;
  assigned_user_identifier: number;
  assigned_user: string;
}
export interface QuoteListElementI {
  identifier: number;
  name: string;
  version: number;
}

export interface Info {
  list_type: string;
  count: number;
  pages: number;
}

export interface DataResponseQuote {
  all_quotes_list: QuoteItemI[];
  assigned_quotes_list: QuoteItemI[];
  info: Info;
}

interface UserI {
  user_identifier: number;
  user_name: string;
  user_role: number;
}

//////////////////////LISTA DE PROVEEDORES/////

export interface QuoteInterface {
  quote_identifier: number;
  name: string;
  alias: string;
  agency: string;
  arrival_date: string;
  departure_date: string;
  pax: number;
  sgl: number;
  dbl: number;
  tpl: number;
  cpl: number;
  adults: number;
  childs: number;
  proforma_identifier?: number;
  status_identifier?: number;
}

export interface ProviderInterfaceOP {
  register_identifier: number;
  provider_name: string;
  provider_type_identifier: number;
  provider_type: string;
  entry_number: number;
}

export interface updateProviderI {
  service_identifier: number | null; // no mandar cuando estas eliminando
  fee_identifier: number | null; // no mandar cuando estas eliminando
  provider_identifier: number;
  status_identifier: number;
}
export interface updateServiceI {
  service_identifier: number; // ejemplo para añadir un servicio
  fee_identifier: number; // este id debe ser del servicio que se registró en el catálogo
}
export interface operationDataI {
  alias: string;
  pax: number;
  sgl: number;
  dbl: number;
  tpl: number;
  cpl: number;
  adults: number;
  childs: number;
  status_identifier: number;
}
export interface operationUpdateI {
  operation_identifier: number;
  operation_data: operationDataI;
  services: updateProviderI[] | updateServiceI[];
}
export interface OperationI {
  quote: QuoteInterface;
  providers: {
    hotel: ProviderInterfaceOP[];
    restaurant: ProviderInterfaceOP[];
    agency: ProviderInterfaceOP[];
    transport: ProviderInterfaceOP[];
    expenses: ProviderInterfaceOP[];
    honorary: ProviderInterfaceOP[];
    optionals: ProviderInterfaceOP[];
  };
}

export interface MetaProviders {
  status: boolean;
  messages: string[];
}

export interface ApiResponseListProviders {
  data: OperationI;
  meta: MetaProviders;
}
///////////////////////////FIN LISTA DE PROVEEDORES///////////

export interface GenerarCotizacionI {
  fee_identifier: number;
  service_identifier: number;
}

export interface bodyRequestOperacion {
  quote_identifier: number;
  services_identifier_list: GenerarCotizacionI[];
}

//////////////INTERFAZ DE BUSQUEDA::::
export interface ValidityOP {
  validity_identifier: number;
  fee_identifier: number;
  validity_start_date: string;
  validity_end_date: string;
}

export interface ServiceOP {
  provider_identifier: number;
  provider_name: string;
  service_identifier: number;
  service_name: string;
  service_type_identifier: number;
  service_type: string;
  fee_identifier: number;
  service_fee: number;
  currency_identifier: number;
  currency: string;
  room_type: string;
  capacity: any; // Puedes definir un tipo específico si conoces el tipo de datos
  validity_list: ValidityOP[];
  identificador_local: string;
  cantidad_uno: number;
  cantidad_dos: number;
}

export interface DataServiceOP {
  services_list: ServiceOP[];
  provider_list_search: NameProvider[];
}
export interface ResponseServicesOp {
  data: DataServiceOP;
}

/////INTERFAZ DE BUSQUEDA, GUARDADO DE DATOS:

export interface OperationNewProviders {
  operation_identifier: number;
  operation_data: {
    alias: string;
    pax: number;
    sgl: number;
    dbl: number;
    tpl: number;
    cpl: number;
    adults: number;
    childs: number;
    status_identifier: number;
  };
  services: ServiceOPNew[];
}

export interface ServiceOPNew {
  service_identifier: number;
  fee_identifier: number;
  quantity1: number;
  quantity2: number;
  arrival_date?: string;
  departure_date?: string;
  occupation: string | null;
}

/////////////////////Traer entradas//////////////////
export interface BodyEntradaI {
  operation_identifier: number;
  provider_identifier: number;
  entry_number: number;
}

export interface EntryService {
  entry_service_identifier: number;
  entry_identifier: number;
  provider_identifier: number;
  service_identifier: number;
  service_name: string;
  service_type_identifier: number;
  service_type: string;
  initial_validity: string;
  final_validity: string;
  quantity1: number;
  quantity2: number;
  occupation: string;
  guide_identifier: number | null;
  iva: number;
  taxes: number;
  unitary_fee: number;
  total_fee: number;
  currency_identifier: number;
  currency: string;
  guides: GuideNumberI[] | null;
  provider_type_identifier: number;
}

export interface EntryData {
  entry_identifier: number;
  operation_identifier: number;
  provider_identifier: number;
  status_identifier: number;
  entry_number: number;
  initial_date: string;
  final_date: string;
  sgl: number;
  dbl: number;
  tpl: number;
  cpl: number;
  adults: number;
  childs: number;
  confirmation_number: string;
  creator_user_identifier: number;
  creator_user: string;
  contact1_identifier: number;
  contact2_identifier: number;
  guide_identifier: number;
  payday_limit: string;
  comment: string;
  total_mxn: number;
  services_tips_mxn: number;
  lodging_taxes_mxn: number;
  iva_mxn: number;
  advance_mxn: number;
  total_usd: number;
  services_tips_usd: number;
  lodging_taxes_usd: number;
  iva_usd: number;
  advance_usd: number;
  guide_courtesy: number;
  provider_name: string;
  total_without_advance_mxn: number;
  total_without_advance_usd: number;
}

export interface EntryDataResponse {
  data: {
    entry_data: EntryData;
    entry_services_list: {
      mxn_services_list: EntryService[];
      usd_services_list: EntryService[];
    };
    files: FileOP[];
    entry_advances?: EntryAdvance[];
  };
}
export interface InitialContextOP {
  entry_data: EntryData;
  entry_services_list: {
    mxn_services_list: EntryService[];
    usd_services_list: EntryService[];
  };
  files?: FileOP[];
  entry_advances?: EntryAdvance[];
}
export interface InitialContextOPGastos {
  entry_data: EntryData | null;
  entry_services_list: {
    mxn_services_list: EntryService[];
    usd_services_list: EntryService[];
  };
}
export interface EntryAdvance {
  entry_identifier: number;
  entry_number: number;
  advance: number;
  currency_identifier: number;
  currency: string;
  payment_date: string;
}

/////DATOS EXTRA DE PROVEEDOR:::::::

export interface ContactProveedor {
  contact_identifier: number;
  contact_name: string;
  contact_email: string;
}

export interface BankAccountProveedor {
  bank_account_identifier: number;
  bank_account: string;
  clabe: string;
  bank_identifier: number;
  bank: string;
  currency_identifier: number;
  currency: string;
}

export interface DaysOperation {
  ProovedorID: number;
  Ocupacion: string;
  FechaInicial: string;
  FechaFinal: string;
  Dias: number;
}

export interface Data {
  business_name: string;
  contact_list: ContactProveedor[];
  bank_account_list: BankAccountProveedor[];
}

export interface Meta {
  status: boolean;
  messages: string[];
}

export interface ApiResponseInfoProveedor {
  data: Data;
  meta: Meta;
}

/////
export interface GuideI {
  provider_identifier: number;
  provider_name: string;
  provider_type_identifier: number;
  provider_type: string;
}

export interface GuideNumberI {
  guide_identifier: number;
  guide_id_key?: string;
}

/////////////////////////
export interface EntryServiceI {
  entry_service_identifier: number;
  entry_identifier: number;
  provider_identifier: number;
  service_identifier: number;
  service_name: string;
  provider_type_identifier: number;
  provider_type: string;
  service_type_identifier: number;
  service_type: string;
  initial_validity: string;
  final_validity: string;
  quantity1: number;
  quantity2: number;
  occupation: string;
  guides: any[]; // Puedes especificar un tipo más específico para los elementos de esta matriz si es necesario
  iva: number;
  taxes: number;
  unitary_fee: number;
  total_fee: number;
  currency_identifier: number;
  currency: string;
  arrival_date: string;
  departure_date: string;
  isSelected: boolean;
  identifierService: string;
  isShowedInSecondList: boolean;
}

export interface ApiResponseServicesGuia {
  data: {
    entry_services_list: EntryServiceI[];
  };
}

////////////////////////INTERFAZ GUIA DESGLOSE////////////////

export interface ContactIGuia {
  contact_identifier: number;
  contact_name: string;
  contact_email: string;
}

export interface BankAccountIGuia {
  bank_account_identifier: number;
  bank_account: string;
  clabe: string;
  bank_identifier: number;
  bank: string;
  currency_identifier: number;
  currency: string;
}

export interface DataR {
  business_name: string;
  contact_list: ContactIGuia[];
  bank_account_list: BankAccountIGuia[];
}

export interface MetaR {
  status: boolean;
  messages: string[];
}

export interface ApiResponseGuia {
  data: DataR;
  meta: MetaR;
}

///////////////////////////////////////DAME EL MODELO DE DATOS::::::

export interface ProviderIdI {
  provider_identifier: number;
}

export interface OperationISend {
  operation_identifier: number;
  provider_list: ProviderIdI[];
}

////////////////////////// Subir archivos

export interface FileDataOPI {
  file: string;
  file_extension: string;
  file_name: string;
}

export interface OperationRequestDocumentsI {
  entry_identifier: number;
  operation_identifier: number;
  files: FileDataOPI[];
}

export interface FileOP {
  file_identifier: number;
  file_name: string;
  file_url: string;
  file_alt_name: string;
  status_identifier?: number;
}

///////////////////////QUEJAS

export interface ComplaintRequest {
  operation_identifier: number;
  complaint_type_identifier: number;
  provider_identifier: number;
  agency: string;
  complaint_body: string;
}

/////////////////////////
export interface ComplaintTypeI {
  complaint_type_identifier: number;
  complaint_type: string;
}

/////
export interface BankAccountInformation {
  bank_account_identifier: number;
  beneficiary: string;
  address_1: string;
  address_2: string;
  bank: string;
  clabe: string;
  account_number: string;
  iban: string;
  aba: string;
  branch: string;
  swift_code_1: string;
  swift_code_2: string;
  reference: string;
  sucursal: string;
  corresponding_bank: string;
}

/////////////////////////////////////////INTERFACE REQUEST QUEJASSS::::

export interface ProviderResponseI {
  register_identifier: number;
  provider_name: string;
  provider_type_identifier: number;
  provider_type: string;
  arrival_date: string;
  departure_date: string;
}

export interface OperationInfoI {
  operation_identifier: number;
  alias: string;
  agency: string;
  arrival_date: string;
  departure_date: string;
}

export interface DataIProviders {
  operation: OperationInfoI;
  providers: ProviderResponseI[];
}

export interface Meta {
  status: boolean;
  messages: string[];
}

export interface ApiResponseProvidersI {
  data: DataIProviders;
  meta: Meta;
}

/////////////////////COmplain request

export interface ComplaintRequestI {
  operation_identifier: number;
  complaint_type_identifier: number;
  provider_identifier: number;
  agency: string;
  complaint_body: string;
}

///////////////Complaint response
export interface ComplaintContent {
  complaint_identifier: number;
  complaint_type_identifier: number;
  complaint_type: string;
  provider_identifier: number;
  provider: string;
  complaint_body: string;
  user_identifier: number;
  user: string;
  arrival_date: string;
  creation_date: string;
  departure_date: string;
  alias: string;
  agency: string;
}

export interface ComplaintResponseGet {
  data: ComplaintContent[];
  meta: {
    status: boolean;
    messages: any[];
  };
}

////////////////////////////////////HOJA DE CONTROL

export interface QuoteResponse {
  operation_identifier: number;
  name: null;
  alias: string;
  agency: string;
  arrival_date: string;
  departure_date: string;
  pax: number;
  sgl: number;
  dbl: number;
  tpl: number;
  cpl: number;
  adults: number;
  childs: number;
  status_identifier: number;
}

export interface ProviderResponse {
  operation_provider_identifier: number;
  operation_identifier: number;
  provider_identifier: number;
  provider_name: string;
  provider_clave: string;
  provider_type_identifier: number;
  provider_type: string;
  arrival_date: string;
  departure_date: string;
  status_identifier: number;
  Entry_no: number;
  entry_total_mxn: number;
  entry_total_usd: number;
  payday_limit: string;
  entry_advance_usd: number;
  entry_advance_mxn: number;
  entry_remaining_usd: number;
  entry_remaining_mxn: number;
  has_files: boolean;
  mxn_payment_status_identifier: number;
  mxn_payment_status: string;
  usd_payment_status_identifier: number;
  usd_payment_status: string;
  guide_payment_status_identifier: number;
  guide_payment_status: string;
  policy: string;
  usd_payment_date: string;
  mxn_payment_date: string;
  usd_payment_policy: string;
  mxn_payment_policy: string;
}

export interface GuideResponse {
  guide_identifier: number;
  guide_name: string;
}

export interface ExpenseProviderResponse {
  operation_provider_identifier: number;
  operation_identifier: number;
  provider_identifier: number;
  provider_name: string;
  provider_clave: string;
  provider_type_identifier: number;
  provider_type: string;
  arrival_date: string;
  departure_date: string;
  status_identifier: number;
  Entry_no: number;
  entry_total_mxn: number;
  entry_total_usd: number;
  payday_limit: string;
  mxn_payment_status_identifier: number;
  mxn_payment_status: string;
  usd_payment_status_identifier: number;
  usd_payment_status: string;
  guide_payment_status_identifier: number;
  guide_payment_status: string;
  usd_payment_date: string;
  mxn_payment_date: string;
  guide_payment_policy: string;
}

export interface ExpenseResponse {
  guide: GuideResponse;
  providers: ExpenseProviderResponse[];
  total_mxn: number;
  total_usd: number;
}

export interface HonoraryResponse {
  operation_provider_identifier: number;
  operation_identifier: number;
  provider_identifier: number;
  provider_name: string;
  provider_clave: string;
  provider_type_identifier: number;
  provider_type: string;
  arrival_date: string;
  departure_date: string;
  status_identifier: number;
  Entry_no: number;
  entry_total_mxn: number;
  entry_total_usd: number;
}

export interface AdvanceResponse {
  dateLimit: Date;
  total: number;
}

export interface DataResponse {
  quote: QuoteResponse;
  providers: {
    hotel: ProviderResponse[];
    restaurant: ProviderResponse[];
    agency: ProviderResponse[];
    transport: ProviderResponse[];
    expenses: ExpenseResponse[];
    honorary: HonoraryResponse[];
    optionals: any[];
  };
  proforma: ProformaDataI;
  invoices: InvoicesContainer | null;
  advance: AdvanceResponse[];
}

export interface RootObjectResponse {
  data: DataResponse;
  meta: null;
}

export interface ProformaDataI {
  proforma_identifier: number;
  proforma_total: number;
  currency_identifier: number;
  currency: string;
}

export interface Invoice {
  invoice_identifier: number;
  exchange_rate: number;
  invoice_type_identifier: number;
  invoice_type: string;
  total_invoiced: number;
  iva: number;
  total_iva: number;
  subtotal_invoiced: number;
  invoice_number: string;
  advance_commission: number;
  advance: number;
  status_identifier: number;
  invoice_local_id: string;
}

export interface InvoicesContainer {
  operation_identifier: number;
  currency_identifier: number;
  currency: string;
  forfait: number;
  exchange_rate: number;
  proforma_total: number;
  total_paid: number;
  group_commission: number;
  verif_total_proforma: number;
  verif_total_paid_mxn: number;
  verif_total_paid_usd: number;
  invoices: Invoice[];
}

/////////////////FUNCIONES

export function extraerBase64(dataURL: string): string | null {
  const base64Regex = /^data:application\/pdf;base64,([a-zA-Z0-9+/=]+)$/;
  const matches = dataURL.match(base64Regex);

  if (matches && matches.length === 2) {
    return matches[1];
  } else {
    // La cadena proporcionada no es un data URL válido con base64
    return null;
  }
}

// export function isBase64(input: string): boolean {
//   // Expresión regular para verificar si es una cadena base64 válida
//   const base64Regex = /^(data:){0,1}([a-zA-Z0-9+/])+[=]{0,2}$/;
//   return base64Regex.test(input);
// }

export function isBase64(input: string): boolean {
  const base64Regex = /^(data:)([\w/+]+);base64,([A-Za-z0-9+/]+={0,2})$/;
  return base64Regex.test(input);
}
////////

///////
