import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import ServiciosLayout from "../../components/layout/ServiciosLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../../components/entrys/CustomInput";
import { v4 as uuidv4 } from "uuid";
import CustomInputt from "../../components/entrys/CustomInputt";
import {
  dataServicesI,
  seasonInterface,
  coinInterface,
  viewInterface,
  serviceInterface,
  serviciosInterface,
  categoriaI,
  maealI,
  validityDates,
  serviceObject,
} from "../../../interfaces/GeneralTypes";
import DateInput from "../entrys/DateInput";
import deletelogo from "../../../images/deletelogo.png";
import { GeneralModifiedI } from "../../../interfaces/GuardadoTypes";
import {
  ServiceFeeDelete,
  FeeDelete,
  ValidityDelete,
  BlackoutDelete,
  DeleteService,
} from "../../../interfaces/DeleteTypes";
import agregar from "../../../images/AGREGAR.png";
import DeleteConfirmation from "../common/DeleteConfirmation";
interface vigenciaProps {
  setDataServices: React.Dispatch<React.SetStateAction<dataServicesI>>;
  tarifa: serviceObject;
  dataServices: dataServicesI;
  vigenciaData: validityDates;
  indice: number;
  addNewVigencia: () => void;
  isShowingAddButton: boolean;
}
export default function Vigencia({
  setDataServices,
  tarifa,
  dataServices,
  vigenciaData,
  indice,
  addNewVigencia,
  isShowingAddButton,
}: vigenciaProps) {
  const {
    registerData,
    setRegisterData,
    contratoContext,
    setContratoContext,
    idCurrentProvider,
    registerGeneral,
    setTitleAlert,
    setContentAlert,
    setShowAlert,
    setShowLoader,
    dataServicesContext,
  } = useContext(AppContext);
  const [localDate, setLocalDate] = useState<string>("");
  const [currentDate, setCurrentDate] = useState<validityDates>({
    validity_identifier: vigenciaData.validity_identifier,
    /////////////////////
    validity_startdate: vigenciaData?.validity_startdate,
    validity_enddate: vigenciaData?.validity_enddate,
    identifierValidity: vigenciaData.identifierValidity,
  });
  const deleteVigencia = async () => {
    ////Para la tarifa actual, creamos un nuevo arreglo para la propiedad de objecto "validez"
    const currentArrayValidity = tarifa.fee_validity_list;
    if (currentArrayValidity.length == 1) {
      alert("No puedes borrar más elementos");
      return;
    }
    const currentTarifaNewValidityArray = currentArrayValidity.filter(
      (validity, index) => {
        return validity.identifierValidity !== vigenciaData.identifierValidity;
      }
    );

    ////HASTA AQUI PARECE TODO BIEN:
    const newServicesFeeList = dataServices.services_fees.map(
      (tarifaIndividual, index) => {
        return tarifaIndividual.identifier_list != tarifa.identifier_list
          ? tarifaIndividual
          : {
              ...tarifaIndividual,
              fee_validity_list: currentTarifaNewValidityArray,
            };
      }
    );

    ///////////////BORRADO DE VIGENCIA DESDE SERVICIOS
    if (idCurrentProvider != 0) {
      setShowLoader(true);
      const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
      //console.log("REACT BACK END POINT: ");
      //console.log(backEndPoint);

      const urlModified = backEndPoint + "/provider/update";
      const modifiedGeneral: GeneralModifiedI = {
        register_identifier: idCurrentProvider,
        name: registerGeneral.name,
        registration_type_identifier:
          registerGeneral.registration_type_identifier,
        lender_type_identifier:
          registerGeneral.lender_type_identifier == 0
            ? 1
            : registerGeneral.lender_type_identifier,
        provider_type_identifier: registerGeneral.provider_type_identifier
          ? registerGeneral.provider_type_identifier
          : 1,
        business_name: registerGeneral.business_name,
        street: registerGeneral.calles,
        number: registerGeneral.telefono,
        neighborhood_identifier: registerGeneral.neighborhood_identifier,
        city_identifier: registerGeneral.city_identifier
          ? registerGeneral.city_identifier
          : 1,
        country_identifier: registerGeneral.country_identifier,
        zip_code: registerGeneral.zip_code,
        important_notes: registerGeneral.important_notes,
        description: registerGeneral.lead,
        id: registerGeneral.id,
        key: registerGeneral.claveGeneral,
        rfc: registerGeneral.rfc,
        category:
          registerGeneral.categoriaGeneral == 0
            ? null
            : registerGeneral.categoriaGeneral,
        last_purchase: registerGeneral.ultimaCompra,
        feedback: registerGeneral.feedback,
        lead: registerGeneral.lead,
        commission_scheme: registerGeneral.comisionEsquema,
        web: registerGeneral.web,
        comments: registerGeneral.comments,
        category_identifier: registerGeneral.category_identifier,
      };

      const arrayVigenciaEliminar: ValidityDelete[] = currentArrayValidity
        .filter((validity, index) => {
          return validity.identifierValidity == vigenciaData.identifierValidity;
        })
        .map((validity, index) => {
          const validityToDelete: ValidityDelete = {
            validity_identifier: validity.validity_identifier,
            status_identifier: 2,
            validity_startdate: validity.validity_startdate,
            validity_enddate: validity.validity_enddate,
          };
          return validityToDelete;
        });

      const serviciosModified: ServiceFeeDelete[] =
        dataServicesContext.services_fees
          .filter((currentService, index) => {
            return (
              currentService.service_identifier == tarifa.service_identifier
            );
          })
          .map((servicio, index) => {
            const currentListFee: FeeDelete[] = servicio.service_fee_list.map(
              (fee, index) => {
                const serviceFee: FeeDelete = {
                  fee_identifier: fee.fee_identifier,
                  fee_currency_identifier: fee.fee_currency_identifier,
                  fee_iva: fee.fee_iva,
                  fee_tax: fee.fee_tax,
                  fee: fee.fee,
                  fee_total: fee.fee_total,
                  fee_SGL: fee.fee_SGL,
                  fee_DBL: fee.fee_DBL,
                  fee_TPL: fee.fee_TPL,
                  fee_CPL: fee.fee_CPL,
                  fee_FP: fee.fee_FP,
                  fee_TSGL: fee.fee_TSGL,
                  fee_TDBL: fee.fee_TDBL,
                  fee_TTPL: fee.fee_TTPL,
                  fee_TCPL: fee.fee_TCPL,
                  fee_TFP: fee.fee_TFP,
                  fee_BB: fee.fee_BB,
                  fee_BBT: fee.fee_BBT,
                  fee_MAID: fee.fee_MAID,
                  fee_TMAID: fee.fee_TMAID,
                  fee_capacity: fee.fee_capacity,
                  status_identifier: 1,
                };
                return serviceFee;
              }
            );

            const blackoutList: BlackoutDelete[] =
              servicio.service_blackout_list.map((blackout, index) => {
                const blackoutCurrent: BlackoutDelete = {
                  blackout_identifier: blackout.blackout_identifier,
                  blackout_startdate: blackout.blackout_startdate,
                  blackout_enddate: blackout.blackout_enddate,
                  status_identifier: 1,
                };
                return blackoutCurrent;
              });

            const currentService: ServiceFeeDelete = {
              fee_type_identifier: servicio.fee_type_identifier,
              service_identifier: servicio.service_identifier,
              service_type_identifier: servicio.service_type_identifier,
              service_mealplan_identifier: servicio.service_mealplan_identifier,
              service_category_identifier: servicio.service_category_identifier,
              service_category: servicio.service_category,
              service_name: servicio.service_name,
              service_comments: servicio.service_comments,
              service_key: servicio.service_key,
              service_fees_list: [],
              service_validity_list: arrayVigenciaEliminar,
              service_blackout_list: [],
              status_identifier: 1,
              service_minimum_stay_list: [],
              operating_days: [],
            };

            return currentService;
          });

      const modifiedObject: DeleteService = {
        user_identifier: 1,
        general: modifiedGeneral,
        services_fees: serviciosModified,
      };

      //console.log('CUERPO DE PETICION: ' );
      //console.log(modifiedObject);

      try {
        const response = await fetch(urlModified, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(modifiedObject),
        });
        if (response.ok) {
          ////RESPUESTA CORRECTA
          //console.log(response);
          //console.log("bien");

          setTitleAlert("Modificación correcta correcto");
          setContentAlert(
            "Tus datos fueron modificados correctamente, puedes consultarlos en el catalogo."
          );

          setShowAlert(true);
          setShowLoader(false);
        } else {
          ///RESPUESTA INCORRECTA DE SERVICIOS
          // setShowLoader(false);
          setTitleAlert("Error al BORRAR:");
          setContentAlert(
            "Tus datos no fueron guardados correctamente, por favor, verificalos."
          );
          setShowAlert(true);
          setShowLoader(false);
        }
      } catch (error) {
        ///ERROR AL REALIZAR PETICION
        alert("ERROR AL REALIZAR BORRADO");
        setShowLoader(false);
      }
    }

    ////////////////////////////////////////////////////////////

    setDataServices({
      ...dataServices,
      services_fees: newServicesFeeList,
    });
  };

  useEffect(() => {
    //console.log("FECHA INTERNA HA CAMBIADO!: ");
    //console.log(currentDate);
    ///////////////Esta linea sirve para encontrar la tarifa actual en la lista de Servicios, si la encuentra, sustituye la lista de vigencias
    ///////Sustituye por: encuentra la vigencia que se está modificando, en la lista de vigencias, luego sustituye con la fecha actual cuando la encuentra
    const newServicesList = dataServices?.services_fees.map(
      (currentTarifa, index) => {
        return currentTarifa.identifier_list != tarifa.identifier_list
          ? currentTarifa
          : {
              ...currentTarifa,
              fee_validity_list: currentTarifa.fee_validity_list.map(
                (validity, index) => {
                  return validity.identifierValidity !==
                    currentDate.identifierValidity
                    ? validity
                    : currentDate;
                }
              ),
            };
      }
    );
    //console.log("ACTUALIZACION DEL ARREGLO!!!!!!: ");
    //console.log(newServicesList);
    setDataServices({
      ...dataServices,
      services_fees: newServicesList,
    });
  }, [currentDate]);
  useEffect(() => {
    //console.log("Así quedo dataServices----------------------------------!!!!!!!!!!!!");
    //console.log(dataServices);
  }, []);
  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    deleteVigencia();
    setIsShownDelete(false);
    ////BORRADO
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };

  return (
    <>
      {" "}
      <VigenciaStyles>
        <div className="vigencia-container">
          <div className="initial-date">
            <DateInput
              text={indice == 0 ? "Vigencia Inicial" : ""}
              txtWidth={100}
              setGeneralData={setCurrentDate}
              field="validity_startdate"
              value={currentDate.validity_startdate}
            />
          </div>
          <div className="end-date">
            <DateInput
              text={indice == 0 ? "Vigencia Final" : ""}
              txtWidth={100}
              setGeneralData={setCurrentDate}
              field="validity_enddate"
              value={currentDate.validity_enddate}
            />
          </div>
          <div className="logo-container-trash">
            <img
              src={deletelogo}
              width="30px"
              onClick={handleDeleteClick}
              className="trash"
            />
          </div>
          {isShowingAddButton && (
            <img
              src={agregar}
              width="30px"
              alt="agregar"
              onClick={addNewVigencia}
              className="plus-icon"
            />
          )}
        </div>
      </VigenciaStyles>
      {isShownDelete && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          itemName={`¿Deseas borrar la vigencia ${
            currentDate.validity_startdate
          } ${
            currentDate.validity_startdate !== "" &&
            currentDate.validity_enddate
              ? "-"
              : ""
          } ${currentDate.validity_enddate}?`}
          setIsShownDelete={setIsShownDelete}
        />
      )}
    </>
  );
}

const VigenciaStyles = styled.div`
  .initial-date {
    margin-right: 0.9rem;
  }
  .vigencia-container {
    width: 60%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr 0.3fr;
    justify-items: center;
    align-items: center;
    max-width: 60%;
  }

  .vigencia-container {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 900px) {
    .vigencia-container {
      width: 70%;
      max-width: 70%;
    }
  }
  .vigencia-container div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .trash {
    cursor: pointer;
  }
  .logo-container-trash {
    display: flex;
    justify-content: center;
  }
`;
