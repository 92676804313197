import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "./RowCotizacion";
import { QuoteItemI } from "../InterfacesOperacion/OperacionesI";
import {
  servicioDayElementI,
  servicioIndividual,
} from "../../../../context/InterfacesContext";
import IndividualService from "./IndividualService";

interface TransporteServiceI {
  servicioTransporte: servicioIndividual;
  servicioDayTransporte: servicioDayElementI;
  checkboxAll: boolean;
  setCheckBoxAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TransporteService({
  servicioTransporte,
  servicioDayTransporte,
  checkboxAll,
  setCheckBoxAll,
}: TransporteServiceI) {
  const { dataCotizacionContext, setDataCotizacionContext } =
    useContext(AppContext);

  const [isChecked, setIsChecked] = useState(servicioTransporte.isIncludedOPS);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setIsChecked(event.target.checked);
    //console.log("COMBO BOX!!!!!!!!!!!!!!!!!!!!!!!!::");
    //console.log(event.target.checked);
    if (dataCotizacionContext.serviciosDayElementsTransporteOpt) {
      if (
        dataCotizacionContext.serviciosDayElementsTransporteOpt
          .servicioDayElement.length > 0
      ) {
        const newArrayService =
          dataCotizacionContext.serviciosDayElementsTransporteOpt.servicioDayElement.map(
            (diaServicios, index) => {
              return diaServicios.identifierDay !==
                servicioDayTransporte.identifierDay
                ? diaServicios
                : {
                    ...diaServicios,
                    serviciosIndividuales:
                      diaServicios.serviciosIndividuales.map(
                        (currentService, index) => {
                          return currentService.identifierService !==
                            servicioTransporte.identifierService
                            ? currentService
                            : {
                                ...currentService,
                                isIncludedOPS: event.target.checked,
                              };
                        }
                      ),
                  };
            }
          );
        //console.log("Asi queda el nuevo arreglo de servicios:");
        // //console.log(newArrayService);
        //console.log("-------------------------------------------------------");
        setDataCotizacionContext({
          ...dataCotizacionContext,
          serviciosDayElementsTransporteOpt: {
            servicioDayElement: newArrayService,
          },
        });
        setIsChecked(event.target.checked);
        if (!event.target.checked) {
          setCheckBoxAll(false);
        }
      }
    }
  };
  useEffect(() => {
    setIsChecked(servicioTransporte.isIncludedOPS);
  }, [servicioTransporte.isIncludedOPS]);
  return (
    <TransporteServiceI>
      <div className="header-table-content">
        <div>
          <h3>{servicioTransporte.providerDescripcion}</h3>
        </div>
        <div>
          <h3>{servicioTransporte.service_name}</h3>
        </div>
        <div>
          <h3>{servicioTransporte.tarifa.toFixed(2)}</h3>
        </div>
        <div>
          <h3>Vigencia</h3>
        </div>
        <div>
          <h3>{servicioTransporte.dateRequired}</h3>
        </div>

        <div className="combo">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
    </TransporteServiceI>
  );
}

const TransporteServiceI = styled.div`
  .header-table-content {
    border: black solid 1px;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
  }
  h3 {
    color: black !important;
  }
  .combo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
