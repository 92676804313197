import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Provider } from "../../../interfaces/interfaces";
import { ApiResponse } from "../../../interfaces/interfaces";
import SearchResultsClient from "./SearchResultsClient";
import { fetchServer } from "../../../services/fetchServer";

interface searchBoxProps {
  data: ApiResponse["data"];
  initialData: ApiResponse["data"];
  setData: React.Dispatch<
    React.SetStateAction<{
      provider_list: Provider[];
    }>
  >;
  resultsList: ApiResponse["data"];
  setResultsList: React.Dispatch<
    React.SetStateAction<{
      provider_list: Provider[];
    }>
  >;
}
export default function SearchBoxClients({
  data,
  setData,
  initialData,
  resultsList,
  setResultsList,
}: searchBoxProps) {
  const [searchText, setSearchText] = useState<string>("");
  const [showResults, setShowResults] = useState<boolean>(false);
  const [resultsSearch, setResultsSearch] = useState<ApiResponse["data"]>({
    provider_list: [],
  });
  useEffect(() => {
    //console.log("STATE CHANGED");
    //console.log(searchText);
    if (searchText.length > 0) {
      setShowResults(true);
      setResultsSearch({
        provider_list: buscarElementos(searchText),
      });
    } else {
      ////LLAMAR A SERVICIO PARA REESTABLECER DATOS:
      setShowResults(false);
      setResultsSearch({
        provider_list: [],
      });
      setData(initialData);
      setResultsList({
        provider_list: [],
      });
      //   setCatalogElements(catalogo);
    }
  }, [searchText]);
  function buscarElementos(texto: string): Provider[] {
    const resultados: Provider[] = initialData.provider_list.filter(
      (elemento) => {
        // Verificar si el texto coincide con alguna propiedad del objeto
        const textoSinTildes = texto
          .trim()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return elemento.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(textoSinTildes.toLowerCase());
      }
    );
    //console.log(resultados);
    return resultados;
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value; // Elimina espacios en blanco al inicio y al final del texto
    setSearchText(searchText);
  };
  const findClient = () => {
    setData(resultsSearch);
    setResultsSearch({
      provider_list: [],
    });
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      //console.log("Presionaste enter");
      setData(resultsSearch);
      setResultsSearch({
        provider_list: [],
      });
      setResultsList(resultsSearch);
    }
  };
  const handleClick = (e: MouseEvent) => {
    setShowResults(false);
    setResultsSearch({
      provider_list: [],
    });
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <SearchBoxStyless>
      <div className="search-container">
        <div className="search-input">
          <input
            type="text"
            className="input-data"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Busca por nombre"
            value={searchText}
          />
          <button className="search-button" onClick={findClient}>
            Buscar
          </button>
        </div>
        {resultsSearch.provider_list.length > 0 && showResults && (
          <SearchResultsClient
            results={resultsSearch.provider_list}
            setData={setData}
            setSearchText={setSearchText}
            setResultsList={setResultsList}
          />
        )}
      </div>
    </SearchBoxStyless>
  );
}
const SearchBoxStyless = styled.div`
  .search-input {
    background-color: white;
    width: auto;
    border-radius: 0.6rem;
    padding-right: 0.7rem;
  }
  .search-button {
    border: none;
    width: 25%;
    background-color: #08a6a5;
    padding: 0.35rem;
    color: white;
    border-radius: 0.5rem;
  }
  .input-data {
    width: 75%;
    padding: 0.8rem;
    border: none;
    outline: none;
    border-radius: 0.6rem;
    padding-right: 0.5rem !important;
  }
  @media screen and (min-width: 900px) {
    .search-button {
      width: 15%;
    }
    .input-data {
      width: 85%;
    }
  }
  h3 {
    margin-bottom: 0.5rem;
  }
  background-color: transparent;
  width: 100%;
  height: 3rem;
  padding-top: 1rem;
  .search-container {
    width: 80%;
    height: 5rem;
    margin: 0 auto;
  }
`;
