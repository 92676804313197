import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TarifaLayout from "../../components/layout/TarifaLayout";
import { v4 as uuidv4 } from "uuid";

interface feeInterface {
  name: string;
  fee: number;
  start_date: string;
  end_date: string;
  currency_identifier: number;
  season_identifier: number;
  observations: string;
}
interface seasonInterface {
  identifier: number;
  description: string;
}
interface coinInterface {
  identifier: number;
  description: string;
  abbreviation: string;
}
interface tarifaList {
  name: string;
  fee: string;
  start_date: string;
  end_date: string;
  currency_identifier: number;
  season_identifier: number;
  observations: string;
  identifier: string;
}
export default function Tarifa() {
  const { catalogsData } = useCatalogs();
  const { registerData, setRegisterData } = useContext(AppContext);
  const [seasons, setSeasons] = useState<seasonInterface[]>([]);
  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  const [feeList, setFeeList] = useState<tarifaList[]>(registerData.fee_list);
  const { showAlert, setShowAlert } = useContext(AppContext);
  const { contentAlert, setContentAlert } = useContext(AppContext);
  const { titleAlert, setTitleAlert } = useContext(AppContext);
  const editService = (identifier: string, tarifaModified: tarifaList) => {
    const newServiciosInterface = feeList.map((tarifa, index) => {
      return identifier != tarifa.identifier ? tarifa : tarifaModified;
    });
    setFeeList(newServiciosInterface);
    setRegisterData({
      ...registerData,
      fee_list: [...newServiciosInterface],
    });
  };
  const deleteService = (identifier: string) => {
    //console.log('Borrando');
    if (feeList.length == 1) {
      setContentAlert("No puedes borrar más elementos");
      setTitleAlert("Error al borrar");
      setShowAlert(true);

      return;
    }
    const newServiciosInterface = feeList.filter((tarifa, index) => {
      return identifier !== tarifa.identifier;
    });
    setFeeList(newServiciosInterface);
    setRegisterData({
      ...registerData,
      fee_list: [...newServiciosInterface],
    });
  };

  useEffect(() => {
    //console.log("DAtos que vienen del servicio: ");
    //console.log(catalogsData);
    setSeasons(catalogsData.season_list);
    setCurrencyList(catalogsData.currency_list);
  }, [catalogsData]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;

    //console.log(newDate);
    // //console.log(new Date(newDate).toLocaleString('en-US', { timeZone: 'America/Mexico_City' }))
    // setcontractObject({...contractObject, start_date: newDate});
    // setfeeListObject({
    //   ...feeListObject,
    //   start_date: newDate,
    // });
  };
  const handleDateChangeTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;

    //console.log(newDate);
    // setcontractObject({...contractObject, end_date: newDate});
    // setfeeListObject({
    //   ...feeListObject,
    //   end_date: newDate,
    // });
  };

  const addNewTarifa = () => {
    //console.log("HOLA ESTA ES TU NUEVA TARIFA: ");
    setFeeList([
      ...feeList,
      {
        name: "",
        fee: "",
        start_date: " ",
        end_date: " ",
        currency_identifier: 0,
        season_identifier: 0,
        observations: "",
        identifier: uuidv4(),
      },
    ]);
    setRegisterData({
      ...registerData,
      fee_list: [
        ...feeList,
        {
          name: "",
          fee: "",
          start_date: " ",
          end_date: " ",
          currency_identifier: 1,
          season_identifier: 1,
          observations: "",
          identifier: uuidv4(),
        },
      ],
    });
  };
  useEffect(() => {
    //console.log('Fee list actualizada: ');
    //console.log(feeList);
  }, [feeList]);
  useEffect(() => {
    //console.log("SE ACTUALIZO ESTADO GLOBAL: ");
    //console.log(registerData);
  }, [registerData]);
  return (
    <TarifaStyles>
      <div className="box-container">
        <h2 className="title-tarifa">Datos de Tarifa</h2>
        {feeList.map((fee, index) => {
          return (
            <TarifaLayout
              key={fee.identifier}
              currencyList={currencyList}
              seasons={seasons}
              feeData={fee}
              editService={editService}
              deleteService={deleteService}
            />
          );
        })}
      </div>
      <FontAwesomeIcon
        className="plus-symbol"
        icon={faPlus}
        onClick={addNewTarifa}
        style={{ cursor: "pointer", fontSize: "2rem" }}
      />
    </TarifaStyles>
  );
}

const TarifaStyles = styled.div`
  .title-tarifa {
    color: rgb(8, 166, 165);
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .data-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center; /* centrado horizontal */
    align-items: center; /* centrado vertical */

    grid-row-gap: 0.5rem;
  }
`;
const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border-radius: 5rem;
  border: 2px solid rgb(133, 198, 195);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 18px;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
`;
