import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import ServiciosLayout from "./ServiciosLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faPencil,
  faPen,
  faFloppyDisk,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../entrys/CustomInput";
import { v4 as uuidv4 } from "uuid";
import CustomInputt from "../entrys/CustomInputt";
import {
  dataServicesI,
  seasonInterface,
  coinInterface,
  viewInterface,
  serviceInterface,
  serviciosInterface,
  categoriaI,
  maealI,
  serviceObject,
  commentI,
} from "../../../interfaces/GeneralTypes";
import Vigencia from "./Vigencia";
import ImpuestosGet from "./ImpuestosGet";
import deletelogo from "../../../images/deletelogo.png";
import agregar from "../../../images/AGREGAR.png";

interface comentarioI {
  isIncludeTrash: boolean;
  arrayComentarios: commentI[];
  setArrayComentarios: React.Dispatch<React.SetStateAction<commentI[]>>;
  indice: number;
  identifier: string;
  coment: string;
}
export default function Comentario({
  isIncludeTrash,
  arrayComentarios,
  setArrayComentarios,
  indice,
  identifier,
  coment,
}: comentarioI) {
  const [comentario, setComentario] = useState({
    identifier: identifier,
    comment: coment,
  });
  useEffect(() => {
    //console.log("Tu comentario ha cambiado");
    //console.log(comentario);
    const newArrayComments = arrayComentarios.map((comment) => {
      return comment.identifier != identifier ? comment : comentario;
    });
    //console.log('CAMBIO: ');
    //console.log(newArrayComments);
    setArrayComentarios(newArrayComments);
  }, [comentario]);

  const addNewComment = () => {
    const key = uuidv4(); //
    setArrayComentarios([
      ...arrayComentarios,
      {
        identifier: key,
        comment: "",
      },
    ]);
  };

  const deleteComment = () => {
    if (arrayComentarios.length == 1) {
      alert("No puedes borrar más comentarios");
      return;
    }
    const arrayNewComments = arrayComentarios.filter((comment, index) => {
      return comment.identifier != comentario.identifier;
    });
    //console.log('ARREGLO FINAL');
    //console.log(arrayNewComments);
    setArrayComentarios(arrayNewComments);
  };
  return (
    <ComentarioStyle>
      <CustomInputt
        text=""
        txtWidth={80}
        setGeneralData={setComentario}
        field="comment"
        value={comentario.comment}
      />
      <div className="plus-icon-box">
        <img
          src={deletelogo}
          width="30px"
          className="icon-image"
          onClick={deleteComment}
        />

        {isIncludeTrash && (
          <img
            src={agregar}
            width="30px"
            onClick={addNewComment}
            className="icon-image"
          />
        )}
      </div>
    </ComentarioStyle>
  );
}

const ComentarioStyle = styled.div`
  margin-bottom: 1rem;
  .icon-image {
    margin-left: 1rem;
    cursor: pointer;
  }
`;
