import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import ReembolsoElement from "./ReembolsoMesContent";
import { fetchServer } from "../../../../services/fetchServer";
import { dataResults } from "./mockDataContabilidad";
import {
  DataStructure,
  DeleteRefundRequest,
  Item,
  MonthlyData,
  RefundObjectDelete,
} from "../InterfacesContabilidad";
import { AppContext } from "../../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormReembolso from "./FormReembolso";
import ReembolsoMesContent from "./ReembolsoMesContent";
import OptionSwitchContabilidad from "../Components/OptionSwitchContabilidad";

export default function ReembolsoList() {
  // Crear el estado para almacenar los años
  const { setShowLoader } = useContext(AppContext);
  const [isShowingCurrentCard, setIsShowingCurrentCard] =
    useState<boolean>(false);
  const [años, setAños] = useState<number[]>([]);

  const currentYear = new Date().getFullYear();
  const [yearSelected, setYearSelected] = useState<number>(currentYear);
  const [listResults, setListResults] = useState<MonthlyData[]>([]);
  const [modeSwitch, setModeSwitch] = useState<number>(1);

  useEffect(() => {
    const añosArray: number[] = [];
    for (let año = 2010; año <= currentYear; año++) {
      añosArray.push(año);
    }
    setAños(añosArray);
  }, []);

  const manejarCambioAño = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const añoSeleccionado = parseInt(event.target.value, 10);
    // Coloca aquí la lógica que deseas realizar con el año seleccionado
    //console.log(`Año seleccionado: ${añoSeleccionado}`);
    setYearSelected(añoSeleccionado);
  };
  useEffect(() => {
    ////TRaer datos de la lista:
    getInitialData();
  }, [yearSelected, modeSwitch]);
  const getInitialData = async () => {
    setShowLoader(true);
    const resJson: DataStructure = await fetchServer(
      `/accounting/refunds/searchRefunds/${modeSwitch}/${yearSelected}`
    );
    setListResults(resJson.data);
    setShowLoader(false);
    //console.log("Datos traidos de acuerdo al año:");
    //console.log(resJson);
  };
  const [listForDelete, setListForDelete] = useState<RefundObjectDelete[]>([]);
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const deleteFromServices = async () => {
    if (listForDelete.length == 0) {
      alert("Por favor selecciona registros antes de borrar.");
      return;
    }
    setDeleteMode(false);
    const bodyRequest: DeleteRefundRequest = {
      refunds: listForDelete,
    };
    try {
      const res = await fetchServer(
        "/accounting/refund/delete",
        "post",
        bodyRequest
      );

      getInitialData();
      setListForDelete([]);
    } catch (error) {
      alert("Error al guardar");
    }
  };
  return (
    <ReembolsoListStyle>
      <br />
      <br />
      <br />
      <div className="container-headers">
        <div className="select-etiqueta">
          <label htmlFor="años">Selecciona un año: </label>
          <select
            id="años"
            onChange={manejarCambioAño}
            value={yearSelected || ""}
          >
            {años.map((año) => (
              <option key={año} value={año}>
                {año}
              </option>
            ))}
          </select>
        </div>
        <OptionSwitchContabilidad
          modeSwitch={modeSwitch}
          setModeSwitch={setModeSwitch}
          deleteMode={deleteMode}
          setDeleteMode={setDeleteMode}
          setListForDelete={setListForDelete}
        />
      </div>
      {modeSwitch == 1 && (
        <div className="container-whole-table">
          <div className="header-table">
            <div>
              <h3>Estatus</h3>
            </div>
            <div>
              <h3>Fecha</h3>
            </div>
            <div>
              <h3>No. de poliza</h3>
            </div>
            <div>
              <h3>Prestador de servicio</h3>
            </div>
            <div>
              <h3>Concepto de pago</h3>
            </div>
            <div>
              <h3>Monto de pago</h3>
            </div>
            <div>
              <h3>Monto del servicio</h3>
            </div>
            <div>
              <h3>Saldo a favor de Alico</h3>
            </div>
            <div>
              <h3>Moneda</h3>
            </div>
            <div>
              <h3>Fecha de modificación</h3>
            </div>
            <div>
              <h3>Motivo</h3>
            </div>

            <div>
              <h3>Comentario</h3>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faPlus}
                onClick={() => setIsShowingCurrentCard(true)}
                className="plus-boton"
              />
            </div>
          </div>
        </div>
      )}
      {modeSwitch == 2 && (
        <div className="header-table header-table-dos">
          <div>
            <h3>Estatus</h3>
          </div>
          <div>
            <h3>Fecha</h3>
          </div>
          <div>
            <h3>Agencia</h3>
          </div>

          <div>
            <h3>Concepto de pago</h3>
          </div>
          <div>
            <h3>Monto de pago</h3>
          </div>
          <div>
            <h3>Monto del servicio</h3>
          </div>
          <div>
            <h3>Saldo a favor de Agencia</h3>
          </div>
          <div>
            <h3>Moneda</h3>
          </div>
          <div>
            <h3>Fecha de modificación</h3>
          </div>
          <div>
            <h3>Motivo</h3>
          </div>

          <div>
            <h3>Acciones a realizar</h3>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() => setIsShowingCurrentCard(true)}
              className="plus-boton"
            />
          </div>
        </div>
      )}
      {/* Aquí colocaras el arreglo que reenderizara cada mes */}
      {listResults.map((month, index) => {
        return (
          <ReembolsoMesContent
            key={month.month_identifier}
            month={month}
            getInitialData={getInitialData}
            modeSwitch={modeSwitch}
            setListResults={setListResults}
            listResults={listResults}
            listForDelete={listForDelete}
            setListForDelete={setListForDelete}
            deleteMode={deleteMode}
          />
        );
      })}
      <br />
      <br />
      {isShowingCurrentCard && (
        <FormReembolso
          isShowingCurrentCard={isShowingCurrentCard}
          setIsShowingCurrentCard={setIsShowingCurrentCard}
          getInitialData={getInitialData}
          modeSwitch={modeSwitch}
        />
      )}
      <div className="boton-containers">
        <button
          className="botton-current borrar-boton"
          style={{
            backgroundColor: !deleteMode
              ? "rgba(255, 0, 0, 0.6)"
              : "rgb(255, 0, 0)",
          }}
          onClick={() => {
            if (deleteMode == false) {
              setDeleteMode(!deleteMode);
            } else {
              deleteFromServices();
            }
          }}
        >
          Borrar
        </button>
        {deleteMode && (
          <button
            className="botton-current cancel-boton"
            onClick={() => {
              setListForDelete([]);
              setDeleteMode(false);
            }}
          >
            Cancelar
          </button>
        )}
      </div>
    </ReembolsoListStyle>
  );
}

const ReembolsoListStyle = styled.div`
  .boton-containers {
    margin-left: 2rem;
    width: 140%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .borrar-boton {
    margin-right: 1rem;
  }
  .botton-current {
    padding: 0.5rem 2.5rem;
    border: none;

    border-radius: 0.2rem;
    color: white;
    cursor: pointer;
  }
  .cancel-boton {
    background-color: #d86e3a;
  }
  .container-whole-table {
    border: black solid 1px;
    width: 140%;
    margin-left: 2rem;
    position: sticky;
  }
  .select-etiqueta {
    margin-right: 2rem;
  }
  .container-headers {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
  .select-etiqueta {
    margin-left: 2rem;
  }
  .plus-boton {
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
  }

  .header-table {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: 0 auto;
    padding: 0.5rem;
    margin-right: 2rem;
    background-color: #d86e3a;
  }
  .header-table div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-table div h3 {
    font-size: 0.85vw;
    text-align: center;
    color: white;
    font-weight: 300;
  }
  .header-table-dos {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 140%;
    margin-left: 2rem;
  }
`;
