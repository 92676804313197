import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import GerenteTable from "./GerenteTable";
import OptionSwitch from "./Components/OptionSwitch";
import CotizacionDetails from "./CotizacionDetails";
import { BrowserRouter, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import HeaderInfo from "./HeaderInfo";
import PrestadoresList from "./PrestadoresList";
import {
  OperationI,
  OperationISend,
  ProviderIdI,
  ProviderInterfaceOP,
} from "./InterfacesOperacion/OperacionesI";
import ElementPrestador from "./Components/ElementPrestador";
import { Link, useNavigate } from "react-router-dom";
import agregarImage from "../../../images/AGREGAR.png";
import { OperationContext } from "../../../context/operationContext";

interface ListPrestadoresI {
  nameList: string;
  listProvider: ProviderInterfaceOP[];
  setListProvider: React.Dispatch<React.SetStateAction<ProviderInterfaceOP[]>>;
  statusOperacion: number;
  setStatusOperacion: React.Dispatch<React.SetStateAction<number>>;
}

export default function ListPrestadores({
  nameList,
  listProvider,
  setListProvider,
  statusOperacion,
  setStatusOperacion,
}: ListPrestadoresI) {
  const navigate = useNavigate();
  const { setShowSearchProviders, setShowLoader, currentUser } =
    useContext(AppContext);
  const { currentOperation } = useContext(OperationContext);

  const asignarGuia = async () => {
    await asignarGastos();
  };
  const desgloseGuia = () => {
    navigate("/dashboard/Operaciones/Desgloce-Guia");
  };
  const asignarGastos = async () => {
    const getIdProveedores: ProviderIdI[] = listProvider.map(
      (proveedor, id) => {
        const proveedor_objeto: ProviderIdI = {
          provider_identifier: proveedor.register_identifier,
        };
        return proveedor_objeto;
      }
    );

    const objetoEnviar: OperationISend = {
      operation_identifier: currentOperation.quote.quote_identifier,
      provider_list: getIdProveedores,
    };

    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/ExpensesEntries/register`;
    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objetoEnviar),
      });

      if (response.ok) {
        const data = await response.json();

        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION
        //console.log("-------------------------------------------DATA--------------------------------------")
        //console.log(data);
        //setShowLoader(false);
        navigate("/dashboard/Operaciones/Desgloce-Asignar-Guia");
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al guardar");
        setShowLoader(false);

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al guardar");
      console.error("Error in request:", error);

      alert("Error en el servicio");
      setShowLoader(false);
      // setShowLoader(false);
    }
    //console.log("ASIGNANDO GASTOS A COMPROBAR:::");
  };

  const [grupoActual, setGrupoActual] = useState(() => {
    const grupo = localStorage.getItem("groupUsuario");

    return grupo !== null ? parseInt(grupo) : currentUser.group_identifier;
  });
  return (
    <ListPrestadoresStyle>
      <div className="first-row">
        <div className="title-table">
          <h3>{nameList}</h3>
          {nameList == "Gastos a comprobar" && (
            <div className="box-buttons">
              {grupoActual !== 1018 && (
                <button className="guia-boton" onClick={asignarGuia}>
                  Asignar guía
                </button>
              )}

              <button className="desglose-boton" onClick={desgloseGuia}>
                Ver desglose{" "}
              </button>
            </div>
          )}
        </div>
        <div className="botons-one">
          {listProvider?.length == 0 &&
            statusOperacion !== 3 &&
            grupoActual !== 1018 && (
              <img
                src={agregarImage}
                width={25}
                height={25}
                className="icon"
                onClick={() => {
                  setShowSearchProviders(true);
                }}
              />
            )}
        </div>
      </div>

      {listProvider.map((prestador, index) => {
        return (
          <ElementPrestador
            prestador={prestador}
            listProvider={listProvider}
            setListProvider={setListProvider}
            statusOperacion={statusOperacion}
            setStatusOperacion={setStatusOperacion}
          />
        );
      })}
      <div className="list-container">
        <div className="list-elements">
          <div></div>
        </div>
        <div></div>
      </div>
    </ListPrestadoresStyle>
  );
}

const ListPrestadoresStyle = styled.div`
  .icon {
    cursor: pointer;
  }
  .first-row {
    display: flex;
  }
  .botons-one {
    width: 8%;
    display: flex;
    justify-content: center;
  }
  .box-buttons {
    margin-left: 1rem;
  }
  .box-buttons button {
    border: none;
    padding: 0.2rem;
    font-size: 0.85vw;
    cursor: pointer;
  }
  .guia-boton {
    margin-right: 1rem;
    background-color: #f6921e;
    color: white;
  }
  .desglose-boton {
    background-color: #29aae1;
    color: white;
  }
  .title-table {
    display: flex;
    align-items: center;
  }

  .list-elements {
    width: 92%;
  }
  .title-table {
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    color: black;
    background-color: #c9e2df;
    padding: 0.3rem !important;
    width: 92%;
    padding: 0.3rem;
  }
  .title-table {
    font-size: 0.75vw;
  }
`;
