import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from '../../../images/AGREGAR.png'; 
import CotizacionInfo from "./CotizacionInfo";
import { Link } from "react-router-dom";
import GeneralesList from "./GeneralesList";
import PriceBox from "./PriceBox";
import GeneralesTransporte from "./GeneralesTransporte";
import { cotizacionListIndividual, cotizacionListTriple } from "../../../context/InterfacesContext";
import TransporteServices from "./TransporteServices";
interface serviciosDaysI {
  listSingle: cotizacionListIndividual[];
  setListSingle: React.Dispatch<
    React.SetStateAction<cotizacionListIndividual[]>
  >;
  listTriple: cotizacionListTriple[];
  setListTriple: React.Dispatch<React.SetStateAction<cotizacionListTriple[]>>;
}
export default function GeneralesCotizacion({
  listSingle,
  setListSingle,
  listTriple,
  setListTriple,
}: serviciosDaysI) {

  const {
    setShowSearchServices,
    setIdCurrentDayService,
    setCotizationDate,
    setServiciosType,
    dataCotizacionContext,
    setDataCotizacionContext,
    route,
    setRoute,
    currentCurrency,
    showGastosGenerales, setShowGastosGenerales
  } = useContext(AppContext);

  useEffect(()=>{
    //console.log("Este useEffect solo se ejecuta en GENERALES.");
    setServiciosType(3); 
  },[])
  return (
    <GeneralesCotizacionStyles>      
       {/* <GeneralesTransporte
       listSingle={listSingle}
       setListSingle={setListSingle}
       listTriple={listTriple}
       setListTriple={setListTriple}
      /> */}
      <TransporteServices
      listSingle={listSingle}
      setListSingle={setListSingle}
      listTriple={listTriple}
      setListTriple={setListTriple}
      />
      <GeneralesList/>
     
      <PriceBox/>
   
      <div className="button-box">
      <Link to = "/dashboard/Alta-Cotizacion/Registro-Cotizacion" className="next-button">
        Anterior
      </Link>
      </div>
      
    </GeneralesCotizacionStyles>
  )
}


const GeneralesCotizacionStyles = styled.div`
.button-box{  
  display: flex;
  justify-content: right;
  padding-right: 2rem;
}
  .next-button{
    background-color: transparent;
    border: none;
    padding: 1rem;
    background-color: orange;
    border-radius: 0.8rem;
    padding: 0.5rem 2rem;
    color: white;
    cursor: pointer;
  }

`