import React, { useEffect, useContext } from "react";
import { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { catalogElement } from "../../../interfaces/interfaces";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

interface catalogoElementProps {
  showBox: boolean;
}
interface elementCatalog {
  catalogElement: catalogElement;
  handleChangeArray: (dataViajeSelected: catalogElement) => void;
  deleteElementArray: (dataViajeClave: string) => void;
}
export default function CatalogoElement({
  catalogElement,
  handleChangeArray,
  deleteElementArray,
}: elementCatalog) {
  const [showBox, setShowBox] = useState<boolean>(false);
  const [dataCatalog, setDataCatalog] = useState(catalogElement);
  const [editionMode, setEditionMode] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    ////Para actualizar el elemento actual, se ejecuta setDataCatalog
    setDataCatalog({
      ...dataCatalog,
      [e.target.name]: e.target.value,
    });
    ////Para editar el array, se pasa el nuevo elemento actualizado a la función handleChangeArray
    handleChangeArray({
      ...dataCatalog,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    //console.log("Valor nuevo de dataCatalog: ");
    //console.log(dataCatalog);
    //console.log(dataCatalog.fechaInicial);
  }, [dataCatalog]);

  return (
    <CatalogoElemenStyles showBox={showBox}>
      <div className="catalago-element-container">
        <div className="number-box">
          <div className="box-1">
            <div className="number-element">1</div>
            <div>
              <h3>{catalogElement.nombreViaje}</h3>
            </div>
          </div>

          <div>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="fa-circle"
              onClick={() => {
                setShowBox(!showBox);
              }}
            />
          </div>
        </div>

        <div className={`show-element ${showBox ? "show" : "hide-element"}`}>
          <div className="box-element-content">
            <div className="info-box">
              <h3 className=" title-boox">Clave</h3>
              {!editionMode ? (
                <h3 className="descripcion-tex">{catalogElement.clave}</h3>
              ) : (
                <input
                  type="text"
                  name="clave"
                  value={dataCatalog.clave}
                  onChange={handleChange}
                  className="input-data"
                />
              )}
            </div>
            <div className="info-box">
              <h3 className=" title-boox">Moneda</h3>
              {!editionMode ? (
                <h3 className="descripcion-tex">{catalogElement.moneda}</h3>
              ) : (
                <input
                  type="text"
                  name="moneda"
                  value={dataCatalog.moneda}
                  onChange={handleChange}
                  className="input-data"
                />
              )}
            </div>
            <div className="info-box">
              <h3 className=" title-boox">Tarifa</h3>
              {!editionMode ? (
                <h3 className="descripcion-tex">{catalogElement.tarifa}</h3>
              ) : (
                <input
                  type="text"
                  name="tarifa"
                  value={dataCatalog.tarifa}
                  onChange={handleChange}
                  className="input-data"
                />
              )}
            </div>
            <div className="info-box">
              <h3 className=" title-boox">IVA</h3>
              {!editionMode ? (
                <h3 className="descripcion-tex">{catalogElement.iva}</h3>
              ) : (
                <input
                  type="text"
                  name="iva"
                  value={dataCatalog.iva}
                  onChange={handleChange}
                  className="input-data"
                />
              )}
            </div>
            <div className="info-box">
              <h3 className=" title-boox">Impuesto</h3>
              {!editionMode ? (
                <h3 className="descripcion-tex">{catalogElement.impuesto}</h3>
              ) : (
                <input
                  type="text"
                  name="impuesto"
                  value={dataCatalog.impuesto}
                  onChange={handleChange}
                  className="input-data"
                />
              )}
            </div>
            <div className="info-box">
              <h3 className=" title-boox">Total</h3>
              {!editionMode ? (
                <h3 className="descripcion-tex">{catalogElement.total}</h3>
              ) : (
                <input
                  type="text"
                  name="total"
                  value={dataCatalog.total}
                  onChange={handleChange}
                  className="input-data"
                />
              )}
            </div>
            <div className="info-box">
              <h3 className="title-boox">Temporada</h3>
              {!editionMode ? (
                <h3 className="descripcion-tex">{catalogElement.temporada}</h3>
              ) : (
                <input
                  type="text"
                  name="temporada"
                  value={dataCatalog.temporada}
                  onChange={handleChange}
                  className="input-data"
                />
              )}
            </div>
            <div className="info-box">
              <h3 className=" title-boox">Fecha inicial</h3>
              {!editionMode ? (
                <h3 className="descripcion-tex">
                  {catalogElement.fechaInicial}
                </h3>
              ) : (
                <input
                  type="date"
                  name="fechaInicial"
                  value={dataCatalog.fechaInicial}
                  onChange={handleChange}
                  className="input-data"
                />
              )}
            </div>
            <div className="info-box">
              <h3 className=" title-boox">Fecha final</h3>
              {!editionMode ? (
                <h3 className="descripcion-tex">
                  {catalogElement.fechaInicial}
                </h3>
              ) : (
                <input
                  type="date"
                  name="fechaInicial"
                  value={dataCatalog.fechaInicial}
                  onChange={handleChange}
                  className="input-data"
                />
              )}
            </div>
          </div>
          <div className="icon-options">
            <div>
              <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
              <h4>Detalle</h4>
            </div>
            <div>
              <FontAwesomeIcon icon={faEyeSlash} />
              <h4>Ocultar</h4>
            </div>
            <div>
              <FontAwesomeIcon
                icon={!editionMode ? faPen : faFloppyDisk}
                onClick={() => {
                  setEditionMode(!editionMode);
                }}
                className={`${!editionMode ? "" : "edition-icon"}`}
              />
              {!editionMode ? <h4>Editar</h4> : <h4>Guardar</h4>}
            </div>
            <div>
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => {
                  deleteElementArray(dataCatalog.clave);
                }}
              />
              <h4>Borrar</h4>
            </div>
          </div>
        </div>
      </div>
    </CatalogoElemenStyles>
  );
}

const CatalogoElemenStyles = styled.div<catalogoElementProps>`
  .edition-icon {
    color: #08a6a5;
  }
  .show-element {
    height: auto;
    opacity: 1;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  }
  .input-data {
    width: 70%;
    padding: 0.2rem;
    text-align: center;
  }
  .hide-element {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 1s ease-in-out, opacity 1s ease-in-out;
  }

  .icon-options {
    margin-top: 1rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-around;
    color: #0c4856;
  }
  .icon-options div h4 {
    font-size: 0.9rem;
    color: #0c4856;
    margin-top: 0.5rem;
  }
  .number-element {
    background-color: #08a6a5;
    color: white;
    border: none !important;
  }
  .icon-options div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 0.6rem;
    cursor: pointer;
  }
  .title-boox {
    color: #0c4856;
    margin-bottom: 0.3rem;
    font-size: 1.07rem;
  }
  .descripcion-tex {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  .box-element-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    /* grid-column-gap: 0.2rem; */
    grid-row-gap: 0.35rem;
  }
  .info-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .box-1 {
    display: flex;
  }
  .fa-circle {
    font-size: 1.8rem;
    color: #b1b1b1;
    cursor: pointer;
  }
  .catalago-element-container {
    border-bottom: #b1b1b1 solid 3px;
  }
  .number-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 1.3rem;
    /* border-bottom:    ${(props) =>
      props.showBox ? "" : "#b1b1b1 solid 3px"}; */
  }
  .number-element {
    width: 30px;
    height: 30px;
    border: black solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 0.5rem;
    margin-right: 1.5rem;
  }
  .catalogo-element-container {
    border: red solid 1px;
  }
`;
