import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { ProformaOptionalPackage } from "./ProformaI";
import { isEqual } from "lodash";

interface ElementOptionalI {
  optionalPackage: ProformaOptionalPackage;
  setProformaOpcionalPackages: React.Dispatch<
    React.SetStateAction<ProformaOptionalPackage[]>
  >;
  proformaOpcionalPackages: ProformaOptionalPackage[];
}
export default function ElementOptional({
  optionalPackage,
  setProformaOpcionalPackages,
  proformaOpcionalPackages,
}: ElementOptionalI) {
  const [opcionalElement, setOpcionalElement] =
    useState<ProformaOptionalPackage>(optionalPackage);
  useEffect(() => {
    if (!isEqual(optionalPackage, opcionalElement)) {
      const newArregloOpcional: ProformaOptionalPackage[] =
        proformaOpcionalPackages.map((packageOpcional, index) => {
          //console.log(packageOpcional);
          //console.log("packageOpcional.optional_quote_identifier  !== optionalPackage.optional_quote_identifier  ");
          //console.log(packageOpcional.optional_quote_identifier + "!=="+ optionalPackage.optional_quote_identifier );
          return packageOpcional.optional_quote_identifier !==
            optionalPackage.optional_quote_identifier
            ? packageOpcional
            : opcionalElement;
        });
      //console.log("OPERACION CON ARREGLOS:");
      //console.log(newArregloOpcional);
      setProformaOpcionalPackages(newArregloOpcional);
    }
  }, [opcionalElement]);

  useEffect(() => {
    //console.log("OPCIONAL PACKAGE CAMBIO-------------------------------------------------------------");
    setOpcionalElement(optionalPackage);
  }, [optionalPackage]);

  useEffect(() => {
    //console.log("Se actualizó lista padre::::");
    //console.log(proformaOpcionalPackages);
  }, [proformaOpcionalPackages]);
  return (
    <ElementOptionalStyles>
      <div className="row-table-one">
        <div>
          <h3>{opcionalElement.optional_quote_name}</h3>
        </div>
        <div>
          <h3>{opcionalElement.city}</h3>
        </div>
        <div>
          <h3>{opcionalElement.estatus}</h3>
        </div>
        <div>
          <h3>{opcionalElement.arrival_date}</h3>
        </div>
        <div>
          <h3>{opcionalElement.departure_date}</h3>
        </div>
        <div>
          {/* <input
              type="text"
              value={opcionalElement.optional_quote_pax}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  // setDesgloceGeneralInfo({
                  //   ...desgloceGeneralInfo,
                  //   guide_courtesy: 0,
                  // });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   guide_courtesy: parsedValue,
                    // });
                  }
                }
              }}
            /> */}
          <h3> {opcionalElement.optional_quote_pax}</h3>
        </div>
        <div>
          {/* Comission */}
          {/* <input
              type="text"
              value={opcionalElement.commission}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setOpcionalElement({
                    ...opcionalElement,
                    commission: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setOpcionalElement({
                      ...opcionalElement,
                      commission: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   guide_courtesy: parsedValue,
                    // });
                  }
                }
              }}
            /> */}
          <h3>{opcionalElement.commission}</h3>
        </div>
        <div>
          <h3>{""}</h3>
        </div>
        <div>
          <h3>{""}</h3>
        </div>
        <div>
          {/* PRICE */}
          {/* <input
              type="text"
              value={opcionalElement.total}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  // setDesgloceGeneralInfo({
                  //   ...desgloceGeneralInfo,
                  //   guide_courtesy: 0,
                  // });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   guide_courtesy: parsedValue,
                    // });
                  }
                }
              }}
            /> */}
          <h3>{opcionalElement.total_dbl}</h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3></h3>
        </div>
        <div>
          <h3>
            {opcionalElement.optional_quote_pax * opcionalElement.total_dbl +
              opcionalElement.optional_quote_pax *
                opcionalElement.total_dbl *
                (opcionalElement.commission / 100)}
          </h3>
        </div>
      </div>
    </ElementOptionalStyles>
  );
}

const ElementOptionalStyles = styled.div`
  margin-top: 0;
  .row-table-one {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: black solid 1px;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    border-top: none;
  }

  .row-table-one div h3 {
    text-align: center;
    font-size: 0.85vw;
    font-weight: 300 !important;
  }
  input {
    width: 95%;
    max-width: 95%;
    font-size: 0.85vw;
    text-align: center;
  }
`;
