import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { AppContext } from "../../../context/appContext";
import { ApiResponse } from "../../../interfaces/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import SearchElement from "./SearchElement";
import { QuoteElementI, QuoteListI } from "./interfaces/CotizacionI";
import {
  CommentI,
  GeneralServiceI,
  QuoteObjectI,
} from "../../../interfaces/GeneralTypes";
import agregar from "../../../images/AGREGAR.png";
import deletelogo from "../../../images/deletelogo.png";
import { feeChargesI } from "../../../context/InterfacesContext";
import { v4 as uuidv4 } from "uuid";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";

interface ElementComentI {
  comentario: CommentI;
  finantialData: feeChargesI;
  setFinantialData: React.Dispatch<React.SetStateAction<feeChargesI>>;
}

export default function ElementComment({
  comentario,
  finantialData,
  setFinantialData,
}: ElementComentI) {
  const [commentData, setCommentData] = useState<CommentI>(comentario);
  const {
    setDataCotizacionContext,
    dataCotizacionContext,
    currentCurrency,
    cotizacionMode,
  } = useContext(AppContext);

  const deleteFromServices = async () => {
    if (cotizacionMode == 2) {
      const commentsDelete: CommentI[] = finantialData.comments
        .filter((currentComment, index) => {
          return currentComment.status_identifier !== 0;
        })
        .map((currentComment, index) => {
          return {
            ...currentComment,
            status_identifier:
              currentComment.commentIdentifier == comentario.commentIdentifier
                ? 2
                : currentComment.status_identifier,
          };
        });

      const bodySaveRequest: QuoteObjectI = {
        user_identifier: 1,
        quote: {
          private_quote: true,
          name: dataCotizacionContext.personalInfo.name,
          type: dataCotizacionContext.personalInfo.typeCotizacion,
          agency: dataCotizacionContext.personalInfo.agencia,
          series: dataCotizacionContext.personalInfo.serieId,
          PAX: dataCotizacionContext.personalInfo.pax,
          TL: dataCotizacionContext.personalInfo.TL,
          MIN: dataCotizacionContext.personalInfo.min,
          MAX: dataCotizacionContext.personalInfo.max,
          arrival_date:
            dataCotizacionContext.personalInfo.arrivingData == "arrivingData"
              ? ""
              : dataCotizacionContext.personalInfo.arrivingData,
          departure_date:
            dataCotizacionContext.personalInfo.departureDate == "departureData"
              ? ""
              : dataCotizacionContext.personalInfo.departureDate,
          currency_identifier: currentCurrency,
          commission: dataCotizacionContext.feeCharges.comision,
          commission_auto: dataCotizacionContext.feeCharges.comisonAuto,
          courtesy: dataCotizacionContext.feeCharges.cortesia,
          exchange_rate: dataCotizacionContext.feeCharges.changeType,
          comments: commentsDelete,
          total: dataCotizacionContext.totalPrice.totalPrice,
          quote_register_type:
            dataCotizacionContext.personalInfo.typeCotizacion,
          number_of_days:
            dataCotizacionContext.personalInfo.numberDaysOpenQuote,
          serie:
            dataCotizacionContext.personalInfo.modeCotizacion == "Normal"
              ? false
              : true,
          status_identifier:
            dataCotizacionContext.personalInfo.status_identifier,
          quote_identifier: dataCotizacionContext.personalInfo.quote_identifier,
          courtesy_type_identifier:
            dataCotizacionContext.feeCharges.courtesy_type_identifier,
          optional_quote:
            dataCotizacionContext.personalInfo.modeCotizacion == "Opcional"
              ? true
              : false,
        },
        quote_general_services: [],
        quote_individual_services: [],
        general_transport_services: [],
        optional_groups: [],
      };

      const urlModified: string = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/update`;
      try {
        const response = await fetch(urlModified, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodySaveRequest),
        });

        if (response.ok) {
          const data = await response.json();
        } else {
        }
      } catch (error) {
        ///ERROR AL REALIZAR PETICION
      }
      return;
    }
  };

  const deleteComentario = () => {
    if (finantialData.comments.length == 1) {
      alert("No puedes borrar más comentarios");
      return;
    }
    //console.log("Comentario actual:::");
    //console.log(comentario);
    if (comentario.comment_identifier !== 0) {
      // alert("Entrando a servicios");
      deleteFromServices();
    }

    const dataCommentsArray: CommentI[] = finantialData.comments.filter(
      (currentComment, index) => {
        return (
          currentComment.commentIdentifier !== comentario.commentIdentifier
        );
      }
    );
    setFinantialData({
      ...finantialData,
      comments: dataCommentsArray,
    });
  };

  const addNewComment = () => {
    const identificadoComment = uuidv4();
    const newComent = {
      comment_identifier: 0,
      comment: "",
      comment_date: "",
      status_identifier: 0,
      commentIdentifier: identificadoComment,
    };
    setFinantialData({
      ...finantialData,
      comments: [...finantialData.comments, newComent],
    });
  };

  useEffect(() => {
    const dataCommentsArray: CommentI[] = finantialData.comments.map(
      (currentComment, index) => {
        return currentComment.commentIdentifier !== comentario.commentIdentifier
          ? currentComment
          : commentData;
      }
    );
    setFinantialData({
      ...finantialData,
      comments: dataCommentsArray,
    });
  }, [commentData.comment]);

  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    setIsShownDelete(false);
    // borrarServicios(serviceIndividual.identifierService, servicioDay.identifierDay)
    borrarElementoGeneral();
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };

  const borrarElementoGeneral = () => {
    deleteComentario();
  };
  return (
    <>
      <CommentStyles>
        <div>
          <textarea
            className="text-container"
            name={""}
            id={""}
            onChange={(e) => {
              setCommentData({
                ...commentData,
                comment: e.target.value,
              });
            }}
            value={commentData.comment}
          />
          <div className="element-container">
            <h5>{commentData.comment_date}</h5>
            <div className="non-printable-section">
              <img
                className="add-button"
                src={deletelogo}
                alt=""
                width={25}
                onClick={handleDeleteClick}
              />
              <img src={agregar} alt="" width={25} onClick={addNewComment} />
            </div>
          </div>
        </div>
      </CommentStyles>
      {isShownDelete && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          itemName={`¿Deseas borrar el comentario ${truncateText(
            commentData.comment,
            200
          )}?`}
          setIsShownDelete={setIsShownDelete}
        />
      )}
    </>
  );
}

const CommentStyles = styled.div`
  .element-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
  }
  .add-button {
    margin-right: 0.5rem;
  }
  img {
    cursor: pointer;
  }
`;

function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength) + "...";
  }
}
