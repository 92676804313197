import { useState } from "react";
import styled from "styled-components";

interface Props<T> {
  field: string;
  text: string;
  txtWidth: number;
  value?: number;
  setGeneralData: React.Dispatch<React.SetStateAction<T>>;
  onChange?: (value: string) => void;
  typeData: string;
  isAvailable: boolean;
}
interface styledInputProps {
  percentageWidth: number;
}
function CustomInputttt<T>(props: Props<T>) {
  const [inputValue, setInputValue] = useState(props.value || "");

  const obtenerValor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!props.isAvailable) return;
    setInputValue(value);
    props.setGeneralData((prevProvider) => ({
      ...prevProvider,
      [props.field]: parseFloat(value),
    }));

    if (props.onChange) {
      props.onChange(value);
    }
  };
  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    event.preventDefault();
  };
  return (
    <div
      style={{ width: props.txtWidth + "%", maxWidth: props.txtWidth + "%" }}
    >
      <PrincipalContainer style={{ width: "100%", maxWidth: "100%" }}>
        <EntryContainer>
          <Entry
            id={props.field}
            type={props.typeData}
            style={{
              width: "100%",
              border: !props.isAvailable ? "grey solid 1px" : "",
            }}
            inputMode="decimal"
            onChange={obtenerValor}
            value={inputValue}
            autoComplete="off"
            className={
              inputValue ? "has-value input-style-data" : "input-style-data"
            }
            onWheel={handleWheel}
          />
          <Label
            htmlFor={props.field}
            style={{ color: !props.isAvailable ? "grey" : "" }}
          >
            {" "}
            {props.text}
          </Label>
        </EntryContainer>
      </PrincipalContainer>
    </div>
  );
}

export default CustomInputttt;

const Entry = styled.input`
  background-color: #ffffff;
  border-style: none;
  border-bottom: 2px solid #b1b1b1;
  color: "#000000";
  cursor: text;
  outline: none;
  border: solid rgb(8, 166, 165) 2px;

  padding-left: 20px;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  position: relative;
  z-index: 1;

  &::placeholder:not(.has-value) {
    position: absolute;
    top: -0.5rem;
    left: 1rem;
    font-size: 1rem;
    color: #b1b1b1;
    pointer-events: none;
    transition: all 0.2s ease-out;
    z-index: 1;
  }
  .input-style-data::-webkit-inner-spin-button,
  .input-style-data::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .input-style-data {
    border: black solid 1px;
  }
`;

const PrincipalContainer = styled.div`
  height: auto;
  display: flex;
`;

const EntryContainer = styled.div`
  width: 100%;
  position: relative;
  max-width: 100%;
`;

const Label = styled.label`
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  font-size: 12px;
  color: rgb(8, 166, 165);
  pointer-events: none;
  transition: all 0.2s ease-out;
  z-index: 1;
  background-color: white;
`;
