import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import ServiciosLayout from "../../components/layout/ServiciosLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCopy, faBars } from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../../components/entrys/CustomInput";
import { v4 as uuidv4 } from "uuid";
import CustomInputt from "../../components/entrys/CustomInputt";
import {
  dataServicesI,
  seasonInterface,
  coinInterface,
  viewInterface,
  serviceInterface,
  serviciosInterface,
  categoriaI,
  maealI,
} from "../../../interfaces/GeneralTypes";
import TarifaGeneral from "../../components/layout/TarifaGeneral";
import TarifaHab from "../../components/pages/TarifaHab";
import Blackout from "../../components/layout/Blackout";
import BlackoutElement from "../../components/layout/BlackoutElement";
import { serviceObject } from "../../../interfaces/GeneralTypes";
import deletedatos from "../../../images/deletelogo.png";
import {
  DeleteService,
  FeeDelete,
  ServiceFeeDelete,
  ValidityDelete,
} from "../../../interfaces/DeleteTypes";
import { GeneralModifiedI } from "../../../interfaces/GuardadoTypes";
import ListServices from "./ComponentsRegister/ListServices";

export default function Servicios() {
  let identificador = uuidv4();
  let identifierValidity = uuidv4();
  const { catalogsData } = useCatalogs();
  const { registerGeneral, setShowLoader } = useContext(AppContext);
  const {
    registerData,
    setRegisterData,
    dataServicesContext,
    setDataServicesContext,
    idCurrentProvider,
  } = useContext(AppContext);
  const [seasons, setSeasons] = useState<seasonInterface[]>([]);
  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  const [viewList, setViewList] = useState<viewInterface[]>([]);
  const [serviceList, setServiceList] = useState<serviceInterface[]>([]);
  const [categoryServices, setCategoryServices] = useState<categoriaI[]>([]);
  const [foodPlan, setFoodPlan] = useState<maealI[]>([]);
  const { showAlert, setShowAlert } = useContext(AppContext);
  const { contentAlert, setContentAlert } = useContext(AppContext);
  const { titleAlert, setTitleAlert } = useContext(AppContext);
  const [serviciosInterface, setServiciosInterface] = useState<
    serviciosInterface[]
  >(registerData.service_list);
  const [dataServices, setDataServices] = useState<dataServicesI>({
    services_fees: dataServicesContext.services_fees,
  });
  useEffect(() => {
    setSeasons(catalogsData?.season_list);
    setCurrencyList(catalogsData?.currency_list);
    setViewList(catalogsData?.view_list);
    setServiceList(catalogsData?.service_type_list);
    setCategoryServices(catalogsData?.category_list);
    setFoodPlan(catalogsData?.meal_plan_list);
  }, [catalogsData]);

  /////BORRAR TARIFA

  ////EDITAR TARIFA
  const editService = (
    indice: number,
    servicioModified: serviciosInterface
  ) => {
    const newServiciosInterface = serviciosInterface.map((servicio, index) => {
      return indice != index ? servicio : servicioModified;
    });
    setServiciosInterface(newServiciosInterface);
    setRegisterData({
      ...registerData,
      service_list: [...newServiciosInterface],
    });
  };

  ////AGREGAR SERVICIO:
  const addNewServicio = () => {
    //console.log("AGREGANDO NUEVO SERVICIO: ");
    let identificador = uuidv4();
    let identifierValidity = uuidv4();
    const newServicio = {
      fee_type_identifier: 0,
      service_identifier: 0,
      service_name: "",
      service_type_identifier: 0,
      service_capacity: "",
      service_comments: "",
      service_key: identificador,
      service_category_identifier: 0,
      service_category: "",
      service_mealplan_identifier: 0,
      continent_identifier: 2,
      minimum_stay: "",
      market_restrictions: "",
      operating_days: [],
      initial_schedule: "",
      final_schedule: "",
      transport_type_identifier: 3,
      description: "",
      minimum_to_operate: "",
      /////LOS SIGUIENTES  3 no van a servicio
      fee_is_included: true,
      type_fee: 0,
      identifier_list: identificador,

      /////ARREGLOS DE ObJETOS A ENVIAR A SERVICIOS
      service_fee_list: [
        {
          fee_identifier: 0,
          fee_BBT: 0,
          /////////////////////////////////
          fee_currency_identifier: 0,
          fee_iva: 0,
          fee: 0,
          fee_total: 0,
          fee_SGL: 0,
          fee_DBL: 0,
          fee_TPL: 0,
          fee_CPL: 0,
          fee_BB: 0,
          fee_MAID: 0,
          fee_FP: 0,
          fee_TSGL: 0,
          fee_TDBL: 0,
          fee_TTPL: 0,
          fee_TCPL: 0,
          fee_TFP: 0,
          fee_TMAID: 0,
          fee_TBB: 0,
          fee_tax: 0,
          fee_key: identificador,
          fee_capacity: "",
          vehicle: "",
        },
      ],
      service_blackout_list: [
        {
          blackout_identifier: 0,
          blackout_startdate: "",
          blackout_enddate: "",
          blackoutIdentifier: identifierValidity,
        },
      ],
      fee_validity_list: [
        {
          validity_identifier: 0,
          validity_startdate: "",
          validity_enddate: "",
          identifierValidity: identifierValidity,
        },
      ],
      service_minimum_stay_list: [
        {
          minimum_stay: "",
          minimum_stay_startdate: "",
          minimum_stay_enddate: "",
          minimuStayIdentifier: identifierValidity,
          status_identifier: 0,
          minimum_stay_identifier: null,
        },
      ],
    };

    setDataServices({
      services_fees: [...dataServices.services_fees, newServicio],
    });
    setCounterNewElements(counterNewElements + 1);
  };
  const [counterNewElements, setCounterNewElements] = useState<number>(0);
  useEffect(() => {
    setIndexServiceSelectes(dataServices.services_fees.length - 1);
  }, [counterNewElements]);
  /////////////////////////////BORRAR SERVICIOS
  const deleteServicio = async (tarifa: serviceObject) => {
    if (dataServices.services_fees.length == 1) {
      alert("No puedes borrar más tarifas");
      return;
    }

    //console.log("Borrando servicio");
    const arrayTarifas = dataServices.services_fees;
    const newArrayTarifas = arrayTarifas.filter((tarifaEvaluada, index) => {
      return tarifaEvaluada.identifier_list != tarifa.identifier_list;
    });

    ////////////////////DELETE DESDE SERVICIOS
    if (idCurrentProvider != 0) {
      setShowLoader(true);
      const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
      //console.log("REACT BACK END POINT: ");
      //console.log(backEndPoint);

      const urlModified = backEndPoint + "/provider/update";
      const modifiedGeneral: GeneralModifiedI = {
        register_identifier: idCurrentProvider,
        name: registerGeneral.name,
        registration_type_identifier:
          registerGeneral.registration_type_identifier,
        lender_type_identifier:
          registerGeneral.lender_type_identifier == 0
            ? 1
            : registerGeneral.lender_type_identifier,
        provider_type_identifier: registerGeneral.provider_type_identifier
          ? registerGeneral.provider_type_identifier
          : 1,
        business_name: registerGeneral.business_name,
        street: registerGeneral.calles,
        number: registerGeneral.telefono,
        neighborhood_identifier: registerGeneral.neighborhood_identifier,
        city_identifier: registerGeneral.city_identifier
          ? registerGeneral.city_identifier
          : 1,
        country_identifier: registerGeneral.country_identifier,
        zip_code: registerGeneral.zip_code,
        important_notes: registerGeneral.important_notes,
        description: registerGeneral.lead,
        id: registerGeneral.id,
        key: registerGeneral.claveGeneral,
        rfc: registerGeneral.rfc,
        category:
          registerGeneral.categoriaGeneral == 0
            ? null
            : registerGeneral.categoriaGeneral,
        last_purchase: registerGeneral.ultimaCompra,
        feedback: registerGeneral.feedback,
        lead: registerGeneral.lead,
        commission_scheme: registerGeneral.comisionEsquema,
        web: registerGeneral.web,
        comments: registerGeneral.comments,
        category_identifier: registerGeneral.category_identifier,
      };

      const serviciosModified: ServiceFeeDelete[] =
        dataServicesContext.services_fees
          .filter((currentService, index) => {
            return (
              currentService.service_identifier == tarifa.service_identifier
            );
          })
          .map((servicio, index) => {
            const currentListFee: FeeDelete[] = servicio.service_fee_list.map(
              (fee, index) => {
                const serviceFee: FeeDelete = {
                  fee_identifier: fee.fee_identifier,
                  fee_currency_identifier: fee.fee_currency_identifier,
                  fee_iva: fee.fee_iva,
                  fee_tax: fee.fee_tax,
                  fee: fee.fee,
                  fee_total: fee.fee_total,
                  fee_SGL: fee.fee_SGL,
                  fee_DBL: fee.fee_DBL,
                  fee_TPL: fee.fee_TPL,
                  fee_CPL: fee.fee_CPL,
                  fee_FP: fee.fee_FP,
                  fee_TSGL: fee.fee_TSGL,
                  fee_TDBL: fee.fee_TDBL,
                  fee_TTPL: fee.fee_TTPL,
                  fee_TCPL: fee.fee_TCPL,
                  fee_TFP: fee.fee_TFP,
                  fee_BB: fee.fee_BB,
                  fee_BBT: fee.fee_BBT,
                  fee_MAID: fee.fee_MAID,
                  fee_TMAID: fee.fee_TMAID,
                  fee_capacity: fee.fee_capacity,
                  status_identifier: 1,
                };
                return serviceFee;
              }
            );

            const currentService: ServiceFeeDelete = {
              fee_type_identifier: servicio.fee_type_identifier,
              service_identifier: servicio.service_identifier,
              service_type_identifier: servicio.service_type_identifier,
              service_mealplan_identifier: servicio.service_mealplan_identifier,
              service_category_identifier: servicio.service_category_identifier,
              service_category: servicio.service_category,
              service_name: servicio.service_name,
              service_comments: servicio.service_comments,
              service_key: servicio.service_key,
              service_fees_list: [],
              service_validity_list: [],
              service_blackout_list: [],
              status_identifier: 2,
              service_minimum_stay_list: [],
              operating_days: [],
            };

            return currentService;
          });

      const modifiedObject: DeleteService = {
        user_identifier: 1,
        general: modifiedGeneral,
        services_fees: serviciosModified,
      };

      //console.log("CUERPO DE PETICION: ");
      //console.log(modifiedObject);

      try {
        const response = await fetch(urlModified, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(modifiedObject),
        });
        if (response.ok) {
          ////RESPUESTA CORRECTA
          //console.log(response);
          //console.log("bien");

          setTitleAlert("Modificación correcta correcto");
          setContentAlert(
            "Tus datos fueron modificados correctamente, puedes consultarlos en el catalogo."
          );

          setShowAlert(true);
          setShowLoader(false);
        } else {
          ///RESPUESTA INCORRECTA DE SERVICIOS
          // setShowLoader(false);
          setTitleAlert("Error al BORRAR:");
          setContentAlert(
            "Tus datos no fueron guardados correctamente, por favor, verificalos."
          );
          setShowAlert(true);
          setShowLoader(false);
        }
      } catch (error) {
        ///ERROR AL REALIZAR PETICION
        alert("ERROR AL REALIZAR BORRADO");
        setShowLoader(false);
      }
    }

    /////FIN DELETE DESDE SERVICIOS

    setDataServices({
      ...dataServices,
      services_fees: newArrayTarifas,
    });
  };

  useEffect(() => {
    //console.log("Se actualizó la información de tarifas: ");
    //console.log(dataServices);
  }, [dataServices]);

  useEffect(() => {}, [registerData]);

  const [tipoTarifa, setTipoTarifa] = useState<number>(0);

  const [previousIdentifier, setPreviousIdentifier] = useState(0);
  useEffect(() => {
    setDataServicesContext(dataServices);
  }, [dataServices]);
  const [isShowingList, setIsShowingList] = useState<boolean>(false);
  const [indexServiceSelected, setIndexServiceSelectes] = useState<number>(0);
  const [indexBlueServiceSelected, setIndexBlueServiceSelected] = useState(0);
  return (
    <ServiciosStyle
      isShowingList={isShowingList}
      idCurrentProvider={idCurrentProvider}
    >
      <br />
      <div className="box-container">
        <div className="tarifa-general-container">
          <div className="tarifa-get-container">
            <div>
              {!isShowingList && (
                <div className="container-box-two">
                  <div className="burger-button-container box-two">
                    <FontAwesomeIcon
                      icon={faBars}
                      className="burger-icon menu-burger"
                      onClick={() => {
                        setIsShowingList(!isShowingList);
                      }}
                    />
                  </div>
                </div>
              )}

              {dataServices.services_fees
                .filter((tarifa, index) => index == indexServiceSelected)
                .map((tarifa, index) => {
                  return (
                    <TarifaGeneral
                      tarifa={tarifa}
                      setDataServices={setDataServices}
                      currencyList={currencyList}
                      dataServices={dataServices}
                      key={tarifa.identifier_list}
                      addNewServicio={addNewServicio}
                      isShowingAddButton={true}
                      tipoTarifa={tipoTarifa}
                      setTipoTarifa={setTipoTarifa}
                      isEditMode={true}
                      deleteServicio={deleteServicio}
                    />
                  );
                })}
            </div>
          </div>

          {
            <div className="box-list-services">
              <div className="burger-button-container active-button-container">
                <h3>Consulta</h3>
                <FontAwesomeIcon
                  icon={faBars}
                  className="burger-icon menu-burger"
                  onClick={() => {
                    setIsShowingList(!isShowingList);
                  }}
                />
              </div>
              <div className="container-list">
                {dataServices.services_fees.map((servicio, index) => {
                  const currentVigencia =
                    servicio.fee_validity_list[
                      servicio.fee_validity_list.length - 1
                    ];
                  const vigenciaUno = currentVigencia?.validity_startdate;
                  const vigenciaDos = currentVigencia?.validity_enddate;
                  let vigenciaTexto;
                  if (vigenciaUno || vigenciaDos) {
                    vigenciaTexto = vigenciaUno + " / " + vigenciaDos;
                  }
                  return (
                    <div
                      className="list-element"
                      onClick={() => {
                        setIndexServiceSelectes(index);
                      }}
                      style={{
                        backgroundColor:
                          index == indexServiceSelected
                            ? "rgba(8, 166, 165, 0.3)"
                            : "",
                      }}
                    >
                      <div className="titulo-tarifa">
                        <div
                          onClick={() => {
                            setIsShowingList(!isShowingList);
                          }}
                        >
                          <h3 className="title-tarifa">
                            {servicio.service_name.trim()
                              ? servicio.service_name
                              : "Servicio sin nombre"}
                          </h3>
                        </div>
                        <img
                          src={deletedatos}
                          alt="Delete"
                          width="25"
                          onClick={() => deleteServicio(servicio)}
                        />
                      </div>
                      <div
                        className="vigencia-info"
                        onClick={() => {
                          setIsShowingList(!isShowingList);
                        }}
                      >
                        <h5>{vigenciaTexto}</h5>
                        <div></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }
          {/* <ListServices
          setIsShowingList ={setIsShowingList}
          isShowingList={isShowingList}
          dataServices={dataServices}
          setIndexServiceSelectes = {setIndexServiceSelectes}
          indexServiceSelected={indexServiceSelected}
          deletedatos={deletedatos}
          deleteServicio= {deleteServicio}
          indexBlueServiceSelected={indexBlueServiceSelected}
          setIndexBlueServiceSelected={setIndexBlueServiceSelected}
          /> */}
        </div>
      </div>
    </ServiciosStyle>
  );
}
interface ListI {
  isShowingList: boolean;
  idCurrentProvider: number;
}

const ServiciosStyle = styled.div<ListI>`
  .menu-burger {
    font-size: 1.5vw !important;
  }
  .title-tarifa {
    font-size: 1.1vw;
  }
  @media screen and (max-width: 900px) {
    .title-tarifa {
      font-size: 2vw;
    }
  }
  .vigencia-info {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
  .titulo-tarifa {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 1.2rem;
    padding: 0.2rem;
    justify-content: center;
    align-items: center;
  }
  .titulo-tarifa div {
    overflow: hidden;
  }
  .container-box-two {
    display: flex;
    justify-content: right;
  }
  .box-two {
    border: rgb(8, 166, 165) solid 1px;
    width: 10vw;
    padding: 0.5rem;
    background-color: rgb(8, 166, 165);
    color: white;
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem;

    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
  .active-button-container {
    display: flex;
    justify-content: space-between !important;
    border: rgb(8, 166, 165) solid 1px;
    align-items: center;
    padding-left: 1rem;
    background-color: rgba(8, 166, 165);
    padding: 0.7rem;
    color: white;
    border-top-left-radius: 1.2rem;
  }
  .box-container {
    display: flex;
  }
  .list-element {
    cursor: pointer;
    border: rgb(8, 166, 165) solid 1px;
    padding: 0.5rem;
    text-align: center;
    padding-top: 0.9rem;
  }
  .tarifa-get-container div {
  }
  .tarifa-general-container {
    display: ${(props) => (props.idCurrentProvider != 0 ? "flex" : "flex")};
    margin: 0 auto;
    width: 100% !important;
  }
  .box-list-services {
    border: rgb(8, 166, 165) solid 1px;
    border-top-left-radius: 1.2rem;
    width: 30%;
    display: ${(props) => (props.isShowingList ? "block" : "none")};
    position: fixed;
    min-height: 60vh;
    max-height: 60vh;
    background-color: white;
    z-index: 10;
    right: 5%;
  }
  .container-list {
    max-height: calc(60vh - 47.2px);
    overflow-y: scroll;
  }
  @media screen and (max-width: 900px) {
    .box-list-services {
      width: 50%;
    }
  }
  .burger-button-container {
    justify-content: right;
    padding-right: 2rem;
    display: ${(props) => (props.idCurrentProvider != 0 ? "flex" : "flex")};
  }
  .burger-icon {
    font-size: 2rem;
    cursor: pointer;
  }
  .tarifa-get-container {
    width: ${(props) => (props.isShowingList ? "100%" : "100%")};
    min-width: 100%;

    display: ${(props) => (props.idCurrentProvider != 0 ? "flex" : "flex")};
    justify-content: center;
  }
  .title-blackout {
    text-align: center;
    color: rgb(8, 166, 165);
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .blackout-header {
    width: 60%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr;
    justify-items: center;
  }
  @media screen and (max-width: 900px) {
    .blackout-header {
      width: 70%;
    }
  }
  .blackout-header-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-column: span 2;
    border: rgb(8, 166, 165) solid 2px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-items: center;
    align-items: center;
  }
  .icons-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: right;
  }
  .plus-icon {
    font-size: 1.6rem;
    color: rgb(8, 166, 165);
    border-radius: 50%;
    cursor: pointer;
  }
  .tarifas-title {
    margin-bottom: 1rem;
    color: rgb(8, 166, 165);
    text-decoration: none !important;
    user-select: none;
  }
  .tarifas-section {
    width: 90%;
    margin: 0 auto;
    margin-top: 1.2rem;
    border: black solid 1px;
    padding: 0.7rem;
    overflow: scroll;
    max-height: 100vh;
    display: ${(props) => (props.idCurrentProvider != 0 ? "none" : "block")};
  }
  .tarifas-general-header {
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr;

    border: rgb(8, 166, 165) solid 1px;
  }
  max-width: 100%;

  .tarifas-general-header div {
    border: rgb(8, 166, 165) solid 1px;
    padding: 0.6rem;
    text-align: center;
    font-size: 0.9rem;
  }

  .tarifas-general-header-dos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.6fr 1fr 1fr 1fr 0.7fr;

    border: rgb(8, 166, 165) solid 1px;
  }
  .tarifas-general-header-dos div {
    border: rgb(8, 166, 165) solid 1px;
    padding: 0.6rem;
    text-align: center;
    font-size: 0.9rem;
  }
  .comments-section {
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
  }
  .servicios-container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin-top: 2rem;
    grid-row-gap: 1rem;
  }
  .title-servicios {
    color: rgb(8, 166, 165);
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .data-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center; /* centrado horizontal */
    align-items: center; /* centrado vertical */

    grid-row-gap: 0.5rem;
  }
  ////RESPONSIVE COMBOBOX////
  @media screen and (max-width: 700px) {
    .titulo-select {
      font-size: 2.5vw !important;
    }
    select {
      font-size: 2.2vw !important;
    }
    .title-comment-section {
      font-size: 2.5vw !important;
    }
  }

  //////////////////////////
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
