import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import EDITAR from "../../../images/edit.png";
import StyledTable from "../tables/StyledTable";
import {
  GeneralsData,
  bodyResponseProvider,
  ContactData,
  Tax,
  Service,
  Contract,
} from "../../../interfaces/ProviderTypes";
import guardar from "../../../images/GUARDARDATOS.png";
import Generales from "../layout/Generales";
import ContactLayoutNew from "../layout/ContactLayoutNew";
import GetRowContact from "../layout/GetRowContact";
import GetContact from "../layout/GetContact";
import { v4 as uuidv4 } from "uuid";
import {
  objectCard,
  serviceObject,
  blackoutObject,
  validityDates,
  feeIndividual,
} from "../../../interfaces/GeneralTypes";
import { objetoPrueba } from "../../../context/initialContext";
import {
  fiscalContextInicial,
  registerGeneralData,
} from "../../../context/initialContext";
import GetFiscal from "../layout/GetFiscal";
import {
  faFilePdf,
  faFileImage,
  faClose,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import GetContract from "../layout/GetContract";
import {
  contratoContextI,
  contratoContextInterface,
} from "../../../context/InterfacesContext";
import { dataServicesInicial } from "../../../context/initialContext";
import { useNavigate } from "react-router-dom";
import {
  BankAccount,
  ServiceElement,
  ServiceBlackout,
  FeeElement,
  ServiceValidity,
} from "../../../interfaces/ProviderTypes";
import { Fee } from "../../../interfaces/ProviderTypes";
import { Validity } from "../../../interfaces/CotizacionI";
import { formatearCantidad } from "../functionsTs/utilidades";
import { transformarFecha } from "../../pages/Cotizacion/funciontes";

interface ServiceGeneralI {
  fee: Fee;
  serviceTypeId: number;
  servicio: Service;
  indiceTarifa: number;
}

export default function ServiceGIndividual({
  fee,
  serviceTypeId,
  servicio,
  indiceTarifa,
}: ServiceGeneralI) {
  useEffect(() => {
    //console.log("Indice de la tarifa::", indiceTarifa)
    //console.log(indiceTarifa);
  }, [indiceTarifa]);
  return (
    <ServiceGIndividualStyles>
      <div className="container-info">
        <div>
          <h3>{fee.fee_currency}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(fee.fee)}</h3>
        </div>
        <div>
          <h3>{fee.fee_iva}</h3>
        </div>

        <div>
          <h3>{serviceTypeId != 18 ? fee.fee_tax : fee.fee_capacity}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(fee.fee_total)}</h3>
        </div>
        <div>
          <h3>
            {servicio.service_validity_list.length > 0 &&
              transformarFecha(
                servicio?.service_validity_list[indiceTarifa]
                  ?.validity_startdate
              )}{" "}
            /{" "}
            {transformarFecha(
              servicio?.service_validity_list[indiceTarifa]?.validity_enddate
            )}
          </h3>
        </div>
      </div>
    </ServiceGIndividualStyles>
  );
}

const ServiceGIndividualStyles = styled.div`
  .container-info {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr 1fr 1fr;
    width: 100%;
    margin: 0 auto;
    padding: 0.5rem;
    border: rgb(8, 166, 165) solid 2px;
    border-bottom: none;
  }

  .container-info div h3 {
    text-align: center;
    font-weight: 300;
    font-size: 0.85vw;
  }
  .vigencia-contenedor {
    margin-top: 0.5rem;
  }
  .fila-dos {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
    width: 70% !important;
    border: rgb(8, 166, 165) solid 2px;
  }
  @media screen and (max-width: 900px) {
    .fila-dos {
      width: 100% !important;
    }
  }
  .value {
    font-weight: bolder;
  }
  p {
    font-weight: 100 !important;
  }
  .filaa {
  }
`;
