import {
  DataService,
  ServiceFees,
} from "../../../../interfaces/TransformTypes";
import {
  blackoutObject,
  serviceObject,
  stayDates,
} from "../../../../interfaces/GeneralTypes";
import {
  filterEmptyDates,
  filterEmptyDatesBlackout,
  filterEmptyDatesBlackoutTwo,
} from "./FunctionsStepeer";
import { ServiceValiditySave } from "../../../../interfaces/GuardadoTypes";
import { validityDates } from "../../../../interfaces/GeneralTypes";
import { ServiceBlackout } from "../../../../interfaces/ProviderTypes";

export function transformServices(
  serviciosLista: serviceObject[]
): ServiceFees[] | null {
  let newServices: ServiceFees[] = serviciosLista
    .filter((servicio, index) => {
      return true;
    })
    .map((servicio, index) => {
      const currentValidityListFilered: validityDates[] | null =
        filterEmptyDates(servicio.fee_validity_list);
      const currentBlackoutFiltered: blackoutObject[] | null =
        filterEmptyDatesBlackoutTwo(servicio.service_blackout_list);
      /////AUN NO SE FILTRA LAS ESTANCIAS MINIMAS:
      const currentMinimumStay: stayDates[] =
        servicio.service_minimum_stay_list;

      return {
        fee_type_identifier: servicio.fee_type_identifier,
        service_type_identifier: servicio.service_type_identifier,
        service_mealplan_identifier: servicio.service_mealplan_identifier,
        service_category_identifier: servicio.service_category_identifier,
        service_category: servicio.service_category,
        service_name: servicio.service_name,
        service_capacity: servicio.service_capacity,
        service_comments: servicio.service_comments,
        service_key: servicio.service_key,
        continent_identifier:
          servicio.service_type_identifier != 10 ||
          servicio.continent_identifier == 0
            ? 5
            : servicio.continent_identifier,
        minimum_stay: servicio.minimum_stay,
        market_restrictions: servicio.market_restrictions,
        operating_days: servicio.operating_days,
        initial_schedule: servicio.initial_schedule,
        final_schedule: servicio.final_schedule,
        description: servicio.description,
        minimum_to_operate: servicio.minimum_to_operate,
        transport_type_identifier:
          servicio.service_type_identifier != 18 ||
          servicio.transport_type_identifier == 0
            ? 3
            : servicio.transport_type_identifier,
        service_fees_list: servicio.service_fee_list.map((servicio, index) => {
          return {
            fee_currency_identifier: servicio.fee_currency_identifier,
            fee_type_identifier: 2,
            fee_iva: servicio.fee_iva,
            fee_tax: servicio.fee_tax,
            fee: servicio.fee,
            fee_total: servicio.fee_total,
            fee_SGL: servicio.fee_SGL,
            fee_DBL: servicio.fee_DBL,
            fee_TPL: servicio.fee_TPL,
            fee_CPL: servicio.fee_CPL,
            fee_FP: servicio.fee_FP,
            fee_TSGL: servicio.fee_TSGL,
            fee_TDBL: servicio.fee_TDBL,
            fee_TTPL: servicio.fee_TTPL,
            fee_TCPL: servicio.fee_TCPL,
            fee_TFP: servicio.fee_TFP,
            fee_BBT: servicio.fee_TBB,
            fee_BB: servicio.fee_BB,
            fee_MAID: servicio.fee_MAID,
            fee_TMAID: servicio.fee_TMAID,
            fee_capacity: servicio.fee_capacity,
            vehicle: servicio.vehicle,
          };
        }),
        service_validity_list:
          currentValidityListFilered == null
            ? []
            : servicio.fee_validity_list.map((validity, index) => {
                return {
                  validity_startdate: validity.validity_startdate,
                  validity_enddate: validity.validity_enddate,
                };
              }),
        service_blackout_list:
          currentBlackoutFiltered == null
            ? []
            : servicio.service_blackout_list.map((blackout, index) => {
                return {
                  blackout_startdate: blackout.blackout_startdate,
                  blackout_enddate: blackout.blackout_enddate,
                };
              }),
        service_minimum_stay_list:
          currentMinimumStay == null ? [] : currentMinimumStay,
      };
    });

  return newServices;
}

export function validateServiceFees(
  serviceFees: serviceObject[]
): serviceObject[] | boolean {
  let foundZero = false;

  for (let i = 0; i < serviceFees.length; i++) {
    const fee = serviceFees[i];

    if (fee.service_type_identifier === 0) {
      foundZero = true;
    }

    if (foundZero && i > 0) {
      alert(
        "Tus servicios no fueron guardados, algunos datos fueron inválidos."
      );
      return false;
    }
  }

  return serviceFees;
}

export function secondValidatonFees(
  servicesFees: ServiceFees[] | null
): ServiceFees[] {
  //console.log('EJECUTANDO SECOND VALIDATION-----------------------------------------: ');
  if (!servicesFees) return [];

  const arrayServicios = servicesFees.filter((fee, index) => {
    return fee.service_type_identifier !== 0;
  });
  //console.log('RESULTADO DEL ARRAY A ENVIAR-----------------------------------------: ');
  //console.log(arrayServicios);
  if (arrayServicios.length == 0) {
    return [];
  } else {
    return arrayServicios;
  }
}
