import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { faBox, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { faNotEqual } from "@fortawesome/free-solid-svg-icons";
import NavigationItem from "../common/NavigationItem";
import UserInfo from "../common/UserInfo";
import rombos from "../../../images/rombos.png";
import catalogo from "../../../images/catalogo.png";
import contabilidad from "../../../images/contabilidad.png";
import operacionesicon from "../../../images/operacionesicon.png";
import consulta from "../../../images/Consulta.png";
import alta from "../../../images/Alta.png";
import operaciones from "../../../images/operaciones.png";
import productos from "../../../images/productos.png";
import cotizacion from "../../../images/cotizacion.png";
import reportes from "../../../images/reportes.png";
import usuario from "../../../images/usuario.png";
import configuracion from "../../../images/configuracion.png";
import {
  faPlus,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import whiteuser from "../../../images/whiteuser.png";
import { AppContext } from "../../../context/appContext";
import {
  objetoPrueba,
  registerGeneralData,
  contratoContextInicial,
  fiscalContextInicial,
  dataServicesInicial,
} from "../../../context/initialContext";
import { dataCotizacionInicial } from "../../../context/initialContext";
import { OperationContext } from "../../../context/operationContext";

interface asideBarProps {
  showSideBar: boolean;
  setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function AsideBar({
  showSideBar,
  setShowSideBar,
}: asideBarProps) {
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;

  useEffect(() => {
    //console.log("HELLO: ");
    //console.log(screenWidth);
  }, [screenWidth]);
  const [isShowingMenu, setIsShowingMenu] = useState<boolean>(false);
  const [isShowingMenuTwo, setIsShowingMenuTwo] = useState<boolean>(false);
  const [isShowingMenuThree, setIsShowingMenuThree] = useState<boolean>(false);
  const [isShowingMenuFour, setIsShowingMenuFour] = useState<boolean>(false);
  const {
    setIdCurrentProvider,
    setRegisterData,
    setRegisterGeneral,
    setContratoContext,
    setDataServicesContext,
    setFiscalContext,
    setDataCotizacionContext,
    dataCotizacionContext,
    cotizacionMode,
    setCotizacionMode,
    currentUser,
    setCurrentUser,
    setListOpcionalesContext,
  } = useContext(AppContext);
  const { contabilidadLink, setContabilidadLink } =
    useContext(OperationContext);
  const [usuarioActual, setUsuarioActual] = useState(() => {
    const usuario = localStorage.getItem("usuarioLogeado");
    return usuario !== null ? usuario : currentUser.user_name;
  });
  const [grupoActual, setGrupoActual] = useState(() => {
    const grupo = localStorage.getItem("groupUsuario");

    return grupo !== null ? parseInt(grupo) : currentUser.group_identifier;
  });
  useEffect(() => {
    //console.log("USUARIO ACTUAL----------- ");
    //console.log(currentUser);
    //console.log("GGRUPO ACTUAL:");
    //console.log(grupoActual)
  }, [grupoActual]);
  // const [numberPage, setNumberPage] = useState(() => {
  //   const lastPage = localStorage.getItem("lastPage");
  //   return lastPage !== null ? parseInt(lastPage) : 0;
  // });
  return (
    <AsidebarStyles>
      <div
        className={`sidebar-container non-printable-section ${
          showSideBar ? "show-element" : "hide-element"
        }`}
        style={{ backgroundImage: `url(${rombos})` }}
      >
        <div className={`nav-items`}>
          <div className="user-info-box">
            <UserInfo />
          </div>
          <div className="user-info-box-website">
            <img
              src={whiteuser}
              alt="Imagen de perfil de usuario alico"
              className="picture-profile-alico"
            />
          </div>
          <div>
            <h3>{usuarioActual}</h3>
          </div>
          <br />
          <div className="new-aside-menu">
            <img
              src={catalogo}
              alt="Catalogo"
              width="25px"
              className="img-title-icon"
            />
            <h3 className="catalogo-title">Catálogo</h3>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="down-button"
              onClick={() => {
                setIsShowingMenu(!isShowingMenu);
                setIsShowingMenuTwo(false);
                setIsShowingMenuThree(false);
                setIsShowingMenuFour(false);
              }}
            />
          </div>
          <div className="container-links-all"></div>
          {isShowingMenu && (
            <div className="container-menu-options">
              <div></div>
              <div className="link-container-new">
                <div
                  onClick={() => {
                    //console.log("DISTE CLICK A ALTA:");
                    setRegisterGeneral(registerGeneralData);
                    setContratoContext(contratoContextInicial);
                    setDataServicesContext(dataServicesInicial);
                    setFiscalContext(fiscalContextInicial);
                    setRegisterData(objetoPrueba);
                    setIdCurrentProvider(0);
                    setRegisterData(objetoPrueba);
                  }}
                >
                  <NavigationItem
                    linkInfo="Alta"
                    iconItem={faMagnifyingGlass}
                    urlLink="Registro"
                    showSideBar={showSideBar}
                    setShowSideBar={setShowSideBar}
                    imgIcon={alta}
                  />
                </div>

                <NavigationItem
                  linkInfo="Consulta"
                  iconItem={faBook}
                  urlLink="Providers-List"
                  showSideBar={showSideBar}
                  setShowSideBar={setShowSideBar}
                  imgIcon={consulta}
                />
              </div>
            </div>
          )}

          {/* ///////////////Cotización------------------------------- */}
          {(grupoActual == 3 ||
            grupoActual == 1013 ||
            grupoActual == 1017 ||
            grupoActual == 1012 ||
            grupoActual == 1) && (
            <>
              <div className="new-aside-menu">
                <img
                  src={contabilidad}
                  alt="Catalogo"
                  width="25px"
                  className="img-title-icon"
                />
                <h3 className="catalogo-title">Cotización</h3>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="down-button"
                  onClick={() => {
                    setIsShowingMenuTwo(!isShowingMenuTwo);
                    setIsShowingMenu(false);
                    setIsShowingMenuThree(false);
                    setIsShowingMenuFour(false);
                  }}
                />
              </div>
              {isShowingMenuTwo && (
                <div className="container-menu-options">
                  <div></div>
                  <div className="link-container-new">
                    <div
                      onClick={() => {
                        setDataCotizacionContext(dataCotizacionInicial);
                        setListOpcionalesContext([]);
                      }}
                    >
                      <div
                        onClick={() => {
                          setCotizacionMode(1);
                          setListOpcionalesContext([]);
                        }}
                      >
                        <NavigationItem
                          linkInfo="Alta"
                          iconItem={faMagnifyingGlass}
                          urlLink="Alta-Cotizacion"
                          showSideBar={showSideBar}
                          setShowSideBar={setShowSideBar}
                          imgIcon={alta}
                        />
                      </div>
                    </div>

                    <NavigationItem
                      linkInfo="Consulta"
                      iconItem={faBook}
                      urlLink="List-Cotizacion"
                      showSideBar={showSideBar}
                      setShowSideBar={setShowSideBar}
                      imgIcon={consulta}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          {/* ///////////////OPERACIONES------------------------------- */}

          {(grupoActual == 1 ||
            grupoActual == 1011 ||
            grupoActual == 1012 ||
            grupoActual == 1013 ||
            grupoActual == 1017 ||
            grupoActual == 1018) && (
            <>
              <div className="new-aside-menu">
                <img
                  src={operacionesicon}
                  alt="Catalogo"
                  width="25px"
                  className="img-title-icon"
                />
                <h3 className="catalogo-title">Operaciones</h3>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="down-button"
                  onClick={() => {
                    setIsShowingMenuThree(!isShowingMenuThree);
                    setIsShowingMenu(false);
                    setIsShowingMenuFour(false);
                    setIsShowingMenuTwo(false);
                  }}
                />
              </div>
              {isShowingMenuThree && (
                <div className="container-menu-options">
                  <div></div>
                  <div className="link-container-new">
                    {grupoActual !== 1018 && (
                      <div
                        onClick={() => {
                          setDataCotizacionContext(dataCotizacionInicial);
                          setListOpcionalesContext([]);
                        }}
                      >
                        <div
                          onClick={() => {
                            setCotizacionMode(1);
                          }}
                        >
                          <NavigationItem
                            linkInfo="Operaciones"
                            iconItem={faMagnifyingGlass}
                            urlLink="Operaciones"
                            showSideBar={showSideBar}
                            setShowSideBar={setShowSideBar}
                            imgIcon={alta}
                          />
                        </div>
                      </div>
                    )}

                    <NavigationItem
                      linkInfo="Consulta"
                      iconItem={faBook}
                      urlLink="Search-Operacion"
                      showSideBar={showSideBar}
                      setShowSideBar={setShowSideBar}
                      imgIcon={consulta}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          {/* ///////////////CONTABILIDAD------------------------------- */}
          {(grupoActual == 2 || grupoActual == 1018 || grupoActual == 1) && (
            <>
              <div className="new-aside-menu">
                <img
                  src={contabilidad}
                  alt="Catalogo"
                  width="25px"
                  className="img-title-icon"
                />
                <h3 className="catalogo-title">Contabilidad</h3>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="down-button"
                  onClick={() => {
                    setIsShowingMenuFour(!isShowingMenuFour);
                    setIsShowingMenu(false);
                    setIsShowingMenuThree(false);
                    setIsShowingMenuThree(false);
                  }}
                />
              </div>
              {isShowingMenuFour && (
                <div className="container-menu-options">
                  <div></div>
                  <div className="link-container-new">
                    <div
                      onClick={() => {
                        // setDataCotizacionContext(dataCotizacionInicial);
                        // setListOpcionalesContext([]);
                      }}
                    >
                      <div
                        onClick={() => {
                          // setCotizacionMode(1);
                          // setListOpcionalesContext([]);
                          setContabilidadLink(1);
                        }}
                      >
                        <NavigationItem
                          linkInfo="Grupos a Pagar"
                          iconItem={faMagnifyingGlass}
                          urlLink="Contabilidad"
                          showSideBar={showSideBar}
                          setShowSideBar={setShowSideBar}
                          imgIcon={alta}
                        />
                      </div>
                    </div>

                    <NavigationItem
                      linkInfo="Pagos Realizados"
                      iconItem={faBook}
                      urlLink="Contabilidad-Busqueda"
                      showSideBar={showSideBar}
                      setShowSideBar={setShowSideBar}
                      imgIcon={consulta}
                    />
                    <NavigationItem
                      linkInfo="Reembolsos"
                      iconItem={faBook}
                      urlLink="contabilidad/Contabilidad-Reembolsos"
                      showSideBar={showSideBar}
                      setShowSideBar={setShowSideBar}
                      imgIcon={consulta}
                    />

                    <div
                      onClick={() => {
                        setContabilidadLink(2);
                      }}
                    >
                      <NavigationItem
                        linkInfo="Auxiliares"
                        iconItem={faBook}
                        urlLink="Contabilidad"
                        showSideBar={showSideBar}
                        setShowSideBar={setShowSideBar}
                        imgIcon={consulta}
                      />
                    </div>
                    <NavigationItem
                      linkInfo="Estados de cuenta"
                      iconItem={faBook}
                      urlLink="contabilidad/Contabilidad-EstadosCuenta"
                      showSideBar={showSideBar}
                      setShowSideBar={setShowSideBar}
                      imgIcon={consulta}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          <br />

          <div className="logoutcontainer">
            <Link to="/" className="link link-item">
              <div className="logout">
                <h3>Cerrar sesión</h3>
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  className="logouticon"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </AsidebarStyles>
  );
}

const AsidebarStyles = styled.div`
  @media print {
    /* Estilo para imprimir secciones imprimibles */
    .printable-section {
      display: block;
    }

    /* Estilo para ocultar secciones no imprimibles */
    .non-printable-section {
      display: none;
    }
  }
  text-decoration: none;
  user-select: none; /* Evitar la selección de texto */
  h3 {
    font-size: 1vw;
  }

  @media screen and (max-width: 900px) {
    h3 {
      font-size: 1.6vw !important;
    }
    .img-title-icon {
      display: none;
    }
  }
  .catalogo-title {
    text-decoration: none;
    user-select: none; /* Evitar la selección de texto */
  }
  .logoutcontainer {
    position: absolute;
    bottom: 5rem;
  }
  .link-container-new {
    max-width: 80%;
    margin: 0 auto;
    padding-top: 1rem;
    text-decoration: none;
    user-select: none; /* Evitar la selección de texto */
  }
  .link-container-new link {
    color: red !important;
  }
  .container-menu-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .down-button {
    font-size: 1.3vw;
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    .down-button {
      font-size: 1.8vw;
    }
  }
  .new-aside-menu {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  @media screen and (max-width: 900px) {
    .new-aside-menu {
      padding-bottom: 0 !important;
    }
  }
  .user-info-box-website {
    border: white solid 1px;
    background-color: white;
    width: 7vw;
    height: 7vw;
    border-radius: 50%;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }
  .picture-profile-alico {
    width: 100%;
  }
  .logout {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .link {
    color: white;
  }
  .logout h3 {
    margin-right: 1rem;
  }
  .logouticon {
    font-size: 1.5vw;
  }
  @media screen and (max-width: 900px) {
    .logouticon {
      font-size: 2.8vw;
    }
  }

  .plus-icon {
    color: red;
    border-radius: 50%;
    padding: 0.3rem;
    margin-right: 0.9rem;
    background-color: #08a6a5;
    color: white;
    border: none;
  }
  .button-aside {
    width: 170px;
    background-color: #0c4856;
    border: none;
    outline: none;
    padding: 0.3rem;
    background-color: white;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    color: #0c4856;
    font-weight: 500;
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .plus-button {
    border-radius: 50%;
  }

  .sidebar-container {
    border: white solid 1px;
    min-height: 100vh;
    max-height: 100vh;
    position: fixed;
    min-width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    /* align-items: center ;  */
    background-color: #08a6a5;
    z-index: 100;

    /* background-image: url("https://thumbs.dreamstime.com/b/fondo-vertical-de-tecnolog%C3%ADa-digital-futurista-en-tonos-grises-y-azules-texturas-fractales-tecnol%C3%B3gicas-servidor-pantalla-para-200152991.jpg"); */
  }
  .show-element {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }

  .hide-element {
    /* opacity: 0; */
    transform: translateX(-100%);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  .user-info-box {
    display: black solid 1px;
    width: 80%;
    display: none;
  }

  @media screen and (min-width: 900px) {
    .user-info-box {
      display: none;
    }
  }
  .nav-items {
    /* border: red solid 1px;  */
    width: 80%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
  }
  .sidebar-container {
    min-width: 20%;
    max-width: 20%;
  }
  @media screen and (min-width: 900px) {
    .sidebar-container {
      min-width: 20%;
      max-width: 20%;
    }
  }
`;
