import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus } from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { BrowserRouter, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import ResumenBar from "./ResumenBar";
import { useCatalogs } from "../../../context/CatalogContext";
import { CatalogsProviderDos } from "../../../context/CatalogContextDos";
import {
  cotizacionListIndividual,
  cotizacionListResults,
  cotizacionListTriple,
} from "../../../context/InterfacesContext";
import {
  QuoteGeneralElement,
  coinInterface,
} from "../../../interfaces/GeneralTypes";
import { servicioDayElementI } from "../../../context/InterfacesContext";
import guardardatos from "../../../images/GUARDARDATOS.png";
import cancelar from "../../../images/CANCELAR.png";
import SaveCotizacion from "./SaveCotizacion";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";
import ListOpcionales from "./ListOpcionales";
import { NumericFormat } from "react-number-format";

export default function AltaCotizacion() {
  const [isShowingBarOptions, setIsShowingBarOptions] =
    useState<boolean>(false);
  const { catalogsData } = useCatalogs();
  const {
    dataCotizacionContext,
    currentCurrency,
    setCurrentCurrency,
    setDataCotizacionContext,
    selectedOption,
    setSelectedOption,
    setServiciosType,
    serviciosType,
    selectedSerieOption,
    setSelectedSerieOption,
    showDeleteNotification,
    cotizationExtraProperties,
    setCotizationExtraProperties,
  } = useContext(AppContext);
  const [currentSumaTotal, setCurrentSumaTotal] = useState<number>(0);
  const [cortesia, setCortesia] = useState<number>(0);
  const [generales, setGenerales] = useState<number>(0);
  const [listPrices, setListPrices] = useState<cotizacionListResults[]>([]);
  const [listPricesOPT, setListPricesOPT] = useState<cotizacionListResults[]>(
    []
  );
  const [listSingle, setListSingle] = useState<cotizacionListIndividual[]>([]);
  const [listTriple, setListTriple] = useState<cotizacionListTriple[]>([]);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    setDataCotizacionContext({
      ...dataCotizacionContext,
      personalInfo: {
        ...dataCotizacionContext.personalInfo,
        modeCotizacion: option,
      },
    });
  };

  const handleSerieChange = (option: string) => {
    setSelectedSerieOption(option);
    setDataCotizacionContext({
      ...dataCotizacionContext,
      personalInfo: {
        ...dataCotizacionContext.personalInfo,
        selectedSerieOption: selectedSerieOption,
      },
    });
  };

  useEffect(() => {
    let totalTarifa = 0;
    let totalServiciosGenerales = 0;
    let totalServiciosGeneralesDos = 0;
    let totalServicios = 0;
    let precioCortesia = 0;
    let dinamicCortesia = 0;
    let generalesServicesDinamic = 0;

    let generalesDinamicDos = 0;
    let generalesDinamicTres = 0;
    let totalServiciosGeneralesTres = 0;

    let sumSingleServices = 0;
    let listSingleServices: cotizacionListIndividual[] = [];
    let listTripleServices: cotizacionListTriple[] = [];
    //////////////////Suma de los servicios individuales
    ///////////////////////////////////////VARIABLES PARA OPCIONALES////////////////////

    let totalTarifaOPT = 0;
    let totalServiciosGeneralesOPT = 0;
    let totalServiciosGeneralesDosOPT = 0;
    let totalServiciosOPT = 0;
    let precioCortesiaOPT = 0;
    let dinamicCortesiaOPT = 0;
    let generalesServicesDinamicOPT = 0;

    let generalesDinamicDosOPT = 0;
    let generalesDinamicTresOPT = 0;
    let totalServiciosGeneralesTresOPT = 0;

    let sumSingleServicesOPT = 0;
    let listSingleServicesOPT: cotizacionListIndividual[] = [];
    let listTripleServicesOPT: cotizacionListTriple[] = [];

    /**Aquí se calcula la suma de los servicios individuales (totalTarifa) */
    /*También se calcula la tabla de suplementos y complementos (DBL y TPL) con los datos de consulta o nuevas tarifas guardadas */
    dataCotizacionContext.serviciosDayElements.servicioDayElement.forEach(
      (servicioDay) => {
        if (servicioDay.isAvailable) {
          servicioDay.serviciosIndividuales.forEach((servicioIndividual) => {
            if (!servicioIndividual.isIncludedOpt) {
              totalTarifa += servicioIndividual.tarifa;
            } else {
              totalTarifaOPT += servicioIndividual.tarifa;
            }

            if (servicioIndividual.tarifaSingleComplementConverted) {
              if (servicioIndividual.tarifaSingleComplementConverted > 0) {
                listSingleServices.push({
                  singleTarifa:
                    servicioIndividual.tarifaSingleComplementConverted
                      ? servicioIndividual.tarifaSingleComplementConverted
                      : 0,
                  fechaTarifa: servicioIndividual.dateRequired,
                  idTarifaIndividual: servicioIndividual.identifierService,
                });
              }
            }
            if (servicioIndividual.tarifaTripleSuplementConverted) {
              if (servicioIndividual.tarifaTripleSuplementConverted > 0) {
                listTripleServices.push({
                  tripleTarifa:
                    servicioIndividual.tarifaTripleSuplementConverted
                      ? servicioIndividual.tarifaTripleSuplementConverted
                      : 0,
                  fechaTarifa: servicioIndividual.dateRequired,
                  idTarifaIndividual: servicioIndividual.identifierService,
                  currencyId: servicioIndividual.currencyId,
                });
              }
            }
          });
        }
      }
    );

    precioCortesia += totalTarifa;
    precioCortesiaOPT += totalTarifaOPT;
    ////////Para calcular la cortesía, primero se toman en cuenta el precio de la suma de los servicios individuales por día
    dinamicCortesia += totalTarifa;
    dinamicCortesiaOPT += totalTarifaOPT;
    //////////////Se guardan los datos para cada fila de single y triple habitación (de ser el caso)
    setListSingle(listSingleServices);
    setListTriple(listTripleServices);
    /////////////LO SIGUIENTE FUE COMENTADO  18 de octubre 2023 , cuidado por si algo falla
    dataCotizacionContext.generalsElements.generalElement.forEach(
      (servicioGeneral) => {
        totalServiciosGenerales += servicioGeneral.totalPrice;
      }
    );

    /*Aquí se calcula la suma de gastos generales */
    dataCotizacionContext.generalServices.forEach((servicioGeneral) => {
      /*Aquí se debería determinar si sumar a gastos generales o gastos opcionales*/
      if (!servicioGeneral.isIncludedOpt) {
        totalServiciosGeneralesDos +=
          servicioGeneral.fee_price * parseFloat(servicioGeneral.number_days);
      } else {
        totalServiciosGeneralesDosOPT +=
          servicioGeneral.fee_price * parseFloat(servicioGeneral.number_days);
      }
    });
    //////////////Suma de los servicios Generales de transporte
    dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.forEach(
      (currentDay, index) => {
        /*Aquí se debería determinar si sumar a gastos generales de transportes*/
        currentDay.serviciosIndividuales.forEach((currentService, index) => {
          if (!currentService.isIncludedOpt) {
            totalServiciosGeneralesTres =
              totalServiciosGeneralesTres +
              currentService.tarifa * currentService.number_of_services;
          } else {
            totalServiciosGeneralesTresOPT =
              totalServiciosGeneralesTresOPT +
              currentService.tarifa * currentService.number_of_services;
          }
        });
      }
    );

    /**Aquí es el resumen */
    /** */
    generalesServicesDinamic = totalServiciosGenerales;
    //console.log("Nuevo RESULTADO TOTAL ");
    //console.log(totalServiciosGeneralesTres);
    /**TOTAL DE SERVICIOS GENERALES */
    generalesDinamicDos = totalServiciosGeneralesDos;
    generalesDinamicDosOPT = totalServiciosGeneralesDosOPT;
    /**Total de servicios de Transporte */
    generalesDinamicTres = totalServiciosGeneralesTres;
    generalesDinamicTresOPT = totalServiciosGeneralesTresOPT;
    ////////////CICLO PARA LLENAR TABLA DE CLIENTES
    ////////////////////ARRAY CUIADO CON ESTE CAMBIO; ESTOS ARREGLOS ESTABAN DENTRO DEL IF
    let listResults: cotizacionListResults[] = [];
    let listResultsOPT: cotizacionListResults[] = [];
    if (
      ((dataCotizacionContext.personalInfo.min
        ? dataCotizacionContext.personalInfo.min
        : 0) <= dataCotizacionContext.personalInfo.pax &&
        (dataCotizacionContext.personalInfo.pax
          ? dataCotizacionContext.personalInfo.pax
          : 0) <= dataCotizacionContext.personalInfo.max) ||
      dataCotizacionContext.personalInfo.min <=
        dataCotizacionContext.personalInfo.max ||
      (!dataCotizacionContext.personalInfo.max &&
        !dataCotizacionContext.personalInfo.min &&
        dataCotizacionContext.personalInfo.pax) ||
      (!dataCotizacionContext.personalInfo.max &&
        !dataCotizacionContext.personalInfo.pax &&
        dataCotizacionContext.personalInfo.min)
    ) {
      let minPersonal = dataCotizacionContext.personalInfo.min
        ? dataCotizacionContext.personalInfo.min
        : 0;
      let minNumber = [
        dataCotizacionContext.personalInfo.min,
        dataCotizacionContext.personalInfo.pax,
        dataCotizacionContext.personalInfo.max,
      ].filter((num) => num > 0)[0];
      //console.log("Numeros:::");
      //console.log(dataCotizacionContext.personalInfo.min);
      //console.log(dataCotizacionContext.personalInfo.pax);
      //console.log(dataCotizacionContext.personalInfo.max);
      let maxNumber = dataCotizacionContext.personalInfo.max
        ? dataCotizacionContext.personalInfo.max
        : dataCotizacionContext.personalInfo.pax
        ? dataCotizacionContext.personalInfo.pax
        : dataCotizacionContext.personalInfo.min;
      for (let i = minNumber; i <= maxNumber; i++) {
        ////Calculo de servicios generales que paga cada pasajero, dependiendo el número de pasajeros en cuestión
        const totalServicioGeneral =
          (generalesDinamicDos + generalesDinamicTres) / i;
        const totalServicioGeneralOPT =
          (generalesDinamicDosOPT + generalesDinamicTresOPT) / i;
        /////Calculo de precio a pagar de cortesía, dependiendo el número de pasajeros y la suma SINGLE ROOM y el número de cortesías requerida
        const conversionTipoCortesia =
          dataCotizacionContext.feeCharges.courtesy_type_identifier == 2
            ? totalSingleTarifa
            : 0;
        const conversionTipoCortesiaOPT =
          dataCotizacionContext.feeCharges.courtesy_type_identifier == 2
            ? totalSingleTarifa
            : 0;

        let currentCortesia = parseFloat(
          ((dinamicCortesia + conversionTipoCortesia) / i).toFixed(2)
        );

        let currentCortesiaOPT = parseFloat(
          ((dinamicCortesiaOPT + conversionTipoCortesiaOPT) / i).toFixed(2)
        );
        if (dataCotizacionContext.feeCharges.cortesia > 0) {
          currentCortesia =
            dataCotizacionContext.feeCharges.cortesia * currentCortesia;
          currentCortesiaOPT =
            dataCotizacionContext.feeCharges.cortesia * currentCortesia;
        } else {
          currentCortesia = 0;
          currentCortesiaOPT = 0;
        }

        let totalCurrentServicios = parseFloat(
          (totalServicioGeneral + totalTarifa + currentCortesia).toFixed(2)
        );
        let totalCurrentServiciosOPT = parseFloat(
          (
            totalServicioGeneralOPT +
            totalTarifaOPT +
            currentCortesiaOPT
          ).toFixed(2)
        );
        ////Se calcula la comisión si la comisión es mayor al 0%
        if (dataCotizacionContext.feeCharges.comision > 0) {
          totalCurrentServicios = parseFloat(
            (
              totalCurrentServicios +
              (dataCotizacionContext.feeCharges.comision / 100) *
                totalCurrentServicios
            ).toFixed(2)
          );
          totalCurrentServiciosOPT = parseFloat(
            (
              totalCurrentServiciosOPT +
              (dataCotizacionContext.feeCharges.comision / 100) *
                totalCurrentServiciosOPT
            ).toFixed(2)
          );
        }
        let comisionCurrentService =
          (dataCotizacionContext.feeCharges.comision / 100) *
          (totalServicioGeneral + totalTarifa + currentCortesia);
        let comisionCurrentServiceOPT =
          (dataCotizacionContext.feeCharges.comision / 100) *
          (totalServicioGeneralOPT + totalTarifaOPT + currentCortesiaOPT);
        const listResult: cotizacionListResults = {
          total: totalCurrentServicios,
          cortesia: currentCortesia,
          general: totalServicioGeneral,
          numberPassengers: i,
          comision: comisionCurrentService,
          totalHotel: totalTarifa,
          totalXPax: totalServicioGeneral + totalTarifa + currentCortesia,
        };
        const listResultOPT: cotizacionListResults = {
          total: totalCurrentServiciosOPT,
          cortesia: currentCortesiaOPT,
          general: totalServicioGeneralOPT,
          numberPassengers: i,
          comision: comisionCurrentServiceOPT,
          totalHotel: totalTarifaOPT,
          totalXPax:
            totalServicioGeneralOPT + totalTarifaOPT + currentCortesiaOPT,
        };
        listResults.push(listResult);
        listResultsOPT.push(listResultOPT);
      }
      setListPrices(listResults);
      setListPricesOPT(listResultsOPT);
    } else {
      {
        setListPrices([]);
        setListPricesOPT([]);
      }
    }

    /////Calculo comisiones
    if (dataCotizacionContext.feeCharges.comision > 0) {
      totalServicios =
        totalServicios +
        (dataCotizacionContext.feeCharges.comision / 100) * totalServicios;
    }

    setCurrentSumaTotal(parseFloat(totalServicios.toFixed(2)));

    /////////////////////AQUI DEBES CALCULAR LOS COMPLEMENTOS ETC PARA LA PROFORMA::::::::::::
    const totalSingleTarifaSuma = listSingle.reduce(
      (total, item) => total + item.singleTarifa,
      0
    );
    const totalTripleTarifaSuma = listTriple.reduce(
      (total, item) => total + item.tripleTarifa,
      0
    );
    const totalDobleTarifaSuma = listResults.filter((precio, index) => {
      return precio.numberPassengers == dataCotizacionContext.personalInfo.pax;
    });
    //console.log("valor de la suma doble");
    //console.log(totalDobleTarifaSuma);
    //console.log("Array de la suma doble");
    //console.log(listPrices);
    let totalSumaDBL = 0;
    if (totalDobleTarifaSuma) {
      totalSumaDBL = totalDobleTarifaSuma[0]?.total ?? 0;
    }
    setCotizationExtraProperties({
      ...cotizationExtraProperties,
      total_base_sgl: totalSumaDBL + totalSingleTarifaSuma,
      total_base_dbl: totalSumaDBL,
      total_base_tpl: totalSumaDBL - totalTripleTarifaSuma,
      simple_supplement: totalSingleTarifaSuma,
      triple_reduction: totalTripleTarifa,
    });
    //console.log("CALCULANDO DE NUEVOOOOOO");
  }, [
    dataCotizacionContext.serviciosDayElements,
    dataCotizacionContext.generalsElements.generalElement,
    dataCotizacionContext.personalInfo.pax,
    dataCotizacionContext.feeCharges.comision,
    dataCotizacionContext.personalInfo.max,
    dataCotizacionContext.personalInfo.min,
    dataCotizacionContext.feeCharges.cortesia,
    dataCotizacionContext.feeCharges.changeType,
    dataCotizacionContext.generalServices,
    dataCotizacionContext.serviciosDayElementsTransporte,
    dataCotizacionContext.feeCharges.courtesy_type_identifier,
  ]);
  useEffect(() => {
    //console.log("DATOS HAN CAMBIADO TOTALES COMPLEMENTOS --------  SUPLEMENTOS::::");
    //console.log(cotizationExtraProperties);
  }, [cotizationExtraProperties]);
  useEffect(() => {}, [listPrices]);
  const totalSingleTarifa = listSingle.reduce(
    (total, item) => total + item.singleTarifa,
    0
  );
  const totalTripleTarifa = listTriple.reduce(
    (total, item) => total + item.tripleTarifa,
    0
  );

  //////////////Cambio de monedas
  useEffect(() => {
    const newServicios: Array<servicioDayElementI> =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (servicioDia, index) => {
          return {
            ...servicioDia,
            serviciosIndividuales: servicioDia.serviciosIndividuales.map(
              (servicioIndividual, index) => {
                if (
                  currentCurrency !== servicioIndividual.currencyId &&
                  currentCurrency == 2
                ) {
                  const simpleSuplemento =
                    servicioIndividual.tarifaSingleComplementConverted !=
                    undefined
                      ? servicioIndividual.tarifaSingleComplementConverted *
                        dataCotizacionContext.feeCharges.changeType
                      : 0;
                  const tripleComplemento =
                    servicioIndividual.tarifaTripleSuplementConverted !=
                    undefined
                      ? servicioIndividual.tarifaTripleSuplementConverted *
                        dataCotizacionContext.feeCharges.changeType
                      : 0;
                  return {
                    ...servicioIndividual,
                    currencyId: 2,
                    currencyName: "Dolar",
                    tarifa:
                      servicioIndividual.tarifa *
                      dataCotizacionContext.feeCharges.changeType,
                    tarifaModifed:
                      servicioIndividual.tarifaSingleComplementConverted
                        ? servicioIndividual.tarifaSingleComplementConverted *
                          dataCotizacionContext.feeCharges.changeType
                        : 0,
                    tarifaTripleSuplementConverted: tripleComplemento,
                    tarifaSingleComplementConverted: simpleSuplemento,
                  };
                } else if (
                  currentCurrency !== servicioIndividual.currencyId &&
                  currentCurrency == 1
                ) {
                  const simpleSuplemento =
                    servicioIndividual.tarifaSingleComplementConverted !=
                    undefined
                      ? servicioIndividual.tarifaSingleComplementConverted /
                        dataCotizacionContext.feeCharges.changeType
                      : 0;
                  const tripleComplemento =
                    servicioIndividual.tarifaTripleSuplementConverted !=
                    undefined
                      ? servicioIndividual.tarifaTripleSuplementConverted /
                        dataCotizacionContext.feeCharges.changeType
                      : 0;
                  return {
                    ...servicioIndividual,
                    currencyId: 1,
                    currencyName: "Pesos",
                    tarifa:
                      servicioIndividual.tarifa /
                      dataCotizacionContext.feeCharges.changeType,
                    tarifaModifed:
                      servicioIndividual.tarifaSingleComplementConverted
                        ? servicioIndividual.tarifaSingleComplementConverted /
                          dataCotizacionContext.feeCharges.changeType
                        : 0,
                    tarifaTripleSuplementConverted: tripleComplemento,
                    tarifaSingleComplementConverted: simpleSuplemento,
                  };
                } else {
                  return servicioIndividual;
                }
              }
            ),
          };
        }
      );
    //console.log("Asi debería quedar servicios: :");
    //console.log("---------------------------------23456789876543234567890987654321q234567890987654");
    //console.log(newServicios);
    const serviciosGeneralesModificados: QuoteGeneralElement[] =
      dataCotizacionContext.generalServices.map((generalServicio, index) => {
        let tarifaTotal: number = generalServicio.fee_price;
        let tarifaTransformed: number = 0;
        ////Se ajusta la moneda a la moneda requerida (Si es necesario, si no, no)
        if (currentCurrency == 1 && generalServicio.currency_identifier == 2) {
          //// lo quiero en dolar y está en pesos
          tarifaTransformed =
            tarifaTotal / dataCotizacionContext.feeCharges.changeType;
        } else if (
          currentCurrency == 2 &&
          generalServicio.currency_identifier == 1
        ) {
          ///Lo quiero en pesos y está en dolar
          tarifaTransformed =
            tarifaTotal * dataCotizacionContext.feeCharges.changeType;
        } else {
          tarifaTransformed = tarifaTotal;
        }
        return {
          ...generalServicio,
          currency: currentCurrency == 1 ? "Dolar" : "Peso",
          currency_identifier: currentCurrency,
          fee_price: tarifaTransformed,
        };
      });

    const newServiciosTrasnsporte: Array<servicioDayElementI> =
      dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
        (servicioDia, index) => {
          return {
            ...servicioDia,
            serviciosIndividuales: servicioDia.serviciosIndividuales.map(
              (servicioIndividual, index) => {
                if (
                  currentCurrency !== servicioIndividual.currencyId &&
                  currentCurrency == 2
                ) {
                  return {
                    ...servicioIndividual,
                    currencyId: 2,
                    currencyName: "Dolar",
                    tarifa:
                      servicioIndividual.tarifa *
                      dataCotizacionContext.feeCharges.changeType,
                    tarifaModifed:
                      servicioIndividual.tarifaSingleComplementConverted
                        ? servicioIndividual.tarifaSingleComplementConverted *
                          dataCotizacionContext.feeCharges.changeType
                        : 0,
                  };
                } else if (
                  currentCurrency !== servicioIndividual.currencyId &&
                  currentCurrency == 1
                ) {
                  return {
                    ...servicioIndividual,
                    currencyId: 1,
                    currencyName: "Pesos",
                    tarifa:
                      servicioIndividual.tarifa /
                      dataCotizacionContext.feeCharges.changeType,
                    tarifaModifed:
                      servicioIndividual.tarifaSingleComplementConverted
                        ? servicioIndividual.tarifaSingleComplementConverted /
                          dataCotizacionContext.feeCharges.changeType
                        : 0,
                  };
                } else {
                  return servicioIndividual;
                }
              }
            ),
          };
        }
      );

    const listTripleConverted: cotizacionListTriple[] = listTriple.map(
      (element, index) => {
        let monto: number = 0;
        if (currentCurrency !== element.currencyId && currentCurrency == 2) {
          monto =
            dataCotizacionContext.feeCharges.changeType * element.tripleTarifa;
        } else if (
          currentCurrency !== element.currencyId &&
          currentCurrency == 1
        ) {
          monto =
            element.tripleTarifa / dataCotizacionContext.feeCharges.changeType;
        }
        return {
          ...element,
          tripleTarifa: monto,
        };
      }
    );
    setListTriple(listTripleConverted);
    //console.log("Aqui puedes ver la moneda que se está convirtiendo---------------------[[[[[[]]]]]]]");
    //console.log(listTripleConverted);
    // //console.log('COMO QUEDO LA LISTA:');
    // //console.log(newServicios);

    const totalSingleTarifa = listSingle.reduce(
      (total, item) => total + item.singleTarifa,
      0
    );
    const totalTripleTarifa = listTriple.reduce(
      (total, item) => total + item.tripleTarifa,
      0
    );
    setDataCotizacionContext({
      ...dataCotizacionContext,
      serviciosDayElements: {
        servicioDayElement: newServicios,
      },
      generalServices: serviciosGeneralesModificados,
      serviciosDayElementsTransporte: {
        servicioDayElement: newServiciosTrasnsporte,
      },
    });
  }, [currentCurrency]);
  useEffect(() => {}, [dataCotizacionContext]);

  useEffect(() => {
    //console.log("LISSTA TRIPLE HA CAMBIADO: ");
    //console.log(listTriple);
  }, [listTriple]);
  return (
    <>
      <AltaCotizacionStyles isShowingBarOptions={isShowingBarOptions}>
        <div>
          <div className="title-cotización">
            <h3>Cotización</h3>
          </div>
        </div>

        <div className="content-alta">
          <div>
            <div className="bars-box">
              <div className="non-printable-section">
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={() => {
                    setIsShowingBarOptions(!isShowingBarOptions);
                  }}
                  className="bars"
                />
              </div>
            </div>
            <br /> <br /> <br />
            <div className="container-content">
              <Routes>
                <Route
                  path="Registro-Cotizacion"
                  element={
                    <CotizacionInfo
                      listSingle={listSingle}
                      setListSingle={setListSingle}
                      listTriple={listTriple}
                      setListTriple={setListTriple}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      handleOptionChange={handleOptionChange}
                      handleSerieChange={handleSerieChange}
                      selectedSerieOption={selectedSerieOption}
                      setSelectedSerieOption={setSelectedOption}
                    />
                  }
                ></Route>
                <Route
                  path="CotizacionGeneral"
                  element={
                    <GeneralesCotizacion
                      listSingle={listSingle}
                      setListSingle={setListSingle}
                      listTriple={listTriple}
                      setListTriple={setListTriple}
                    />
                  }
                ></Route>
              </Routes>
            </div>
          </div>
          <div className="container-tables">
            {/* TABLA DE SINGLE COMPLEMENT: */}
            {listSingle.length > 0 && (
              <>
                <div className="single-table">
                  <div className="single-table-header">
                    <h3>SUPPL SGL</h3>
                  </div>
                  <div className="container-data">
                    {listSingle.map((triple, index) => {
                      return (
                        <>
                          <div>
                            <h3>$ {triple.singleTarifa.toFixed(2)}</h3>
                          </div>
                          <div>
                            <h3>{triple.fechaTarifa}</h3>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="single-footer">
                    TOTAL: $ {totalSingleTarifa.toFixed(2)}
                  </div>
                </div>
              </>
            )}
            <br />
            <br />
            {/* TABLE DE TRIPLE COMPLEMENT:::: */}
            {listTriple.length > 0 && (
              <>
                <div className="single-table">
                  <div className="single-table-header">
                    <h3>TPL ROOM REDUCE</h3>
                  </div>
                  <div className="container-data">
                    {listTriple.map((triple, index) => {
                      return (
                        <>
                          <div>
                            <h3>$ {triple.tripleTarifa.toFixed(2)}</h3>
                          </div>
                          <div>
                            <h3>{triple.fechaTarifa}</h3>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="single-footer">
                    TOTAL: $ {totalTripleTarifa.toFixed(2)}
                  </div>
                </div>
              </>
            )}
            <br />
            <br />
            {/* TABLA DE COSTOS A ENTREGAR AL CLIENTE::: */}
            {listPrices.length > 0 && (
              <div className="table-pricess">
                <div className="table-prices">
                  <h3>Costo de venta por persona</h3>
                </div>
                <div className="container-table">
                  {listPrices?.map((price, index) => {
                    return (
                      <>
                        <div className="file-n">
                          <h3
                            style={{
                              color:
                                price.numberPassengers ==
                                dataCotizacionContext.personalInfo.pax
                                  ? "red"
                                  : "",
                            }}
                          >
                            {price.numberPassengers} pasajeros{" "}
                            {dataCotizacionContext.feeCharges.cortesia > 0
                              ? `+ ${dataCotizacionContext.feeCharges.cortesia}`
                              : ""}
                          </h3>
                          <h3>
                            ${" "}
                            <NumericFormat
                              value={(
                                price.total +
                                dataCotizacionContext.feeCharges.comisonAuto
                              ).toFixed(2)}
                              displayType="text"
                              thousandSeparator=","
                              decimalSeparator="."
                              decimalScale={2}
                            />
                          </h3>
                        </div>
                      </>
                    );
                  })}
                  {totalSingleTarifa > 0 && (
                    <div className="file-n">
                      <h3>Suplemento sencillo</h3>
                      <h3>
                        ${" "}
                        <NumericFormat
                          value={totalSingleTarifa.toFixed(2)}
                          displayType="text"
                          thousandSeparator=","
                          decimalSeparator="."
                          decimalScale={2}
                        />
                      </h3>
                    </div>
                  )}
                  {totalTripleTarifa > 0 && (
                    <div className="file-n">
                      <h3>Reducción triple</h3>
                      <h3>
                        ${" "}
                        <NumericFormat
                          value={totalTripleTarifa.toFixed(2)}
                          displayType="text"
                          thousandSeparator=","
                          decimalSeparator="."
                          decimalScale={2}
                        />
                      </h3>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            {listPrices.length > 0 && (
              <div className="header-resume">
                <h3>Transporte y guía</h3>
                <h3>Pasajeros</h3>
                <h3>Hoteles</h3>
                <h3>FOC</h3>
                <h3>Total x PAX</h3>
                <h3>Comisión</h3>
                <h3>Venta</h3>
              </div>
            )}
            {listPrices.length > 0 &&
              listPrices.map((resume, index) => {
                return (
                  <div className="table-resume">
                    <h3>
                      <NumericFormat
                        value={resume.general.toFixed(2)}
                        displayType="text"
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                      />
                    </h3>
                    <h3>
                      <NumericFormat
                        value={resume.numberPassengers}
                        displayType="text"
                        thousandSeparator=","
                      />
                    </h3>
                    <h3>
                      <NumericFormat
                        value={resume.totalHotel.toFixed(2)}
                        displayType="text"
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                      />
                    </h3>
                    <h3>
                      <NumericFormat
                        value={resume.cortesia.toFixed(2)}
                        displayType="text"
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                      />
                    </h3>
                    <h3>
                      <NumericFormat
                        value={resume.totalXPax.toFixed(2)}
                        displayType="text"
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                      />
                    </h3>
                    <h3>
                      <NumericFormat
                        value={resume.comision.toFixed(2)}
                        displayType="text"
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                      />
                    </h3>
                    <h3>
                      <NumericFormat
                        value={resume.total.toFixed(2)}
                        displayType="text"
                        thousandSeparator=","
                        decimalSeparator="."
                        decimalScale={2}
                      />
                    </h3>
                  </div>
                );
              })}

            <br />
            <br />
            <br />
            <br />
            <br />
            {/* DESGLOSE DE PRECIOS OPCIONALES */}
            <br />
            {/* LISTA DE COSTOS A ENTREGAR A CLIENTE OPCIONALES */}
            {dataCotizacionContext.personalInfo.modeCotizacion !==
              "Opcional" && <ListOpcionales />}
            {/* FIN DE LISTQA DE COSTOS A ENTREGAR A CLIENTE OPCIONALES */}
            <div className="resumen-bar-element">
              <ResumenBar
                isShowingBarOptions={isShowingBarOptions}
                setIsShowingBarOptions={setIsShowingBarOptions}
              />
            </div>
          </div>
          <div className="non-printable-section">
            <SaveCotizacion />
          </div>
        </div>
      </AltaCotizacionStyles>
    </>
  );
}

interface cotizacionStylesI {
  isShowingBarOptions: boolean;
}

const AltaCotizacionStyles = styled.div<cotizacionStylesI>`
  .container-table-resultados {
    display: flex;
    justify-content: center;
  }
  .gastos-opcionales {
    text-align: center;
    color: rgb(8, 166, 165);
  }
  .header-opcionales {
    background-color: orange !important;
  }
  .table-pricess-doss {
    margin-left: 2rem;
  }
  .table-pricess-dos {
    background-color: orange !important;
  }
  .header-resume {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem;
    background-color: rgb(8, 166, 165);
    justify-content: center;
    align-items: center;
    color: white;
    border-bottom: none;
  }

  .header-resume h3 {
    font-size: 0.95vw;
    text-align: center;
    padding: 0.5rem;
  }
  .table-resume {
    border: black solid 1px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding: 0.6rem;
  }
  @media screen and (max-width: 900px) {
  }
  .table-resume h3 {
    text-align: center;
    font-size: 0.9vw;
  }
  .container-tables {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
  }

  .single-table {
    width: 20%;
  }

  .single-table h3 {
  }

  .single-footer {
    background-color: rgb(8, 166, 165);
    padding: 0.8rem;
    font-size: 0.9vw;
    text-align: center;
    color: white;
  }
  .container-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .container-data div {
    padding: 0.7rem;
  }
  .container-data div h3 {
    text-align: center;
    font-size: 0.9vw !important;
  }
  @media screen and (max-width: 900px) {
    .container-tables {
      flex-direction: column;
      align-items: center;
    }
    .single-table {
      width: 40%;
    }
    .single-table h3 {
      font-size: 1.6vw !important;
    }
    .container-data div h3 {
      text-align: center;
      font-size: 1.6vw !important;
    }
    .single-footer {
      font-size: 1.6vw !important;
    }
    .file-n h3 {
      font-size: 1.6vw !important;
    }
    .table-prices h3 {
      font-size: 1.6vw !important;
    }
    .table-pricess {
      width: 50% !important;
    }
  }

  .table-prices {
    margin: 0 auto;
    border-bottom: none;
    background-color: rgb(8, 166, 165);
    color: white;
  }
  .table-pricess {
    width: 40%;
  }
  .table-prices h3 {
    padding: 0.5rem;
    text-align: center;
  }
  .file-n {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: black solid 1px;
    align-items: center;
    justify-content: center;
  }
  .file-n h3 {
    text-align: center;
    padding: 0.5rem;
    font-size: 0.85vw;
  }
  .table-prices h3 {
    font-size: 0.85vw;
  }

  .container-table {
    border: black solid 1px;

    margin: 0 auto;
    border-bottom: none;
  }
  .bars-box {
    padding: 1rem;
    display: ${(props) => (props.isShowingBarOptions ? "none" : "flex")};
    justify-content: right;
    padding-right: 2rem;
    position: fixed;
    right: 5%;
    z-index: 5;
  }

  .bars {
    font-size: 2vw;
    cursor: pointer;
    color: rgb(8, 166, 165);
    background-color: white;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 6px 2px;
    border-radius: 0.8rem;
  }
  .container-content {
    margin-top: ${(props) =>
      props.isShowingBarOptions ? "calc(2rem + 2vw)" : ""};
  }
  @media screen and (max-width: 900px) {
    .bars {
      font-size: 2.8vw;
    }
  }
  .title-cotización {
    /* border: black solid 1px; */
    display: flex;
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
    background-color: rgba(8, 166, 165);
    padding: 1rem;
    font-size: 1.2vw;
    border-top-right-radius: 1.2rem;
    border-top-left-radius: 1.2rem;
    color: white;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 900px) {
    .title-cotización {
      font-size: 2vw;
    }
  }
  .alta-cotizacion-container {
    /* border: black solid 1px; */
    display: flex;
    width: 100%;
    margin: 0 auto;
    background-color: white;
  }
  .content-alta {
    width: ${(props) => (!props.isShowingBarOptions ? "100%" : "100%")};
    /* border: blue solid 1px; */
    min-height: 100vh;
  }
  .resumen-bar-element {
    width: 30%;
    min-height: 10vh;

    max-height: 10vh;
    display: ${(props) => (!props.isShowingBarOptions ? "none" : "block")};
    position: fixed;
    right: 5%;
    z-index: 10;
  }
  @media screen and (max-width: 900px) {
    .content-alta {
      width: ${(props) => (!props.isShowingBarOptions ? "100%" : "100%")};
    }
    .resumen-bar-element {
      width: 40%;
      display: ${(props) => (!props.isShowingBarOptions ? "none" : "block")};
    }
  }
  .single-table-header {
    background-color: rgba(155, 155, 155, 0.4);
    text-align: center;
    padding: 0.8rem;
    font-size: 0.8vw !important;
  }
`;
