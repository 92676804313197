import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import {
  Country,
  City,
  Neighborhood,
  Zipcode,
  RegistrationType,
  ProviderType,
} from "../../../interfaces/GeneralTypes";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFloppyDisk,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import { objectCard, coinInterface } from "../../../interfaces/GeneralTypes";
import { bankInterface } from "../../../interfaces/GeneralTypes";
import deletelogo from "../../../images/deletelogo.png";
import agregar from "../../../images/AGREGAR.png";
import edit from "../../../images/edit.png";
import guardar from "../../../images/GUARDARDATOS.png";
import DeleteConfirmation from "../common/DeleteConfirmation";

interface FiscalElementInterface {
  deleteCard: (indice: number) => void;
  numberCard: string;
  clabe: string;
  bankList: bankInterface[];
  identifier: string;
  indice: number;
  arrayDataCards: objectCard[];
  setArrayDataCards: React.Dispatch<React.SetStateAction<objectCard[]>>;
  bank_identifier: number;
  initialState: boolean;
  currency_element: number;
  bank_account_identifier: number;
  addNewFiscal: () => void;
}

export default function FiscalElement({
  numberCard,
  clabe,
  bankList,
  identifier,
  indice,
  deleteCard,
  arrayDataCards,
  setArrayDataCards,
  bank_identifier,
  initialState,
  currency_element,
  bank_account_identifier,
  addNewFiscal,
}: FiscalElementInterface) {
  const { catalogsData } = useCatalogs();
  const [bankListTwo, setBankListTwo] = useState<bankInterface[]>([]);
  const [editionMode, setEditionMode] = useState<boolean>(initialState);
  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  const [cardInfo, setCardInfo] = useState<objectCard>({
    bank_account_identifier: bank_account_identifier,
    /////////////////////////////
    numberCard: numberCard,
    clabe: clabe,
    identifier: identifier,
    bank_identifier: bank_identifier,
    currency_element: currency_element,
  });

  useEffect(() => {
    // //console.log("DAtos que vienen del servicio: ");
    // //console.log(catalogsData);
    setBankListTwo(catalogsData?.bank_list);
    setCurrencyList(catalogsData?.currency_list);
  }, [catalogsData]);
  useEffect(() => {
    const newArrayDataCard = arrayDataCards.map((currentCard, index) => {
      return currentCard.identifier != identifier
        ? currentCard
        : {
            bank_account_identifier: cardInfo.bank_account_identifier,
            /////////////////////////////
            numberCard: cardInfo.numberCard,
            clabe: cardInfo.clabe,
            identifier: cardInfo.identifier,
            bank_identifier: cardInfo.bank_identifier,
            currency_element: cardInfo.currency_element,
          };
    });
    setArrayDataCards(newArrayDataCard);
  }, [cardInfo]);

  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    deleteCard(indice);
    setIsShownDelete(false);
    ////BORRADO
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };

  return (
    <>
      <FiscalElementStyles>
        <div className="container-fiscal">
          <div>
            <SelectListdefault
              style={{
                width: "90%",
                maxWidth: "90%",
              }}
              id="sl6"
              onChange={(e) => {
                //console.log("BANK IDENTIFIER SELECTED: ");
                //console.log(e.target.value);
                setCardInfo({
                  ...cardInfo,
                  currency_element: parseInt(e.target.value),
                });
              }}
              disabled={!editionMode}
            >
              <option value="" disabled selected>
                Moneda
              </option>

              {currencyList?.length > 0
                ? currencyList.map((item) => {
                    return (
                      <option
                        key={item.identifier}
                        value={item.identifier}
                        selected={item.identifier == cardInfo.currency_element}
                      >
                        {item.description}
                      </option>
                    );
                  })
                : null}
            </SelectListdefault>
          </div>
          <div>
            {editionMode ? (
              <input
                type="text"
                value={cardInfo.numberCard}
                className="input-cards"
                onChange={(e) => {
                  const numberCard = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 10); // Eliminar caracteres no numéricos y limitar a 18 dígitos
                  setCardInfo({
                    ...cardInfo,
                    numberCard,
                  });
                }}
              />
            ) : (
              <h3>{cardInfo.numberCard}</h3>
            )}
          </div>
          <div>
            {editionMode ? (
              <input
                type="text"
                value={cardInfo.clabe}
                className="input-cards"
                onChange={(e) => {
                  const clabe = e.target.value.replace(/\D/g, "").slice(0, 18); // Eliminar caracteres no numéricos y limitar a 18 dígitos
                  setCardInfo({
                    ...cardInfo,
                    clabe,
                  });
                  //console.log("ON CHANGE");
                }}
              />
            ) : (
              <h3>{cardInfo.clabe}</h3>
            )}
          </div>
          <div>
            <SelectListdefault
              style={{
                width: "90%",
                maxWidth: "90%",
              }}
              id="sl6"
              onChange={(e) => {
                //console.log("BANK IDENTIFIER SELECTED: ");
                //console.log(e.target.value);
                setCardInfo({
                  ...cardInfo,
                  bank_identifier: parseInt(e.target.value),
                });
              }}
              disabled={!editionMode}
            >
              <option value="" disabled selected>
                Banco
              </option>

              {bankListTwo?.length > 0
                ? bankListTwo.map((item) => {
                    return (
                      <option
                        key={item.identifier}
                        value={item.identifier}
                        selected={item.identifier == cardInfo.bank_identifier}
                      >
                        {item.description}
                      </option>
                    );
                  })
                : null}
            </SelectListdefault>
          </div>
          <div className="box-buttons-fiscal">
            {editionMode ? (
              <img
                src={guardar}
                width="25px"
                onClick={() => setEditionMode(false)}
                className="icon-contact"
              />
            ) : (
              <img
                src={edit}
                width="25px"
                onClick={() => setEditionMode(true)}
                className="icon-contact"
              />
            )}
            <img
              src={deletelogo}
              width="25px"
              onClick={handleDeleteClick}
              className="icon-contact"
            />
            {initialState ? (
              <img
                src={agregar}
                width="25px"
                className="icon-contact"
                onClick={addNewFiscal}
                style={{ cursor: "pointer", fontSize: "2rem" }}
              />
            ) : (
              <img
                src={agregar}
                width="25px"
                className="icon-contact"
                onClick={addNewFiscal}
                style={{
                  cursor: "pointer",
                  fontSize: "2rem",
                  visibility: "hidden",
                }}
              />
            )}
          </div>
        </div>
      </FiscalElementStyles>
      {isShownDelete && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          itemName={`¿Deseas borrar el siguiente número de cuenta ${cardInfo.numberCard}?`}
          setIsShownDelete={setIsShownDelete}
        />
      )}
    </>
  );
}

const FiscalElementStyles = styled.div`
  .input-cards {
    max-width: 100%;
  }
  h3 {
    font-size: 0.96vw !important;
  }
  @media screen and (max-width: 900px) {
    h3 {
      font-size: 1.6vw !important;
    }
  }
  .container-fiscal {
    border: grey solid 1px;
    border-top: grey solid 2px;
    border-bottom: grey solid 2px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr;
    justify-items: center;
    align-items: center;
  }
  .container-fiscal div {
    width: 100%;
    text-align: center;
    padding: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: grey solid 1px;
    overflow: hidden;
    height: 100%;
  }
  .container-fiscal div h3 {
    word-wrap: break-word;
    word-break: break-all;
    max-width: 30em;
  }
  .icon-contact {
    font-size: 1.3rem;
    cursor: pointer;
    margin-left: 0.3rem;
  }
  .box-buttons-fiscal {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 900px) {
    .icon-contact {
      width: 15px;
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: none;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  .fake-icon {
    max-width: 10px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
