import React from "react";
import styled from "styled-components";
import { catalogElement } from "../../../interfaces/interfaces";

interface SearchResultProps {
  results: catalogElement[];
  setResultsSearch: React.Dispatch<React.SetStateAction<catalogElement[]>>;
  catalogElements: Array<catalogElement>;
  setCatalogElements: React.Dispatch<React.SetStateAction<catalogElement[]>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}
export default function SearchResult({
  results,
  setResultsSearch,
  catalogElements,
  setCatalogElements,
  searchText,
  setSearchText,
}: SearchResultProps) {
  const handleClick = (elementCatalog: catalogElement) => {
    //console.log("Diste click");

    const result: catalogElement[] = [elementCatalog];
    //console.log(result);
    setSearchText(elementCatalog.nombreViaje);
    setCatalogElements(result);
  };

  return (
    <SearchResultStyles>
      {results.map((result: catalogElement) => (
        <div
          key={result.clave}
          className="search-result"
          onClick={() => {
            handleClick(result);
          }}
        >
          <p>{result.nombreViaje}</p>
          <p>{result.temporada}</p>
          <p>{result.tarifa}</p>
        </div>
      ))}
    </SearchResultStyles>
  );
}

const SearchResultStyles = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid black;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  .search-result {
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: lightgray;
    }
  }
  width: 80%;
  margin: 0 auto;
`;
