import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import {
  Country,
  City,
  Neighborhood,
  Zipcode,
  RegistrationType,
  ProviderType,
} from "../../../interfaces/GeneralTypes";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFloppyDisk,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import { objectCard } from "../../../interfaces/GeneralTypes";
import { bankInterface } from "../../../interfaces/GeneralTypes";
import {
  dataServicesI,
  seasonInterface,
  coinInterface,
  viewInterface,
  serviceInterface,
  serviciosInterface,
  categoriaI,
  maealI,
  feeTypeI,
  feeObjectI,
  serviceObject,
} from "../../../interfaces/GeneralTypes";
interface impuestosProps {
  isTaxesAvailable: boolean;
  setIsTaxesAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  handleOptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  tarifa: serviceObject;
  localTarifa: serviceObject;
  setLocalTarifa: React.Dispatch<React.SetStateAction<serviceObject>>;
  isTaxesIncluded: boolean;
  serviceCurrentType: number;
}
export default function ImpuestosGet({
  isTaxesAvailable,
  setIsTaxesAvailable,
  handleOptionChange,
  tarifa,
  localTarifa,
  setLocalTarifa,
  isTaxesIncluded,
  serviceCurrentType,
}: impuestosProps) {
  const { catalogsData } = useCatalogs();
  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  const [tipoTarifa, setTipoTarifa] = useState<feeTypeI[]>([]);
  const [tipoTarifaObject, setTipoTarifaObject] = useState<feeObjectI>({
    type_fee: tarifa.fee_type_identifier,
    identifier_list: 0,
    fee_is_included: tarifa.fee_is_included,
  });

  useEffect(() => {
    //console.log(catalogsData?.currency_list);
    setCurrencyList(catalogsData?.currency_list);
    //console.log("DATOS DE TYPE TARIFA");
    //console.log(catalogsData.fee_type_list);
    setTipoTarifa(catalogsData.fee_type_list);
  }, [catalogsData]);
  useEffect(() => {
    setLocalTarifa({
      ...localTarifa,
      fee_type_identifier: tipoTarifaObject.type_fee,
    });
  }, [tipoTarifaObject]);
  useEffect(() => {
    //console.log("VALOR INICIAL DEL TIPO DE TARIFA: ");
    //console.log(tarifa.fee_is_included);
  }, [tipoTarifaObject]);
  return (
    <div className="box-select">
      {tarifa.fee_type_identifier != 0 && (
        <h3 className="titulo-select">Tipo de Tarifa</h3>
      )}
      <SelectListdefault
        style={{
          width: "100%",
          maxWidth: "100%",
        }}
        id="sl6"
        onChange={(e) => {
          //   setLocalTarifa({
          //     ...localTarifa,
          //     fee_currency_identifier: parseInt(e.target.value),
          //   });
          setTipoTarifaObject({
            ...tipoTarifaObject,
            type_fee: parseInt(e.target.value),
          });
        }}
      >
        <option value="" disabled selected>
          Tipo de tarifa
        </option>

        {tipoTarifa?.length > 0
          ? tipoTarifa.map((item) => {
              return (
                <option
                  key={item.identifier}
                  value={item.identifier}
                  selected={item.identifier == tarifa.fee_type_identifier}
                >
                  {item.description}
                </option>
              );
            })
          : null}
      </SelectListdefault>
    </div>
  );
}

const ImpuestosStyles = styled.div``;

const SelectListdefault = styled.select`
  width: 400px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: rgb(8, 166, 165) solid 2px;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }

  max-height: 38.4px !important;
`;
