import { OpcionalCotizacion } from "../alico/pages/Cotizacion/interfaces/CotizacionI";
import { CatalogDataOperacion } from "../context/OperacionI";

export interface CatalogsData {
  country_list: Country[];
  city_list: City[];
  neighborhood_list: Neighborhood[];
  zipcode_list: Zipcode[];
  currency_list: Currency[];
  season_list: Season[];
  registration_type_list: RegistrationType[];
  provider_type_list: ProviderType[];
  service_type_list: ServiceType[];
  view_list: View[];
  bank_list: Bank[];
  category_list: categoriaI[];
  meal_plan_list: maealI[];
  lender_type_list: lenderI[];
  client_type_list: ProviderType[];
  fee_type_list: feeTypeI[];
  client_category_list: ClientCategory[];
  job_list: jobI[];
  transport_service_type_list: transportTypeI[];
  continent_list: continentI[];
  expenses_category_list: expensesI[];
  agencias_list: AgenciaElement[];
}
export interface expensesI {
  identifier: number;
  description: string;
}

export interface continentI {
  identifier: number;
  description: string;
}
export interface feeObjectI {
  type_fee: number;
  identifier_list: number;
  fee_is_included: boolean;
}
export interface jobI {
  identifier: number;
  description: string;
}
export interface transportTypeI {
  identifier: number;
  description: string;
}
export interface feeTypeI {
  identifier: number;
  description: string;
}
export interface lenderI {
  identifier: number;
  description: string;
}
export interface maealI {
  identifier: number;
  description: string;
}

export interface categoriaI {
  identifier: number;
  description: string;
}
export interface Country {
  identifier: number;
  description: string;
  abbreviation: string;
}

export interface City {
  identifier: number;
  description: string;
  country_name: string;
  country_identifier: number;
}

export interface Neighborhood {
  identifier: number;
  description: string;
  city_name: string;
  city_identifier: number;
}

export interface Zipcode {
  identifier: number;
  description: string;
  neighborhood_name: string;
  neighborhood_identifier: number;
}

export interface Currency {
  identifier: number;
  description: string;
  abbreviation: string;
}

export interface Season {
  identifier: number;
  description: string;
}

export interface RegistrationType {
  identifier: number;
  description: string;
}

export interface ProviderType {
  identifier: number;
  description: string;
}

export interface ClientCategory {
  identifier: number;
  description: string;
}
export interface ServiceType {
  identifier: number;
  description: string;
  abbreviation: string;
}

export interface View {
  identifier: number;
  description: string;
}

export interface Bank {
  identifier: number;
  description: string;
}

export interface CatalogsContextType {
  catalogsData: CatalogsData;
}
export interface RegisterGeneralI {
  register_identifier: number;
  ////////////////////////
  name: string;
  business_name: string;
  registration_type_identifier: number;
  lender_type_identifier: number;
  imagen: string;
  provider_type_identifier: number | null;
  claveGeneral: string;
  razonSocial: string;
  nombreComercial: string;
  rfc: string;
  id: string;
  categoriaGeneral: number;
  ultimaCompra: string;
  telefono: string;
  web: string;
  country_identifier: number;
  city_identifier: number | null;
  neighborhood_identifier: number;
  zip_code: string;
  calles: string;
  actividades: string;
  lead: string;
  comisionEsquema: string;
  comentarios: string;
  identifier: number;
  imageExtension: string;
  comments: string;
  important_notes: string;
  category_identifier: number;
  client_category_identifier: number;
  feedback: string;
}

export interface ContactoI {
  contact_identifier: number;
  ///////////////////////
  identifier: string;
  name: string;
  email: string;
  personal_phone: string;
  extension: string;
  charge_identifier: number;
  web: string;
  status: number;
  charge: string;
}
export interface RegisterInterface {
  user_identifier: number;
  general: {
    identifier: number;
    name: string;
    registration_type_identifier: number;
    provider_type_identifier: number;
    business_name: string;
    street: string;
    number: string;
    neighborhood_identifier: number;
    city_identifier: number;
    country_identifier: number;
    zip_code: string;
    observations: string;
    imagen: string;
    type: string;
    web: string;
    category_identifier: number;
    client_category_identifier: number;
    feedback: string;
    comments: string;
  };
  contact_list: ContactoI[];
  contract: {
    start_date: string;
    end_date: string;
    file: string;
    file_extension: string;
  };
  service_list: {
    name: string;
    start_date: string;
    end_date: string;
    service_type_identifier: number;
    view_identifier: number;
    capacity: string;
    season_identifier: number;
    rate: number;
    service_key: string;
    identifier: string;
  }[];
  fee_list: {
    name: string;
    fee: string;
    start_date: string;
    end_date: string;
    currency_identifier: number;
    season_identifier: number;
    observations: string;
    identifier: string;
  }[];
  tax_data: {
    name: string;
    business_name: string;
    rfc: string;
    bank_account: string;
    clabe: string;
    bank: string;
    currency_identifier: number;
    email: string;
  };
}

export interface FileI {
  file_identifier: number;
  contract_identifier: number;
  file_name: string;
  fileName: string;
  file: string;
  file_extension: string;
  start_date: string;
  end_date: string;
  identificador: string;
  status_identifier: number;
}
export interface contractInfoI {
  cancellation_policies: string;
  observations: string;
}

export interface coinInterface {
  identifier: number;
  description: string;
  abbreviation: string;
}
export interface bankInterface {
  identifier: number;
  description: string;
}

export interface objectCard {
  bank_account_identifier: number;
  ////////////////////////////////
  numberCard: string;
  clabe: string;
  identifier: string;
  bank_identifier: number;
  currency_element: number;
}
////////////////////ESTRUCTURA DE MODELO DE TARIFAS//////////////////////////
export interface dataServicesI {
  services_fees: Array<serviceObject>;
}

export interface serviceObject {
  ///////////////////////////////////////
  fee_type_identifier: number;
  /////////////////////////////////////////
  service_identifier: number;
  service_name: string;
  service_type_identifier: number;
  service_capacity: string;
  service_comments: string;
  service_key: string;
  service_category_identifier: number;
  service_category: string;
  service_mealplan_identifier: number;
  continent_identifier: number;
  minimum_stay: string;
  market_restrictions: string;
  operating_days: Array<string>;
  initial_schedule: string;
  final_schedule: string;
  transport_type_identifier: number;
  description: string;
  minimum_to_operate: string;
  /////ARREGLOS DE OBJETOS A ENVIAR A SERVICIOS
  service_fee_list: Array<feeIndividual>;
  service_blackout_list: Array<blackoutObject>;
  fee_validity_list: Array<validityDates>;
  service_minimum_stay_list: Array<stayDates>;
  /////LOS SIGUIENTES no van a servicio
  fee_is_included: boolean;
  type_fee: number;
  identifier_list: string;
}
export interface feeIndividual {
  fee_identifier: number;
  fee_BBT: number;
  ///////////////////////////////
  fee_currency_identifier: number;
  fee_iva: number;
  fee: number;
  fee_tax: number;
  fee_total: number;
  fee_SGL: number;
  fee_DBL: number;
  fee_TPL: number;
  fee_CPL: number;
  fee_FP: number;
  fee_BB: number;
  fee_MAID: number;
  fee_TSGL: number;
  fee_TDBL: number;
  fee_TTPL: number;
  fee_TCPL: number;
  fee_TFP: number;
  fee_TBB: number;
  fee_TMAID: number;
  fee_key: string;
  fee_capacity: string;
  vehicle: string;
}
export interface validityDates {
  validity_identifier: number;
  validity_startdate: string;
  validity_enddate: string;
  identifierValidity: string;
}
export interface stayDates {
  minimum_stay: string;
  minimum_stay_startdate: string;
  minimum_stay_enddate: string;
  minimum_stay_identifier: number | null;
  status_identifier: number | null;
  minimuStayIdentifier: string;
}
export interface blackoutObject {
  blackout_identifier: number;
  blackout_startdate: string;
  blackout_enddate: string;
  blackoutIdentifier: string;
}

export interface seasonInterface {
  identifier: number;
  description: string;
}

export interface coinInterface {
  identifier: number;
  description: string;
  abbreviation: string;
}

export interface viewInterface {
  identifier: number;
  description: string;
}

export interface serviceInterface {
  identifier: number;
  description: string;
  abbreviation: string;
}

export interface serviciosInterface {
  name: string;
  start_date: string;
  end_date: string;
  service_type_identifier: number;
  view_identifier: number;
  capacity: string;
  season_identifier: number;
  rate: number;
  service_key: string;
  identifier: string;
}

export interface commentI {
  identifier: string;
  comment: string;
}

//////////////////////////USER PROVIDER  DATA TYPE///////////////////

/////////////////CATALOGS DATA DOS
export interface ProveedorElement {
  register_identifier: number;
  provider_name: string;
  fee_identifier: number;
}
export interface ServiceElementI {
  register_identifier: number;
  provider_name: string;
  service_identifier: number;
  service_name: string;
  service_type_identifier: number;
  service_type: string;
  general_fee_list: GeneralFee;
}
interface GeneralFee {
  fee_identifier: number;
  fee: string;
  capacity: string;
  currency_identifier: number;
  currency: string;
}

export interface QuoteGeneralElement {
  service_type: number;
  provider_id: number | null;
  service_name_id: number | null;
  fee_price: number;
  number_days: string;
  total_price: number;
  identifierGeneralService: string;
  currency: string;
  currency_identifier: number;
  expenses_type_identifier: number | null;
  general_service_identifier: number;
  status_identifier: number;
  service_name?: string;
  provider_name?: string;
  isIncludedOPS?: boolean;
  fee_identifier?: number;
  isIncludedOpt: boolean;
}

export interface QuoteType {
  identifier: number;
  description: string;
}

export interface Series {
  identifier: number;
  description: string;
  origin: string;
  destination: string;
  service_identifier: number;
  final_date: string;
}

export interface CatalogsContextTres {
  catalogsDataTres: {
    series_list: SeriesCotizacionI[];
  };
}
export interface SeriesCotizacionI {
  identifier: number;
  name: string;
}
export interface CatalogsDataTres {
  series_list: SeriesCotizacionI[];
}

export interface CatalogsDataDos {
  quote_types_list: QuoteType[];
  series_list: Series[];
  general_services_list: GeneralService[];
  expenses_category_list: CategoriaElement[];
  quote_status_list: QuoteStatusI[];
  quote_search_parameter_list: quoteSearchParams[];
  service_type_list: ServiceType[];
}
export interface QuoteStatusI {
  identifier: number;
  description: string;
}
export interface CategoriaElement {
  identifier: number;
  description: string;
}

export interface AgenciaElement {
  identifier: number;
  description: string;
}

export interface GeneralService {
  identifier: number;
  description: string;
  key: string;
}
export interface CatalogsContextTypeDos {
  catalogsDataDos: CatalogsDataDos;
}
export interface CatalogsContextTypeOperacion {
  catalogsDataOperaciones: CatalogDataOperacion;
}
export interface CategoryInterface {
  identifier: number;
  description: string;
}
///////////////////////::::::::::::::::GUARDADO DE DATOS::::::::::::::::::::::::::::::::::::::
// Interfaz para cada servicio individual
export interface IndividualServiceI {
  service_date: string;
  service_tittle: string;
  services: ServiceIndividualI[];
  isActive: boolean;
  service_identifier: number;
  status_identifier: number;
}

// Interfaz para cada servicio dentro del arreglo de servicios individuales
export interface ServiceIndividualI {
  service_type_identifier: number;
  occupation: string;
  provider_identifier: number;
  service_identifier: number;
  category_identifier: number;
  fee: number;
  IsActive: boolean;
  single_complement: number;
  triple_complement: number;
  individual_service_identifier: number;
  status_identifier: number;
  number_of_services?: number;
  fee_identifier?: number;
}

// Interfaz para cada servicio general
export interface GeneralServiceI {
  lender_identifier: number;
  provider_identifier: number;
  service_identifier: number;
  fee: number;
  number_of_services: number;
  service_total: number;
  general_service_identifier: number;
  status_identifier: number;
  fee_identifier?: number;
}

// Interfaz para el objeto completo
export interface QuoteObjectI {
  user_identifier: number;
  quote: {
    private_quote: boolean;
    name: string;
    type: number;
    agency: string;
    series: number;
    PAX: number;
    TL: number;
    MIN: number;
    MAX: number;
    arrival_date: string;
    departure_date: string;
    currency_identifier: number;
    commission: number;
    commission_auto: number;
    courtesy: number;
    exchange_rate: number;
    comments: CommentI[];
    total: number;
    quote_register_type: number;
    number_of_days: number;
    serie: boolean;
    status_identifier: number;
    quote_identifier: number;
    courtesy_type_identifier: number;
    total_base_sgl?: number | undefined;
    total_base_dbl?: number | undefined;
    total_base_tpl?: number | undefined;
    simple_supplement?: number | undefined;
    triple_reduction?: number | undefined;
    optional_quote: boolean;
    operation_identifier?: number;
  };
  quote_individual_services: IndividualServiceI[];
  quote_general_services: GeneralServiceI[];
  general_transport_services: IndividualServiceI[];
  optional_groups: OpcionalCotizacion[];
}

export interface CommentI {
  comment_identifier: number;
  comment: string;
  comment_date: string;
  status_identifier: number;
  commentIdentifier: string;
}

export interface quoteSearchParams {
  description: string;
  identifier: number;
}

export interface bodyI {
  dateSearch: string;
  textSearch: string;
  textSearchDos?: string;
  idUser?: number;
}

export const horariosServicios = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const diasSemana = [
  { id: 1, nombre: "Lunes" },
  { id: 2, nombre: "Martes" },
  { id: 3, nombre: "Miércoles" },
  { id: 4, nombre: "Jueves" },
  { id: 5, nombre: "Viernes" },
  { id: 6, nombre: "Sábado" },
  { id: 7, nombre: "Domingo" },
];
