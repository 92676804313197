import React, {
  ChangeEvent,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import styled from "styled-components";
import {
  Invoice,
  ProformaDataI,
  TotalesOperacionI,
} from "./AuxiliaresInterface";
import CustomInputtt from "../../../components/entrys/CustomInputtt";
import TableAuxiliar from "./TableAuxiliar";
import CustomInputt from "../../../components/entrys/CustomInputt";
import guardardatos from "../../../../images/GUARDARDATOS.png";
import {
  InvoiceRegister,
  TransactionDataRegister,
} from "../InterfacesContabilidad";
import { fetchServer } from "../../../../services/fetchServer";
import { AppContext } from "../../../../context/appContext";
import OptionSwitchAux from "./OptionSwitchAux";
import CustomInputMoney from "../../../components/entrys/CustomInputMoney";
import { NumericFormat } from "react-number-format";
import { TextField } from "@mui/material";
import InputMoney from "../../../components/entrys/InputMoney";
import { v4 as uuidv4 } from "uuid";
import DeleteConfirmation from "../../../components/common/DeleteConfirmation";

interface FormAuxiliaresI {
  totalOperacion: TotalesOperacionI;
  setTotalOperacion: React.Dispatch<React.SetStateAction<TotalesOperacionI>>;
  statusOperacion: number;
}

export default function FormAuxiliares({
  totalOperacion,
  setTotalOperacion,

  statusOperacion,
}: FormAuxiliaresI) {
  const { setShowLoader } = useContext(AppContext);
  const [listInvoices, setListInvoices] = useState<Invoice[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isBadge, setBadge] = useState<boolean>(false);

  const changeBadge = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBadge(event.target.checked);
  };

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  const sumaAnticipos = listInvoices.reduce(
    (total, item) => total + item.advance,
    0
  );

  const [auxiliar, setAuxiliar] = useState<ProformaDataI>({
    operation_identifier: 0,
    forfait: 0,
    exchange_rate: 0,
    proforma_total: 0,
    total_paid: 0,
    group_commission: 0,
    verif_total_proforma: 0,
    verif_total_paid_mxn: 0,
    verif_total_paid_usd: 0,
    comissionDolares: 0,
    comissionPesos: 0,
    proformaConvertedPesos: 0,
    menosTotalPesos: 0,
    menosTotalDls: 0,
    comisionPesos: 0,
  });

  let identificadorCurrent = uuidv4();
  const { dataCotizacionContext, idOperation, currentUser } =
    useContext(AppContext);

  const [currentInvoice, setCurrentInvoice] = useState<Invoice>({
    invoice_type_identifier: 0,
    total_invoiced: 0,
    iva: 0,
    total_iva: 0,
    subtotal_invoiced: 0,
    invoice_number: "",
    advance_commission: 0,
    advance: 0,
    tipo_anticipo: "",
    invoice_identifier: 0,
    status_identifier: 0,
    invoice_local_id: identificadorCurrent,
  });

  useEffect(() => {
    if (totalOperacion.currency_identifier == 1) {
      ////sumaPagosPrevios se inicializa para saber si hay pagos previos que tomar en cuenta
      let sumaPagosPrevios = 0;
      if (totalOperacion.datosGeneralesOperacion) {
        if (totalOperacion.datosGeneralesOperacion.invoices) {
          const listaInvoicesTransformed: Invoice[] =
            totalOperacion.datosGeneralesOperacion.invoices.map(
              (currentInvoice, index) => {
                let identificador = uuidv4();
                const invoiceElement: Invoice = {
                  invoice_type_identifier:
                    currentInvoice.invoice_type_identifier,
                  total_invoiced: currentInvoice.total_invoiced,
                  iva: currentInvoice.iva,
                  total_iva: currentInvoice.total_iva,
                  subtotal_invoiced: currentInvoice.subtotal_invoiced,
                  invoice_number: currentInvoice.invoice_number,
                  advance_commission: currentInvoice.advance_commission,
                  advance: currentInvoice.advance,
                  tipo_anticipo: currentInvoice.invoice_type,
                  invoice_identifier: currentInvoice.invoice_identifier,
                  status_identifier: currentInvoice.status_identifier,
                  invoice_local_id: identificador,
                };

                return invoiceElement;
              }
            );

          setListInvoices(listaInvoicesTransformed);
          sumaPagosPrevios = listaInvoicesTransformed.reduce(
            (total, item) => total + item.total_invoiced,
            0
          );
          //console.log("Total pagos previos!!!!");
          //console.log(sumaPagosPrevios);
        }
      }

      ////La moneda seleccionada es dolares (moneda manejada en proforma), por lo cual , se convierte el monto de proforma(precio pagado a operadores?) a dolares (los servicios que estén en pesos)
      let dolaresPagadosAOperadores =
        totalOperacion.total_operacion_mxn / auxiliar.exchange_rate
          ? totalOperacion.total_operacion_mxn / auxiliar.exchange_rate
          : 0;

      dolaresPagadosAOperadores = parseFloat(
        (
          dolaresPagadosAOperadores + totalOperacion.total_operacion_usd
        ).toFixed(2)
      );
      ///Aquí la proforma llegó en dolares, no es necesario convertir, pero se redondea dos digitos, y se resta de lo cobrado a operadores para obtener la comisión.
      let proformaTotal = parseFloat(totalOperacion.total_proforma.toFixed(2));
      let comisionGrupo = proformaTotal - dolaresPagadosAOperadores;

      /////Tal cual, el siguiente calculo es para calcular el menosTotaPagadoMXN
      let menosTotalPesosGet = parseFloat(
        totalOperacion.total_operacion_mxn.toFixed(2)
      );
      ////Estos valores se convierte a pesos SIEMPRE porque así lo solicitó el cliente, para mayor facilidad de lectura
      let menosTotalDolaresGet =
        parseFloat(totalOperacion.total_operacion_usd.toFixed(2)) *
        auxiliar.exchange_rate;
      /////Para calcular la comisión en pesos, se reusa la comisión calculada para el grupo en dolares, se convierte a pesos

      let comisionPesosGet = parseFloat(
        (comisionGrupo * auxiliar.exchange_rate).toFixed(2)
      );

      //console.log(auxiliar.exchange_rate);
      //console.log("Dolares");
      //console.log(dolaresPagadosAOperadores);

      if (totalOperacion.tipoCambio !== 0) {
        setAuxiliar({
          ...auxiliar,
          total_paid: dolaresPagadosAOperadores,
          proforma_total: proformaTotal,
          group_commission: comisionGrupo,
          comissionDolares: parseFloat(
            totalOperacion.total_operacion_usd.toFixed(2)
          ),
          comissionPesos: parseFloat(
            totalOperacion.total_operacion_mxn.toFixed(2)
          ),
          proformaConvertedPesos: parseFloat(
            (proformaTotal * auxiliar.exchange_rate).toFixed(2)
          ),
          menosTotalPesos: menosTotalPesosGet,
          menosTotalDls: menosTotalDolaresGet,
          comisionPesos: comisionPesosGet,
          exchange_rate: totalOperacion.tipoCambio,
        });
      } else {
        setAuxiliar({
          ...auxiliar,
          total_paid: dolaresPagadosAOperadores,
          proforma_total: proformaTotal,
          group_commission: comisionGrupo,
          comissionDolares: parseFloat(
            totalOperacion.total_operacion_usd.toFixed(2)
          ),
          comissionPesos: parseFloat(
            totalOperacion.total_operacion_mxn.toFixed(2)
          ),
          proformaConvertedPesos: parseFloat(
            (proformaTotal * auxiliar.exchange_rate).toFixed(2)
          ),
          menosTotalPesos: menosTotalPesosGet,
          menosTotalDls: menosTotalDolaresGet,
          comisionPesos: comisionPesosGet,
        });
      }
    } else if (totalOperacion.currency_identifier == 2) {
      ////La moneda seleccionada es pesos, por lo cual , se convierte el monto de proforma a pesos (los servicios que estén en dolares)
      let pesos =
        totalOperacion.total_operacion_usd * auxiliar.exchange_rate
          ? totalOperacion.total_operacion_usd * auxiliar.exchange_rate
          : 0;

      pesos = parseFloat(
        (pesos + totalOperacion.total_operacion_mxn).toFixed(0)
      );
      let proformaTotal = parseFloat(totalOperacion.total_proforma.toFixed(2));
      let comisionGrupo = proformaTotal - pesos;

      let menosTotalPesosGet = parseFloat(
        totalOperacion.total_operacion_mxn.toFixed(2)
      );

      ////Este valor se convierte a pesos SIEMPRE porque así lo solicitó el cliente, para mayor facilidad de lectura
      let menosTotalDolaresGet =
        parseFloat(totalOperacion.total_operacion_usd.toFixed(2)) *
        auxiliar.exchange_rate;
      let comisionPesosGet = parseFloat(comisionGrupo.toFixed(2));
      //console.log(auxiliar.exchange_rate);
      //console.log("Pesos");
      //console.log(pesos);
      setAuxiliar({
        ...auxiliar,
        total_paid: pesos,
        proforma_total: proformaTotal,
        group_commission: comisionGrupo,
        comissionDolares: parseFloat(
          totalOperacion.total_operacion_usd.toFixed(2)
        ),
        comissionPesos: parseFloat(
          totalOperacion.total_operacion_mxn.toFixed(2)
        ),
        proformaConvertedPesos: proformaTotal,
        menosTotalPesos: menosTotalPesosGet,
        menosTotalDls: menosTotalDolaresGet,
        comisionPesos: comisionPesosGet,
        exchange_rate: totalOperacion.tipoCambio,
      });
    }
    ///////////////////Verificar si ya hay un arreglo guardado previamente.
  }, [totalOperacion, auxiliar.exchange_rate]);

  const [originalValue, setOriginalValue] = useState<number>(
    totalOperacion.total_proforma
  );

  const [inicialProformaTotal, setInicialProformaTotal] = useState<number>(0);
  const [isFirstValor, setIsFirstValor] = useState<boolean>(false);
  useEffect(() => {
    if (totalOperacion.total_proforma !== 0 && isFirstValor == false) {
      setInicialProformaTotal(totalOperacion.total_proforma);
      setIsFirstValor(true);
    }
  }, [totalOperacion]);

  useEffect(() => {
    ///////////////El total de proforma será ahora equivalente al valor inicial de proforma + el valor ingresado de forfait
    if (auxiliar.forfait) {
      setTotalOperacion({
        ...totalOperacion,
        total_proforma: inicialProformaTotal + auxiliar.forfait,
      });
      setPrevNumber(auxiliar.forfait);
    } else {
      ///////////////El total de proforma será ahora equivalente al valor inicial de proforma + el valor ingresado de forfait (Este ultimo vale NaN en este caso)
      setTotalOperacion((prevOp) => {
        return {
          ...totalOperacion,
          total_proforma: inicialProformaTotal,
        };
      });
    }
  }, [auxiliar.forfait]);

  useEffect(() => {
    //Aqui calcula los valores de IVA y Subtotal
    let subtotal = parseFloat(
      (currentInvoice.total_invoiced / 1.16).toFixed(2)
    );

    setCurrentInvoice({
      ...currentInvoice,
      iva: parseFloat((subtotal * 0.16).toFixed(2)),
      subtotal_invoiced: subtotal,
    });
  }, [currentInvoice.total_invoiced]);

  useEffect(() => {
    if (selectedOption == "anticipo" && modoEdicion == false) {
      setCurrentInvoice({
        ...currentInvoice,
        invoice_type_identifier: 0,
        total_invoiced: 0,
        iva: 0,
        total_iva: 0,
        subtotal_invoiced: 0,
        invoice_number: "",
        advance_commission: 0,
        advance: auxiliar.proforma_total,
      });
    } else if (selectedOption == "pagoTotal" && modoEdicion == false) {
      ////sumaPagosPrevios se inicializa para saber si hay pagos previos que tomar en cuenta
      let sumaPagosPrevios = 0;
      if (totalOperacion.datosGeneralesOperacion) {
        if (totalOperacion.datosGeneralesOperacion.invoices) {
          const listaInvoicesTransformed: Invoice[] =
            totalOperacion.datosGeneralesOperacion.invoices.map(
              (currentInvoice, index) => {
                const invoiceElement: Invoice = {
                  invoice_type_identifier:
                    currentInvoice.invoice_type_identifier,
                  total_invoiced: parseFloat(
                    currentInvoice.total_invoiced.toFixed(2)
                  ),
                  iva: currentInvoice.iva,
                  total_iva: currentInvoice.total_iva,
                  subtotal_invoiced: currentInvoice.subtotal_invoiced,
                  invoice_number: currentInvoice.invoice_number,
                  advance_commission: currentInvoice.advance_commission,
                  advance: currentInvoice.advance,
                  tipo_anticipo: currentInvoice.invoice_type,
                  invoice_identifier: currentInvoice.invoice_identifier,
                  status_identifier: currentInvoice.status_identifier,
                  invoice_local_id: currentInvoice.invoice_local_id,
                };

                return invoiceElement;
              }
            );

          setListInvoices(listaInvoicesTransformed);
          sumaPagosPrevios = listaInvoicesTransformed.reduce(
            (total, item) => total + item.total_invoiced,
            0
          );
          //console.log("Total pagos previos!!!!");
          //console.log(sumaPagosPrevios);
        }
      }

      //Asignación de Total de Facturar
      setCurrentInvoice({
        ...currentInvoice,
        total_invoiced: parseFloat(
          (
            (auxiliar.comisionPesos - sumaPagosPrevios) /
            auxiliar.exchange_rate
          ).toFixed(2)
        ),
      });
    }
  }, [selectedOption, auxiliar.comisionPesos]);

  useEffect(() => {
    //console.log("Se actualizó info general de auxiliares");
    //console.log(auxiliar);
  }, [auxiliar]);

  const addNewElement = () => {
    let cantidadPorVerificar = 0;

    if (!currentInvoice.invoice_number.trim()) {
      alert("Por favor, ingresa un número de de factura");
      console.error("Por favor ingresa un número de factura");
      return;
    }
    //console.log("opcion seleccionada: ");
    //console.log(selectedOption);

    auxiliar.comisionPesos =
      auxiliar.menosTotalPesos - auxiliar.proformaConvertedPesos;

    let suma = listInvoices.reduce(
      (total, item) => total + item.total_invoiced,
      0
    );
    //console.log("cantidad por verificar: ");
    //console.log(cantidadPorVerificar);
    //console.log("total_invoiced:");

    //console.log(currentInvoice.total_invoiced);
    //console.log(currentInvoice.advance);
    //console.log("suma normal: ");
    //console.log(suma);

    if (
      suma + cantidadPorVerificar > auxiliar.comisionPesos &&
      selectedOption == "anticipo"
    ) {
      alert("El precio ingresado no puede exceder la comisión");
      //console.log("suma", suma);
      //console.log("auxiliar.comisionPesos");
      //console.log(auxiliar.comisionPesos);

      return;
    } else if (
      selectedOption == "pagoTotal" &&
      suma + currentInvoice.total_invoiced > auxiliar.comisionPesos
    ) {
      alert("El precio ingresado no puede exceder la comisión");

      return;
    }
    suma = currentInvoice.total_invoiced + suma;

    //console.log("listInvoices");
    //console.log(listInvoices);
    //console.log("El resultado de la suma es: ");
    //console.log(suma);
    //console.log("Valor de la comisión original ");
    //console.log(auxiliar.comisionPesos);
    if (!selectedOption) {
      alert("Selecciona el tipo de factura.");
      return;
    }
    //console.log("Tipo de anticipo");
    //console.log(selectedOption);
    setListInvoices([
      ...listInvoices,
      {
        ...currentInvoice,
        tipo_anticipo: selectedOption,
        invoice_type_identifier: selectedOption == "anticipo" ? 2 : 1,
      },
    ]);
    let identificador = uuidv4();
    setCurrentInvoice({
      invoice_type_identifier: 0,
      total_invoiced: 0,
      iva: 0,
      total_iva: 0,
      subtotal_invoiced: 0,
      invoice_number: "",
      advance_commission: 0,
      advance: 0,
      tipo_anticipo: "",
      invoice_identifier: 0,
      status_identifier: 0,
      invoice_local_id: identificador,
    });
  };

  useEffect(() => {
    if (currentInvoice.advance > 0 && currentInvoice.advance_commission > 0) {
      const totalAnticipo =
        currentInvoice.advance * (currentInvoice.advance_commission / 100);
      setCurrentInvoice({
        ...currentInvoice,
        total_invoiced: parseFloat(totalAnticipo.toFixed(2)),
        iva: parseFloat((totalAnticipo * 0.16).toFixed(2)),
        subtotal_invoiced: parseFloat((totalAnticipo * 0.84).toFixed(2)),
      });
    } else {
    }
  }, [currentInvoice.advance, currentInvoice.advance_commission]);

  const updateHojaControl = async () => {
    const listInvoicesToSend: InvoiceRegister[] = listInvoices.map(
      (currentInvoice, index) => {
        const invoiceActual: InvoiceRegister = {
          invoice_identifier: currentInvoice.invoice_identifier,
          invoice_type_identifier: currentInvoice.invoice_type_identifier,
          total_invoiced: currentInvoice.total_invoiced,
          iva: currentInvoice.iva,
          total_iva: currentInvoice.total_iva,
          subtotal_invoiced: currentInvoice.subtotal_invoiced,
          invoice_number: currentInvoice.invoice_number,
          advance_commission: currentInvoice.advance_commission,
          advance: currentInvoice.advance,
          status_identifier: 1,
        };

        return invoiceActual;
      }
    );
    //console.log("list invoices:");
    //console.log(listInvoices);
    //console.log("listInvoicesToSend:");
    //console.log(listInvoicesToSend);

    const bodyRequest: TransactionDataRegister = {
      operation_identifier: idOperation,
      currency_identifier: totalOperacion.currency_identifier,
      forfait: auxiliar.forfait,
      exchange_rate: auxiliar.exchange_rate,
      proforma_total: totalOperacion.total_proforma,
      total_paid: auxiliar.total_paid,
      group_commission: auxiliar.comissionPesos,
      verif_total_proforma: totalOperacion.total_proforma,
      verif_total_paid_mxn: totalOperacion.total_operacion_mxn,
      verif_total_paid_usd: totalOperacion.total_operacion_usd,
      invoices: listInvoicesToSend,
    };
    //console.log("Data to send:");
    //console.log(bodyRequest);

    try {
      const resJsonSave = await fetchServer(
        "/accounting/auxiliars/update",
        "post",
        bodyRequest,
        {
          headers: {
            "Custom-Header": "ValorPersonalizado",
            user_identifier: currentUser.user_identifier.toString(),
          },
        }
      );
      //console.log("respuesta del guardado: ");
      //console.log(resJsonSave);
      alert("Actualización realizada con éxito");
      setShowLoader(false);
    } catch (error) {
      alert("Error al guardar");
      setShowLoader(false);
    }
  };

  const saveHojaControl = async () => {
    //console.log("Hoja de control");
    //console.log("Datos generales auxiliar:");
    //console.log(auxiliar);
    //console.log("Datos de lista ");
    //console.log(listInvoices);
    setShowLoader(true);
    if (totalOperacion.saveMode == 2) {
      updateHojaControl();
      return;
    }
    const listInvoicesToSend: InvoiceRegister[] = listInvoices.map(
      (currentInvoice, index) => {
        const invoiceActual: InvoiceRegister = {
          invoice_identifier: 0,
          invoice_type_identifier:
            currentInvoice.tipo_anticipo == "Anticipo" ? 2 : 1,
          total_invoiced: currentInvoice.total_invoiced,
          iva: currentInvoice.iva,
          total_iva: currentInvoice.total_iva,
          subtotal_invoiced: currentInvoice.subtotal_invoiced,
          invoice_number: currentInvoice.invoice_number,
          advance_commission: currentInvoice.advance_commission,
          advance: currentInvoice.advance,
          status_identifier: 0,
        };

        return invoiceActual;
      }
    );

    const bodyRequest: TransactionDataRegister = {
      operation_identifier: idOperation,
      currency_identifier: totalOperacion.currency_identifier,
      forfait: auxiliar.forfait,
      exchange_rate: auxiliar.exchange_rate,
      proforma_total: totalOperacion.total_proforma,
      total_paid: auxiliar.total_paid,
      group_commission: auxiliar.comissionPesos,
      verif_total_proforma: totalOperacion.total_proforma,
      verif_total_paid_mxn: totalOperacion.total_operacion_mxn,
      verif_total_paid_usd: totalOperacion.total_operacion_usd,
      invoices: listInvoicesToSend,
    };
    //console.log("Data to send:");
    //console.log(bodyRequest);
    setShowLoader(true);
    try {
      const resJsonSave = await fetchServer(
        "/accounting/auxiliars/register",
        "post",
        bodyRequest,
        {
          headers: {
            "Custom-Header": "ValorPersonalizado",
            user_identifier: currentUser.user_identifier.toString(),
          },
        }
      );
      //console.log("respuesta del guardado: ");
      //console.log(resJsonSave);
      // alert("Registro guardado con éxito");
      setShowLoader(false);
    } catch (error) {
      // alert("Error al guardar");
      setShowLoader(false);
    }
  };

  const [optionSelected, setOptionSelected] = useState<number>(1);
  useEffect(() => {
    //console.log("Valor de option Selected");
    //console.log(optionSelected);
    setOptionSelected(statusOperacion == 3 ? 2 : 1);
  }, [statusOperacion]);

  const changeStatus = (requestNumber: number) => {
    //console.log("Cambiando");
    setOptionSelected(statusOperacion);
    //console.log(statusOperacion);
    const bodyRequest = {
      operation_identifier: idOperation,
      status_identifier: requestNumber,
    };
    setShowLoader(true);
    try {
      const resJson = fetchServer(
        "/accounting/closeOperation/update",
        "post",
        bodyRequest
      );

      setOptionSelected(bodyRequest.status_identifier == 1 ? 1 : 2);
      //console.log("body Request");
      //console.log(bodyRequest.status_identifier);
      setShowLoader(false);
    } catch (error) {
      alert("No fue posible cambiar el estado de la operación");
      setShowLoader(false);
    }
  };
  const prevForfaitRef = useRef();
  const [prevNumber, setPrevNumber] = useState<number>(0);
  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const [currentInvoiceToDelete, setCurrentInvoiceToDelete] =
    useState<Invoice | null>(null);
  const deleteInvoice = (currentInvoice: Invoice) => {
    //console.log("Borrando factura");
    //console.log(currentInvoice);
    setIsShownDelete(true);
    setCurrentInvoiceToDelete(currentInvoice);
  };

  const handleDeleteConfirm = () => {
    if (currentInvoiceToDelete) {
      if (currentInvoiceToDelete.invoice_identifier != 0) {
        const arrayToDelete = {
          invoices: [
            {
              invoice_identifier: currentInvoiceToDelete.invoice_identifier,
            },
          ],
        };

        const backendEndPoint = "/accounting/auxiliars/delete";

        try {
          const resJson = fetchServer(backendEndPoint, "post", arrayToDelete);
          //console.log("Respuesta del borrado: ");
          //console.log(resJson);
          deleteLocalInvoice(currentInvoiceToDelete);
        } catch (error) {
          alert("Error al borrar");
        }
      } else {
        deleteLocalInvoice(currentInvoiceToDelete);
      }
    }

    setIsShownDelete(false);
    ////BORRADO
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };

  const deleteLocalInvoice = (currentInvoice: Invoice) => {
    const newListInvoices = listInvoices.filter(
      (currentInvoiceArray, index) => {
        return (
          currentInvoiceArray.invoice_local_id !==
          currentInvoice.invoice_local_id
        );
      }
    );
    setListInvoices(newListInvoices);
  };

  const [modoEdicion, setModoEdicion] = useState<boolean>(false);
  const [currentInvoiceToModify, setCurrentInvoiceToModify] = useState<Invoice>(
    {
      invoice_type_identifier: 0,
      total_invoiced: 0,
      iva: 0,
      total_iva: 0,
      subtotal_invoiced: 0,
      invoice_number: "",
      advance_commission: 0,
      advance: 0,
      tipo_anticipo: "",
      invoice_identifier: 0,
      status_identifier: 0,
      invoice_local_id: identificadorCurrent,
    }
  );
  const editInvoice = (invoiceSelected: Invoice) => {
    if (invoiceSelected.invoice_type_identifier == 2) {
      setSelectedOption("anticipo");
    } else {
      setSelectedOption("pagoTotal");
    }

    //console.log("Se ha puesto para editar");
    //console.log(currentInvoice);

    setCurrentInvoiceToModify({
      ...currentInvoice,
      invoice_type_identifier: invoiceSelected.invoice_type_identifier,
      total_invoiced: invoiceSelected.total_invoiced,
      iva: invoiceSelected.iva,
      total_iva: invoiceSelected.total_iva,
      subtotal_invoiced: invoiceSelected.subtotal_invoiced,
      invoice_number: invoiceSelected.invoice_number,
      advance_commission: invoiceSelected.advance_commission,
      advance: invoiceSelected.advance,
      tipo_anticipo: invoiceSelected.tipo_anticipo,
      invoice_identifier: invoiceSelected.invoice_identifier,
      status_identifier: invoiceSelected.status_identifier,
      invoice_local_id: invoiceSelected.invoice_local_id,
    });
    setCurrentInvoice({
      ...currentInvoice,
      invoice_type_identifier: invoiceSelected.invoice_type_identifier,
      total_invoiced: invoiceSelected.total_invoiced,
      iva: invoiceSelected.iva,
      total_iva: invoiceSelected.total_iva,
      subtotal_invoiced: invoiceSelected.subtotal_invoiced,
      invoice_number: invoiceSelected.invoice_number,
      advance_commission: invoiceSelected.advance_commission,
      advance: invoiceSelected.advance,
      tipo_anticipo: invoiceSelected.tipo_anticipo,
      invoice_identifier: invoiceSelected.invoice_identifier,
      status_identifier: invoiceSelected.status_identifier,
      invoice_local_id: invoiceSelected.invoice_local_id,
    });
  };

  const cancelarEdicion = () => {
    setModoEdicion(false);

    setCurrentInvoice({
      invoice_type_identifier: 0,
      total_invoiced: 0,
      iva: 0,
      total_iva: 0,
      subtotal_invoiced: 0,
      invoice_number: "",
      advance_commission: 0,
      advance: 0,
      tipo_anticipo: "",
      invoice_identifier: 0,
      status_identifier: 0,
      invoice_local_id: identificadorCurrent,
    });
  };
  const saveModification = () => {
    const invoiceCleaned = {
      invoice_type_identifier: 0,
      total_invoiced: 0,
      iva: 0,
      total_iva: 0,
      subtotal_invoiced: 0,
      invoice_number: "",
      advance_commission: 0,
      advance: 0,
      tipo_anticipo: "",
      invoice_identifier: 0,
      status_identifier: 0,
      invoice_local_id: identificadorCurrent,
    };
    const updatedList = listInvoices.map((invoiceCurrentElement, index) => {
      return invoiceCurrentElement.invoice_local_id !=
        currentInvoice.invoice_local_id
        ? invoiceCurrentElement
        : currentInvoice;
    });

    setListInvoices(updatedList);
    setModoEdicion(false);
    setCurrentInvoice(invoiceCleaned);
    setCurrentInvoiceToModify(invoiceCleaned);
  };
  return (
    <FormAuxiliaresStyles>
      <div className="container-input-first">
        <InputMoney<ProformaDataI>
          text="Forfait"
          txtWidth={100}
          setGeneralData={setAuxiliar}
          field="forfait"
          value={auxiliar.forfait}
          typeData="number"
          isNegativeValid={1}
          isNegativeAllowed={true}
        />
        <InputMoney<ProformaDataI>
          text="Tipo de Cambio"
          txtWidth={100}
          setGeneralData={setAuxiliar}
          field="exchange_rate"
          value={auxiliar.exchange_rate}
          typeData="number"
        />
        <InputMoney<ProformaDataI>
          text="Total Proforma Factura"
          txtWidth={100}
          setGeneralData={setAuxiliar}
          field="proforma_total"
          value={auxiliar.proforma_total}
          typeData="number"
          isEditableBox={false}
        />
        <InputMoney<ProformaDataI>
          text="Menos Total Pagado"
          txtWidth={100}
          setGeneralData={setAuxiliar}
          field="total_paid"
          value={auxiliar.total_paid}
          typeData="number"
          isEditableBox={false}
        />
      </div>
      <br />
      <div className="comision-box">
        <InputMoney<ProformaDataI>
          text="USD"
          txtWidth={80}
          setGeneralData={setAuxiliar}
          field="comissionDolares"
          value={auxiliar.comissionDolares}
          typeData="number"
          isEditableBox={false}
        />

        <InputMoney<ProformaDataI>
          text="MEP"
          txtWidth={80}
          setGeneralData={setAuxiliar}
          field="comissionPesos"
          value={auxiliar.comissionPesos}
          typeData="number"
          isEditableBox={false}
        />
      </div>
      <br />
      <div className="third-box">
        <div className="comprobacion-box">
          <h3>Comprobación</h3>
          <br />
          <InputMoney<ProformaDataI>
            text="Proforma"
            txtWidth={80}
            setGeneralData={setAuxiliar}
            field="proformaConvertedPesos"
            value={auxiliar.proformaConvertedPesos}
            typeData="number"
            isEditableBox={false}
          />
          <br />
          <InputMoney<ProformaDataI>
            text="Menos Total Pagado MXN"
            txtWidth={80}
            setGeneralData={setAuxiliar}
            field="menosTotalPesos"
            value={auxiliar.menosTotalPesos}
            typeData="number"
            isEditableBox={false}
          />
          <br />
          <InputMoney<ProformaDataI>
            text="Menos Total Pagado USD"
            txtWidth={80}
            setGeneralData={setAuxiliar}
            field="menosTotalDls"
            value={auxiliar.menosTotalDls}
            typeData="number"
            isEditableBox={false}
          />
          <br />
          <InputMoney<ProformaDataI>
            text="Comisión Grupo"
            txtWidth={80}
            setGeneralData={setAuxiliar}
            field="comisionPesos"
            value={auxiliar.comisionPesos}
            typeData="number"
            isEditableBox={false}
            isNegativeValid={1}
            isNegativeAllowed={true}
          />
        </div>
        <div>
          <h3>Calculo de factura</h3>
          <br />
          <div>
            <label>
              <input
                type="radio"
                name="paymentOption"
                value="anticipo"
                checked={selectedOption === "anticipo"}
                onChange={() => {
                  handleOptionChange("anticipo");

                  setModoEdicion(false);
                }}
              />
              Anticipo
            </label>

            <br />

            <label>
              <input
                type="radio"
                name="paymentOption"
                value="pagoTotal"
                checked={selectedOption === "pagoTotal"}
                onChange={() => {
                  handleOptionChange("pagoTotal");
                  setModoEdicion(false);
                }}
              />
              Pago total
            </label>
          </div>
          <br />
          {selectedOption == "anticipo" && (
            <>
              <InputMoney<Invoice>
                text="Anticipo"
                txtWidth={80}
                setGeneralData={setCurrentInvoice}
                field="advance"
                value={currentInvoice.advance}
                typeData="number"
              />
              <br />
              <CustomInputtt<Invoice>
                text="Comisión anticipo %"
                txtWidth={80}
                setGeneralData={setCurrentInvoice}
                field="advance_commission"
                value={currentInvoice.advance_commission}
                typeData="number"
                max={100}
              />
              <br />
            </>
          )}
          <div>
            <InputMoney<Invoice>
              text="Total a Facturar"
              txtWidth={80}
              setGeneralData={setCurrentInvoice}
              field="total_invoiced"
              value={currentInvoice.total_invoiced}
              typeData="number"
              isEditableBox={selectedOption == "anticipo" ? false : true}
            />

            {/*<input type="checkbox" checked={isBadge} onChange={changeBadge} /> */}
          </div>
          <br />
          <InputMoney<Invoice>
            text="IVA"
            txtWidth={80}
            setGeneralData={setCurrentInvoice}
            field="iva"
            value={currentInvoice.iva}
            typeData="number"
            isEditableBox={false}
          />

          <br />
          <InputMoney<Invoice>
            text="Sub Total"
            txtWidth={80}
            setGeneralData={setCurrentInvoice}
            field="subtotal_invoiced"
            value={currentInvoice.subtotal_invoiced}
            typeData="number"
            isEditableBox={false}
          />
          <br />
          <CustomInputt<Invoice>
            text="No de Factura"
            txtWidth={80}
            setGeneralData={setCurrentInvoice}
            field="invoice_number"
            value={currentInvoice.invoice_number}
          />
          <br />
          {modoEdicion == false ? (
            <div className="non-printable-section">
              <button onClick={addNewElement}>Agregar</button>
            </div>
          ) : (
            <div className="non-printable-section">
              <div className="container-botones">
                <button
                  onClick={() => {
                    saveModification();
                  }}
                >
                  Modificar
                </button>
                <button onClick={() => cancelarEdicion()}>Cancelar</button>
              </div>
            </div>
          )}

          <br />

          <br />
        </div>
      </div>
      <div>
        <OptionSwitchAux
          optionSelected={optionSelected}
          setOptionSelected={setOptionSelected}
          changeStatus={changeStatus}
        />
        <br />
      </div>
      <div>
        <TableAuxiliar
          listInvoices={listInvoices}
          auxiliar={auxiliar}
          deleteInvoice={deleteInvoice}
          editInvoice={editInvoice}
          setModoEdicion={setModoEdicion}
          modoEdicion={modoEdicion}
          currentInvoiceToModify={currentInvoiceToModify}
        />
        <div className="non-printable-section">
          <div className="buttons" style={{ backgroundColor: "transparent" }}>
            <img
              src={guardardatos}
              alt="leavePage"
              width="45px"
              onClick={saveHojaControl}
              style={{ cursor: "pointer", marginBottom: "1rem" }}
            />
          </div>
        </div>
      </div>
      {isShownDelete && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          itemName={`¿Deseas borrar la siguiente factura? \n ${currentInvoiceToDelete?.invoice_number}`}
          setIsShownDelete={setIsShownDelete}
        />
      )}
    </FormAuxiliaresStyles>
  );
}

const FormAuxiliaresStyles = styled.div`
  .container-botones {
    display: flex;
    justify-content: space-around;
    width: 80%;
  }
  .third-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
  }
  .container-input-first {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1rem;
  }
  .comision-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .check-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
