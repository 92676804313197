import React, { createContext, useContext, useEffect, useState } from "react";
import { AppContext } from "./appContext";
import {
  CatalogsData,
  Country,
  City,
  Neighborhood,
  Zipcode,
  Currency,
  Season,
  RegistrationType,
  ProviderType,
  ServiceType,
  View,
  Bank,
  CatalogsContextType,
} from "../interfaces/GeneralTypes";

const CatalogsContext = createContext<CatalogsContextType>({
  catalogsData: {
    country_list: [],
    city_list: [],
    neighborhood_list: [],
    zipcode_list: [],
    currency_list: [],
    season_list: [],
    registration_type_list: [],
    provider_type_list: [],
    service_type_list: [],
    view_list: [],
    bank_list: [],
    category_list: [],
    meal_plan_list: [],
    lender_type_list: [],
    client_type_list: [],
    fee_type_list: [],
    client_category_list: [],
    job_list: [],
    transport_service_type_list: [],
    continent_list: [],
    expenses_category_list: [],
    agencias_list: [],
  },
});
interface AppProviderProps {
  children: React.ReactNode;
}

const CatalogsProvider: React.FC<AppProviderProps> = ({ children }) => {
  const { showLoader, setShowLoader } = useContext(AppContext);
  const [catalogsData, setCatalogsData] = useState<CatalogsData>({
    country_list: [],
    city_list: [],
    neighborhood_list: [],
    zipcode_list: [],
    currency_list: [],
    season_list: [],
    registration_type_list: [],
    provider_type_list: [],
    service_type_list: [],
    view_list: [],
    bank_list: [],
    category_list: [],
    meal_plan_list: [],
    lender_type_list: [],
    client_type_list: [],
    fee_type_list: [],
    client_category_list: [],
    job_list: [],
    transport_service_type_list: [],
    continent_list: [],
    expenses_category_list: [],
    agencias_list: [],
  });

  useEffect(() => {
    setShowLoader(true);
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/catalog/all`)
      .then((response) => response.json())
      .then((data) => {
        //console.log('TRayendo datos de la api');
        //console.log(data.data);
        setCatalogsData(data.data);
        setShowLoader(false);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <CatalogsContext.Provider value={{ catalogsData }}>
      {children}
    </CatalogsContext.Provider>
  );
};

const useCatalogs = () => useContext(CatalogsContext);

export { CatalogsProvider, useCatalogs };
