import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ListPrestadores from "../../Operaciones/ListPrestadores";
import agregarImage from "../../../../images/AGREGAR.png";
import { AppContext } from "../../../../context/appContext";
import { List } from "@mui/material";
import { OperationContext } from "../../../../context/operationContext";
import { Link, useNavigate } from "react-router-dom";
import {
  ApiResponseListProviders,
  ProviderIdI,
  ProviderInterfaceOP,
} from "../../Operaciones/InterfacesOperacion/OperacionesI";
import HeaderOpEdit from "../../Operaciones/HeaderOpEdit";
import ListPrestadoresCont from "./ListPrestadoresCont";
import HeaderOpEditCont from "./HeaderOpEditCont";

export default function DefinicionPrestadoresCont() {
  const {
    dataCotizacionContext,
    setDataCotizacionContext,
    setShowLoader,
    setCotizacionMode,
    idOperation,
    setIdOperation,
    setIdProforma,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const { currentOperation, setCurrentOperation, contadorOperacion } =
    useContext(OperationContext);

  const [listHotelsProviders, setListHotelsProviders] = useState<
    ProviderInterfaceOP[]
  >([]);
  const [listRestaurants, setListRestaurants] = useState<ProviderInterfaceOP[]>(
    []
  );
  const [listAgencies, setListAgencies] = useState<ProviderInterfaceOP[]>([]);
  const [listTransport, setListTransport] = useState<ProviderInterfaceOP[]>([]);
  const [listExpenses, setListExpenses] = useState<ProviderInterfaceOP[]>([]);
  const [listHonorary, setListHonorary] = useState<ProviderInterfaceOP[]>([]);
  const [listOptional, setListOptional] = useState<ProviderInterfaceOP[]>([]);

  const getProforma = () => {
    setIdOperation(currentOperation.quote.quote_identifier);
    setIdProforma(currentOperation.quote.proforma_identifier ?? 0);
    navigate("/dashboard/Operaciones/Proforma-Operacion");
  };

  const getProviderList = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    setShowLoader(true);
    const urlModified =
      backEndPoint + `/operations/getQuoteProviders/${idOperation}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        //console.log(response);
        //console.log("bien1");
        const data: ApiResponseListProviders = await response.json();

        //console.log("-------------------------------------------LISTA DE OPERACIONES:::::");
        //console.log("ID DE OPERACION:::");
        //console.log(idOperation);
        //console.log(data.data.providers);

        setCurrentOperation(data.data);

        setShowLoader(false);
        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        // setTitleAlert("Error al realizar la solicitud!:");
        // setContentAlert(
        //   "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
        // );
        // setShowAlert(true);
        setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      //   alert("ERROR AL REALIZAR LA SOLICITUD");

      //   setTitleAlert("Error al realizar la solicitud:");
      //   setContentAlert(
      //     "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
      //   );
      setShowLoader(false);
    }
  };

  const printDocument = () => {
    window.print();
  };

  useEffect(() => {
    setListAgencies(currentOperation.providers.agency);
    setListExpenses(currentOperation.providers.expenses);
    setListHonorary(currentOperation.providers.honorary);
    setListHotelsProviders(currentOperation.providers.hotel);
    setListOptional(currentOperation.providers.optionals);
    setListRestaurants(currentOperation.providers.restaurant);
    setListTransport(currentOperation.providers.transport);
  }, [currentOperation]);

  useEffect(() => {
    getProviderList();
  }, [contadorOperacion]);

  return (
    <PrestadorListStyle>
      <div className="non-printable-section">
        <div className="print-box">
          <button onClick={printDocument}>Imprimir</button>
        </div>
      </div>

      <br />
      <br />
      <HeaderOpEditCont />
      <br />
      <br />
      <br />
      <div className="encabezado-container">
        <div className="encabezado">
          <div>
            <h3>Prestador nombre</h3>
          </div>
          <div>
            <h3>Prestador clave</h3>
          </div>
          <div></div>
        </div>
        <div className="boton-spaces"></div>
      </div>
      {/* Cada uno representa un distinto tipo de proveedor (HOTEL, RESTAURANT, AGENCY) */}
      {/* Hotel */}
      <ListPrestadoresCont
        nameList="Hoteles"
        listProvider={listHotelsProviders}
        setListProvider={setListHotelsProviders}
      />
      {/*  Restaurant */}
      <ListPrestadoresCont
        nameList="Restaurantes"
        listProvider={listRestaurants}
        setListProvider={setListRestaurants}
      />
      {/* Agencia */}
      <ListPrestadoresCont
        nameList="Agencias"
        listProvider={listAgencies}
        setListProvider={setListAgencies}
      />
      {/* Transporte */}
      <ListPrestadoresCont
        nameList="Transporte"
        listProvider={listTransport}
        setListProvider={setListTransport}
      />
      {/* Gastos a comprobar */}
      <ListPrestadoresCont
        nameList="Gastos a comprobar"
        listProvider={listExpenses}
        setListProvider={setListExpenses}
        isGastosComprobar={true}
      />
      {/*Honorarios guía */}
      <ListPrestadoresCont
        nameList="Honorarios guía"
        listProvider={listHonorary}
        setListProvider={setListHonorary}
      />

      <br />
      <br />
    </PrestadorListStyle>
  );
}
const PrestadorListStyle = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem;
  .boton-style {
    margin-right: 3rem;
    border: 1px solid rgb(8, 166, 165);
    padding: 0.4rem 2rem;
    border-radius: 1.3rem;
    cursor: pointer;
    font-weight: bolder;
    background-color: rgb(8, 166, 165);
    color: white;
    transition: all 0.4s linear 0s;
    margin-top: 1rem;
  }
  .encabezado-container {
    display: flex;
  }
  .boton-spaces {
    /* width: 8%; */
  }
  .encabezado {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: rgb(8, 166, 165);
    color: white;
    padding: 0.5rem;
    font-size: 0.75vw;
    font-weight: 400 !important;
    align-items: center;
    width: 100%;
  }
  h3 {
    font-weight: 400;
  }
`;
