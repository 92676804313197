import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import EstadosCuentaList from "./EstadosCuentaList";
import OptionSwitch from "../../Operaciones/Components/OptionSwitch";
import OptionSwitchCuenta from "./OptionSwitchCuenta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {
  BusquedaPagosI,
  OperationISearch,
  PaymentSearch,
  ProviderDataSearch,
} from "../InterfacesContabilidad";
import DateInput from "../../../components/entrys/DateInput";
import { fetchServer } from "../../../../services/fetchServer";
import BusquedaEstadosCuenta from "./Busqueda/BusquedaEstadosCuenta";
import AgenciaInfo from "./AgenciaInfo";

export default function EstadosCuenta() {
  const [optionTableSelected, setOptionTableSelected] = useState<number>(2);
  const [selectedOption, setSelectedOption] = useState<string>("Prestador");
  const [currentPage, setCurrentPage] = useState(1);
  const [busquedaPagos, setBusquedaPagos] = useState<BusquedaPagosI>({
    payment_status_identifier: 2, // 1 pendiente, 2 pagado
    account_status_type_identifier: null, // proveedor o agencia
    search_parameter: "",
    initial_date: "",
    final_date: "",
    provider_identifier: 0,
  });
  const [resultsPagos, setResultsPagos] = useState<PaymentSearch[]>([]);
  const [providerData, setProviderData] = useState<ProviderDataSearch>({
    provider_identifier: 0,
    provider_name: "",
    business_name: "",
    bank_accounts_list: [],
  });
  const [listSearchAgencias, setListSearchAgencias] = useState<
    OperationISearch[]
  >([]);
  const findOperaciones = async () => {
    const resRequest = await fetchServer(
      "/accounting/accountStatus/search",
      "post",
      busquedaPagos
    );
    //console.log("Respuesta del request");
    //console.log(resRequest);
  };
  useEffect(() => {
    //console.log("El objeto de pagos se actualizó");
    //console.log(busquedaPagos);
  }, [busquedaPagos]);
  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };
  return (
    <EstadosCuentaStyles>
      <br />
      <br />
      <div className="non-printable-section">
        <div className="container-radio">
          <label>
            <input
              type="radio"
              name="paymentOption"
              value="Agencia"
              checked={selectedOption === "Agencia"}
              onChange={() => {
                setListSearchAgencias([]);
                setResultsPagos([]);
                setProviderData({
                  provider_identifier: 0,
                  provider_name: "",
                  business_name: "",
                  bank_accounts_list: [],
                });
                handleOptionChange("Agencia");
              }}
            />
            Agencia
          </label>

          <br />

          <label>
            <input
              type="radio"
              name="paymentOption"
              value="Prestador"
              checked={selectedOption === "Prestador"}
              onChange={() => {
                setListSearchAgencias([]);
                setProviderData({
                  provider_identifier: 0,
                  provider_name: "",
                  business_name: "",
                  bank_accounts_list: [],
                });
                setResultsPagos([]);
                handleOptionChange("Prestador");
              }}
            />
            Prestador
          </label>
        </div>
        <BusquedaEstadosCuenta
          busquedaPagos={busquedaPagos}
          resultsPagos={resultsPagos}
          setResultsPagos={setResultsPagos}
          providerData={providerData}
          setProviderData={setProviderData}
          selectedOption={selectedOption}
          listSearchAgencias={listSearchAgencias}
          setListSearchAgencias={setListSearchAgencias}
        />
        {selectedOption == "Prestador" && (
          <>
            <div className="container-search-componentss">
              <div className="switch-container">
                <OptionSwitchCuenta
                  optionTableSelected={optionTableSelected}
                  setOptionTableSelected={setOptionTableSelected}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  busquedaPagos={busquedaPagos}
                  setBusquedaPagos={setBusquedaPagos}
                />
              </div>
              <DateInput
                text={true ? "Fecha inicial" : ""}
                txtWidth={100}
                setGeneralData={setBusquedaPagos}
                field="initial_date"
                value={busquedaPagos.initial_date}
              />
              <DateInput
                text={true ? "Fecha inicial" : ""}
                txtWidth={100}
                setGeneralData={setBusquedaPagos}
                field="final_date"
                value={busquedaPagos.final_date}
              />
            </div>
          </>
        )}
      </div>
      <br />
      {providerData.business_name.trim() && (
        <div className="info-detail">
          <h3>Razón social: {providerData.business_name}</h3>
        </div>
      )}
      <EstadosCuentaList resultsPagos={resultsPagos} />
      <AgenciaInfo listSearchAgencias={listSearchAgencias} />
    </EstadosCuentaStyles>
  );
}

const EstadosCuentaStyles = styled.div`
  .container-radio {
    width: 80%;
    margin: 0 auto;
  }
  .info-detail {
    width: 80%;
    margin: 0 auto;
  }
  .container-search-componentss {
    /* display: grid;
    grid-template-columns: 1fr 1fr ; */
    width: 80%;
    margin: 0 auto;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    align-items: center;
  }

  .switch-container {
  }
  .box-search-elements {
    display: flex;
    flex-direction: column;
  }
  .elements-search {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
  }
  .combo-box-container {
    width: 200px;
    height: 10px;
  }
  .search-box-container {
    border: rgb(8, 166, 165) solid 2px;
    width: 80%;
    display: flex;
    margin-left: 2rem;

    /* margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
    */
  }
  .input-container {
    width: 85%;
  }
  .input-container input {
    width: 100%;
    padding: 0.8rem;
    border: none;
    outline: none;
    border-right: rgb(8, 166, 165) solid 2px;
  }
  .icon-container {
    width: 15%;
    background-color: rgb(8, 166, 165);

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    transition: ease all 0.7s;
  }
  .icon-container:hover {
    background-color: white;
    color: rgb(8, 166, 165);
  }
`;
