import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ListPrestadores from "./ListPrestadores";
import { AppContext } from "../../../context/appContext";
import {
  ApiResponseGuia,
  ApiResponseListProviders,
  BankAccountIGuia,
  ContactIGuia,
  EntryDataResponse,
  EntryService,
  GuideI,
  InitialContextOP,
  InitialContextOPGastos,
  ProviderInterfaceOP,
} from "./InterfacesOperacion/OperacionesI";
import { List } from "@mui/material";
import { OperationContext } from "../../../context/operationContext";
import HeaderOpRead from "./HeaderOpRead";
import { useCatalogsOperacion } from "../../../context/OperacionCatalog";
import ElementReadDesgloce from "./Components/ElementReadDesgloce";
import guardardatos from "../../../images/GUARDARDATOS.png";
import { Link, useNavigate } from "react-router-dom";
import { formatearCantidad } from "../../components/functionsTs/utilidades";

export default function () {
  const navigate = useNavigate();
  const { catalogsDataOperaciones } = useCatalogsOperacion();
  const { currentOperation } = useContext(OperationContext);
  const [listGuides, setListGuide] = useState<GuideI[]>([]);
  const [guideSelected, setGuideSelected] = useState<number>(0);
  const [listServicesGuiaPesos, setListServicesGuiaPesos] = useState<
    EntryService[]
  >([]);
  const [listServicesGuiaDolares, setListServicesGuiaDolares] = useState<
    EntryService[]
  >([]);
  const [contactGuiaList, setContactGuiaList] = useState<ContactIGuia[]>([]);
  const [bankListGuia, setBankListGuia] = useState<BankAccountIGuia[]>([]);
  const [nombreNegocio, setNombreNegocio] = useState<string>("");

  //////////////////////////////ESTE ES EL ID DE TU OPERACION
  useEffect(() => {
    //console.log(currentOperation.quote.quote_identifier);
  }, [currentOperation]);

  //////Esta es la lista de guías
  useEffect(() => {
    //console.log("CATALOGOS DE OPERACIONES::::AQUI SE GUARDAN LOS GUIAS");
    //console.log(catalogsDataOperaciones);
    setListGuide(catalogsDataOperaciones.guides_list);
  }, [catalogsDataOperaciones]);

  const getEntradaData = async () => {
    //////Construcción de URL
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    ////AQUI CONSTRUYES TU ENPOINT; MODIFICALO
    const urlModified =
      backEndPoint +
      `/operations/getServicesByGuide/${currentOperation.quote.quote_identifier}/${guideSelected}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        //console.log(response);
        //console.log("bien");
        const data: EntryDataResponse = await response.json();
        //console.log("Acceso a respuesta DE VER DESGLOSE");
        //console.log(data.data.entry_services_list);
        setListServicesGuiaPesos(
          data.data.entry_services_list.mxn_services_list
        );
        setListServicesGuiaDolares(
          data.data.entry_services_list.usd_services_list
        );
        ///Redirigir a otra pantalla
        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
    }

    const urlDos =
      backEndPoint + `/operations/getProviderData/${guideSelected}`;
    try {
      const response: Response = await fetch(urlDos, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        //console.log("Se realiza petición a: ");
        //console.log(urlDos);

        //console.log("bien");
        const data: ApiResponseGuia = await response.json();
        //console.log(data.data);
        //console.log("Acceso a respuesta lista de contactos");
        //console.log(data.data.contact_list);
        //console.log(data.data.bank_account_list);
        //console.log(data.data.business_name);
        setBankListGuia(data.data.bank_account_list);
        setContactGuiaList(data.data.contact_list);
        setNombreNegocio(data.data.business_name);
        ///Redirigir a otra pantalla
        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
    }
  };
  useEffect(() => {
    if (guideSelected !== 0) {
      getEntradaData();
    }
  }, [guideSelected]);

  useEffect(() => {
    //console.log("Lista en pesos");
    //console.log(listServicesGuiaPesos);
    //console.log("Lista en dolares");
    //console.log(listServicesGuiaDolares);
  }, [listServicesGuiaPesos, listServicesGuiaDolares]);

  const totalPesos = listServicesGuiaPesos.reduce(
    (total, item) => total + item.quantity1 * item.quantity2 * item.unitary_fee,
    0
  );

  const totalDolares = listServicesGuiaDolares.reduce(
    (total, item) => total + item.quantity1 * item.quantity2 * item.unitary_fee,
    0
  );
  useEffect(() => {
    //console.log("CAMBIO EL TOTAL DE DOLARES:::");
    //console.log(totalDolares);
  }, [totalDolares]);
  useEffect(() => {
    //console.log("Cambio el total pesos: ");
    //console.log(totalPesos);
  }, [totalPesos]);
  const updatePrice = (servicioActual: EntryService, tipoMoneda: number) => {
    //console.log("MONEDA UTILIZADA ID");
    //console.log(tipoMoneda);

    if (tipoMoneda == 1) {
      const listaDolares = listServicesGuiaDolares.map((servicio, indice) => {
        //console.log("servicio.entry_service_identifier : ",servicio.entry_service_identifier," servicioActual.entry_service_identifier",servicioActual.entry_service_identifier);
        return servicio.entry_service_identifier !==
          servicioActual.entry_service_identifier
          ? servicio
          : {
              ...servicioActual,
              total_fee:
                servicioActual.quantity1 *
                servicioActual.quantity2 *
                servicioActual.unitary_fee,
            };
      });
      //console.log("COMO DEBE QUEDAR TU ARREGLO EN USD:");
      //console.log(listaDolares);

      setListServicesGuiaDolares(listaDolares);
      return;
    } else if (tipoMoneda == 2) {
      const listaPesos = listServicesGuiaPesos.map((servicio, indice) => {
        //console.log("servicio.entry_service_identifier : ",servicio.entry_service_identifier," servicioActual.entry_service_identifier",servicioActual.entry_service_identifier);
        return servicio.entry_service_identifier !==
          servicioActual.entry_service_identifier
          ? servicio
          : {
              ...servicioActual,
              total_fee:
                servicioActual.quantity1 *
                servicioActual.quantity2 *
                servicioActual.unitary_fee,
            };
      });
      //console.log("COMO DEBE QUEDAR TU ARREGLO EN PESOS:");
      //console.log(listaPesos);
      setListServicesGuiaPesos(listaPesos);
      return;
    }
  };
  useEffect(() => {
    //console.log("cambio arreglo de pesos");
    //console.log(listServicesGuiaPesos);
  }, [listServicesGuiaPesos]);
  useEffect(() => {
    //console.log("cambio arreglo dolares");
    //console.log(listServicesGuiaDolares);
  }, [listServicesGuiaDolares]);
  const saveDesgloce = async () => {
    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/entryUpdate`;
    const listaDolares = listServicesGuiaDolares.map((servicio, indice) => {
      return {
        ...servicio,
        total_fee:
          servicio.quantity1 * servicio.quantity2 * servicio.unitary_fee,
      };
    });

    const listaPesos = listServicesGuiaPesos.map((servicio, indice) => {
      return {
        ...servicio,
        total_fee:
          servicio.quantity1 * servicio.quantity2 * servicio.unitary_fee,
      };
    });
    const bodyRequest: InitialContextOPGastos = {
      entry_data: null,
      entry_services_list: {
        mxn_services_list: listaPesos,
        usd_services_list: listaDolares,
      },
    };

    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyRequest),
      });

      if (response.ok) {
        const data = await response.json();

        // alert("El valor se ha enviado correctamente");

        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION

        navigate("/dashboard/Operaciones/Definicion-Prestador");
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al guardar");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al guardar");
      console.error("Error in request:", error);

      alert("Error en el servicio");
      // setShowLoader(false);
    }
    // alert("GUARDANDO DESGLOCE");
  };
  return (
    <VerDesgloseStyles>
      <HeaderOpRead
        bankListGuia={bankListGuia}
        contactGuiaList={contactGuiaList}
        nombreNegocio={nombreNegocio}
      />
      {/* Aqui seleccionas un guia */}
      <br />
      <br />
      <div className="container-combobox">
        <SelectListdefault
          style={{
            width: "100%",
            maxWidth: "100%",
          }}
          id="sl6"
          onChange={(e) => {
            setGuideSelected(parseInt(e.target.value));
          }}
        >
          <option value="" disabled selected>
            Guias
          </option>

          {listGuides.length > 0
            ? listGuides.map((item) => {
                return (
                  <option
                    key={item.provider_identifier}
                    value={item.provider_identifier}

                    // selected={
                    //   item.provider_type_identifier ==
                    //   currentGuia.guide_identifier
                    // }
                  >
                    {item.provider_name}
                  </option>
                );
              })
            : null}
        </SelectListdefault>
      </div>
      <br />
      <br />
      {listServicesGuiaPesos.length > 0 && (
        <div className="header-container-desglose">
          <div>
            <h3>Nombre</h3>
          </div>
          <div>
            <h3>Fecha de llegada</h3>
          </div>
          <div>
            <h3>No PAX</h3>
          </div>
          <div>
            <h3>Cantidad</h3>
          </div>
          <div>
            <h3>Tarifa Unitario</h3>
          </div>
          <div>
            <h3>Tarifa Total</h3>
          </div>
          <div>
            <h3>Moneda</h3>
          </div>
        </div>
      )}

      {listServicesGuiaPesos.map((service, index) => {
        return (
          <>
            <ElementReadDesgloce service={service} updatePrice={updatePrice} />
          </>
        );
      })}
      <br />
      {listServicesGuiaPesos.length == 0 ? null : (
        <h3 className="total-prices">
          Total: ${formatearCantidad(totalPesos)} MXN
        </h3>
      )}
      {listServicesGuiaPesos.length == 0 ? null : <br />}

      {listServicesGuiaDolares.length > 0 && (
        <div className="header-container-desglose">
          <div>
            <h3>Nombre</h3>
          </div>
          <div>
            <h3>Fecha de llegada</h3>
          </div>
          <div>
            <h3>No PAX</h3>
          </div>
          <div>
            <h3>Cantidad</h3>
          </div>
          <div>
            <h3>Tarifa Unitario</h3>
          </div>
          <div>
            <h3>Tarifa Total</h3>
          </div>
          <div>
            <h3>Moneda</h3>
          </div>
        </div>
      )}

      {listServicesGuiaDolares.map((service, index) => {
        return (
          <>
            <ElementReadDesgloce service={service} updatePrice={updatePrice} />
          </>
        );
      })}
      <br />
      {listServicesGuiaDolares.length == 0 ? null : (
        <h3 className="total-prices">
          Total: ${formatearCantidad(totalDolares)}USD
        </h3>
      )}
      <div className="buttons" style={{ backgroundColor: "transparent" }}>
        <img
          src={guardardatos}
          alt="leavePage"
          width="45px"
          onClick={saveDesgloce}
          style={{ cursor: "pointer", marginBottom: "1rem" }}
        />
      </div>
    </VerDesgloseStyles>
  );
}

const VerDesgloseStyles = styled.div`
  .container-combobox {
    width: 20%;
  }
  .total-prices {
    font-size: 0.85vw;
  }
  .header-container-desglose {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    border-right: none;
  }
  .header-container-desglose div {
    padding: 0.5rem;
  }
  .header-container-desglose div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
  .header-container-desglose div {
    padding: 0.5rem;
    border-right: black solid 1px;
    border-bottom: none;
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: rgb(8, 166, 165) solid 2px;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  min-height: 38.4px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
