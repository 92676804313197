import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { PaymentDataGastosIC } from "./InterfacesContabilidad";
import DateInput from "../../components/entrys/DateInput";
import { useCatalogs } from "../../../context/CatalogContext";
import { useCatalogsContabilidad } from "../../../context/ContabilidadCatalogs";
import { BankAccountContabilidadI } from "../../../context/ContabilidadCatalogI";
import { coinInterface } from "../../../interfaces/GeneralTypes";
import { bankInterface } from "../../../context/InterfacesContext";
import CustomInputtt from "../../components/entrys/CustomInputtt";
import CustomInputt from "../../components/entrys/CustomInputt";
import { isEqual } from "lodash";
import { AppContext } from "../../../context/appContext";
import { OperationContext } from "../../../context/operationContext";

interface FormPagoGastosI {
  paymentData: PaymentDataGastosIC;
  setPaymentData: React.Dispatch<React.SetStateAction<PaymentDataGastosIC>>;
  saveInfo: () => void;
}
export default function FormPagoGastos({
  paymentData,
  setPaymentData,
  saveInfo,
}: FormPagoGastosI) {
  const { catalogsData } = useCatalogs();
  const { currentUser } = useContext(AppContext);
  const { currentOperation, contabilidadMode } = useContext(OperationContext);
  const { catalogsDataContabilidad } = useCatalogsContabilidad();

  const [localPayment, setLocalPayment] =
    useState<PaymentDataGastosIC>(paymentData);
  const [listCuentas, setListCuentas] = useState<BankAccountContabilidadI[]>(
    []
  );
  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  const [bankListTwo, setBankListTwo] = useState<bankInterface[]>([]);
  useEffect(() => {
    if (!isEqual(paymentData, localPayment)) {
      setLocalPayment(paymentData);
    }
  }, [paymentData]);
  useEffect(() => {
    setPaymentData(localPayment);
  }, [localPayment]);
  useEffect(() => {
    setBankListTwo(catalogsDataContabilidad?.banks_list);
    setCurrencyList(catalogsData?.currency_list);
    setListCuentas(catalogsDataContabilidad?.accounting_bank_accounts_list);
    //console.log("CATALOGS DATA CONTABILIDAD");
    //console.log(catalogsDataContabilidad);
  }, [catalogsDataContabilidad]);
  return (
    <FormPagoGastosStyle>
      <div className="header-contabilidad">
        <br />
        <h3 className="title-contabilidad">Registro para liquidar</h3>
        <br />
        <div className="container-titles headers-title">
          <div>
            {" "}
            <h3>Pagado por </h3>
          </div>
          <div>
            {" "}
            <h3>Clave grupo </h3>
          </div>
          <div>
            {" "}
            <h3></h3>
          </div>
          <div>
            {" "}
            <h3></h3>
          </div>
          <div>
            <h3> </h3>
          </div>
          <div>
            {" "}
            <h3> </h3>
          </div>
        </div>
        <br />
        <div className="container-titles">
          <div>
            {" "}
            <h3>
              {contabilidadMode == 1
                ? currentUser.user_name
                : paymentData.user_identifier}
            </h3>
          </div>
          <div>
            {" "}
            <h3>
              {contabilidadMode == 1
                ? currentOperation.quote.name
                : paymentData.alias}
            </h3>
          </div>
          <div>
            {" "}
            <h3>{""}</h3>
          </div>
          <div>
            {" "}
            <h3>{} </h3>
          </div>
          <div>
            <h3>{}</h3>
          </div>
          <div>
            {" "}
            <h3>{} </h3>
          </div>
        </div>
        {/* <div className="container-titles">
          <div>
            {" "}
            <h3>{currentOperation.quote.name} </h3>
          </div>
          <div>
            {" "}
            <h3>{currentOperation.quote.agency}</h3>
          </div>
          <div>
            {" "}
            <h3>{inicialData.provider_type} </h3>
          </div>
          <div>
            <h3>{inicialData.provider_name}</h3>
          </div>
          <div>
            {" "}
            <h3>{inicialData.key} </h3>
          </div>
        </div> */}
      </div>

      <br />
      <br />
      <div>
        <div>
          <h3></h3>
        </div>
      </div>
      <div>
        <div className="box-finantial-data">
          <DateInput
            text={true ? "Fecha de pago" : ""}
            txtWidth={100}
            setGeneralData={setLocalPayment}
            field="payment_date"
            value={localPayment.payment_date}
          />
          <SelectListdefault
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
            id="sl6"
            onChange={(e) => {
              //console.log("BANK IDENTIFIER SELECTED: ");
              //console.log(e.target.value);
              //   setCardInfo({
              //     ...cardInfo,
              //     currency_element: parseInt(e.target.value),
              //   });
              // setProviderLocalInfo({
              //   ...providerLocalInfo,
              //   currency_identifier: parseInt(e.target.value),
              // });
              setLocalPayment({
                ...localPayment,
                currency_identifier: parseInt(e.target.value),
              });
            }}
            // disabled={!editionMode}
            value={localPayment.currency_identifier || ""}
          >
            <option className="default-opcion" value="" disabled selected>
              Moneda
            </option>

            {currencyList?.length > 0
              ? currencyList.map((item) => {
                  return (
                    <option
                      key={item.identifier}
                      value={item.identifier}
                      // selected={item.identifier == peo}
                    >
                      {item.description}
                    </option>
                  );
                })
              : null}
          </SelectListdefault>

          <CustomInputtt<PaymentDataGastosIC>
            text="Cantidad pagada"
            txtWidth={100}
            setGeneralData={setLocalPayment}
            field="total_payment"
            value={localPayment.total_payment}
            typeData="number"
          />
          <SelectListdefault
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
            id="sl6"
            onChange={(e) => {
              setLocalPayment({
                ...localPayment,
                bank_identifier: parseInt(e.target.value),
              });
            }}
            value={localPayment.bank_identifier || ""}
            // disabled={!editionMode}
          >
            <option value="" disabled selected>
              Banco
            </option>

            {bankListTwo?.length > 0
              ? bankListTwo.map((item) => {
                  return (
                    <option
                      key={item.identifier}
                      value={item.identifier}
                      //   selected={item.identifier == cardInfo.bank_identifier}
                    >
                      {item.description}
                    </option>
                  );
                })
              : null}
          </SelectListdefault>
          <SelectListdefault
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
            id="sl6"
            onChange={(e) => {
              // setProviderLocalInfo({
              //   ...providerLocalInfo,
              //   account_identifier: parseInt(e.target.value),
              // });
              setLocalPayment({
                ...localPayment,
                bank_account_identifier: parseInt(e.target.value),
              });
            }}
            value={localPayment.bank_account_identifier || ""}
            // disabled={!editionMode}
          >
            <option value="" disabled selected>
              Cuentas de banco
            </option>

            {listCuentas?.length > 0
              ? listCuentas.map((item) => {
                  return (
                    <option
                      key={item.bank_account_identifier}
                      value={item.bank_account_identifier}
                      //   selected={item.identifier == cardInfo.bank_identifier}
                    >
                      {item.bank_account}
                    </option>
                  );
                })
              : null}
          </SelectListdefault>
        </div>

        <br />
        <div className="label-container">
          <CustomInputt<PaymentDataGastosIC>
            text="A favor de"
            txtWidth={100}
            setGeneralData={setLocalPayment}
            field="business_name"
            value={localPayment.business_name}
            extraFunction={saveInfo}
          />
          <br />
          <CustomInputt<PaymentDataGastosIC>
            text="Concepto"
            txtWidth={100}
            setGeneralData={setLocalPayment}
            field="concept"
            value={localPayment.concept}
            extraFunction={saveInfo}
          />
          <br />
        </div>
        <div className="data-aditional">
          <CustomInputt<PaymentDataGastosIC>
            text="Número de poliza"
            txtWidth={100}
            setGeneralData={setLocalPayment}
            field="policy"
            value={localPayment.policy}
            extraFunction={saveInfo}
          />

          <CustomInputtt<PaymentDataGastosIC>
            text="Tipo de cambio"
            txtWidth={100}
            setGeneralData={setLocalPayment}
            field="exchange_rate"
            value={localPayment.exchange_rate}
            typeData="number"
            extraFunction={saveInfo}
          />
          {/* <CustomInputt<PaymentDataGastosIC>
            text="Pagado por"
            txtWidth={100}
            setGeneralData={setLocalPayment}
            field="comment"
            value={localPayment.comment}
          /> */}
        </div>

        <div className="box-field">
          <h3>Comentarios</h3>
          <textarea
            className="text-container"
            name={""}
            id={""}
            onChange={(e) => {
              //   setContractRules({
              //     ...contractRules,
              //     observations: e.target.value
              //   })
              //   setContratoContext({
              //     ...contratoContext,
              //     observations: e.target.value
              //   })
              setLocalPayment({
                ...localPayment,
                comment: e.target.value,
              });
            }}
            value={localPayment.comment}
          />
          <br />
          <br />
          <br />
        </div>
      </div>
    </FormPagoGastosStyle>
  );
}

const FormPagoGastosStyle = styled.div`
  .header-contabilidad {
    width: 85%;
    margin: 0 auto;
  }
  .header-contabilidad h3 {
    font-weight: 300;
    font-size: 0.85vw;
  }
  .title-contabilidad {
    color: rgb(8, 166, 165);
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
  .data-aditional {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 85%;
    grid-column-gap: 1rem;
    margin: 0 auto;
    justify-items: left;
  }
  .container-titles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .container-titles div h3 {
    text-align: center;
  }
  .label-container {
    width: 85%;
    margin: 0 auto;
  }
  .headers-title h3 {
    font-weight: 600 !important;
    color: rgb(8, 166, 165);
  }
  .box-finantial-data {
    width: 85%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    justify-items: left;
  }
  .box-fields {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
  }

  .box-field {
    width: 85%;
    margin: 0 auto;
  }
  .box-field h3 {
    color: rgb(8, 166, 165);
    margin-bottom: 1rem;
  }
  .text-container {
    resize: none;
    width: 100%;
    height: 200px;
    padding: 0.9rem;
    border: rgb(8, 166, 165) solid 2px;
    outline: none;
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  .fake-icon {
    max-width: 10px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
