import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import {
  ApiResponseOpcionalI,
  OpcionalCotizacion,
  OptionalQuote,
} from "./interfaces/CotizacionI";
import agregarIcon from "../../../images/AGREGAR.png";
import { v4 as uuidv4 } from "uuid";
import ElementOpcional from "./ElementOpcional";
import { AppContext } from "../../../context/appContext";
import {
  CommentI,
  GeneralServiceI,
  IndividualServiceI,
  QuoteObjectI,
  ServiceIndividualI,
} from "../../../interfaces/GeneralTypes";
export default function ListOpcionales() {
  let identifierService = uuidv4();
  const {
    listOpcionalesContext,
    setListOpcionalesContext,
    dataCotizacionContext,
    currentCurrency,
    cotizationExtraProperties,
    cotizacionMode,
  } = useContext(AppContext);
  const [listElementosOpcionales, setElementosOpcionales] = useState<
    OptionalQuote[]
  >(listOpcionalesContext);

  const addNewOpcional = () => {
    let identifierService = uuidv4();
    const newElementOpcional = {
      identifier: 0,
      name: "",
      total_base_sgl: 0,
      total_base_dbl: 0,
      total_base_tpl: 0,
      arrival_date: "",
      departure_date: "",
      id_local: identifierService,
      pax: 0,
      optional_group_identifier: 0,
      status_identifier: 1,
      optional_quote_identifier: 0,
    };
    setElementosOpcionales([...listElementosOpcionales, newElementOpcional]);
  };

  const deleteServer = async (id_currentOpcional: string) => {
    const serviciosIndivualesRequest: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (serviceDay, index) => {
          const currentIndividualServices: ServiceIndividualI[] =
            serviceDay.serviciosIndividuales.map((serviceD, index) => {
              const serviceIndividual: ServiceIndividualI = {
                service_type_identifier: serviceD.serviceType,
                occupation: serviceD.ocupacionDescripcion,
                provider_identifier: serviceD.providerType,
                service_identifier: serviceD.service_name_identifier,
                category_identifier: 1,
                fee: serviceD.tarifa,
                IsActive: true,
                single_complement:
                  serviceD.tarifaSingleComplementConverted ?? 0,
                triple_complement: serviceD.tarifaTripleSuplementConverted ?? 0,
                individual_service_identifier:
                  serviceD.individual_service_identifier ?? 0,
                status_identifier: serviceD.status_identifier ?? 1,
              };
              return serviceIndividual;
            });

          const individualService: IndividualServiceI = {
            service_date: serviceDay.validityStartDay,
            service_tittle: serviceDay.travelRoute,
            services: currentIndividualServices,
            isActive: true,
            service_identifier: 0,
            status_identifier: 1,
          };
          return individualService;
        }
      );

    const general_transport_services: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: sI.serviceType,
                occupation: sI.ocupacionDescripcion,
                provider_identifier: sI.providerType,
                service_identifier: sI.service_name_identifier,
                category_identifier: 2,
                fee: sI.tarifa,
                IsActive: true,
                single_complement: 20,
                triple_complement: 10,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier: sI.status_identifier ?? 1,
                number_of_services: sI.number_of_services ?? 1,
                fee_identifier: sI.fee_identifier,
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: currentService.service_identifier,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );

    const serviciosIndivuales: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: currentService.serviceType,
                occupation: sI.ocupacionDescripcion,
                provider_identifier: sI.providerType,
                service_identifier: sI.service_name_identifier,
                category_identifier: 2,
                fee: sI.tarifa,
                IsActive: true,
                single_complement: sI.tarifaSingleComplementConverted ?? 0,
                triple_complement: sI.tarifaTripleSuplementConverted ?? 0,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier: sI.status_identifier ?? 1,
                fee_identifier: sI.fee_identifier,
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: currentService.service_identifier,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );

    const serviciosGenerales: GeneralServiceI[] =
      dataCotizacionContext.generalServices
        .map((currentService, index) => {
          const generalService: GeneralServiceI = {
            lender_identifier: currentService.service_type,
            provider_identifier: currentService.provider_id ?? 0,
            service_identifier: currentService.service_name_id ?? 0,
            fee: currentService.fee_price,
            number_of_services: parseFloat(currentService.number_days),
            service_total:
              currentService.fee_price * parseFloat(currentService.number_days),
            general_service_identifier:
              currentService.general_service_identifier,
            status_identifier: currentService.status_identifier,
            fee_identifier: currentService.fee_identifier ?? 0,
          };
          return generalService;
        })
        .filter((currentService, index) => {
          return (
            currentService.fee != 0 && currentService.number_of_services != 0
          );
        });
    const dataCommentsArray: CommentI[] =
      dataCotizacionContext.feeCharges.comments.map((currentComment, index) => {
        return {
          comment_identifier: currentComment.comment_identifier,
          comment: currentComment.comment,
          comment_date: currentComment.comment_date,
          status_identifier: currentComment.status_identifier,
          commentIdentifier: currentComment.comment_identifier.toString(),
        };
      });

    const listCotizacionesOpcionalesToSend: OpcionalCotizacion[] =
      listOpcionalesContext
        .filter((opcional, index) => {
          return opcional.id_local == id_currentOpcional;
        })
        .map((opcional, index) => {
          const currentOpcionalCotizacion: OpcionalCotizacion = {
            quote_identifier: opcional.optional_quote_identifier,
            optional_group_identifier: opcional.optional_group_identifier,
            status_identifier: 2,
          };
          return currentOpcionalCotizacion;
        });
    //console.log("ELEMENTO A ELIMINAR");
    //console.log(listCotizacionesOpcionalesToSend);

    listOpcionalesContext
      .map((opcionalCotizacion, indice) => {
        const currentOpcionalCotizacion: OpcionalCotizacion = {
          quote_identifier: opcionalCotizacion.identifier,
          optional_group_identifier:
            opcionalCotizacion.optional_group_identifier,
          status_identifier: 1,
        };
        return currentOpcionalCotizacion;
      })
      .filter((opcional, index) => {
        return opcional.optional_group_identifier == 0;
      });

    const bodySaveRequest: QuoteObjectI = {
      user_identifier: 1,
      quote: {
        private_quote: true,
        name: dataCotizacionContext.personalInfo.name,
        type: dataCotizacionContext.personalInfo.typeCotizacion,
        agency: dataCotizacionContext.personalInfo.agencia,
        series: dataCotizacionContext.personalInfo.serieId,
        PAX: dataCotizacionContext.personalInfo.pax,
        TL: dataCotizacionContext.personalInfo.TL,
        MIN: dataCotizacionContext.personalInfo.min,
        MAX: dataCotizacionContext.personalInfo.max,
        arrival_date:
          dataCotizacionContext.personalInfo.arrivingData == "arrivingData"
            ? ""
            : dataCotizacionContext.personalInfo.arrivingData,
        departure_date:
          dataCotizacionContext.personalInfo.departureDate == "departureData"
            ? ""
            : dataCotizacionContext.personalInfo.departureDate,
        currency_identifier: currentCurrency,
        commission: dataCotizacionContext.feeCharges.comision,
        commission_auto: dataCotizacionContext.feeCharges.comisonAuto,
        courtesy: dataCotizacionContext.feeCharges.cortesia,
        exchange_rate: dataCotizacionContext.feeCharges.changeType,
        comments: dataCotizacionContext.feeCharges.comments,
        total: dataCotizacionContext.totalPrice.totalPrice,
        quote_register_type: dataCotizacionContext.personalInfo.typeCotizacion,
        number_of_days: dataCotizacionContext.personalInfo.numberDaysOpenQuote,
        serie:
          dataCotizacionContext.personalInfo.modeCotizacion == "Serie"
            ? true
            : false,
        status_identifier: dataCotizacionContext.personalInfo.status_identifier,
        quote_identifier: dataCotizacionContext.personalInfo.quote_identifier,
        courtesy_type_identifier:
          dataCotizacionContext.feeCharges.courtesy_type_identifier,
        total_base_sgl: cotizationExtraProperties.total_base_sgl,
        total_base_dbl: cotizationExtraProperties.total_base_dbl,
        total_base_tpl: cotizationExtraProperties.total_base_tpl,
        simple_supplement: cotizationExtraProperties.simple_supplement,
        triple_reduction: cotizationExtraProperties.triple_reduction,
        optional_quote:
          dataCotizacionContext.personalInfo.modeCotizacion == "Opcional"
            ? true
            : false,
      },
      quote_general_services: serviciosGenerales,
      quote_individual_services: serviciosIndivuales,
      general_transport_services: general_transport_services,
      optional_groups: listCotizacionesOpcionalesToSend,
    };

    const urlModified: string = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/update`;

    try {
      const response = await fetch(urlModified, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodySaveRequest),
      });

      if (response.ok) {
        const data = await response.json();

        alert("Elemento borrado");
        // setListOpcionalesContext([]); ?????? Esto qué?
        if (cotizacionMode == 3 || cotizacionMode == 4) {
          deleteOptionalsFromOperationsAndStuff(id_currentOpcional);
        }
      } else {
        alert("El elemento no se ha borrado");
      }
    } catch (error) {
      ///ERROR AL REALIZAR PETICION
      alert("Error al borrar en servidor");
    }
    return;
  };
  useEffect(() => {
    setListOpcionalesContext(listElementosOpcionales);
  }, [listElementosOpcionales]);
  useEffect(() => {
    //console.log("Actualizando contexto de opcionales::");
    //console.log(listOpcionalesContext);
  }, [listOpcionalesContext]);
  const deleteOptionalsFromOperationsAndStuff = async (
    id_currentOpcional: string
  ) => {
    const serviciosIndivualesRequest: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (serviceDay, index) => {
          const currentIndividualServices: ServiceIndividualI[] =
            serviceDay.serviciosIndividuales.map((serviceD, index) => {
              const serviceIndividual: ServiceIndividualI = {
                service_type_identifier: serviceD.serviceType,
                occupation: serviceD.ocupacionDescripcion,
                provider_identifier: serviceD.providerType,
                service_identifier: serviceD.service_name_identifier,
                category_identifier: 1,
                fee: serviceD.tarifa,
                IsActive: true,
                single_complement:
                  serviceD.tarifaSingleComplementConverted ?? 0,
                triple_complement: serviceD.tarifaTripleSuplementConverted ?? 0,
                individual_service_identifier:
                  serviceD.individual_service_identifier ?? 0,
                status_identifier: serviceD.status_identifier ?? 1,
              };
              return serviceIndividual;
            });

          const individualService: IndividualServiceI = {
            service_date: serviceDay.validityStartDay,
            service_tittle: serviceDay.travelRoute,
            services: currentIndividualServices,
            isActive: true,
            service_identifier: 0,
            status_identifier: 1,
          };
          return individualService;
        }
      );

    const general_transport_services: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElementsTransporte.servicioDayElement.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: sI.serviceType,
                occupation: sI.ocupacionDescripcion,
                provider_identifier: sI.providerType,
                service_identifier: sI.service_name_identifier,
                category_identifier: 2,
                fee: sI.tarifa,
                IsActive: true,
                single_complement: 20,
                triple_complement: 10,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier: sI.status_identifier ?? 1,
                number_of_services: sI.number_of_services ?? 1,
                fee_identifier: sI.fee_identifier,
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: currentService.service_identifier,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );

    const serviciosIndivuales: IndividualServiceI[] =
      dataCotizacionContext.serviciosDayElements.servicioDayElement.map(
        (currentService, index) => {
          const individualServices: ServiceIndividualI[] =
            currentService.serviciosIndividuales.map((sI, index) => {
              const servicioI: ServiceIndividualI = {
                service_type_identifier: currentService.serviceType,
                occupation: sI.ocupacionDescripcion,
                provider_identifier: sI.providerType,
                service_identifier: sI.service_name_identifier,
                category_identifier: 2,
                fee: sI.tarifa,
                IsActive: true,
                single_complement: sI.tarifaSingleComplementConverted ?? 0,
                triple_complement: sI.tarifaTripleSuplementConverted ?? 0,
                individual_service_identifier:
                  sI.individual_service_identifier ?? 0,
                status_identifier: sI.status_identifier ?? 1,
                fee_identifier: sI.fee_identifier,
              };
              return servicioI;
            });

          const servicioIndividual: IndividualServiceI = {
            service_date: currentService.validityStartDay,
            service_tittle: currentService.travelRoute,
            services: individualServices,
            isActive: true,
            service_identifier: currentService.service_identifier,
            status_identifier: 1,
          };

          return servicioIndividual;
        }
      );

    const serviciosGenerales: GeneralServiceI[] =
      dataCotizacionContext.generalServices
        .map((currentService, index) => {
          const generalService: GeneralServiceI = {
            lender_identifier: currentService.service_type,
            provider_identifier: currentService.provider_id ?? 0,
            service_identifier: currentService.service_name_id ?? 0,
            fee: currentService.fee_price,
            number_of_services: parseFloat(currentService.number_days),
            service_total:
              currentService.fee_price * parseFloat(currentService.number_days),
            general_service_identifier:
              currentService.general_service_identifier,
            status_identifier: currentService.status_identifier,
            fee_identifier: currentService.fee_identifier ?? 0,
          };
          return generalService;
        })
        .filter((currentService, index) => {
          return (
            currentService.fee != 0 && currentService.number_of_services != 0
          );
        });
    const dataCommentsArray: CommentI[] =
      dataCotizacionContext.feeCharges.comments.map((currentComment, index) => {
        return {
          comment_identifier: currentComment.comment_identifier,
          comment: currentComment.comment,
          comment_date: currentComment.comment_date,
          status_identifier: currentComment.status_identifier,
          commentIdentifier: currentComment.comment_identifier.toString(),
        };
      });

    const listCotizacionesOpcionalesToSend: OpcionalCotizacion[] =
      listOpcionalesContext
        .filter((opcional, index) => {
          return opcional.id_local == id_currentOpcional;
        })
        .map((opcional, index) => {
          const currentOpcionalCotizacion: OpcionalCotizacion = {
            quote_identifier: opcional.optional_quote_identifier,
            optional_group_identifier: opcional.optional_group_identifier,
            status_identifier: 2,
          };
          return currentOpcionalCotizacion;
        });
    //console.log("ELEMENTO A ELIMINAR");
    //console.log(listCotizacionesOpcionalesToSend);

    listOpcionalesContext
      .map((opcionalCotizacion, indice) => {
        const currentOpcionalCotizacion: OpcionalCotizacion = {
          quote_identifier: opcionalCotizacion.identifier,
          optional_group_identifier:
            opcionalCotizacion.optional_group_identifier,
          status_identifier: 1,
        };
        return currentOpcionalCotizacion;
      })
      .filter((opcional, index) => {
        return opcional.optional_group_identifier == 0;
      });

    const bodySaveRequest: QuoteObjectI = {
      user_identifier: 1,
      quote: {
        private_quote: true,
        name: dataCotizacionContext.personalInfo.name,
        type: dataCotizacionContext.personalInfo.typeCotizacion,
        agency: dataCotizacionContext.personalInfo.agencia,
        series: dataCotizacionContext.personalInfo.serieId,
        PAX: dataCotizacionContext.personalInfo.pax,
        TL: dataCotizacionContext.personalInfo.TL,
        MIN: dataCotizacionContext.personalInfo.min,
        MAX: dataCotizacionContext.personalInfo.max,
        arrival_date:
          dataCotizacionContext.personalInfo.arrivingData == "arrivingData"
            ? ""
            : dataCotizacionContext.personalInfo.arrivingData,
        departure_date:
          dataCotizacionContext.personalInfo.departureDate == "departureData"
            ? ""
            : dataCotizacionContext.personalInfo.departureDate,
        currency_identifier: currentCurrency,
        commission: dataCotizacionContext.feeCharges.comision,
        commission_auto: dataCotizacionContext.feeCharges.comisonAuto,
        courtesy: dataCotizacionContext.feeCharges.cortesia,
        exchange_rate: dataCotizacionContext.feeCharges.changeType,
        comments: dataCotizacionContext.feeCharges.comments,
        total: dataCotizacionContext.totalPrice.totalPrice,
        quote_register_type: dataCotizacionContext.personalInfo.typeCotizacion,
        number_of_days: dataCotizacionContext.personalInfo.numberDaysOpenQuote,
        serie:
          dataCotizacionContext.personalInfo.modeCotizacion == "Serie"
            ? true
            : false,
        status_identifier: dataCotizacionContext.personalInfo.status_identifier,
        quote_identifier: dataCotizacionContext.personalInfo.quote_identifier,
        courtesy_type_identifier:
          dataCotizacionContext.feeCharges.courtesy_type_identifier,
        total_base_sgl: cotizationExtraProperties.total_base_sgl,
        total_base_dbl: cotizationExtraProperties.total_base_dbl,
        total_base_tpl: cotizationExtraProperties.total_base_tpl,
        simple_supplement: cotizationExtraProperties.simple_supplement,
        triple_reduction: cotizationExtraProperties.triple_reduction,
        optional_quote:
          dataCotizacionContext.personalInfo.modeCotizacion == "Opcional"
            ? true
            : false,
      },
      quote_general_services: serviciosGenerales,
      quote_individual_services: serviciosIndivuales,
      general_transport_services: general_transport_services,
      optional_groups: listCotizacionesOpcionalesToSend,
    };

    const urlModified: string = `${process.env.REACT_APP_BACKEND_ENDPOINT}/quote/optionalsOpsUpdate`;

    try {
      const response = await fetch(urlModified, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodySaveRequest),
      });

      if (response.ok) {
        const data = await response.json();

        alert("Elemento borrado");
        // setListOpcionalesContext([]); ?????? Esto qué?
        // alert("cambbio");
      } else {
        alert("El elemento no se ha borrado");
      }
    } catch (error) {
      ///ERROR AL REALIZAR PETICION
      alert("Error al borrar en servidor");
    }
  };

  const deleteCurrentOpcional = (id_currentOpcional: string) => {
    const newArreglo = listElementosOpcionales.filter((opcional, index) => {
      return opcional.id_local !== id_currentOpcional;
    });

    const findToDelete = listElementosOpcionales.find((opcional, index) => {
      return opcional.id_local == id_currentOpcional;
    })?.optional_group_identifier;
    //console.log("Valor opcional");
    //console.log(findToDelete);
    //console.log("newArreglo");
    //console.log(newArreglo);
    if (findToDelete) {
      if (findToDelete !== 0) {
        deleteServer(id_currentOpcional);
      }
    }

    setElementosOpcionales(newArreglo);
  };

  const editCurrentElement = (id_currentOpcional: string) => {
    const newArreglo = listElementosOpcionales.map((opcional, index) => {
      return opcional.id_local !== id_currentOpcional
        ? opcional
        : {
            ...opcional,
            identifier: opcional.identifier,
            name: opcional.name,
            total_base_sgl: opcional.total_base_sgl,
            total_base_dbl: opcional.total_base_dbl,
            total_base_tpl: opcional.total_base_tpl,
            arrival_date: opcional.arrival_date,
            departure_date: opcional.departure_date,
          };
    });
    setElementosOpcionales(newArreglo);
  };
  return (
    <ListOpcionalesStyle>
      <div className="container-table-opcionales">
        <div className="header-table">
          <div>
            <h3>Cotización opcional</h3>
          </div>
          <div>
            <h3>SGL</h3>
          </div>
          <div>
            <h3>DBL</h3>
          </div>
          <div>
            <h3>TPL</h3>
          </div>
          <div>
            <h3>Fecha</h3>
          </div>
          <div>
            <h3>Pax</h3>
          </div>
          <div>
            {true && (
              <div className="non-printable-section">
                <img
                  src={agregarIcon}
                  alt=""
                  onClick={addNewOpcional}
                  width={25}
                />
              </div>
            )}
          </div>
        </div>
        {listElementosOpcionales.map((opcional, index) => {
          return (
            <ElementOpcional
              key={opcional.id_local}
              addNewOpcional={addNewOpcional}
              deleteCurrentOpcional={deleteCurrentOpcional}
              opcional={opcional}
              non-printable-section
              editCurrentElement={editCurrentElement}
              listElementosOpcionales={listElementosOpcionales}
              setElementosOpcionales={setElementosOpcionales}
            />
          );
        })}
      </div>
    </ListOpcionalesStyle>
  );
}

const ListOpcionalesStyle = styled.div`
  .container-table-opcionales {
  }
  .header-table {
    border: black solid 1px;
    width: 70%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    margin: 0 auto;
    padding: 0.5rem;
    background-color: rgb(255, 159, 0, 0.8);
    color: white;
    align-items: center;
    justify-content: center;
  }
  .header-table div h3 {
    font-size: 0.85vw;
    text-align: center;
  }
  margin-bottom: 5rem;
`;
