import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import {
  Item,
  PaymentDataResponse,
  ProviderReembolso,
  RefundI,
} from "../InterfacesContabilidad";
import { fetchServer } from "../../../../services/fetchServer";
import { AppContext } from "../../../../context/appContext";
import CustomInputt from "../../../components/entrys/CustomInputt";
import CustomInputtt from "../../../components/entrys/CustomInputtt";
import {
  RefundReason,
  RefundStatus,
} from "../../../../context/ContabilidadCatalogI";
import { useCatalogsContabilidad } from "../../../../context/ContabilidadCatalogs";
import DateInput from "../../../components/entrys/DateInput";
import { useCatalogs } from "../../../../context/CatalogContext";
import { Currency } from "../../../../interfaces/GeneralTypes";
interface FormReembolsoI {
  isShowingCurrentCard: boolean;
  setIsShowingCurrentCard: React.Dispatch<React.SetStateAction<boolean>>;
  currentDay?: Item;
  getInitialData: () => Promise<void>;
  modeSwitch: number;
}
const fechaActual: Date = new Date();
// Obtiene los componentes de la fecha
const año: number = fechaActual.getFullYear();
const mes: number = fechaActual.getMonth() + 1; // ¡Recuerda que los meses comienzan desde 0!
const dia: number = fechaActual.getDate();

// Formatea la fecha en el formato deseado
const fechaFormateada: string = `${año}-${mes < 10 ? "0" : ""}${mes}-${
  dia < 10 ? "0" : ""
}${dia}`;

export default function FormReembolso({
  isShowingCurrentCard,
  setIsShowingCurrentCard,
  currentDay,
  getInitialData,
  modeSwitch,
}: FormReembolsoI) {
  const { catalogsDataContabilidad } = useCatalogsContabilidad();
  const { catalogsData } = useCatalogs();
  const [currencyList, setCurrencyList] = useState<Currency[]>([]);
  const { setShowLoader, currentUser } = useContext(AppContext);
  const [listRefundStatus, setListRefundStatus] = useState<RefundStatus[]>([]);
  const [listReasonRefund, setListReasonRefund] = useState<RefundReason[]>([]);
  const [listAgencyRefund, setListAgencyRefund] = useState<RefundReason[]>([]);
  const [listProveedores, setListProveedores] = useState<ProviderReembolso[]>(
    []
  );
  const [dataFormReembolso, setDataFormReembolso] = useState<RefundI>({
    refund_identifier: 0, // solo en update, de lo contrario es cero (id Refund)
    refund_status_identifier: 0, //prestador de servicioo
    provider_identifier: 0, ////Viene de la busqueda de poliza
    payment_identifier: 0, ////monto del servicio
    real_amount: 0, ///saldo a favor
    positive_balance: 0, ///por ahora mandarlo siempre en 1 (al menos el primer bloque)
    refund_type_identifier: modeSwitch, ////motivo (catalogo)
    reason_identifier: 0, ////estatus pendiente o pagado
    comment: "", ///comentario
    poliza_number: "",
    date_pago: fechaFormateada,
    payment_concept: "",
    currency: "",
    user: currentUser.user_name,
    monto_pagado: 0,
    agency: "",
    currency_identifier: 1,
  });
  useEffect(() => {
    setCurrencyList(catalogsData.currency_list);
  }, [catalogsData]);
  useEffect(() => {
    setListReasonRefund(catalogsDataContabilidad.provider_refund_reason_list);
    setListRefundStatus(catalogsDataContabilidad.refund_status_list);
    setListAgencyRefund(catalogsDataContabilidad.agency_refund_reason_list);
  }, [catalogsDataContabilidad]);
  const manejarPresionarEsc = () => {
    // Coloca aquí la lógica que deseas ejecutar al presionar Esc
    //console.log("Esc presionada");
    setIsShowingCurrentCard(false);
  };
  useEffect(() => {
    const manejarTeclaPresionada = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        manejarPresionarEsc();
      }
    };

    // Agrega el event listener cuando el componente se monta
    document.addEventListener("keydown", manejarTeclaPresionada);

    // Remueve el event listener cuando el componente se desmonta
    return () => {
      document.removeEventListener("keydown", manejarTeclaPresionada);
    };
  }, []);

  const modifyData = async () => {
    try {
      setShowLoader(true);
      const resJson = await fetchServer(
        `/accounting/refunds/update`,
        "post",
        dataFormReembolso,
        {
          headers: {
            "Custom-Header": "ValorPersonalizado",
            user_identifier: currentUser.user_identifier.toString(),
          },
        }
      );
      //console.log("Refund GUARDADO INICIAL:");
      //console.log(resJson);

      setShowLoader(false);
      getInitialData();
      setIsShowingCurrentCard(false);
    } catch (e) {
      setShowLoader(false);
    }
  };
  const postNewRefund = async () => {
    if (currentDay) {
      modifyData();

      return;
    }
    const bodyRefund: RefundI = {
      refund_identifier: 0, // solo en update, de lo contrario es cero (id Refund)
      refund_status_identifier: 1, //prestador de servicioo
      provider_identifier: 1, ////Viene de la busqueda de poliza
      payment_identifier: 0, ////monto del servicio
      real_amount: 200, ///saldo a favor
      positive_balance: 300, ///por ahora mandarlo siempre en 1 (al menos el primer bloque)
      refund_type_identifier: 1, ////motivo (catalogo)
      reason_identifier: 1, ////estatus pendiente o pagado
      comment: "", ///comentario
      poliza_number: "",
      date_pago: "",
      payment_concept: "",
      currency: "",
      user: "",
      monto_pagado: 0,
      agency: "",
      currency_identifier: 1,
    };
    //     //console.log("DAto a enviar");
    // //console.log(dataFormReembolso)
    //     return;
    try {
      setShowLoader(true);
      const resJson = await fetchServer(
        `/accounting/refunds/register`,
        "post",
        dataFormReembolso,
        {
          headers: {
            "Custom-Header": "ValorPersonalizado",
            user_identifier: currentUser.user_identifier.toString(),
          },
        }
      );
      //console.log("Refund GUARDADO INICIAL:");
      //console.log(resJson);
      setShowLoader(false);
      setIsShowingCurrentCard(false);
      getInitialData();
    } catch (e) {
      setShowLoader(false);
    }
  };
  /////Encontrar poliza después de igual a 5 digitos
  useEffect(() => {
    if (dataFormReembolso.poliza_number.length == 10) {
      //console.log("Traer busqueda de proveedor");

      getPagoInformationFromPoliza(dataFormReembolso.poliza_number);
    }
  }, [dataFormReembolso.poliza_number]);

  const getPagoInformationFromPoliza = async (poliza) => {
    try {
      setShowLoader(true);
      const informacionPago: PaymentDataResponse = await fetchServer(
        `/accounting/refunds/search/${dataFormReembolso.poliza_number}`
      );
      //console.log(informacionPago);
      //console.log(informacionPago.data.payment_identifier);
      //console.log(informacionPago.data.policy);
      //console.log(informacionPago.data.providers);
      setListProveedores(informacionPago.data.providers);
      setShowLoader(false);
      setDataFormReembolso({
        ...dataFormReembolso,
        payment_identifier: informacionPago.data.payment_identifier,
      });
      if (informacionPago.data.providers) {
        if (informacionPago.data.providers.length == 1) {
          //console.log("DATOS ");
          //console.log(informacionPago);
          //console.log(informacionPago.data.providers);

          setDataFormReembolso({
            ...dataFormReembolso,
            provider_identifier:
              informacionPago.data.providers[0].provider_identifier,
          });

          setDataFormReembolso({
            ...dataFormReembolso,
            provider_identifier:
              informacionPago.data.providers[0].provider_identifier, ////Viene de la busqueda de poliza
            payment_identifier: informacionPago.data.payment_identifier, ////monto del servicio
            date_pago: fechaFormateada,
            currency: informacionPago.data.providers[0].currency,
            user: currentUser.user_name,
            monto_pagado: informacionPago.data.providers[0].total_payment,
            currency_identifier:
              informacionPago.data.providers[0].currency_identifier,
            payment_concept: informacionPago.data.providers[0].concept,
          });
        }
      }
    } catch (e) {
      alert("No se encontró poliza");
    }
  };
  // useEffect(() => {
  //   //console.log("Se actualizó el formulario:");
  //   //console.log(dataFormReembolso);
  // }, [dataFormReembolso]);

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    if (currentDay) {
      setDataFormReembolso({
        refund_identifier: currentDay.refund_identifier, // solo en update, de lo contrario es cero (id Refund)
        refund_status_identifier: currentDay.refund_status_identifier, //prestador de servicioo
        provider_identifier: currentDay.provider_identifier, ////Viene de la busqueda de poliza
        payment_identifier: currentDay.payment_identifier, ////monto del servicio
        real_amount: currentDay.real_amount, ///saldo a favor
        positive_balance: currentDay.positive_balance, ///por ahora mandarlo siempre en 1 (al menos el primer bloque)
        refund_type_identifier: currentDay.refund_type_identifier, ////motivo (catalogo)
        reason_identifier: currentDay.reason_identifier, ////estatus pendiente o pagado
        comment: currentDay.comment, ///comentario
        poliza_number: currentDay.policy,
        date_pago: currentDay.creation_date,
        payment_concept: currentDay.concept,
        currency: currentDay.currency,
        user: currentDay.user,
        monto_pagado: currentDay.total_payment,
        agency: currentDay.agency,
        currency_identifier: currentDay.currency_identifier,
      });
      return;
    } else {
      return;
    }
  }, []);
  useEffect(() => {}, [dataFormReembolso.provider_identifier]);
  const fillExtraData = (proveedor_id: number) => {
    ////Encontrar coincidencia de proveedor seleccionado con proveedor de la lista de proveedores
    const arraySeleccionadoProveedor: ProviderReembolso[] =
      listProveedores.filter((objeto, index) => {
        return objeto.provider_identifier == proveedor_id;
      });

    if (arraySeleccionadoProveedor.length == 1) {
      const objetoProveedor: ProviderReembolso = arraySeleccionadoProveedor[0];
      setDataFormReembolso({
        ...dataFormReembolso,
        payment_concept: objetoProveedor.concept,
        monto_pagado: objetoProveedor.total_payment,
        currency: objetoProveedor.currency,
        provider_identifier: objetoProveedor.provider_identifier,
      });
    }
  };
  return (
    <FormReembolsoStyle>
      <div className="box-formulario-shadow">aa</div>
      <div className="box-formulario">
        <div className="first-box">
          <h3 className="title-reembolsos-box">Saldos a favor y reembolsos</h3>
          <FontAwesomeIcon
            icon={faClose}
            className="icon-close"
            onClick={() => {
              setIsShowingCurrentCard(!isShowingCurrentCard);
            }}
          />
        </div>
        {modeSwitch == 1 && (
          <div className="header-info-dos">
            <div>
              <h3 className="subtitle-info">Fecha</h3>
              <h3>{convertirFormatoFecha(dataFormReembolso.date_pago)}</h3>
            </div>
            <div>
              <h3 className="subtitle-info">Concepto del pago</h3>
              <h3>{dataFormReembolso.payment_concept}</h3>
            </div>
            <div>
              <h3 className="subtitle-info">Monto pagado</h3>
              <h3>{dataFormReembolso.monto_pagado}</h3>
            </div>
            <div>
              <h3 className="subtitle-info">Moneda</h3>
              <h3>{dataFormReembolso.currency}</h3>
            </div>
            <div>
              <h3 className="subtitle-info">Empleado</h3>
              <h3>{dataFormReembolso.user}</h3>
            </div>
          </div>
        )}
        {modeSwitch == 2 && (
          <div className="header-info-dos header-info-tres">
            <div>
              <h3 className="subtitle-info">Empleado</h3>
              <h3>{dataFormReembolso.user}</h3>
            </div>
          </div>
        )}

        {/* ---------------------------------------------------FORMULARIO PARA proveedores--------------------------------------------------- */}
        {modeSwitch == 1 && (
          <>
            <div className="box-form">
              <CustomInputt<RefundI>
                text="Número de poliza"
                txtWidth={100}
                setGeneralData={setDataFormReembolso}
                field="poliza_number"
                value={dataFormReembolso.poliza_number}
              />
              <div className="box-select">
                {dataFormReembolso.provider_identifier != 0 && (
                  <h3 className="titulo-select">Prestador del servicio</h3>
                )}
                <SelectListdefault
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    //console.log("BANK IDENTIFIER SELECTED: ");
                    //console.log(e.target.value);
                    const proveedor_id: number = parseInt(e.target.value);
                    fillExtraData(proveedor_id);
                  }}
                  // disabled={!editionMode}
                  value={dataFormReembolso.provider_identifier || ""}
                >
                  {dataFormReembolso.provider_identifier == 0 && (
                    <option
                      className="default-opcion"
                      value=""
                      disabled
                      selected
                    >
                      Prestador del servicio
                    </option>
                  )}

                  {listProveedores?.length > 0
                    ? listProveedores.map((item) => {
                        return (
                          <option
                            key={item.provider_identifier}
                            value={item.provider_identifier}
                            // selected={item.identifier == peo}
                          >
                            {item.resume_info}
                          </option>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>

              <CustomInputtt<RefundI>
                text="Monto real"
                txtWidth={100}
                setGeneralData={setDataFormReembolso}
                field="real_amount"
                value={dataFormReembolso.real_amount}
                typeData="number"
              />
              <CustomInputtt<RefundI>
                text="Saldo a favor de Alico"
                txtWidth={100}
                setGeneralData={setDataFormReembolso}
                field="positive_balance"
                value={
                  dataFormReembolso.monto_pagado - dataFormReembolso.real_amount
                }
                typeData="number"
                isEditableBox={false}
              />
              <div className="box-select">
                {dataFormReembolso.reason_identifier != 0 && (
                  <h3 className="titulo-select">Motivo</h3>
                )}
                <SelectListdefault
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    setDataFormReembolso({
                      ...dataFormReembolso,
                      reason_identifier: parseInt(e.target.value),
                    });
                  }}
                  // disabled={!editionMode}
                  value={dataFormReembolso.reason_identifier || ""}
                >
                  {dataFormReembolso.reason_identifier == 0 && (
                    <option
                      className="default-opcion"
                      value=""
                      disabled
                      selected
                    >
                      Motivo
                    </option>
                  )}

                  {listReasonRefund?.length > 0
                    ? listReasonRefund.map((item) => {
                        return (
                          <option
                            key={item.refund_reason_identifier}
                            value={item.refund_reason_identifier}
                            // selected={item.identifier == peo}
                          >
                            {item.refund_reason}
                          </option>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>

              <div className="box-select">
                {dataFormReembolso.refund_status_identifier != 0 && (
                  <h3 className="titulo-select">Estatus</h3>
                )}

                <SelectListdefault
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    //console.log("BANK IDENTIFIER SELECTED: ");
                    //console.log(e.target.value);
                    setDataFormReembolso({
                      ...dataFormReembolso,
                      refund_status_identifier: parseInt(e.target.value),
                    });
                  }}
                  // disabled={!editionMode}
                  value={dataFormReembolso.refund_status_identifier || ""}
                >
                  {dataFormReembolso.refund_status_identifier == 0 && (
                    <option
                      className="default-opcion"
                      value=""
                      disabled
                      selected
                    >
                      Estatus
                    </option>
                  )}

                  {listRefundStatus?.length > 0
                    ? listRefundStatus.map((item) => {
                        return (
                          <option
                            key={item.refund_status_identifier}
                            value={item.refund_status_identifier}
                            // selected={item.identifier == peo}
                          >
                            {item.refund_status}
                          </option>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>
            </div>

            <div className="box-field">
              <h3>Observaciones</h3>
              <textarea
                className="text-container"
                name={""}
                id={""}
                onChange={(e) => {
                  setDataFormReembolso({
                    ...dataFormReembolso,
                    comment: e.target.value,
                  });
                }}
                value={dataFormReembolso.comment}
              />
            </div>
          </>
        )}

        {/* ---------------------------------------------------FORMULARIO PARA AGENCIAS--------------------------------------------------- */}
        {modeSwitch == 2 && (
          <>
            <div className="box-form">
              <DateInput
                text={0 == 0 ? "Fecha" : ""}
                txtWidth={100}
                setGeneralData={setDataFormReembolso}
                field="date_pago"
                value={dataFormReembolso.date_pago}
              />
              <CustomInputt<RefundI>
                text="Agencia"
                txtWidth={100}
                setGeneralData={setDataFormReembolso}
                field="agency"
                value={dataFormReembolso.agency}
              />
              <CustomInputt<RefundI>
                text="Concepto del pago"
                txtWidth={100}
                setGeneralData={setDataFormReembolso}
                field="payment_concept"
                value={dataFormReembolso.payment_concept}
              />

              <CustomInputtt<RefundI>
                text="Monto pagado"
                txtWidth={100}
                setGeneralData={setDataFormReembolso}
                field="monto_pagado"
                value={dataFormReembolso.monto_pagado}
                typeData="number"
              />

              <CustomInputtt<RefundI>
                text="Monto del servicio"
                txtWidth={100}
                setGeneralData={setDataFormReembolso}
                field="real_amount"
                value={dataFormReembolso.real_amount}
                typeData="number"
              />
              <CustomInputtt<RefundI>
                text="Saldo a favor de la Agencia"
                txtWidth={100}
                setGeneralData={setDataFormReembolso}
                field="positive_balance"
                value={dataFormReembolso.positive_balance}
                typeData="number"
              />
              <div className="box-select">
                {dataFormReembolso.currency_identifier != 0 && (
                  <h3 className="titulo-select">Motivo</h3>
                )}
                <SelectListdefault
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    setDataFormReembolso({
                      ...dataFormReembolso,
                      currency_identifier: parseInt(e.target.value),
                    });
                  }}
                  // disabled={!editionMode}
                  value={dataFormReembolso.currency_identifier || ""}
                >
                  {dataFormReembolso.currency_identifier == 0 && (
                    <option
                      className="default-opcion"
                      value=""
                      disabled
                      selected
                    >
                      Moneda
                    </option>
                  )}

                  {currencyList?.length > 0
                    ? currencyList.map((item) => {
                        return (
                          <option
                            key={item.identifier}
                            value={item.identifier}
                            // selected={item.identifier == peo}
                          >
                            {item.description}
                          </option>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>

              <div className="box-select">
                {dataFormReembolso.reason_identifier != 0 && (
                  <h3 className="titulo-select">Motivo</h3>
                )}
                <SelectListdefault
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    //console.log("BANK IDENTIFIER SELECTED: ");
                    //console.log(e.target.value);
                    //   setCardInfo({
                    //     ...cardInfo,
                    //     currency_element: parseInt(e.target.value),
                    //   });
                    // setProviderLocalInfo({
                    //   ...providerLocalInfo,
                    //   currency_identifier: parseInt(e.target.value),
                    // });
                    setDataFormReembolso({
                      ...dataFormReembolso,
                      reason_identifier: parseInt(e.target.value),
                    });
                  }}
                  // disabled={!editionMode}
                  value={dataFormReembolso.reason_identifier || ""}
                >
                  {dataFormReembolso.reason_identifier == 0 && (
                    <option
                      className="default-opcion"
                      value=""
                      disabled
                      selected
                    >
                      Motivo
                    </option>
                  )}

                  {listAgencyRefund?.length > 0
                    ? listAgencyRefund.map((item) => {
                        return (
                          <option
                            key={item.refund_reason_identifier}
                            value={item.refund_reason_identifier}
                            // selected={item.identifier == peo}
                          >
                            {item.refund_reason}
                          </option>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>
              <div className="box-select">
                {dataFormReembolso.refund_status_identifier != 0 && (
                  <h3 className="titulo-select">Motivo</h3>
                )}
                <SelectListdefault
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    //console.log("BANK IDENTIFIER SELECTED: ");
                    //console.log(e.target.value);
                    setDataFormReembolso({
                      ...dataFormReembolso,
                      refund_status_identifier: parseInt(e.target.value),
                    });
                  }}
                  // disabled={!editionMode}
                  value={dataFormReembolso.refund_status_identifier || ""}
                >
                  {dataFormReembolso.refund_status_identifier == 0 && (
                    <option
                      className="default-opcion"
                      value=""
                      disabled
                      selected
                    >
                      Estatus
                    </option>
                  )}

                  {listRefundStatus?.length > 0
                    ? listRefundStatus.map((item) => {
                        return (
                          <option
                            key={item.refund_status_identifier}
                            value={item.refund_status_identifier}
                            // selected={item.identifier == peo}
                          >
                            {item.refund_status}
                          </option>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>
            </div>

            <div className="box-field">
              <h3>Observaciones</h3>
              <textarea
                className="text-container"
                name={""}
                id={""}
                onChange={(e) => {
                  setDataFormReembolso({
                    ...dataFormReembolso,
                    comment: e.target.value,
                  });
                }}
                value={dataFormReembolso.comment}
              />
            </div>
          </>
        )}

        <button onClick={postNewRefund}>Guardar</button>
      </div>
    </FormReembolsoStyle>
  );
}

const FormReembolsoStyle = styled.div`
  .box-select {
    position: relative;
  }
  .titulo-select {
    position: absolute;
    top: -0.5rem;
    font-size: 0.85vw;
    background-color: white;
    margin-left: 1rem;
    color: rgb(8, 166, 165);
    font-weight: 300;
  }
  .title-reembolsos-box {
    color: rgb(8, 166, 165);
  }
  .box-fields {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-around;
  }

  .box-field {
    width: 100%;
    margin-top: 1rem;
  }
  .box-field h3 {
    color: rgb(8, 166, 165);
    margin-bottom: 1rem;
    font-size: 0.85vw;
  }
  .text-container {
    resize: none;
    width: 100%;
    height: 100px;
    padding: 0.9rem;
    border: rgb(8, 166, 165) solid 2px;
    outline: none;
  }
  @media screen and (max-width: 900px) {
    .box-fields {
      width: 80%;
      margin: 0 auto;
      margin-top: 2rem;
      flex-direction: column;
    }
    .box-field {
      width: 100%;
    }
    .box-field h3 {
      font-size: 0.85rem !important;
    }
  }

  .box-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .subtitle-info {
    color: rgb(8, 166, 165);
    font-weight: 600 !important;
  }
  .header-info-dos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 1rem;
  }
  .header-info-dos h3 {
    font-size: 0.85vw;
    font-weight: 300;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  .header-info-tres {
    grid-template-columns: 1fr;
  }
  .header-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 0 !important;
  }
  .header-info div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .header-info div h3 {
    text-align: center;
    font-weight: 300;
    font-size: 0.85vw;
  }
  .box-formulario-shadow {
    border: black solid 1px;
    background-color: black;
    position: fixed;
    top: -5rem;
    z-index: 102;
    left: 0%;
    width: calc(100vw);
    height: calc(100vh + 5rem);
    opacity: 0.6;
  }
  .box-formulario {
    z-index: 103;
    padding: 1.2rem;
    position: fixed;
    top: 10%;
    border: black solid 1px;
    left: 25%;

    background-color: white;
    width: 50%;
    border: 2px solid rgb(8, 166, 165);
    border-radius: 0.2rem;
  }
  .first-box {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
  }
  .icon-close {
    font-size: 2rem;
    cursor: pointer;
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  text-align: left;

  /* Alinea el texto en la parte superior izquierda */
  option.default-opcion {
    vertical-align: text-top;
  }

  .fake-icon {
    max-width: 10px !important;
  }

  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;

function convertirFormatoFecha(inputString: string): string {
  // Divide el string en partes: año, mes y día
  const partes: string[] = inputString.split("-");

  // Reorganiza las partes en el nuevo formato
  const nuevoFormato: string = `${partes[2]}-${partes[1]}-${partes[0]}`;

  return nuevoFormato;
}
