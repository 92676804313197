import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import { FileI } from "../../../interfaces/GeneralTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileImage,
  faClose,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import DeleteConfirmation from "../common/DeleteConfirmation";

interface dataProps {
  nameFile: string;
  file: string;
  file_extension: string;
  start_date: string;
  end_date: string;
  identificador: string;
  deleteContrato: (elementIdentifier: string) => void;
  urlContrato: string;
  document: FileI;
}
export default function FileElement({
  nameFile,
  file,
  file_extension,
  start_date,
  end_date,
  deleteContrato,
  identificador,
  document,
}: dataProps) {
  const currentIcon =
    file_extension == "png"
      ? faFileImage
      : file_extension == "pdf"
      ? faFilePdf
      : faFile;
  const deleteElemento = () => {
    deleteContrato(identificador);
  };

  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    deleteElemento();
    setIsShownDelete(false);
    ////BORRADO
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };
  function abrirEnNuevaPestana(data: string): void {
    if (data.startsWith("http://") || data.startsWith("https://")) {
      // Si es una URL, abrir en una nueva pestaña
      window.open(data, "_blank");
    } else if (data.startsWith("data:")) {
      // Si es un archivo base64, crear un Blob y abrir en una nueva pestaña
      const byteCharacters = atob(data.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" }); // Cambia el tipo MIME según el archivo base64
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL, "_blank");
    } else {
      console.error(
        "Formato no compatible: el string no es una URL válida ni un archivo base64."
      );
    }
  }
  function generarURL(
    direccionArchivo: string,
    variableEntorno: string,
    uuid: string
  ): string {
    let ambiente: number;

    if (variableEntorno === "https://dev.corecms.alicomx.com/api") {
      ambiente = 1;
    } else if (variableEntorno === "https://corecms.alicomx.com/api") {
      ambiente = 2;
    } else {
      return "";
      throw new Error("Variable de entorno no válida");
    }

    const baseUrl =
      ambiente === 1
        ? "https://dev.corecms.alicomx.com"
        : "https://Corecms.alicomx.com";

    // Obtener la ruta del archivo sin el nombre del archivo
    const partesRuta = direccionArchivo.split("\\");
    partesRuta.pop(); // Eliminar el nombre del archivo
    const rutaSinNombreArchivo = partesRuta.join("\\");

    return `${baseUrl}/${rutaSinNombreArchivo
      .replace(/\\/g, "/")
      .replace(/^.*?\bContracts\b/, "Contracts")}/${uuid}`;
  }
  return (
    <>
      <FileElementStyle>
        <div className="file-container">
          <div className="logo-type">
            <FontAwesomeIcon icon={currentIcon} className="document-icon" />
          </div>
          <div className="title-document">
            <h3
              onClick={() => {
                // abrirEnNuevaPestana(file)
                //console.log("Datos del documento:");
                //console.log(document);
                if (file.startsWith("data:")) {
                  abrirEnNuevaPestana(file);
                  return;
                }

                abrirEnNuevaPestana(
                  generarURL(
                    file,
                    process.env.REACT_APP_BACKEND_ENDPOINT || "",
                    document.file_name
                  )
                );
              }}
            >
              {nameFile}s
            </h3>
          </div>
          <div className="icon-options">
            <FontAwesomeIcon
              icon={faClose}
              className="close"
              onClick={handleDeleteClick}
            />
          </div>
        </div>
      </FileElementStyle>
      {isShownDelete && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          itemName={`¿Deseas borrar el Contrato ${nameFile}?`}
          setIsShownDelete={setIsShownDelete}
        />
      )}
    </>
  );
}

const FileElementStyle = styled.div`
  .file-container {
    border: rgb(8, 166, 165) solid 1px;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    justify-items: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
  }
  .document-icon {
    color: orange;
    font-size: 1.6rem;
    cursor: pointer;
  }
  .close {
    font-size: 1.6rem;
    color: red;
    cursor: pointer;
  }
  .title-document h3 {
    font-size: 1.1vw;
  }
  @media screen and (max-width: 900px) {
    .title-document h3 {
      font-size: 1.6vw;
    }
  }
`;
