import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import ServiciosLayout from "../../components/layout/ServiciosLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faPencil,
  faPen,
  faFloppyDisk,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../../components/entrys/CustomInput";
import { v4 as uuidv4 } from "uuid";
import CustomInputt from "../../components/entrys/CustomInputt";
import {
  dataServicesI,
  seasonInterface,
  coinInterface,
  viewInterface,
  serviceInterface,
  serviciosInterface,
  categoriaI,
  maealI,
  blackoutObject,
  serviceObject,
} from "../../../interfaces/GeneralTypes";
import Vigencia from "./Vigencia";
import ImpuestosGet from "./ImpuestosGet";
import DateInput from "../entrys/DateInput";
import Dashboard from "../pages/Dashboard";
import deletedatos from "../../../images/deletelogo.png";
import guardar from "../../../images/GUARDARDATOS.png";
import edit from "../../../images/edit.png";
import agregar from "../../../images/AGREGAR.png";
import copiar from "../../../images/DUPLICAR.png";
import {
  BlackoutDelete,
  DeleteService,
  FeeDelete,
  ServiceFeeDelete,
  ValidityDelete,
} from "../../../interfaces/DeleteTypes";
import { GeneralModifiedI } from "../../../interfaces/GuardadoTypes";
import DeleteConfirmation from "../common/DeleteConfirmation";
interface blackoutPropsI {
  blackout: blackoutObject;
  dataServices: dataServicesI;
  setDataServices: React.Dispatch<React.SetStateAction<dataServicesI>>;
  isShowingAddButton: boolean;
  addBlackout: () => void;
  deleteBlackout: (identifierBlackout: string) => void;
  tarifa: serviceObject;
  indice: number;
}
export default function BlackoutElement({
  blackout,
  isShowingAddButton,
  addBlackout,
  dataServices,
  setDataServices,
  deleteBlackout,
  indice,
  tarifa,
}: blackoutPropsI) {
  const { blackoutIdentifier, blackout_startdate, blackout_enddate } = blackout;
  const {
    registerData,
    setRegisterData,
    contratoContext,
    setContratoContext,
    idCurrentProvider,
    registerGeneral,
    setTitleAlert,
    setContentAlert,
    setShowAlert,
    setShowLoader,
    dataServicesContext,
  } = useContext(AppContext);

  const [blackoutData, setBlackoutData] = useState<blackoutObject>({
    blackout_identifier: blackout.blackout_identifier,
    //////////////////
    blackout_startdate: blackout_startdate,
    blackout_enddate: blackout_enddate,
    blackoutIdentifier: blackoutIdentifier,
  });
  useEffect(() => {
    //console.log("DATOS DE BLACKOUT: ");
    //console.log(blackout);
  }, []);
  useEffect(() => {
    //console.log("CAMBIARON DATOS DE BLACKOUT: ");
    //console.log(blackoutData);
    const newServicesFeeList = dataServices.services_fees.map(
      (tarifaIndividual, index) => {
        return tarifaIndividual.identifier_list != tarifa.identifier_list
          ? tarifaIndividual
          : {
              ...tarifaIndividual,
              service_blackout_list: tarifaIndividual.service_blackout_list.map(
                (blackoutIndividual, index) => {
                  return blackoutIndividual.blackoutIdentifier !==
                    blackout.blackoutIdentifier
                    ? blackoutIndividual
                    : blackoutData;
                }
              ),
            };
      }
    );
    setDataServices({
      ...dataServices,
      services_fees: newServicesFeeList,
    });
    //console.log("NEW ARRAY DE SERVICIOS CON BLACKOUT: ");
    //console.log(newServicesFeeList);
  }, [blackoutData]);

  const deleteBlackoutCall = async () => {
    const currentBlackoutArray = tarifa.service_blackout_list;
    if (currentBlackoutArray.length == 1) {
      alert("No puedes borrar más elementos");
      return;
    }
    const newBlackoutArray = currentBlackoutArray.filter(
      (currentBlackout, index) => {
        return (
          currentBlackout.blackoutIdentifier !== blackoutData.blackoutIdentifier
        );
      }
    );
    const newServicesFeeList = dataServices.services_fees.map(
      (tarifaIndividual, index) => {
        return tarifaIndividual.identifier_list != tarifa.identifier_list
          ? tarifaIndividual
          : {
              ...tarifaIndividual,
              service_blackout_list: newBlackoutArray,
            };
      }
    );

    /////////////////BORRADO DESDE SERVICIOS
    if (idCurrentProvider != 0) {
      setShowLoader(true);
      const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
      //console.log("REACT BACK END POINT: ");
      //console.log(backEndPoint);

      const urlModified = backEndPoint + "/provider/update";
      const modifiedGeneral: GeneralModifiedI = {
        register_identifier: idCurrentProvider,
        name: registerGeneral.name,
        registration_type_identifier:
          registerGeneral.registration_type_identifier,
        lender_type_identifier:
          registerGeneral.lender_type_identifier == 0
            ? 1
            : registerGeneral.lender_type_identifier,
        provider_type_identifier: registerGeneral.provider_type_identifier
          ? registerGeneral.provider_type_identifier
          : 1,
        business_name: registerGeneral.business_name,
        street: registerGeneral.calles,
        number: registerGeneral.telefono,
        neighborhood_identifier: registerGeneral.neighborhood_identifier,
        city_identifier: registerGeneral.city_identifier
          ? registerGeneral.city_identifier
          : 1,
        country_identifier: registerGeneral.country_identifier,
        zip_code: registerGeneral.zip_code,
        important_notes: registerGeneral.important_notes,
        description: registerGeneral.lead,
        id: registerGeneral.id,
        key: registerGeneral.claveGeneral,
        rfc: registerGeneral.rfc,
        category:
          registerGeneral.categoriaGeneral == 0
            ? null
            : registerGeneral.categoriaGeneral,
        last_purchase: registerGeneral.ultimaCompra,
        feedback: registerGeneral.feedback,
        lead: registerGeneral.lead,
        commission_scheme: registerGeneral.comisionEsquema,
        web: registerGeneral.web,
        comments: registerGeneral.comments,
        category_identifier: registerGeneral.category_identifier,
      };

      const blackoutListDeleted: BlackoutDelete[] = currentBlackoutArray
        .filter((currentBlackout, index) => {
          return (
            currentBlackout.blackoutIdentifier == blackout.blackoutIdentifier
          );
        })
        .map((blackout, index) => {
          const blackoutCurrent: BlackoutDelete = {
            blackout_identifier: blackout.blackout_identifier,
            blackout_startdate: blackout.blackout_startdate,
            blackout_enddate: blackout.blackout_enddate,
            status_identifier: 2,
          };
          return blackoutCurrent;
        });
      const serviciosModified: ServiceFeeDelete[] =
        dataServicesContext.services_fees
          .filter((currentService, index) => {
            return (
              currentService.service_identifier == tarifa.service_identifier
            );
          })
          .map((servicio, index) => {
            const currentListFee: FeeDelete[] = servicio.service_fee_list.map(
              (fee, index) => {
                const serviceFee: FeeDelete = {
                  fee_identifier: fee.fee_identifier,
                  fee_currency_identifier: fee.fee_currency_identifier,
                  fee_iva: fee.fee_iva,
                  fee_tax: fee.fee_tax,
                  fee: fee.fee,
                  fee_total: fee.fee_total,
                  fee_SGL: fee.fee_SGL,
                  fee_DBL: fee.fee_DBL,
                  fee_TPL: fee.fee_TPL,
                  fee_CPL: fee.fee_CPL,
                  fee_FP: fee.fee_FP,
                  fee_TSGL: fee.fee_TSGL,
                  fee_TDBL: fee.fee_TDBL,
                  fee_TTPL: fee.fee_TTPL,
                  fee_TCPL: fee.fee_TCPL,
                  fee_TFP: fee.fee_TFP,
                  fee_BB: fee.fee_BB,
                  fee_BBT: fee.fee_BBT,
                  fee_MAID: fee.fee_MAID,
                  fee_TMAID: fee.fee_TMAID,
                  fee_capacity: fee.fee_capacity,
                  status_identifier: 1,
                };
                return serviceFee;
              }
            );

            const currentService: ServiceFeeDelete = {
              fee_type_identifier: servicio.fee_type_identifier,
              service_identifier: servicio.service_identifier,
              service_type_identifier: servicio.service_type_identifier,
              service_mealplan_identifier: servicio.service_mealplan_identifier,
              service_category_identifier: servicio.service_category_identifier,
              service_category: servicio.service_category,
              service_name: servicio.service_name,
              service_comments: servicio.service_comments,
              service_key: servicio.service_key,
              service_fees_list: [],
              service_validity_list: [],
              service_blackout_list: blackoutListDeleted,
              status_identifier: 1,
              service_minimum_stay_list: [],
              operating_days: [],
            };

            return currentService;
          });

      const modifiedObject: DeleteService = {
        user_identifier: 1,
        general: modifiedGeneral,
        services_fees: serviciosModified,
      };

      //console.log('CUERPO DE PETICION: ' );
      //console.log(modifiedObject);

      try {
        const response = await fetch(urlModified, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(modifiedObject),
        });
        if (response.ok) {
          ////RESPUESTA CORRECTA
          //console.log(response);
          //console.log("bien");

          setTitleAlert("Modificación correcta correcto");
          setContentAlert(
            "Tus datos fueron modificados correctamente, puedes consultarlos en el catalogo."
          );

          setShowAlert(true);
          setShowLoader(false);
        } else {
          ///RESPUESTA INCORRECTA DE SERVICIOS
          // setShowLoader(false);
          setTitleAlert("Error al BORRAR:");
          setContentAlert(
            "Tus datos no fueron guardados correctamente, por favor, verificalos."
          );
          setShowAlert(true);
          setShowLoader(false);
        }
      } catch (error) {
        ///ERROR AL REALIZAR PETICION
        alert("ERROR AL REALIZAR BORRADO");
        setShowLoader(false);
      }
    }

    ///////////////FIN DE BORRADO DESDE SERVICIOS
    setDataServices({
      ...dataServices,
      services_fees: newServicesFeeList,
    });

    deleteBlackout(blackout.blackoutIdentifier);
  };
  const addBlackoutElement = () => {
    let identifierValidity = uuidv4();
    const newServicesFeeList = dataServices.services_fees.map(
      (tarifaIndividual, index) => {
        return tarifaIndividual.identifier_list != tarifa.identifier_list
          ? tarifaIndividual
          : {
              ...tarifaIndividual,
              service_blackout_list: [
                ...tarifaIndividual.service_blackout_list,
                {
                  blackout_identifier: 0,
                  //////////////////////
                  blackout_startdate: "",
                  blackout_enddate: "",
                  blackoutIdentifier: identifierValidity,
                },
              ],
            };
      }
    );
    setDataServices({
      ...dataServices,
      services_fees: newServicesFeeList,
    });
  };
  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    deleteBlackoutCall();
    setIsShownDelete(false);
    ////BORRADO
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };

  return (
    <>
      <BlackoutElementStyle>
        <div className="black-element">
          <div className="black-one">
            <DateInput
              text={indice == 0 ? "Blackout inicial" : ""}
              txtWidth={100}
              setGeneralData={setBlackoutData}
              field="blackout_startdate"
              value={blackoutData.blackout_startdate}
            />
          </div>

          <DateInput
            text={indice == 0 ? "Blackout final" : ""}
            txtWidth={100}
            setGeneralData={setBlackoutData}
            field="blackout_enddate"
            value={blackoutData.blackout_enddate}
          />
          <img
            src={deletedatos}
            width="30px"
            className="trash-icon-style"
            onClick={handleDeleteClick}
          />
          {isShowingAddButton && (
            <img
              src={agregar}
              alt=""
              width="30px"
              onClick={addBlackoutElement}
              className="add-icon"
            />
          )}
        </div>
      </BlackoutElementStyle>
      {isShownDelete && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          itemName={`¿Deseas borrar el Blackout ${
            blackoutData.blackout_startdate
          } ${
            blackoutData.blackout_startdate != "" &&
            blackoutData.blackout_enddate != ""
              ? "-"
              : ""
          } ${blackoutData.blackout_enddate}?`}
          setIsShownDelete={setIsShownDelete}
        />
      )}
    </>
  );
}

const BlackoutElementStyle = styled.div`
  width: 60%;
  margin: 0 auto;
  .add-icon {
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    .add-icon,
    .trash-icon-style {
      width: 20px;
      margin-left: 0.25rem;
    }
  }
  .black-one {
    width: 100%;
    margin-right: 0.9rem;
  }
  .black-element:first-child {
  }
  @media screen and (max-width: 900px) {
    width: 70%;
  }
  .trash-icon-style {
    cursor: pointer;
  }
  .black-element {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .black-element {
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr 0.3fr;
    justify-items: center !important;
    align-items: center !important;
  }
  .add-blackout {
    display: flex;
    margin-top: 1rem;
    justify-content: right;
    padding-right: 0.5rem;
  }
  .add-blackout img {
    cursor: pointer;
  }
`;
