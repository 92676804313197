import { useEffect, useState } from "react";
import styled from "styled-components";

interface Props<T> {
  field: string;
  text: string;
  txtWidth: number;
  value?: string;

  setGeneralData: React.Dispatch<React.SetStateAction<T>>;
  onChange?: (value: string) => void;
  extraFunction?: () => void;
}
interface styledInputProps {
  percentageWidth: number;
}
function CustomInputt<T>(props: Props<T>) {
  const [inputValue, setInputValue] = useState(props.value || "");

  const obtenerValor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    //console.warn("Ejecutando:");
    //console.log(props);
    setInputValue(value);
    props.setGeneralData((prevProvider) => {
      return {
        ...prevProvider,
        [props.field]: value,
      };
    });

    if (props.onChange) {
      props.onChange(value);
    }
  };
  useEffect(() => {
    setInputValue(props.value || "");
  }, [props.value]);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (props.extraFunction) {
        props.extraFunction();
      }

      //console.warn(props.variableNueva);
      //console.log(props);
      //if(props.variableNueva){
      //console.log(props.variableNueva);
      // }
    }
  };
  return (
    <div
      style={{ width: props.txtWidth + "%", maxWidth: props.txtWidth + "%" }}
    >
      <PrincipalContainer style={{ width: "100%", maxWidth: "100%" }}>
        <EntryContainer>
          <Entry
            id={props.field}
            type="text"
            placeholder={props.text}
            style={{
              width: "100%",
            }}
            onChange={obtenerValor}
            value={inputValue}
            autoComplete="off"
            className={inputValue ? "has-value" : ""}
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
          />
          <Label htmlFor={props.field}>{props.text}</Label>
        </EntryContainer>
      </PrincipalContainer>
    </div>
  );
}

export default CustomInputt;

const Entry = styled.input`
  background-color: #ffffff;
  border-style: none;
  border-bottom: 2px solid #b1b1b1;
  color: "#000000";
  cursor: text;
  outline: none;
  border: solid rgb(8, 166, 165) 2px;
  max-height: 38.4px;
  padding-left: 20px;
  margin-bottom: 0.5rem;
  font-size: 0.85vw;
  padding: 0.5rem 0.5rem;

  position: relative;
  z-index: 1;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
  @media screen and (max-width: 700px) {
    min-height: 32px !important;
    font-size: 2.3vw;
  }

  &::placeholder:not(.has-value) {
    position: absolute;
    top: -0.5rem;
    left: 1rem;
    font-size: 1.3vw;
    color: #b1b1b1;
    color: blue !important;
    pointer-events: none;
    transition: all 0.2s ease-out;
    z-index: 1;
  }
`;

const PrincipalContainer = styled.div`
  height: auto;
  display: flex;
`;

const EntryContainer = styled.div`
  width: 100%;
  position: relative;
  max-width: 100%;
`;

const Label = styled.label`
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  font-size: 12px;
  color: rgb(8, 166, 165);
  pointer-events: none;
  transition: all 0.2s ease-out;
  z-index: 1;
  background-color: white;
  @media screen and (max-width: 900px) {
    font-size: 1.5vw;
  }
  @media screen and (max-width: 700px) {
    font-size: 2.3vw;
  }
`;
