import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { OperationContext } from "../../../context/operationContext";
import TablePagoGastos from "./TablePagoGastos";
import { AppContext } from "../../../context/appContext";
import { v4 as uuidv4 } from "uuid";
import {
  ApiResponseGastosComprobar,
  EntryItemGastoI,
  GuideGastosComprobar,
  PaymentData,
  PaymentDataGastosIC,
  PendingPaymentGastosComprobar,
  RequestGastosI,
  RequestModifyPayment,
  providerGastosI,
} from "./InterfacesContabilidad";
import FormPagoGastos from "./FormPagoGastos";
import { useNavigate } from "react-router-dom";
import { fetchServer } from "../../../services/fetchServer";

export default function SavePagoGastos() {
  const {
    currentOperation,
    idProveedorSelected,
    contabilidadMode,
    idCurrentPayment,
  } = useContext(OperationContext);
  // Obtén la fecha actual
  const fechaActual: Date = new Date();

  // Obtiene los componentes de la fecha
  const año: number = fechaActual.getFullYear();
  const mes: number = fechaActual.getMonth() + 1; // ¡Recuerda que los meses comienzan desde 0!
  const dia: number = fechaActual.getDate();

  // Formatea la fecha en el formato deseado
  const fechaFormateada: string = `${año}-${mes < 10 ? "0" : ""}${mes}-${
    dia < 10 ? "0" : ""
  }${dia}`;

  const { setShowLoader, currentUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState<PaymentDataGastosIC>({
    payment_identifier: 0,
    payment_date: fechaFormateada,
    currency_identifier: 0,
    total_payment: 0,
    bank_identifier: 0,
    bank_account_identifier: 0,
    concept: "",
    policy: "",
    exchange_rate: 0,
    comment: "",
    status_identifier: 0,
    business_name: "",
    alias: "",
    user_identifier: "",
  });

  const [listCurrentGuides, setListCurrentGuides] = useState<
    GuideGastosComprobar[]
  >([]);

  const [paymentsGastos, setPaymentsGastos] = useState<
    PendingPaymentGastosComprobar[]
  >([]);
  const [idGuideSelected, setIdGuideSelected] = useState<number>(0);

  const sendInfoPOST = async () => {
    const paymentGeneralInfoToSend: PaymentDataGastosIC = {
      payment_identifier: 0,
      payment_date: paymentData.payment_date,
      currency_identifier: paymentData.currency_identifier,
      total_payment: paymentData.total_payment,
      bank_identifier: paymentData.bank_identifier,
      bank_account_identifier: paymentData.bank_account_identifier,
      concept: paymentData.concept,
      policy: paymentData.policy,
      exchange_rate: paymentData.exchange_rate,
      comment: paymentData.comment,
      status_identifier: 0,
      business_name: paymentData.business_name,
      alias: paymentData.alias,
      user_identifier: paymentData.user_identifier,
    };
    const listaEntradasAPagar: EntryItemGastoI[] = paymentsGastos
      .filter((currentPayment, index) => {
        return (
          currentPayment.isSelected &&
          currentPayment.payment_status !== "Pagado"
        );
      })
      .map((currentPayment, index) => {
        const entradaActual: EntryItemGastoI = {
          entry_identifier: currentPayment.entry_identifier,
          currency_identifier: currentPayment.currency_identifier,
          guide_identifier: currentPayment.guide_identifier,
        };
        return entradaActual;
      });

    const objetoEnviar: RequestGastosI = {
      payment_data: paymentGeneralInfoToSend,
      entry_list: listaEntradasAPagar,
    };
    //console.log("Datos a enviar.");
    //console.log(objetoEnviar);

    if (listaEntradasAPagar.length == 0) {
      //console.log("No hay pagos por enviar.");
      return;
    }
    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/accounting/paymentExpenses/register`;
    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        body: JSON.stringify(objetoEnviar),
      });

      if (response.ok) {
        const data = await response.json();

        alert("El valor se ha enviado correctamente");

        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION
        //console.log("-------------------------------------------DATA--------------------------------------");
        //console.log(data.data);
        navigate("/dashboard/Contabilidad/Contabilidad-Prestadores");
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al guardar");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al guardar");
      console.error("Error in request:", error);

      alert("Error en el servicio");
      // setShowLoader(false);
    }
  };
  const sendInfo = () => {
    if (paymentData.currency_identifier == 1 && paymentData.exchange_rate) {
      alert("No puedes guardar tipo de cambio con un pago en dolares.");
      return;
    }
    let sumaPesos = paymentsGastos
      .filter((payment, index) => {
        return (
          (payment.isSelected && payment.payment_status !== "Pagado") ||
          contabilidadMode == 2
        );
      })
      .reduce((total, item) => total + item.advance_mxn, 0);

    let sumaDolares = paymentsGastos
      .filter((payment, index) => {
        return (
          (payment.isSelected && payment.payment_status !== "Pagado") ||
          contabilidadMode == 2
        );
      })
      .reduce((total, item) => total + item.advance_usd, 0);
    if (paymentData.currency_identifier == 0) {
      alert("Por favor, elige una moneda");
      return;
    }
    if (paymentData.total_payment == 0) {
      alert("Ingresa una cantidad de pago");
      return;
    }
    if (sumaPesos !== 0 && sumaDolares !== 0) {
      //console.log("Has seleccionado dos monedas diferentes, por favor selecciona sólo una");
      alert(
        "Has seleccionado dos monedas diferentes, por favor selecciona sólo una"
      );
      return;
    } else {
      //console.log("suma en pesos");
      //console.log(sumaPesos);
      if (sumaPesos != 0 && paymentData.currency_identifier == 2) {
        if (validatePrice(sumaPesos, paymentData.total_payment)) {
          //console.log("Los precios pasaron la validación.");
          alert("Los precios pasaron la validación.");
          guardarDatos();
          // sendInfoPOST();
        } else {
          //console.log("Los precios NO coinciden");
          //console.log("providerInfo.amount_money !== sumaPesos");
          alert("Los precios no coinciden, modificalos");
          return;
        }
        return;
      } else if (sumaDolares !== 0 && paymentData.currency_identifier == 1) {
        if (validatePrice(sumaDolares, paymentData.total_payment)) {
          //console.log("Los precios pasaron la validación.");
          alert("Los precios pasaron la validación");
          guardarDatos();
          // sendInfoPOST();
          return;
        } else {
          //console.log("Los precios NO coinciden");
          //console.log("providerInfo.amount_money !== sumaPesos");
          alert("Los precios no coinciden, modificalos");
          return;
        }
      } else if (sumaDolares !== 0 && paymentData.currency_identifier == 2) {
        if (paymentData.exchange_rate == 0) {
          alert("Ingresa un tipo de cambio");
          return;
        }

        let sumaDolaresConvertida = sumaDolares * paymentData.exchange_rate;

        if (validatePrice(sumaDolaresConvertida, paymentData.total_payment)) {
          //console.log("Los precios pasaron la validación.");
          alert("Los precios pasaron la validación");
          guardarDatos();
          // sendInfoPOST();
          return;
        } else {
          //console.log("Los precios NO coinciden");
          //console.log("providerInfo.amount_money !== sumaPesos");
          alert("Los precios no coinciden, modificalos");
          return;
        }
      } else if (sumaPesos !== 0 && paymentData.currency_identifier == 1) {
        alert("No puedes pagar pesos con dolares");
        return;
      } else {
        alert(
          "Revisa que tu moneda coincida con la monedad de los servicios seleccionados."
        );
        return;
      }
    }
  };
  const getInfoPaymentGastosComprobar = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    setShowLoader(true);
    const urlModified =
      backEndPoint +
      `/accounting/PendingPaymentsExpenses/${currentOperation.quote.quote_identifier}/${idGuideSelected}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        //console.log("bien");
        const data: ApiResponseGastosComprobar = await response.json();
        //console.log("Gastos a comprobar");
        //console.log(data.data.guides_list);
        //console.log(data.data.pending_payments);
        if (data.data.pending_payments) {
          if (data.data.pending_payments.length > 0) {
            const finalArrayGastos: PendingPaymentGastosComprobar[] =
              data.data.pending_payments.map((currentPayment, index) => {
                let identifierLocal = uuidv4();
                const paymentActual: PendingPaymentGastosComprobar = {
                  ...currentPayment,
                  id_local: identifierLocal,
                  isSelected: true,
                };
                return paymentActual;
              });
            setPaymentsGastos(finalArrayGastos);
          }
        }
        setListCurrentGuides(data.data.guides_list);

        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const getInfoModificacion = async () => {
    ////accounting/paymentDetail/{paymentID}/{providerID}
    try {
      const data: ApiResponseGastosComprobar = await fetchServer(
        `/accounting/paymentDetail/${idCurrentPayment}/${idProveedorSelected}`
      );
      // alert("obteniendo gastos a comprobar");
      //console.log("Proveedor seleccionado:");
      //console.log(data);
      //console.log("Gastos a comprobar");
      //console.log(data.data.guides_list);
      //console.log(data.data.pending_payments);
      if (data.data.pending_payments) {
        if (data.data.pending_payments.length > 0) {
          const finalArrayGastos: PendingPaymentGastosComprobar[] =
            data.data.pending_payments.map((currentPayment, index) => {
              let identifierLocal = uuidv4();
              const paymentActual: PendingPaymentGastosComprobar = {
                ...currentPayment,
                id_local: identifierLocal,
                isSelected: true,
              };
              return paymentActual;
            });
          setPaymentsGastos(finalArrayGastos);
        }
      }
      setListCurrentGuides(data.data.guides_list);

      if (data.data.provider) {
        const proveedorInformacion: providerGastosI = {
          register_identifier: data.data.provider.register_identifier,
          provider_name: data.data.provider.provider_name,
          key: data.data.provider.key,
          business_name: data.data.provider.business_name,
          provider_type_identifier: data.data.provider.provider_type_identifier,
          provider_type: data.data.provider.provider_type,
          payment_date: data.data.provider.payment_date,
          currency_identifier: data.data.provider.currency_identifier,
          total: data.data.provider.total,
          bank_identifier: data.data.provider.bank_identifier,
          concept: data.data.provider.concept,
          policy: data.data.provider.policy,
          exchange_rate: data.data.provider.exchange_rate,
          user_identifier: currentUser.user_name,
          comment: data.data.provider.comment,
          bank_account_identifier: data.data.provider.bank_account_identifier,
          alias: data.data.provider.alias,
          agency: data.data.provider.agency,
          creator_user_identifier: data.data.provider.creator_user_identifier,
          currency: data.data.provider.currency,
          bank: data.data.provider.bank,
          bank_account: data.data.provider.bank_account,
        };
        setPaymentData({
          payment_identifier: 1,
          payment_date: proveedorInformacion.payment_date,
          currency_identifier: proveedorInformacion.currency_identifier,
          total_payment: proveedorInformacion.total,
          bank_identifier: proveedorInformacion.bank_identifier,
          bank_account_identifier: proveedorInformacion.bank_account_identifier,
          concept: proveedorInformacion.concept,
          policy: proveedorInformacion.policy,
          exchange_rate: proveedorInformacion.exchange_rate,
          comment: proveedorInformacion.comment,
          status_identifier: 1,
          business_name: proveedorInformacion.business_name,
          alias: proveedorInformacion.alias,
          user_identifier: proveedorInformacion.user_identifier,
        });
      }

      setShowLoader(false);
    } catch (error) {
      alert("Fallo petición");
    }
  };
  const modifyPayment = async () => {
    const bodyRequest: RequestModifyPayment = {
      payment_identifier: idCurrentPayment,
      entry_identifier: 0,
      payment_date: paymentData.payment_date,
      currency_identifier: paymentData.currency_identifier,
      total_payment: paymentData.total_payment,
      bank_identifier: paymentData.bank_identifier,
      bank_account_identifier: paymentData.bank_account_identifier,
      concept: paymentData.concept,
      policy: paymentData.policy,
      exchange_rate: paymentData.exchange_rate,
      comment: paymentData.comment,
      business_name: paymentData.business_name,
      status_identifier: 1,
    };
    setShowLoader(true);
    try {
      const resJson = await fetchServer(
        "/accounting/payment/update",
        "post",
        bodyRequest
      );
      //console.log("Respuesta JSON");
      //console.log(resJson);
    } catch (error) {
      alert("Fallo el guardado");
    }

    setShowLoader(false);
  };
  const guardarDatos = () => {
    if (contabilidadMode == 1) {
      sendInfoPOST();
    } else if (contabilidadMode == 2) {
      modifyPayment();
    }
  };
  useEffect(() => {
    if (contabilidadMode == 2) {
      getInfoModificacion();
    } else {
      getInfoPaymentGastosComprobar();
    }
  }, [idGuideSelected]);
  useEffect(() => {
    //console.log("paymentGastos ha cambiado");
    //console.log(paymentsGastos);
  }, [paymentsGastos]);

  useEffect(() => {
    //console.log("datos actualizados");
    //console.log(paymentData);
  }, [paymentData]);
  return (
    <SavePagoGastosStyle>
      <label>save pago gastos</label>

      <br />
      <br />
      {contabilidadMode == 1 && (
        <div className="combobox-guias">
          <SelectListdefault
            style={{
              width: "45%",
              maxWidth: "45%",
            }}
            id="sl6"
            onChange={(e) => {
              setIdGuideSelected(parseInt(e.target.value));
            }}
            // disabled={!editionMode}
            value={idGuideSelected || ""}
          >
            <option className="default-opcion" value="" disabled selected>
              Todos los guías
            </option>

            {listCurrentGuides?.length > 0
              ? listCurrentGuides.map((item) => {
                  return (
                    <option
                      key={item.guide_identifier}
                      value={item.guide_identifier}
                      // selected={item.identifier == peo}
                    >
                      {item.guide_name}
                    </option>
                  );
                })
              : null}
            {listCurrentGuides.length == 0 && <h3>No hay pagos por mostrar</h3>}
          </SelectListdefault>
        </div>
      )}

      <TablePagoGastos
        setPaymentsGastos={setPaymentsGastos}
        paymentsGastos={paymentsGastos}
        paymentData={paymentData}
      />
      <FormPagoGastos
        paymentData={paymentData}
        setPaymentData={setPaymentData}
        saveInfo={sendInfo}
      />
      <div className="container-button">
        <button onClick={sendInfo}>Enviar</button>
      </div>
      <br />
      <br />
    </SavePagoGastosStyle>
  );
}

const SavePagoGastosStyle = styled.div`
  .container-button {
    width: 85%;
    margin: 0 auto;
  }
  .container-button button {
    padding: 0.5rem 1.5rem;
    border-radius: 0.3rem;
    border: none;
    cursor: pointer;
  }
  .combobox-guias {
    width: 85%;
    margin: 0 auto;
  }
`;
const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  .fake-icon {
    max-width: 10px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;

const validatePrice = (sumaPrecio: number, precioIngresado: number) => {
  //console.log("precioIngresado > (sumaPrecio -1)");
  //console.log(precioIngresado > sumaPrecio - 1);
  //console.log("precioIngresado");
  //console.log(precioIngresado);
  //console.log("(sumaPrecio-1)");
  //console.log(sumaPrecio - 1);
  //console.log("precioIngresado < (sumaPrecio + 1)");
  //console.log(precioIngresado < sumaPrecio + 1);
  return precioIngresado > sumaPrecio - 1 && precioIngresado < sumaPrecio + 1
    ? true
    : false;
};
