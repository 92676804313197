import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import { GeneralI } from "../../../interfaces/RegisterProvider-Client/General";
import styled from "styled-components";
import { useCatalogs } from "../../../context/CatalogContext";
import { AppContext } from "../../../context/appContext";
import ServiciosLayout from "../../components/layout/ServiciosLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faPencil,
  faPen,
  faFloppyDisk,
  faCopy,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../../components/entrys/CustomInput";
import { v4 as uuidv4 } from "uuid";
import CustomInputt from "../../components/entrys/CustomInputt";
import {
  dataServicesI,
  seasonInterface,
  coinInterface,
  viewInterface,
  serviceInterface,
  serviciosInterface,
  categoriaI,
  maealI,
  feeIndividual,
  serviceObject,
  validityDates,
} from "../../../interfaces/GeneralTypes";
import Vigencia from "./Vigencia";
import ImpuestosGet from "./ImpuestosGet";
import deletedatos from "../../../images/deletelogo.png";
import guardar from "../../../images/GUARDARDATOS.png";
import edit from "../../../images/edit.png";
import agregar from "../../../images/AGREGAR.png";
import copiar from "../../../images/DUPLICAR.png";
import { GeneralModifiedI } from "../../../interfaces/GuardadoTypes";
import {
  BlackoutDelete,
  DeleteService,
  FeeDelete,
  ServiceFeeDelete,
  ValidityDelete,
} from "../../../interfaces/DeleteTypes";

interface tarifaIndividualGITransp {
  service_fee_list: Array<feeIndividual>;
  tarifaFees: feeIndividual;
  localTarifaParent: serviceObject;
  setLocalTarifaParent: React.Dispatch<React.SetStateAction<serviceObject>>;
  isTaxesIncluded: boolean;
  serviceCurrentType: number;
  indiceTarifa: number;
  vigenciasList: validityDates[];
  dataServices: dataServicesI;
  setDataServices: React.Dispatch<React.SetStateAction<dataServicesI>>;
  tarifa: serviceObject;
}

export default function TarifaTransporte({
  service_fee_list,
  tarifaFees,
  localTarifaParent,
  setLocalTarifaParent,
  isTaxesIncluded,
  serviceCurrentType,
  indiceTarifa,
  vigenciasList,
  dataServices,
  setDataServices,
  tarifa,
}: tarifaIndividualGITransp) {
  const { catalogsData } = useCatalogs();
  const {
    registerData,
    setRegisterData,
    contratoContext,
    setContratoContext,
    idCurrentProvider,
    registerGeneral,
    setTitleAlert,
    setContentAlert,
    setShowAlert,
    setShowLoader,
    dataServicesContext,
  } = useContext(AppContext);
  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  const [localTarifa, setLocalTarifa] = useState<feeIndividual>(tarifaFees);
  const [editionMode, setEditionMode] = useState(true);
  useEffect(() => {
    setCurrencyList(catalogsData?.currency_list);
  }, [catalogsData]);
  const deleteCurrentTarifa = () => {
    //console.log("BORRANDO TARIFA!");
    if (localTarifaParent.service_fee_list.length == 1) {
      alert("No puedes borrar más elementos");
      return;
    }
    //console.log("HERE");
    deleteFromServices();
    const newArrayTarifa = localTarifaParent.service_fee_list.filter(
      (currentTarifa, indes) => {
        return currentTarifa.fee_key !== localTarifa.fee_key;
      }
    );
    const listaVigencia = vigenciasList.filter((currentVigencia, index) => {
      return index !== indiceTarifa;
    });

    setLocalTarifaParent({
      ...localTarifaParent,
      service_fee_list: newArrayTarifa,
      fee_validity_list: listaVigencia,
    });
  };
  const deleteFromServices = async () => {
    if (idCurrentProvider !== 0) {
      ////PROCESO DE BORRAR DESDE SERVICIOS:
      setShowLoader(true);
      const currentArrayValidity = tarifa.fee_validity_list;
      const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
      //console.log("REACT BACK END POINT: ");
      //console.log(backEndPoint);

      const urlModified = backEndPoint + "/provider/update";
      ///----------------------------------------DATOS GENERALES----------------------------------/////
      //console.log(registerGeneral.registration_type_identifier);
      const modifiedGeneral: GeneralModifiedI = {
        register_identifier: idCurrentProvider,
        name: registerGeneral.name,
        registration_type_identifier:
          registerGeneral.registration_type_identifier,
        lender_type_identifier:
          registerGeneral.lender_type_identifier == 0
            ? 1
            : registerGeneral.lender_type_identifier,
        provider_type_identifier: registerGeneral.provider_type_identifier
          ? registerGeneral.provider_type_identifier
          : 1,
        business_name: registerGeneral.business_name,
        street: registerGeneral.calles,
        number: registerGeneral.telefono,
        neighborhood_identifier: registerGeneral.neighborhood_identifier,
        city_identifier: registerGeneral.city_identifier
          ? registerGeneral.city_identifier
          : 1,
        country_identifier: registerGeneral.country_identifier,
        zip_code: registerGeneral.zip_code,
        important_notes: registerGeneral.important_notes,
        description: registerGeneral.lead,
        id: registerGeneral.id,
        key: registerGeneral.claveGeneral,
        rfc: registerGeneral.rfc,
        category:
          registerGeneral.categoriaGeneral == 0
            ? null
            : registerGeneral.categoriaGeneral,
        last_purchase: registerGeneral.ultimaCompra,
        feedback: registerGeneral.feedback,
        lead: registerGeneral.lead,
        commission_scheme: registerGeneral.comisionEsquema,
        web: registerGeneral.web,
        comments: registerGeneral.comments,
        category_identifier: registerGeneral.category_identifier,
      };
      ////------------------------------------FIN DATOS GENERALES-------------------------------------//////
      const arrayVigenciaEliminar: ValidityDelete[] = currentArrayValidity
        .filter((validity, index) => {
          return index == indiceTarifa;
        })
        .map((validity, index) => {
          const validityToDelete: ValidityDelete = {
            validity_identifier: validity.validity_identifier,
            status_identifier: 2,
            validity_startdate: validity.validity_startdate,
            validity_enddate: validity.validity_enddate,
          };
          return validityToDelete;
        });
      const newArrayTarifa = localTarifaParent.service_fee_list
        .filter((currentTarifa, indes) => {
          return currentTarifa.fee_key == localTarifa.fee_key;
        })
        .map((currentTarifa, index) => {
          return {};
        });
      ///////////////////////////FIN DE ARREGLO VIGENCIA EN 2

      const serviciosModified: ServiceFeeDelete[] =
        dataServicesContext.services_fees
          .filter((currentService, index) => {
            return (
              currentService.service_identifier == tarifa.service_identifier
            );
          })
          .map((servicio, index) => {
            const currentListFee: FeeDelete[] = servicio.service_fee_list
              .filter((currentTarifa, index) => {
                return currentTarifa.fee_key == localTarifa.fee_key;
              })
              .map((fee, index) => {
                const serviceFee: FeeDelete = {
                  fee_identifier: fee.fee_identifier,
                  fee_currency_identifier: fee.fee_currency_identifier,
                  fee_iva: fee.fee_iva,
                  fee_tax: fee.fee_tax,
                  fee: fee.fee,
                  fee_total: fee.fee_total,
                  fee_SGL: fee.fee_SGL,
                  fee_DBL: fee.fee_DBL,
                  fee_TPL: fee.fee_TPL,
                  fee_CPL: fee.fee_CPL,
                  fee_FP: fee.fee_FP,
                  fee_TSGL: fee.fee_TSGL,
                  fee_TDBL: fee.fee_TDBL,
                  fee_TTPL: fee.fee_TTPL,
                  fee_TCPL: fee.fee_TCPL,
                  fee_TFP: fee.fee_TFP,
                  fee_BB: fee.fee_BB,
                  fee_BBT: fee.fee_BBT,
                  fee_MAID: fee.fee_MAID,
                  fee_TMAID: fee.fee_TMAID,
                  fee_capacity: fee.fee_capacity,
                  status_identifier: 2,
                };
                return serviceFee;
              });

            const currentService: ServiceFeeDelete = {
              fee_type_identifier: servicio.fee_type_identifier,
              service_identifier: servicio.service_identifier,
              service_type_identifier: servicio.service_type_identifier,
              service_mealplan_identifier: servicio.service_mealplan_identifier,
              service_category_identifier: servicio.service_category_identifier,
              service_category: servicio.service_category,
              service_name: servicio.service_name,
              service_comments: servicio.service_comments,
              service_key: servicio.service_key,
              service_fees_list: currentListFee,
              service_validity_list: arrayVigenciaEliminar,
              service_blackout_list: [],
              status_identifier: 1,
              service_minimum_stay_list: [],
              operating_days: [],
            };

            return currentService;
          });
      const modifiedObject: DeleteService = {
        user_identifier: 1,
        general: modifiedGeneral,
        services_fees: serviciosModified,
      };
      try {
        const response = await fetch(urlModified, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(modifiedObject),
        });
        if (response.ok) {
          ////RESPUESTA CORRECTA
          //console.log(response);
          //console.log("bien");

          setTitleAlert("Modificación correcta correcto");
          setContentAlert(
            "Tus datos fueron modificados correctamente, puedes consultarlos en el catalogo."
          );

          setShowAlert(true);
          setShowLoader(false);
        } else {
          ///RESPUESTA INCORRECTA DE SERVICIOS
          // setShowLoader(false);
          setTitleAlert("Error al BORRAR:");
          setContentAlert(
            "Tus datos no fueron borrados correctamente, por favor, verificalos."
          );
          setShowAlert(true);
          setShowLoader(false);
        }
      } catch (error) {
        ///ERROR AL REALIZAR PETICION
        alert("ERROR AL REALIZAR BORRADO");
        setShowLoader(false);
      }
    }
  };
  const changeVigenciaFinal = (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log("Changing tarifa ", indiceTarifa);
    const listaVigencia = vigenciasList.map((currentVigencia, index) => {
      return index !== index
        ? currentVigencia
        : {
            ...currentVigencia,
            validity_startdate: e.target.value,
          };
    });
    const newServicesList = dataServices?.services_fees.map(
      (currentTarifa, index) => {
        return currentTarifa.identifier_list != tarifa.identifier_list
          ? currentTarifa
          : {
              ...currentTarifa,
              fee_validity_list: currentTarifa.fee_validity_list.map(
                (validity, index) => {
                  return index !== indiceTarifa
                    ? validity
                    : {
                        ...validity,
                        validity_enddate: e.target.value,
                      };
                }
              ),
            };
      }
    );
    setDataServices({
      ...dataServices,
      services_fees: newServicesList,
    });
    const getCurrentVigencias = newServicesList.find((currentTarifa, index) => {
      return currentTarifa.identifier_list == tarifa.identifier_list;
    })?.fee_validity_list;
    // const getCurrentTarifa = newServicesList.find((currentTarifa, index)=>{
    //   return currentTarifa.service_identifier == tarifa.service_identifier
    // })
    // //console.log("----------------------------Objeto que se regresa----")
    // //console.log(getCurrentTarifa);

    ////////////////////><<<>>>>>>>>>
    //   //console.log("Vigencias actuales--------------------: ");
    //   //console.log(getCurrentVigencias);
    //   //console.log("newServicesList");
    //   //console.log(newServicesList) ;
    // return;
    // setLocalTarifaParent({
    //   ...localTarifaParent,
    //   fee_validity_list: [],

    // });
    // return;

    // if(getCurrentTarifa){
    //   setLocalTarifaParent({

    //   });
    // }
    /////////////////////////><<<<>>>>>>>>

    const getCurrentTarifa = newServicesList.find((currentTarifa, index) => {
      return currentTarifa.identifier_list == tarifa.identifier_list;
    });
    if (getCurrentTarifa) {
      setLocalTarifaParent(getCurrentTarifa);
    }
  };

  const changeVigenciaInicial = (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log("Changing tarifa ", indiceTarifa);
    const listaVigencia = vigenciasList.map((currentVigencia, index) => {
      return index !== index
        ? currentVigencia
        : {
            ...currentVigencia,
            validity_startdate: e.target.value,
          };
    });

    const newServicesList = dataServices?.services_fees.map(
      (currentTarifa, index) => {
        return currentTarifa.identifier_list != tarifa.identifier_list
          ? currentTarifa
          : {
              ...currentTarifa,
              fee_validity_list: currentTarifa.fee_validity_list.map(
                (validity, index) => {
                  return index !== indiceTarifa
                    ? validity
                    : {
                        ...validity,
                        validity_startdate: e.target.value,
                      };
                }
              ),
            };
      }
    );

    //console.log("ACTUALIZACION DEL ARREGLO!!!!!!: ");
    //console.log(newServicesList);
    setDataServices({
      ...dataServices,
      services_fees: newServicesList,
    });
    //console.log("INDICE DE TARIFA: ");
    //console.log(indiceTarifa);
    const getCurrentTarifa = newServicesList.find((currentTarifa, index) => {
      return currentTarifa.identifier_list == tarifa.identifier_list;
    });
    if (getCurrentTarifa) {
      setLocalTarifaParent(getCurrentTarifa);
    }
  };

  useEffect(() => {
    let impuesto = isNaN(localTarifa.fee_tax) ? 0 : localTarifa.fee_tax;
    const costoTotalTarifa =
      localTarifa.fee +
      localTarifa.fee * (impuesto / 100) +
      localTarifa.fee * (localTarifa.fee_iva / 100)
        ? localTarifa.fee +
          localTarifa.fee * (impuesto / 100) +
          localTarifa.fee * (localTarifa.fee_iva / 100)
        : 0.0;
    //console.log("COSTO TOTAL TARIFA: ");
    //console.log(costoTotalTarifa);
    //console.log("LOCAL TARIFA LUCE ASI");
    //console.log(localTarifa);

    const newArrayTarifa = localTarifaParent.service_fee_list.map(
      (currentTarifa, index) => {
        return currentTarifa.fee_key !== tarifaFees.fee_key
          ? currentTarifa
          : {
              ...localTarifa,
              fee_total: parseFloat(costoTotalTarifa.toFixed(2)),
            };
      }
    );
    const getVigencias = dataServices.services_fees.find((servicio, index) => {
      return servicio.identifier_list == tarifa.identifier_list;
    });
    //console.log("ASI QUEDAN TUS VIGENCIAS:::");
    //console.log(getVigencias?.fee_validity_list);

    setLocalTarifa({
      ...localTarifa,
      fee_total: costoTotalTarifa,
    });
    //console.log("TARIFA PARIENTE:::");
    //console.log(localTarifaParent);
    if (getVigencias?.fee_validity_list) {
      //console.log("ENTRANDO AQUI");
      setLocalTarifaParent({
        ...localTarifaParent,
        service_fee_list: newArrayTarifa,
      });
    }
  }, [
    localTarifa.fee,
    localTarifa.fee_tax,
    localTarifa.fee_iva,
    localTarifa.fee_capacity,
    localTarifa.fee_currency_identifier,
    localTarifa.vehicle,
  ]);
  useEffect(() => {}, []);
  return (
    <TarifaTransporteStyles>
      <div className="tarifa-container-dos">
        <div>
          <SelectListdefault
            style={{
              width: "95%",
              maxWidth: "95%",
            }}
            id="sl6"
            onChange={(e) => {
              //console.log("ONCHANGE");
              //console.log(parseInt(e.target.value));
              setLocalTarifa({
                ...localTarifa,
                fee_currency_identifier: parseInt(e.target.value),
              });
            }}
          >
            <option value="" disabled selected>
              Moneda
            </option>

            {currencyList?.length > 0
              ? currencyList.map((item) => {
                  return (
                    <option
                      key={item.identifier}
                      value={item.identifier}
                      selected={
                        item.identifier == localTarifa.fee_currency_identifier
                      }
                    >
                      {item.description}
                    </option>
                  );
                })
              : null}
          </SelectListdefault>
        </div>
        <div>
          <input
            type="text"
            placeholder="Capacidad"
            value={localTarifa.fee_capacity}
            className="input-style-data"
            onChange={(e) =>
              setLocalTarifa({
                ...localTarifa,
                fee_capacity: e.target.value,
              })
            }
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="Vehiculo"
            value={localTarifa.vehicle}
            className="input-style-data"
            onChange={(e) =>
              setLocalTarifa({
                ...localTarifa,
                vehicle: e.target.value,
              })
            }
          />
        </div>

        <div>
          {editionMode ? (
            <input
              type="number"
              inputMode="decimal"
              value={localTarifa.fee}
              className="input-style-data"
              onChange={(e) =>
                setLocalTarifa({
                  ...localTarifa,
                  fee: parseFloat(e.target.value),
                })
              }
            />
          ) : (
            <h3>{localTarifa.fee}</h3>
          )}
        </div>
        <div>
          {editionMode ? (
            <input
              type="number"
              inputMode="decimal"
              placeholder="Tarifa"
              value={localTarifa.fee_iva}
              className="input-style-data"
              onChange={(e) =>
                setLocalTarifa({
                  ...localTarifa,
                  fee_iva: parseFloat(e.target.value),
                })
              }
            />
          ) : (
            <h3>{localTarifa.fee_iva}</h3>
          )}
        </div>
        {serviceCurrentType != 18 && (
          <div>
            {editionMode && serviceCurrentType != 18 ? (
              <input
                type="number"
                inputMode="decimal"
                value={localTarifa.fee_tax}
                className="input-style-data"
                onChange={(e) =>
                  setLocalTarifa({
                    ...localTarifa,
                    fee_tax: parseFloat(e.target.value),
                  })
                }
              />
            ) : editionMode && serviceCurrentType == 18 ? (
              <input
                type="text"
                placeholder="Capacidad"
                value={localTarifa.fee_capacity}
                className="input-style-data"
                onChange={(e) =>
                  setLocalTarifa({
                    ...localTarifa,
                    fee_capacity: e.target.value,
                  })
                }
              />
            ) : !editionMode && serviceCurrentType != 18 ? (
              <h3 className="total-style">
                {isTaxesIncluded ? localTarifa.fee_tax : localTarifa.fee_tax}
              </h3>
            ) : !editionMode && serviceCurrentType == 18 ? (
              <h3 className="total-style">{localTarifa.fee_capacity}</h3>
            ) : (
              <h3 className="total-style">
                {isNaN(localTarifa.fee_tax) ? "" : localTarifa.fee_tax}
              </h3>
            )}
          </div>
        )}

        <div>
          <h3 className="total-style">
            {" "}
            {localTarifa.fee_total % 1 === 0
              ? localTarifa.fee_total.toFixed(0)
              : localTarifa.fee_total.toFixed(2)}
          </h3>
        </div>
        <div>
          <input
            type="date"
            // placeholder="Tarifa Inicial"

            value={vigenciasList[indiceTarifa]?.validity_startdate}
            className="input-style-data data-fechas"
            onChange={(e) => {
              // setLocalTarifa({
              //     ...localTarifa,
              //     fee_capacity: e.target.value,

              //   })
              changeVigenciaInicial(e);
            }}
          />
        </div>
        <div>
          <input
            type="date"
            placeholder="Tarifa Final"
            value={vigenciasList[indiceTarifa]?.validity_enddate}
            className="input-style-data data-fechas"
            onChange={(e) => {
              // setLocalTarifa({
              //     ...localTarifa,
              //     fee_capacity: e.target.value,

              //   })
              changeVigenciaFinal(e);
            }}
          />
        </div>

        <div>
          <img
            src={deletedatos}
            className="trash trash-tarifa"
            width="30px"
            onClick={deleteCurrentTarifa}
          />
        </div>
      </div>
    </TarifaTransporteStyles>
  );
}

const TarifaTransporteStyles = styled.div`
  .total-style {
    font-size: 1.1vw !important;
  }
  @media screen and (max-width: 900px) {
    .total-style {
      font-size: 1.85vw !important;
    }
    .data-fechas {
      font-size: 0.7vw;
      outline: none;
    }
  }

  .data-fechas {
    font-size: 0.85vw;
    outline: none;
  }
  .trash-tarifa {
    cursor: pointer;
  }
  .tarifa-container {
  }

  @media screen and (max-width: 900px) {
    .trash-tarifa {
      width: 20px;
    }
    .data-fechas {
      font-size: 1.1vw;
    }
  }
`;

const TarifaIndividualGStyles = styled.div``;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: none;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }

  max-height: 40.8px !important;
`;
